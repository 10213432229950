import React from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { Text, AspectRatio, Button, Stack, Heading, Box, StackProps } from '@chakra-ui/react'
import { getRoute } from 'utils/helpers'
import mixpanel from 'utils/mixpanel'
import { getPrice } from 'utils/payments'
import { useTiersQuery } from 'src/generated/graphql-frontend'
import { TrackNamesEnum } from 'src/constants'
import { TextTag } from './TextTag'
import { getColorModeValue } from 'utils/theme/helpers'

interface Props extends StackProps {
  tier: NonNullable<ReturnType<typeof useTiersQuery>['data']>['tiers'][0]
  shouldShowJoinNow?: boolean
  showMerchantControls?: boolean
  isFulLWidth?: boolean
  hasShadow?: boolean
  returnTo?: string
}

export const Tier: React.FC<Props> = ({
  tier,
  isFulLWidth,
  alignItems = 'center',
  hasShadow = true,
  shouldShowJoinNow = true,
  showMerchantControls,
  returnTo,
  ...props
}) => {
  const { t } = useTranslation('tier')
  const {
    Product: {
      description,
      name,
      Pictures,
      Price: { amount, currency },
    },
    isRecurringDonation,
    id,
  } = tier
  const picture = Pictures?.[0]
  console.log('Tier returnTo', returnTo)
  return (
    <Box
      flex="1"
      flexBasis="20rem"
      maxW={isFulLWidth ? undefined : '25rem'}
      border={`${hasShadow ? 2 : 1}px solid`}
      borderColor={hasShadow ? 'gray.250' : 'gray.300'}
      borderRadius="3xl"
      alignItems={alignItems}
      boxShadow={hasShadow ? '0px 16px 40px rgba(132, 132, 132, 0.14)' : undefined}
      textAlign="center"
      {...props}
    >
      <Box
        w="full"
        borderRadius="3xl"
        borderBottomLeftRadius="0"
        borderBottomRightRadius="0"
        {...(picture?.thumbnailSignedUrl
          ? { bgImage: picture?.thumbnailSignedUrl }
          : { bgColor: 'primary.500' })}
        bgSize="cover"
        bgPos="center"
        bgRepeat="no-repeat"
        position="relative"
      >
        <AspectRatio ratio={2.125}>
          <div />
        </AspectRatio>
        {isRecurringDonation && (
          <Heading
            position="absolute"
            left="0"
            top="50%"
            w="full"
            size="sm"
            transform="translateY(-50%)"
            color="white"
          >
            {t('monthlyMembership')}
          </Heading>
        )}
      </Box>
      <Stack p={{ base: '3.5', md: '6' }} spacing={{ base: '3.5', md: '6' }}>
        <Stack spacing="1">
          <Heading size="xl">{getPrice(amount, currency)}</Heading>
          <TextTag color="gray.700" opacity={0.6}>
            {t('perMonth')}
          </TextTag>
        </Stack>
        <Heading fontSize="lg">{name}</Heading>
        {shouldShowJoinNow && (
          <Stack pb={{ base: '4', md: '8' }} flex="none" alignItems="center">
            <Button
              as={Link}
              href={getRoute('tier', id, returnTo)}
              w="48"
              maxW="full"
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              onClick={() => {
                mixpanel.track(TrackNamesEnum.JoinMembership, {
                  tier,
                })
              }}
            >
              {t('joinNow')}
            </Button>
          </Stack>
        )}
        <Text
          textAlign="left"
          fontSize="sm"
          __css={{
            color: getColorModeValue('gray.700', 'gray.300'),
          }}
          opacity={0.6}
        >
          {description}
        </Text>
        {showMerchantControls && (
          <Stack direction="column" alignItems="center">
            <Button
              fontWeight="medium"
              variant="link"
              size="sm"
              textDecoration="underline"
              as={Link}
              href={getRoute('tier', id)}
            >
              {t('editTier')}
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
