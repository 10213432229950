import {
  ChakraProps,
  chakra,
  useBreakpointValue,
  useColorModeValue,
  useToken,
} from '@chakra-ui/react'
import Link from 'next/link'
import { rgba } from 'polished'
import React, { ReactNode } from 'react'
import { useIsReducedMotion } from 'utils/hooks'
import { getGradient } from 'utils/theme/helpers'

interface CardButtonProps extends ChakraProps {
  href?: string
  children: ReactNode
}

const CardButton: React.FC<CardButtonProps> = ({
  href,
  padding,
  p,
  px,
  py,
  children,
  ...props
}) => {
  const ref = React.createRef<HTMLDivElement>()
  const isReducedMotion = useIsReducedMotion()
  const bg = useColorModeValue('white', 'gray.700')
  const hoverBoxShadowColor = rgba(useToken('colors', 'primary.300'), 0.4)
  const isMobile = useBreakpointValue({ base: true, md: false })

  React.useEffect(() => {
    if (isReducedMotion) {
      return
    }

    const getRefParent = () => ref.current?.parentElement
    const refParent = getRefParent()
    const getShadowRef = () => refParent?.querySelector('.card-button-shadow') as HTMLDivElement
    const getCardContentRef = () =>
      refParent?.querySelector('.card-button-content') as HTMLDivElement
    const isMouseDown = { current: false }
    const isMouseOver = { current: false }
    const pos = {
      mouseX: 0,
      mouseY: 0,
      rotateX: 0,
      rotateY: 0,
      shadowRotateX: 0,
      shadowRotateY: 0,
    }
    const handleMouseMove = (e: MouseEvent) => {
      if (ref.current && !isMouseDown.current) {
        const { clientX, clientY } = e
        const { width, height, x, y } = ref.current.getBoundingClientRect()
        pos.mouseX = clientX - x
        pos.mouseY = clientY - y
        pos.rotateX = (pos.mouseY / height - 0.5) * (width / 100)
        pos.rotateY = (pos.mouseX / width - 0.5) * (height / 100)
        pos.shadowRotateX = pos.rotateX * -0.5
        pos.shadowRotateY = pos.rotateY * 0.5
        getCardContentRef().style.transform = `perspective(500px) rotateX(${pos.rotateX * -1}deg) rotateY(${pos.rotateY}deg) scale(1.01)`
        getShadowRef().style.transform = `perspective(500px) rotateX(${pos.shadowRotateX}deg) rotateY(${pos.shadowRotateY}deg) scale(1.011)`
      }
    }
    const handleMouseLeave = () => {
      if (ref.current) {
        getCardContentRef().style.transform =
          'perspective(500px) rotateX(0deg) rotateY(0deg) scale(1)'
        getShadowRef().style.transform = 'rotateX(0deg) rotateY(0deg) scale(0.97)'
        isMouseOver.current = false
        isMouseDown.current = false
      }
    }
    const handleMouseDown = () => {
      if (ref.current) {
        getCardContentRef().style.transform = `perspective(500px) rotateX(${pos.rotateX * -1}deg) rotateY(${pos.rotateY}deg) scale(1)`
        getShadowRef().style.transform = `perspective(500px) rotateX(${pos.shadowRotateX * 1.1}deg) rotateY(${pos.shadowRotateY * 1.1}deg) scale(1.012)`
        isMouseDown.current = true
      }
    }
    const handleMouseUp = () => {
      if (ref.current) {
        getCardContentRef().style.transform =
          'perspective(500px) rotateX(0deg) rotateY(0deg) scale(1)'
        getShadowRef().style.transform = 'rotateX(0deg) rotateY(0deg) scale(0.97)'
        isMouseDown.current = false
      }
      isMouseDown.current = false
    }
    if (refParent) {
      if (isMobile) {
        refParent.addEventListener('touchstart', handleMouseDown)
        document.addEventListener('touchend', handleMouseUp)
      } else {
        refParent.addEventListener('mousemove', handleMouseMove)
        refParent.addEventListener('mousedown', handleMouseDown)
        document.addEventListener('mouseup', handleMouseUp)
        refParent.addEventListener('mouseleave', handleMouseLeave)
      }
    }
    return () => {
      if (refParent) {
        console.log('adding events')
        if (isMobile) {
          refParent.removeEventListener('touchstart', handleMouseDown)
          document.removeEventListener('touchend', handleMouseUp)
        } else {
          refParent.removeEventListener('mousemove', handleMouseMove)
          refParent.removeEventListener('mousedown', handleMouseDown)
          document.removeEventListener('mouseup', handleMouseUp)
          refParent.removeEventListener('mouseleave', handleMouseLeave)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, isMobile, isReducedMotion])

  return (
    <chakra.div
      as={href ? Link : undefined}
      href={href}
      position="relative"
      borderRadius={{ base: '4xl', md: '5xl' }}
      cursor="pointer"
      userSelect="none"
      draggable="false"
      // transformOrigin="36% 12.5%"
      _hover={{
        '> div:nth-of-type(1)': {
          opacity: 0.8,
          // transform: 'translateX(2px) scale(1.02)',
          boxShadow: '0px 18px 38px ' + hoverBoxShadowColor,
        },
      }}
      _active={{
        '> div:nth-of-type(1)': {
          boxShadow: '4px 20px 36px 5px ' + hoverBoxShadowColor,
        },
      }}
      {...props}
    >
      {/* When hover is on parent using css, display shadow under the parent element.
       */}
      {/* //scale(min) = 1 + (translateZ * -1) / perspective; */}
      <chakra.div
        position="absolute"
        borderRadius="inherit"
        className="card-button-shadow"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex={0}
        bg={getGradient(
          rgba(useToken('colors', 'primary.500'), 0.9),
          rgba(useToken('colors', 'red.400'), 0.8)
        )}
        transform="scale(0.97)"
        transition="all 0.2s ease-out, transform 0.13s ease-out"
        boxShadow="0px 16px 40px rgba(132, 132, 132, 0.14)"
      />
      <chakra.div
        ref={ref}
        as="div"
        className="card-button-content"
        minH="full"
        bg={bg}
        overflow="hidden"
        borderRadius="inherit"
        transform="translateZ(1px)"
        transition="all 0.2s ease-out, transform 0.13s ease-out"
        zIndex={1}
        {...{ padding, p, px, py }}
      >
        {children}
      </chakra.div>
    </chakra.div>
  )
}

export default CardButton
