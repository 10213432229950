import React from 'react'
import { Control, Controller } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import { Box } from '@chakra-ui/react'
import { countriesOptions } from 'utils/helpers'
import { ReactSelect, ReactSelectProps } from './ReactSelect'

interface Props extends ReactSelectProps {
  name: string
  control: Control<any>
  requiredMessage?: string
}

export const CountrySelect: React.FC<Props> = ({
  control,
  name,
  requiredMessage: requiredMessageProp,
  errorMessage,
  isRequired,
  isDisabled,
  ...rest
}) => {
  const { t } = useTranslation('username')
  const requiredMessage = t('Cart.countryIsRequired')
  return (
    <Controller
      name={name}
      control={control}
      rules={isRequired ? { required: requiredMessage } : undefined}
      render={({ field, fieldState: { error } }) => (
        <Box>
          <ReactSelect
            options={countriesOptions}
            errorMessage={errorMessage ?? error?.message}
            {...field}
            {...rest}
          />
        </Box>
      )}
    />
  )
}
