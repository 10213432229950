import { signIn, useSession } from 'next-auth/react'
import React from 'react'
import { useSaveDeviceMutation } from 'src/generated/graphql-frontend'
import { getBrowserInfo } from 'utils/browserInfo'

export const DeviceInfo = () => {
  const { status } = useSession()
  const [saveDevice] = useSaveDeviceMutation({
    errorPolicy: 'ignore',
    onCompleted: () => {
      if (status === 'unauthenticated') {
        void signIn('email', { redirect: false })
      }
    },
  })
  React.useEffect(() => {
    const browserInfo = getBrowserInfo()
    if (browserInfo) {
      void saveDevice({
        variables: {
          inputData: browserInfo,
        },
      })
    }
  }, [])
  return null
}
