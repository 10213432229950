import { Text, Tooltip, UnorderedList, ListItem, Flex } from '@chakra-ui/react'
import { ProductVariantQuantity } from 'src/generated/graphql-frontend'

const getInventoryCellColor = ({
  trackInventory,
  inventoryQuantity,
}: {
  trackInventory?: boolean | null
  inventoryQuantity?: number | null
}) => {
  if (trackInventory) {
    if (!inventoryQuantity || inventoryQuantity < 10) {
      return 'red.500'
    } else if (inventoryQuantity < 20) {
      return 'yellow.300'
    }
  }
  return 'teal.200'
}

interface Props {
  trackInventory: boolean | null | undefined
  productVariantsQuantities: ProductVariantQuantity[]
}

export const QuantityWithTooltip: React.FC<Props> = ({
  productVariantsQuantities,
  trackInventory,
}) => {
  const sumQuantity = productVariantsQuantities?.reduce(
    (acc, { quantity }) => acc + (quantity || 0),
    0
  )
  return (
    <Tooltip
      label={
        <UnorderedList textAlign="left">
          {productVariantsQuantities.map(({ id, name, quantity }) => (
            <ListItem key={id}>
              <Flex justifyContent="space-between">
                <Text fontSize="sm">{name}:&nbsp;</Text>
                <Text fontSize="sm" fontWeight="medium">
                  {quantity ?? '-'}
                </Text>
              </Flex>
            </ListItem>
          ))}
        </UnorderedList>
      }
      aria-label="Product Variants Quantity"
      placement="left"
      hasArrow
    >
      <Text
        color={getInventoryCellColor({ inventoryQuantity: sumQuantity, trackInventory })}
        display={{ base: 'none', xl: 'block' }}
      >
        {trackInventory ? sumQuantity : '-'}
      </Text>
    </Tooltip>
  )
}
