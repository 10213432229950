import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Button, Stack, Text } from '@chakra-ui/react'
import isEmail from 'validator/lib/isEmail'
import { Controller, useFormContext } from 'react-hook-form'
import { MdOutlineEmail } from 'react-icons/md'
import { getRoute } from 'utils/helpers'
import mixpanel from 'utils/mixpanel'
import { CurrencyType, getPrice } from 'utils/payments'
import colors from 'utils/theme/foundations/colors'
import { TrackNamesEnum } from 'src/constants'
import { PaymentTypeEnum, ProductInput } from 'src/generated/graphql-frontend'
import Link from 'components/Link'
import { InputWrapper } from 'components/InputWrapper'
import { PaymentMethodOptions } from 'components/PaymentMethodOptions'
import { EmbedProps, WidgetEmbedForm } from 'components/Widget/constants'
import { StrikeThrough } from 'components/StrikeThrough'
import { RecurringStripeCheckout } from 'components/RecurringStripeCheckout'
import {
  PaymentSubmitResult,
  PersonalInfo,
  SetSubmitHandlerRef,
} from 'components/PaymentMethodOptions/types'
import { Step, StepProps } from '..'

interface Props extends Omit<EmbedProps, 'id'>, StepProps {
  emailLabel?: string
  isEmailRequired?: boolean
  productInput?: ProductInput
  onBeforeProcessPayment?(): void
  onObtainedPersonalInfo?(personalInfo: PersonalInfo): Promise<void>
  setPaymentId?(id: string): void
}

export const PaymentMethod: React.FC<Props> = ({
  nextStep,
  onPaid,
  emailLabel,
  profile,
  setPaymentId,
  isEmailRequired,
  productInput: productInputProp,
  onBeforeProcessPayment,
  onObtainedPersonalInfo,
  ...props
}) => {
  const { t } = useTranslation('common')
  const { id: profileId, currency, productSettings } = profile
  const submitHandlerRef = React.useRef<SetSubmitHandlerRef>(null)
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    register,
  } = useFormContext<WidgetEmbedForm>()
  const amountCents = getValues('amountCents')!
  const paymentType = watch('paymentType')

  const processPayment = React.useCallback(
    (paymentResult?: PaymentSubmitResult) => {
      if (paymentResult?.isSuccess) {
        onPaid?.(amountCents, getPrice(amountCents, currency), paymentResult.paymentId)
        if (paymentResult.paymentId) {
          setPaymentId?.(paymentResult.paymentId)
        }
        nextStep?.(true)
      } else {
        console.error('Payment error. Try again.')
      }
    },
    [amountCents, currency, nextStep, onPaid, setPaymentId]
  )

  const submitHandler = React.useCallback(async () => {
    if (
      paymentType &&
      [
        PaymentTypeEnum.Card,
        PaymentTypeEnum.ApplePay,
        PaymentTypeEnum.GooglePay,
        PaymentTypeEnum.BankTransfer,
      ].includes(paymentType)
    ) {
      if (onBeforeProcessPayment) {
        onBeforeProcessPayment()
      }

      const res = await submitHandlerRef?.current?.()
      processPayment(res)
    }
  }, [paymentType, submitHandlerRef, onBeforeProcessPayment, processPayment])
  React.useEffect(() => {
    mixpanel.track(TrackNamesEnum.PaymentTypeChanged, {
      paymentStep: 3,
      profile,
      paymentType,
      amountInCents: amountCents,
    })
  }, [paymentType])
  React.useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        mixpanel.track(TrackNamesEnum.Payment, {
          paymentStep: 3,
          profile,
          paymentType,
          amountInCents: amountCents,
          mode: 'form submission',
        })
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])
  const productInput = React.useMemo(
    () => productInputProp ?? { merchantProfileId: profileId },
    [profileId]
  )
  return (
    <Step
      {...props}
      width="full"
      alignSelf="stretch"
      as="form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Stack width="full" spacing="4">
        <InputWrapper
          {...register('email', {
            validate: (email) => {
              const checkEvenIfEmpty = !isEmailRequired && !email?.trim()
              return checkEvenIfEmpty || isEmail(email?.trim() || '')
            },
          })}
          variant="outline"
          placeholder="Email"
          label={emailLabel}
          leftIcon={<MdOutlineEmail size={20} />}
          errorMessage={errors.email && t('emailRequiredError')}
          size="sm"
          borderColor={productSettings?.widgetColorScheme || undefined}
          color={productSettings?.widgetColorScheme || undefined}
          _focus={
            productSettings?.widgetColorScheme
              ? {
                  borderColor: colors[productSettings.widgetColorScheme],
                  boxShadow: `0 0 0 1px ${
                    (
                      colors[productSettings.widgetColorScheme.split('.')[0]] as Record<
                        string,
                        string
                      >
                    )[productSettings.widgetColorScheme.split('.')[1]]
                  }`,
                }
              : undefined
          }
          onBlur={() =>
            mixpanel.track(TrackNamesEnum.PaymentEmail, {
              paymentStep: 3,
              profile,
              paymentType,
              amountInCents: amountCents,
              email: getValues('email'),
            })
          }
        />
        <StrikeThrough>
          <Text fontSize="sm">{t('payWith')}</Text>
        </StrikeThrough>
        {getValues().isRecurringDonation ? (
          <RecurringStripeCheckout
            amount={amountCents}
            getEmail={() => getValues('email') as string}
            merchantId={profile.id}
            ref={submitHandlerRef}
          />
        ) : (
          <Controller
            control={control}
            name="paymentType"
            rules={{
              required: t('selectPaymentOptionError'),
            }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <PaymentMethodOptions
                ref={submitHandlerRef}
                errorMessage={error?.message}
                amountCents={amountCents}
                currency={currency as CurrencyType}
                productInput={productInput}
                bankConnection={productSettings.BankConnections?.[0]}
                colorScheme={productSettings.widgetColorScheme}
                size="sm"
                hasPaymentTitle
                getEmail={() => getValues('email') as string}
                isEmbed
                onGoNext={processPayment}
                onObtainedPersonalInfo={onObtainedPersonalInfo}
                {...field}
              />
            )}
          />
        )}
      </Stack>
      <Text textAlign="center" fontSize="0.7rem" opacity="0.6" w="full">
        By clicking checkout button you agree to our{' '}
        <Link
          href={getRoute('termsAndConditions')}
          textDecoration="underline"
          target="_blank"
          style={{ boxShadow: 'none' }}
        >
          terms and conditions
        </Link>
      </Text>
      <Button
        type="submit"
        alignSelf="stretch"
        size="lg"
        width="100%"
        isLoading={isSubmitting}
        colorScheme={productSettings?.widgetColorScheme || undefined}
        onClick={() =>
          mixpanel.track(TrackNamesEnum.Payment, {
            paymentStep: 3,
            profile,
            paymentType,
            amountInCents: amountCents,
            mode: 'button click',
          })
        }
      >
        {t(getValues().isRecurringDonation ? 'donateRecurringBtn' : 'donateBtn', {
          amount: getPrice(amountCents, currency),
        })}
      </Button>
    </Step>
  )
}
