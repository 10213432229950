import { chakra, Box } from '@chakra-ui/react'
import { getColorModeValue } from 'utils/theme/helpers'

export const Card = chakra(Box, {
  baseStyle: {
    p: { base: '5', md: '10' },
    borderRadius: { base: 'xl', md: '4xl' },
    bg: getColorModeValue('white', 'gray.700'),
    boxShadow: '0px 0px 40px rgba(132, 132, 132, 0.14)',
  },
})
