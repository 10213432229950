import { chakra, CSSWithMultiValues } from '@chakra-ui/react'

const strikeMargin = { base: '4', md: '8' }

const strikeThroughLine: CSSWithMultiValues = {
  content: '""',
  display: 'block',
  borderTopWidth: '1px',
  borderTopColor: 'inherit',
  flex: 1,
}

export const StrikeThrough = chakra('div', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    color: 'gray.300',
    _after: {
      ...strikeThroughLine,
      ml: strikeMargin,
    },
    _before: {
      ...strikeThroughLine,
      mr: strikeMargin,
    },
  },
})
