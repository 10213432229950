import React from 'react'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeElementChangeEvent } from '@stripe/stripe-js'
import {
  PaymentTypeEnum,
  useCompleteStripePaymentMutation,
  useCreatePaymentIntentMutation,
} from 'src/generated/graphql-frontend'
import { StripeCard } from 'components/StripeCard'
import { CheckoutMethodProps, SetSubmitHandlerRef } from '../types'
import { extractExtensionErrorMessage } from './helpers'

export const StripeCheckout = React.forwardRef<SetSubmitHandlerRef, CheckoutMethodProps>(
  ({ amountCents, productInput, size, getEmail, colorScheme, setPaymentError }, ref) => {
    const stripe = useStripe()
    const elements = useElements()
    const [createPaymentIntent, paymentIntentMutation] = useCreatePaymentIntentMutation()
    const [completeStripePayment] = useCompleteStripePaymentMutation({
      onError(error) {
        console.log(error)
      },
    })
    const { data, called, loading } = paymentIntentMutation
    React.useImperativeHandle(ref, () => async () => {
      if (!stripe || !elements) {
        return { isSuccess: false }
      }
      const card = elements?.getElement('card')
      if (!card) {
        setPaymentError?.('Card is not specified')
        return { isSuccess: false }
      }
      const { data } = await createPaymentIntent({
        variables: {
          amount: amountCents,
          type: PaymentTypeEnum.Card,
          productInput,
        },
      })
      const clientSecret = data?.createPaymentIntent.clientSecret
      if (!clientSecret) {
        return { isSuccess: false }
      }
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
          billing_details: {
            email: getEmail(),
          },
        },
      })
      try {
        if (stripeError) {
          throw new Error(stripeError.message)
        } else if (paymentIntent?.status === 'requires_capture') {
          const { data } = await completeStripePayment({
            variables: { stripePaymentId: paymentIntent.id, email: getEmail() },
          })
          if (
            data?.completeStripePayment?.__typename === 'PaymentResult' &&
            data.completeStripePayment.success
          ) {
            return {
              paymentId: paymentIntent.id,
              isSuccess: true,
            }
          } else if (data?.completeStripePayment?.__typename === 'OrderPaymentIntentError') {
            const error = extractExtensionErrorMessage(data.completeStripePayment)
            setPaymentError?.(error)
            return {
              isSuccess: false,
            }
          }
        }
      } catch (error) {
        setPaymentError?.((error as StripeElementChangeEvent['error'])?.message)
      }
      return {
        isSuccess: false,
      }
    })
    return (
      <StripeCard
        size={size}
        setCardError={setPaymentError}
        colorScheme={colorScheme}
        isLoading={(loading && !called && !data) || !stripe || !elements}
      />
    )
  }
)

StripeCheckout.displayName = 'StripeCheckout'
