import React from 'react'
import { forwardRef, Text } from '@chakra-ui/react'
import { InputWrapper, InputWrapperProps } from './InputWrapper'
import { amountToCents, Currencies, getPrice } from 'utils/payments'

// TODO: Take a look at these patterns, add max donation amount
export const amountPattern = /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d{1,2})?$/
export const amountPatternNoDecimal = /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.)?$/

interface AmountInputProps extends Omit<InputWrapperProps, 'value' | 'onChange'> {
  value?: number | null
  currencyName: string
  currencyColor?: string
  showZero?: boolean
  shouldFocusOnZero?: boolean
  onChange(newValue?: number | null): void
}

export const AmountInput = forwardRef<AmountInputProps, 'input'>(
  ({ currencyName, color, value, shouldFocusOnZero, showZero = true, onChange, ...props }, ref) => {
    const [hasTempDecimal, setHasTempDecimal] = React.useState(false)
    React.useEffect(() => {
      setHasTempDecimal(false)
    }, [value])
    return (
      <InputWrapper
        ref={ref}
        inputMode="decimal"
        autoFocus={shouldFocusOnZero && value === 0}
        value={
          typeof value === 'number' && !Number.isNaN(value) && (showZero || value > 0)
            ? [getPrice(value, currencyName, false), hasTempDecimal && '.'].filter(Boolean).join('')
            : ''
        }
        color={color}
        rightIcon={
          <Text
            fontSize="lg"
            position="absolute"
            right="6"
            top="50%"
            transform="translateY(-50%)"
            pointerEvents="none"
            zIndex="1"
            color={color}
          >
            {(Currencies[currencyName] as typeof Currencies.eur).symbol}
          </Text>
        }
        onChange={({ target: { value: newValue } }) => {
          const fixedValue = newValue.replace(',', '.')
          if (
            fixedValue === '' ||
            amountPattern.test(fixedValue) ||
            amountPatternNoDecimal.test(fixedValue)
          ) {
            const parsedValue = parseFloat(fixedValue)
            setHasTempDecimal(fixedValue.endsWith('.'))
            if (parsedValue !== value) {
              onChange(
                fixedValue === '' || Number.isNaN(parsedValue)
                  ? null
                  : Math.min(amountToCents(parsedValue), (10e6 - 1) * 100)
              )
            }
          }
        }}
        autoComplete="off"
        {...props}
      />
    )
  }
)
