import React from 'react'
import Image from 'next/legacy/image'
import { Box, BoxProps, Heading, VStack } from '@chakra-ui/react'
import { Description } from 'components/Description'

interface Props {
  imageAlt?: string
  imageUrl: string
  imageWidth: BoxProps['width']
  imageHeight: BoxProps['height']
  title: React.ReactNode
  description?: React.ReactNode
  ctaButton: React.ReactNode
}

export const EmptyTableContent: React.VFC<Props> = ({
  ctaButton,
  imageAlt = '',
  imageHeight,
  imageWidth,
  imageUrl,
  title,
  description,
}) => (
  <>
    <Box position="relative" width={imageWidth} height={imageHeight}>
      <Image src={imageUrl} objectFit="cover" layout="fill" alt={imageAlt} />
    </Box>
    <VStack spacing={{ base: 2, md: 4 }} textAlign="center">
      <Heading size="md">{title}</Heading>
      <Description>{description}</Description>
    </VStack>
    {ctaButton}
  </>
)
