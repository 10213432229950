import React from 'react'
import { Stack, Grid, GridProps } from '@chakra-ui/react'
import { format } from 'date-fns/format'
import { CalendarButton, CalendarButtonProps } from './Calendar/CalendarButton'
import { GridHeaderText } from './Calendar'
import { TextTag } from 'components/TextTag'
import { useTimePickContext } from './context'
import { useAvailableTimeSlotsQuery } from 'src/generated/graphql-frontend'
import { useShiftedDaysInWeek } from './useShiftedDaysInWeek'
import useTranslation from 'next-translate/useTranslation'

const TimeButton: React.FC<CalendarButtonProps> = (props) => (
  <CalendarButton w="full" h="auto" py="1.5" px="0" borderRadius="lg" {...props} />
)

interface Props extends GridProps {
  date?: Date
  dateStartsWShift?: number
  productVariantId: string
  onPick?(startDate: Date, endDate: Date): void
}

export const Times: React.FC<Props> = ({
  date,
  dateStartsWShift,
  productVariantId,
  onPick,
  ...rest
}) => {
  const { t } = useTranslation('username')
  const { chosenDate, chosenTime } = useTimePickContext()
  const { t: dateT, getDayTranslation } = useShiftedDaysInWeek({
    dateStartsWShift,
  })

  const { data } = useAvailableTimeSlotsQuery({
    variables: {
      month: (chosenDate?.getMonth() ?? 0) + 1,
      year: chosenDate?.getFullYear() as number,
      productVariantId,
    },
    skip: !chosenDate,
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-first',
  })

  const timeSlots = React.useMemo(
    () =>
      data?.availableTimeSlots.filter((timeSlot) => {
        const startDate = new Date(timeSlot.startDate as string)
        return startDate.getDate() === chosenDate?.getDate() && startDate.getMonth() === chosenDate?.getMonth()
      }),
    [chosenDate, data]
  )

  const day = chosenDate?.getDay()
  return (
    <Stack spacing="2">
      {day !== undefined && (
        <div>
          <GridHeaderText>{t('Shop.availableTimeSlots')}</GridHeaderText>
          <TextTag color="gray.300">
            {getDayTranslation(day)}, {dateT(`month_${(chosenDate?.getMonth() ?? 0) + 1}_long`)}{' '}
            {chosenDate?.getFullYear()}
          </TextTag>
        </div>
      )}
      <Grid
        gap="2"
        pt="3.5"
        templateColumns={{
          base: 'repeat(auto-fill, minmax(5.5rem, 1fr))',
          md: 'repeat(auto-fill, minmax(4rem, 1fr))',
        }}
        autoRows={{ base: '3rem', md: '2.4rem' }}
        {...rest}
      >
        {timeSlots?.map((timeSlot, index) => (
          <TimeButton
            key={index.toString()}
            isAvailable
            isSelected={chosenTime && new Date(timeSlot.startDate as string).getTime() === chosenTime.getTime()}
            onClick={() => {
              onPick?.(new Date(timeSlot.startDate as string), new Date(timeSlot.endDate as string))
            }}
          >
            {format(timeSlot.startDate, 'HH:mm')}
          </TimeButton>
        ))}
      </Grid>
    </Stack>
  )
}
