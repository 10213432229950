import React from 'react'
import { AspectRatio, HTMLChakraProps, Box } from '@chakra-ui/react'
import { Label } from 'components/Label'
import { ErrorMessage } from 'components/ErrorMessage'

interface CoverPictureProps extends HTMLChakraProps<'div'> {
  pictureUrl: string
  label?: string
  errorMessage?: string
}

export const CoverPicture: React.FC<CoverPictureProps> = ({
  id,
  pictureUrl,
  children,
  label,
  errorMessage,
  ...props
}) => (
  <>
    {label && <Label>{label}</Label>}
    <Box
      bg={`url(${pictureUrl}) center`}
      bgSize="cover"
      left={0}
      top={0}
      width="full"
      maxH="52"
      minH="30"
      overflow="hidden"
      zIndex={0}
      {...props}
    >
      <AspectRatio maxH="inherit" ratio={2.5}>
        <div />
      </AspectRatio>
      {children}
    </Box>
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </>
)
