import React from 'react'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useToast } from '@chakra-ui/react'
import { UserRoleEnum } from 'src/generated/graphql-frontend'
import { getIsLoggedIn, getRoute } from 'utils/helpers'
import { LoadingScreen } from './LoadingScreen'

interface AuthProp {
  type: {
    auth: AuthInterface
  }
}

const LAST_PAYOUT_REDIRECT_LOCAL_STORAGE_KEY = 'firstPayoutRedirect'

export interface AuthInterface {
  roles?: UserRoleEnum[]
  isMerchant?: boolean
}

export const loggedInUsers: AuthInterface = {
  roles: [UserRoleEnum.User, UserRoleEnum.SuperAdmin],
}

interface AuthProps {
  children: React.ReactElement & AuthProp
}

// TODO: Move to components/Authentication and rename Authentication to Auth (requires more refactoring work, rn it's not a priority)
export const Auth: React.FC<AuthProps> = ({ children }): JSX.Element => {
  const router = useRouter()
  const { data, status } = useSession()
  const toaster = useToast()
  const [isAuthorized, setIsAuthorized] = React.useState(false)
  const Component = React.Children.toArray(children)[0] as React.ReactElement & AuthProp
  const { roles, isMerchant } = Component.type?.auth || {}

  React.useEffect(() => {
    const userRole = data?.user.role
    const isLoggedIn = getIsLoggedIn(data)
    const userHasCurrentProfile = !!data?.user.profile
    const checkLogin = async () => {
      if (status === 'loading') return
      if (!isLoggedIn) {
        setIsAuthorized(false)
        await signIn(undefined, { redirect: true, callbackUrl: location.pathname })
        return
      }
      if (roles && (!userRole || !roles.includes(userRole))) {
        setIsAuthorized(false)
        toaster({
          status: 'error',
          title: 'Unauthorized access',
          // eslint-disable-next-line quotes
          description: "You don't have privilege to view this page.",
        })
        await router.replace('/')
        return
      }
      if (isMerchant) {
        const lastRedirected = localStorage.getItem(LAST_PAYOUT_REDIRECT_LOCAL_STORAGE_KEY)
        if (!userHasCurrentProfile) {
          setIsAuthorized(false)
          await router.replace(getRoute('newProfile'))
          return
        } else if (
          !data.user.profile?.stripeChargesEnabled &&
          (!lastRedirected || Date.now() - Number(lastRedirected) > 1000 * 60 * 60 * 24) &&
          router.pathname !== getRoute('backoffice', 'settings')
        ) {
          localStorage.setItem(LAST_PAYOUT_REDIRECT_LOCAL_STORAGE_KEY, Date.now().toString())
          setIsAuthorized(false)
          await router.replace(`${getRoute('backoffice', 'settings')}?tab=payout`)
          return
        }
      }
      setIsAuthorized(true)
    }
    void checkLogin()
  }, [status, isMerchant, roles, data])

  if (isAuthorized) {
    return children as JSX.Element
  }

  return <LoadingScreen />
}
