import { keyframes } from '@chakra-ui/react'

export const pulse = keyframes`
  0% {opacity: 0.75}
  50% {opacity: 0.4}
  100% {opacity: 0.75}
`

export const getColorModeValue = (light: string, dark: string) => (props: Record<string, any>) =>
  (props as { config: { initialColorMode: string } }).config.initialColorMode === 'dark'
    ? dark
    : light

export const getFadeGradient = (cutoffPercentage: number) =>
  getColorModeValue(
    `linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff ${cutoffPercentage});`,
    `linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--chakra-colors-gray-800) ${cutoffPercentage});`
  )

export const getGradient = (color1: string, color2: string) =>
  `linear-gradient(90deg, ${color1}, ${color2});`
