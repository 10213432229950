import { Box, Icon, Stack, StackProps, Text, useColorModeValue } from '@chakra-ui/react'
import { MotionBox } from 'components/Motion'
import { TextTag } from 'components/TextTag'
import Link from 'next/link'
import React from 'react'
import { MdStar } from 'react-icons/md'
import { getRoute } from 'utils/helpers'

interface Props extends StackProps {
  name: string
  username: string
  label: string
  labelColor: string
  rating?: number
  pictureUrl: string
  target?: string
}

export const MerchantBadge: React.FC<Props> = ({
  name,
  username,
  label,
  labelColor,
  rating,
  pictureUrl,
  target,
  ...props
}) => {
  const color = useColorModeValue('primary.500', 'white')
  const bg = useColorModeValue('transparent', 'rgba(255, 255, 255, 0.12)')
  return (
    <MotionBox
      as={Link}
      href={getRoute('profile', username)}
      alignSelf="flex-start"
      pointerEvents="all"
      whileTap={{ scale: 0.95 }}
      target={target}
    >
      <Stack
        direction="row"
        alignItems="center"
        textAlign="left"
        spacing="4"
        bg={bg}
        borderRadius="lg"
        p="2"
        ml="-2"
        pr="8"
        {...props}
      >
        <Box
          w="10"
          h="10"
          bg={bg}
          borderRadius="lg"
          bgImage={`url(${pictureUrl})`}
          bgSize="cover"
          borderWidth="2px"
          borderColor="white"
        />
        <Box>
          <Text fontWeight="medium" color={color}>
            {name}
          </Text>
          <TextTag
            as="div"
            display="flex"
            alignItems="center"
            mt="0.5"
            color={labelColor}
            opacity={0.65}
          >
            <p>{label}</p>
            {rating && (
              <>
                <Icon as={MdStar} ml="1.5" mr="0.5" w="3" h="3" color="yellow.300" />
                <Text as="div" display="flex" color="yellow.300">
                  {rating}
                  <Text color={color}>/5</Text>
                </Text>
              </>
            )}
          </TextTag>
        </Box>
      </Stack>
    </MotionBox>
  )
}
