import React from 'react'
import Link from 'next/link'
import { useSession } from 'next-auth/react'
import {
  Grid,
  Stack,
  HStack,
  Box,
  Heading,
  Text,
  Button,
  IconButton,
  Icon,
  ColorProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { FiEdit, FiShare } from 'react-icons/fi'
import { getRoute } from 'utils/helpers'
import { useProfileQuery } from 'src/generated/graphql-frontend'
import Container from 'components/Container'
import { TextTag } from 'components/TextTag'
import useTranslation from 'next-translate/useTranslation'
import { SupportersText } from 'components/SupportersText'
import blank from 'public/images/blank.png'
import planticoLogo from 'public/images/plantico-logo-large.png'
import ShareProfileModal from './ShareProfileModal'
import { CoverPicture } from './CoverPicture'
import { ProfilePicture } from './ProfilePicture'

interface ProfileInfoCoverProps {
  isInModal?: boolean
  profile: NonNullable<NonNullable<ReturnType<typeof useProfileQuery>['data']>['profile']>
}

export const ProfileInfoCover: React.FC<ProfileInfoCoverProps> = ({
  isInModal = false,
  profile,
}) => {
  const { t } = useTranslation('merchant')
  const session = useSession()
  const color = useColorModeValue('gray.800', 'whiteAlpha.800')
  const { id, name, username, label, labelColorScheme, supportersCount } = profile
  const profileUrl = `${process.env.NEXT_PUBLIC_APP_URL}${getRoute('profile', username)}`
  return (
    <>
      <CoverPicture
        id={`cover-${username}`}
        pictureUrl={profile.coverPicture?.thumbnailSignedUrl || planticoLogo.src}
        borderRadius={isInModal ? 'inherit' : undefined}
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
      />
      <Container pb={0}>
        <Grid
          position="relative"
          gap="6"
          gridTemplateColumns={{ base: 'auto 1fr', md: 'auto 1fr auto' }}
          justifyItems="stretch"
          alignItems="end"
          mt={{ base: '-16', md: '-20' }}
          zIndex={1}
        >
          <ProfilePicture id={id} pictureUrl={profile.profilePicture?.thumbnailSignedUrl || blank.src} />
          <Box
            pt={{ base: '0', md: '20' }}
            pb="2"
            gridColumn={{ base: 'span 2', md: 'unset' }}
            order={{ base: 2, md: 'unset' }}
          >
            <Stack spacing={{ base: '2', md: '4' }}>
              <TextTag color={labelColorScheme as ColorProps['color']}>{label}</TextTag>
              <Heading size="lg" color={color}>
                {name && (
                  <Link href={getRoute('profile', username)}>
                    {name}
                  </Link>
                )}
              </Heading>
              <Text
                fontSize="sm"
                color="gray.400"
                display={{ base: 'block', md: 'none' }} // TODO - not on md??
              >
                <SupportersText count={supportersCount} />
              </Text>
            </Stack>
          </Box>
          <Box py="2" justifySelf="end" order={{ base: 1, md: 'unset' }}>
            {!isInModal && session.data?.user.profile?.id === id && (
              <>
                <HStack display={{ base: 'flex', md: 'none' }} spacing="2">
                  <IconButton
                    as={Link}
                    href={getRoute('backoffice', 'settings')}
                    aria-label={t('ProfileInfoCover.edit')}
                    width="10"
                    height="10"
                    size="sm"
                    icon={<Icon as={FiEdit} />}
                  />
                  <ShareProfileModal url={profileUrl}>
                    <IconButton
                      variant="outline"
                      aria-label={t('ProfileInfoCover.share')}
                      width="10"
                      height="10"
                      size="sm"
                      icon={<Icon as={FiShare} />}
                    />
                  </ShareProfileModal>
                </HStack>
                <HStack display={{ base: 'none', md: 'flex' }} spacing="4">
                  <Button as={Link} href={getRoute('backoffice', 'settings')} variant="solid" leftIcon={<Icon as={FiEdit} />}>
                    {t('ProfileInfoCover.editProfile')}
                  </Button>
                  <ShareProfileModal url={profileUrl}>
                    <Button variant="outline" leftIcon={<Icon as={FiShare} />}>
                      {t('ProfileInfoCover.shareProfile')}
                    </Button>
                  </ShareProfileModal>
                </HStack>
              </>
            )}
          </Box>
        </Grid>
      </Container>
    </>
  )
}
