import { initializeApp } from 'firebase/app'
import { isSupported } from 'firebase/messaging'
import { firebaseConfig, NotificationsPermissionState } from './constants'

const GET_NOTIFICATION_TOKEN_KEY = `fcm${process.env.NEXT_PUBLIC_APP_NAME.replaceAll(' ', '')}Token`

export const getFcmToken = () => window.localStorage?.getItem(GET_NOTIFICATION_TOKEN_KEY)
export const setFcmToken = (token: string) =>
  window.localStorage?.setItem(GET_NOTIFICATION_TOKEN_KEY, token)
export const firebaseApp = initializeApp(firebaseConfig)

export async function getNotificationsPermissionState() {
  if (!(await isSupported())) {
    return NotificationsPermissionState.Unsupported
  }
  if (Notification.permission === 'granted') {
    return NotificationsPermissionState.Granted
  }
  if (Notification.permission === 'denied') {
    return NotificationsPermissionState.Denied
  }
  return NotificationsPermissionState.Default
}
