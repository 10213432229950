import React from 'react'
import { Grid, Stack } from '@chakra-ui/react'

interface Props {
  isGrid?: boolean
  isCompact?: boolean
  children: React.ReactNode
}

export const ItemsWrapper: React.FC<Props> = ({ isGrid, isCompact, children }) =>
  isGrid ? (
    <Grid
      mt={isCompact ? '3' : '4'}
      templateColumns={{
        base: `repeat(auto-fill, minmax(${isCompact ? '4rem' : '5rem'}, 1fr))`,
        sm: `repeat(auto-fill, minmax(${isCompact ? '7.5rem' : '10rem'}, 1fr))`,
      }}
      gap={isCompact ? '3' : '4'}
    >
      {children}
    </Grid>
  ) : (
    <Stack mt={isCompact ? '3' : '4'} spacing={{ base: isCompact ? 2 : 4, md: isCompact ? 3 : 6 }}>
      {children}
    </Stack>
  )
