import React from 'react'
import { Grid } from '@chakra-ui/react'
import { useProductTypesQuery } from 'src/generated/graphql-frontend'
import { ProductType } from './ProductType'

export const ProductTypesList = () => {
  const { data, loading } = useProductTypesQuery()
  if (loading || !data) {
    return <div>Loading...</div>
  }
  return (
    <Grid gap="4" templateColumns="repeat(auto-fit, minmax(24rem, 1fr))">
      {data.productTypes.map(({ id, ...productType }) => (
        <ProductType key={id} id={id} {...productType} />
      ))}
    </Grid>
  )
}
