import React from 'react'
import { chakra, Flex, FlexProps, Icon } from '@chakra-ui/react'
import { IoIosArrowBack } from 'react-icons/io'
import { getFadeGradient } from 'utils/theme/helpers'

const CarouselNavButton = chakra('button', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    w: '48px',
    h: '48px',
    borderRadius: 'xl',
    color: 'primary.500',
    transition: 'background 0.25s ease-out',
    pointerEvents: 'all',
    bg: getFadeGradient(50),
    _disabled: {
      bg: 'gray.200',
      color: '#C6C6C6',
    },
  },
})

const iconSize = {
  base: '4',
  sm: '6',
  md: '4',
}

interface Props extends FlexProps {
  onPrev(): void
  onNext(): void
}

export const Navigation: React.FC<Props> = ({ onNext, onPrev, ...props }) => (
  <Flex justifyContent="space-between" alignItems="center" pointerEvents="none" {...props}>
    <CarouselNavButton onClick={onPrev}>
      <Icon as={IoIosArrowBack} width={iconSize} height={iconSize} opacity={0.65} />
    </CarouselNavButton>
    <CarouselNavButton transform="scaleX(-1)" onClick={onNext}>
      <Icon
        as={IoIosArrowBack}
        pointerEvents="all"
        width={iconSize}
        height={iconSize}
        opacity={0.65}
      />
    </CarouselNavButton>
  </Flex>
)
