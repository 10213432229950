import { chakra, Grid } from '@chakra-ui/react'

export const StatsGrid = chakra(Grid, {
  baseStyle: {
    gridTemplateColumns: {
      base: 'repeat(auto-fit, minmax(10rem, 1fr))',
      md: 'repeat(auto-fill, minmax(12rem, 1fr))',
    },
    gridGap: '4',
  },
})
