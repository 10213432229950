import React from 'react'
import useTranslation from 'next-translate/useTranslation'

const dayNamesShort = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
export const daysInWeek = Array.from({ length: 7 }, (_, i) => i).map((i) => i)

export const useShiftedDaysInWeek = ({
  dateStartsWShift: dateStartsWShiftProp,
}: {
  dateStartsWShift?: number
} = {}) => {
  const { t } = useTranslation('datetime')

  // TODO: Bug when you have shifted days, they will be saved in db as such because of the translation
  // const dateStartsWShift =
  //   typeof dateStartsWShiftProp === 'number'
  //     ? dateStartsWShiftProp
  //     : parseInt(t('weekStartShift')) || 0
  const dateStartsWShift = 0

  const shiftedDayIndexes = React.useMemo(
    () => daysInWeek.map((day) => (day + dateStartsWShift) % 7),
    [dateStartsWShift]
  )

  const shiftedDaysInWeek = React.useMemo(
    () => daysInWeek.map((day) => dayNamesShort[shiftedDayIndexes[day]]),
    [shiftedDayIndexes]
  )

  const getDayTranslation = React.useCallback((dayInWeek: number, useShort = false) => {
    const day = shiftedDayIndexes[dayInWeek]
    return t(`day_${day}_${useShort ? 'short' : 'long'}`)
  }, [shiftedDayIndexes, t])

  return {
    t,
    getDayTranslation,
    shiftedDaysInWeek,
    dateStartsWShift,
  }
}
