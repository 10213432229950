import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools'

// const subtleVariant: SystemStyleFunction = () => ({

// TODO: https://v2.chakra-ui.com/docs/components/tag/theming
const outlineVariant: SystemStyleFunction = (props) => {
  const color = mode('gray.800', 'white')(props)
  return {
    container: {
      bgColor: 'whiteAlpha.300',
      borderColor: 'whiteAlpha.500',
      borderWidth: 1,
      color,
    },
    _hover: {
      bgColor: 'whiteAlpha.400',
      borderColor: 'whiteAlpha.500',
      borderWidth: 1,
      color,
    },
  }
}

const Tag = {
  baseStyle: {
    container: {
      minH: undefined,
      fontWeight: 'normal',
      transition: 'all 250ms ease-out',
    },
  },
  sizes: {
    sm: {
      container: {
        py: '3.5',
        px: '6',
        fontSize: 'sm',
        borderRadius: 'lg',
      },
    },
    md: {
      container: {
        minH: undefined,
        py: 4,
        px: 7,
        fontSize: 'md',
        borderRadius: 'xl',
      },
    },
  },
  variants: {
    outline: outlineVariant,
  },
  defaultProps: {
    colorScheme: 'primary',
  },
}

export default Tag
