import { chakra, Divider } from '@chakra-ui/react'

export const Line = chakra(Divider, {
  baseStyle: {
    borderBottom: '1px solid',
    borderBottomColor: 'gray.250',
    gridColumn: '1/-1',
    my: { base: 2, xl: 4 },
  },
})
