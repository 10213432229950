import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Grid, Box, Flex } from '@chakra-ui/react'
import { getPageFromOffset } from 'utils/helpers'
import { DataLimit } from 'src/constants'
import { useProductsQuery } from 'src/generated/graphql-frontend'
import { ProductCard } from 'components/Shop'
import { Pagination } from 'components/Pagination'

interface Props {
  username: string
}

export const ShopTab: React.FC<Props> = ({ username }) => {
  const { t } = useTranslation('username')
  const router = useRouter()
  const page = router.query.page as string | undefined
  const { data, loading } = useProductsQuery({
    variables: {
      username,
      pagination: {
        from: getPageFromOffset({ limitPerPage: DataLimit.products, page }),
        length: DataLimit.products,
      },
    },
  })
  const products = data?.products.products
  return (
    <Box w="full">
      <Grid
        my="4"
        mb="20"
        w="full"
        templateColumns={{
          base: '1fr',
          sm: 'repeat(auto-fit, minmax(16rem, 1fr))',
          md: 'repeat(auto-fit, minmax(20rem, 1fr))',
          lg: 'repeat(auto-fit, minmax(24rem, 1fr))',
        }}
        gridColumnGap="4"
        gridRowGap={{ base: 4, sm: 8, md: 12 }}
      >
        {(products || Array.from({ length: 6 })).map((item, index) => (
          <ProductCard key={index} username={username} {...(item || {})} />
        ))}
        {!!products?.length &&
          Array.from({ length: Math.min(3, 3 - products.length) }).map((_, index) => (
            <div key={index} />
          ))}
      </Grid>
      {!loading && !!products?.length ? (
        <Flex justifyContent="center" w="full">
          <Pagination
            lastPage={Math.ceil(data!.products.pagination.total / DataLimit.products)}
            currentPage={Number.parseInt(page || '1')}
            onGeneratePageLink={(pageNumber) => `${router.asPath.split('?')[0]}?page=${pageNumber}`}
          />
        </Flex>
      ) : t('Shop.thisProductDoesntSellAnyProducts')}
    </Box>
  )
}
