import React from 'react'
import { useRouter } from 'next/router'
import { Box, Stack } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { getRoute } from 'utils/helpers'
import { useCartQuery } from 'src/generated/graphql-frontend'
import { LoadingGeneral } from 'components/LoadingGeneral'
import { CheckoutForm } from './CheckoutForm'
import { CartItems } from './CartItems'
import { EmptyCart } from './EmptyCart'
import { Header } from './Header'
import { CartLayout } from './CartLayout'

interface Props {
  username: string
}

export const Cart: React.FC<Props> = ({ username }) => {
  const { t } = useTranslation('username')
  const router = useRouter()
  const [customAmount, setCustomAmount] = React.useState<number | null>(null)

  const { data, loading } = useCartQuery({
    variables: { username },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (!data.profile) {
        void router.replace('/')
      }
    },
  })

  if (!data && loading) {
    return (
      <CartLayout
        header={<Header isLoading />}
        leftComp={<LoadingGeneral loadingText={t('Cart.loadingShoppingCart')} />}
        rightComp={<Box h="48" />}
      />
    )
  }

  const order = data?.order || null
  const profile = data?.profile
  if (!profile) {
    return null
  }

  if (!order || !order?.ProductVariantOrders.length) {
    return (
      <Stack w="full" spacing="10">
        <EmptyCart name={profile.name} username={username} />
      </Stack>
    )
  }

  const amountCents = Math.max(order.amount, customAmount || 0)
  const finalAmountCents = amountCents + (order.Shipping?.amount || 0)
  const requiresAddress = order.requiresAddress

  return (
    <CartLayout
      header={<Header name={profile.name} username={username} />}
      leftComp={
        <CheckoutForm
          username={username}
          amountCents={finalAmountCents}
          requiresAddress={requiresAddress}
          profile={profile}
          orderId={order.id}
          minimumAmount={order.amount}
          onCustomAmountChange={setCustomAmount}
          onPaymentSuccess={() => {
            void router.push(getRoute('orderComplete', order.id))
          }}
        />
      }
      rightComp={
        <CartItems
          currency={profile.currency}
          username={profile.username}
          items={order.ProductVariantOrders}
          subtotal={amountCents}
          shippingAmount={order.Shipping?.amount}
          isUpdating={loading}
        />
      }
    />
  )
}
