import React from 'react'
import { VStack, Text, Checkbox } from '@chakra-ui/react'
import { UseFormRegister, FieldPath } from 'react-hook-form'
import { Form } from './types'

interface Props {
  index: number
  label: string
  register: UseFormRegister<Form>
}

export const DayCheckbox: React.FC<Props> = ({ index, label, register }) => {
  const id = `days.${index}.value` as FieldPath<Form>
  return (
    <VStack
      as="label"
      htmlFor={id}
      flex="1"
      borderRadius="lg"
      // bg={isChecked ? 'teal.200' : 'white'}
      borderColor="teal.200"
      borderWidth="1px"
      spacing="1"
      py="2.5"
      cursor="pointer"
    >
      <Checkbox {...register(id)} id={id} size="sm" />
      <Text>{label}</Text>
    </VStack>
  )
}
