import React from 'react'
import { Grid, Stack } from '@chakra-ui/react'
import { Card } from 'components/Card'

interface Props {
  header: React.ReactNode
  leftComp: React.ReactNode
  rightComp: React.ReactNode
}

export const CartLayout: React.VFC<Props> = ({ header, leftComp, rightComp }) => (
  <Stack spacing="10" alignItems={{ base: 'center', lg: 'flex-start' }}>
    {header}
    <Grid
      w="full"
      maxW={{ base: '600px', lg: 'full' }}
      mx={{ base: 'auto', lg: 0 }}
      templateColumns={{ base: '1fr', lg: 'auto minmax(auto, 400px)' }}
      alignItems="start"
      gap="6"
    >
      <Card>{leftComp}</Card>
      <Card gridRow={{ base: 1, lg: 'unset' }}>{rightComp}</Card>
    </Grid>
  </Stack>
)
