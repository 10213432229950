import React from 'react'
import { chakra, Box, Flex, Stack, Divider, Text, Icon, useToast, useColorModeValue } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import { GrFormSubtract } from 'react-icons/gr'
import { getRoute } from 'utils/helpers'
import { getPrice } from 'utils/payments'
import { pulse } from 'utils/theme/helpers'
import { RefetchQueriesEnum } from 'src/constants'
import {
  ProductTypeEnum,
  useUpdateProductVariantOrderMutation,
} from 'src/generated/graphql-frontend'
import Link from 'components/Link'
import { FilePreview } from 'components/Upload/FilePreview'
import { ProductVariantOrdersType } from '../Cart/types'
import { AttachmentUnavailable } from './AttachmentUnavailable'
import { BsTrash } from 'react-icons/bs'
import { format } from 'date-fns'
import { TimeSlotStatusIndicator } from 'components/Bookings/TimeSlotStatusIndicator'
import { StyledLinkify } from 'components/StyledLinkify'
import useTranslation from 'next-translate/useTranslation'
import { Label } from 'components/Label'

const QuantityButton = chakra('button', {
  baseStyle: {
    bg: 'gray.200',
    width: '7',
    height: '7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 'md',
    transitionDuration: 'fast',
    transitionProperty: 'common',
    _focus: {
      boxShadow: 'outline',
    },
    _disabled: {
      cursor: 'not-allowed',
    },
  },
})

type ItemType = ProductVariantOrdersType[number]

interface Props extends ItemType {
  currency: string
  username: string
  canEdit?: boolean
}

export const ListItem: React.FC<Props> = ({
  id,
  ProductVariant: {
    AttributeVariant,
    slug,
    Price: { amount },
    Pictures: [picture],
    Product,
    Attachments,
    type,
  },
  TimeSlot,
  quantity,
  currency,
  username,
  canEdit,
}) => {
  const { t } = useTranslation('backOffice')
  const toast = useToast()
  const [updateProductVariantOrder, { loading }] = useUpdateProductVariantOrderMutation({
    refetchQueries: [RefetchQueriesEnum.Cart, RefetchQueriesEnum.MyCarts],
    awaitRefetchQueries: true,
    onCompleted(data) {
      if (data.updateProductVariantOrder.isTooBig) {
        toast({
          position: 'top-right',
          status: 'error',
          title: t('username:Shop.quantityTooBig.title', { productName: Product.name }),
          description: t('username:Shop.quantityTooBig.description'),
        })
      }
    },
  })
  const onChangeQuantity = React.useCallback(
    (id: string, quantity: number) => {
      if (!loading) {
        void updateProductVariantOrder({
          variables: {
            id,
            inputData: {
              quantity,
            },
          },
        })
      }
    },
    [loading]
  )

  const handleRemove = React.useCallback(() => {
    if (!loading) {
      void updateProductVariantOrder({
        variables: {
          id,
          inputData: {
            quantity: 0,
          },
        },
      })
    }
  }, [loading])
  const bg = useColorModeValue('white', 'gray.500')
  const isBooking = type === ProductTypeEnum.Booking
  return (
    <Stack
      key={id}
      direction="row"
      spacing="4"
      animation={loading ? `${pulse} infinite 1s linear` : undefined}
    >
      <Box
        w="12"
        h="12"
        bgImage={`url(${picture.thumbnailSignedUrl!})`}
        bgSize="cover"
        bgPos="center"
        borderRadius="lg"
        flex="none"
      />
      <Stack flex="1" spacing="2">
        <Link
          href={getRoute('profile', username, {
            productSlug: Product.slug,
            variantSlug: Product.hasOptions ? slug : undefined,
          })}
        >
          <Text fontWeight="medium">{Product.name}</Text>
        </Link>
        <Stack direction="row" alignItems="center" spacing="2">
          {[
            ...AttributeVariant.map(({ name, values: [{ value }] }) => ({
              label: name,
              value,
            })),
            TimeSlot
              ? { label: format(TimeSlot.start, 'Pp'), value: format(TimeSlot.sessionEnd, 'HH:mm') }
              : {
                  label: 'Qty',
                  value: quantity,
                },
          ].map(({ label, value }, index, { length }) => (
            <React.Fragment key={label}>
              <Flex fontSize="sm" opacity={0.6}>
                <Text fontWeight="medium" pr="1">
                  {label}:
                </Text>
                <Text>{value}</Text>
              </Flex>
              {index < length - 1 && (
                <Divider borderColor="primary.500" h="70%" opacity={0.6} orientation="vertical" />
              )}
            </React.Fragment>
          ))}
        </Stack>
        <Text fontWeight="medium">{getPrice(amount, currency)}</Text>
        {!isBooking && canEdit && (
          <Stack direction="row" alignItems="center" spacing="3">
            <QuantityButton disabled={loading} onClick={() => onChangeQuantity(id, quantity - 1)}>
              <Icon as={GrFormSubtract} w="4" h="4" />
            </QuantityButton>
            <Text fontSize="sm" fontWeight="medium">
              {quantity}
            </Text>
            <QuantityButton
              bg={bg}
              borderColor="primary.500"
              borderWidth="1px"
              disabled={loading}
              onClick={() => onChangeQuantity(id, quantity + 1)}
            >
              <Icon as={MdAdd} w="4" h="4" />
            </QuantityButton>
          </Stack>
        )}
        {isBooking && TimeSlot && (
          <>
            <TimeSlotStatusIndicator type="text" status={TimeSlot.status} />
            {TimeSlot.meetingDetails && (
              <div>
                <Label>{t('Bookings.Grid.meetingDetails')}</Label>
                <StyledLinkify>{TimeSlot.meetingDetails}</StyledLinkify>
              </div>
            )}
          </>
        )}
        {isBooking && canEdit && (
          <QuantityButton
            bg={bg}
            borderColor="primary.500"
            borderWidth="1px"
            disabled={loading}
            onClick={handleRemove}
          >
            <Icon as={BsTrash} w="4" h="4" />
          </QuantityButton>
        )}
        {!!Attachments?.length &&
          Attachments.map(({ S3File }, index) =>
            S3File ? (
              <FilePreview
                key={S3File.signedUrl}
                fileName={S3File.name}
                downloadUrl={S3File.signedUrl}
                percentageUploaded={100}
                previewMediaURL={S3File.thumbnailSignedUrl}
              />
            ) : (
              <AttachmentUnavailable key={index} />
            )
          )}
      </Stack>
    </Stack>
  )
}
