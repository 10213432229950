import React from 'react'
import {
  Grid,
  Box,
  Stack,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Section } from './Section'
import { MerchantMenu } from 'components/Merchant'
import { scrollOverlayStyle } from '../Shop/tabs'
import { localStorageWasHereKey } from 'src/constants'

export const tabStackSpacing = { base: '10', md: '12' }

// TODO: Sticky tabs and footer for saving changes, animation between tabs

const customScrollOverlayStyle = {
  ...scrollOverlayStyle,
  height: 'calc(100% - 8px)',
}

interface LayoutProps {
  title: string
  description?: React.ReactNode
  descriptionOpacity?: number
  defaultTabIndex?: number
  tabs?: string[]
  rightComp?: React.ReactNode
  headerComp?: React.ReactNode
  tabIndex?: number
  onTabIndexChange?(tabIndex: number): void
  children: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({
  title,
  description,
  descriptionOpacity = 1,
  tabs,
  rightComp,
  headerComp,
  tabIndex,
  defaultTabIndex,
  onTabIndexChange,
  children,
}) => {
  // TODO: Merge this to develop
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageWasHereKey, 'true')
    }
  }, [])

  const [scrollStatus, setScrollStatus] = React.useState<'start' | 'stuck-in-middle' | 'end'>(
    'start'
  )
  const isMdDown = useBreakpointValue({base: true, md: true})
  const bg = useColorModeValue('white', isMdDown ? 'transparent' : 'gray.800')
  const handleTabListScroll = React.useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget
      if (scrollLeft === 0) {
        setScrollStatus('start')
      } else if (scrollLeft + clientWidth === scrollWidth) {
        setScrollStatus('end')
      } else {
        setScrollStatus('stuck-in-middle')
      }
    },
    [setScrollStatus]
  )

  return (
    <Grid
      mt={{ base: '0', md: '10' }}
      gap={{ base: '0', md: '10' }}
      templateColumns={{ base: '1fr', md: '16rem minmax(0, 1fr)' }}
      alignItems="start"
    >
      <MerchantMenu display={{ base: 'none', md: 'flex' }} py="10" />
      <Box
        minW={0}
        px={{ base: '0', md: '10' }}
        py="10"
        bg={bg}
        borderRadius="5xl"
        boxShadow={{
          base: 'none',
          md: '0px 0px 40px rgba(132, 132, 132, 0.14)',
        }}
      >
        <Tabs
          defaultIndex={defaultTabIndex}
          index={tabIndex}
          onChange={onTabIndexChange}
          colorScheme="primary"
          isManual
          isLazy
        >
          <Stack spacing={{ base: '6', md: '12' }}>
            {headerComp}
            <Section
              title={<Heading size="lg">{title}</Heading>}
              description={description}
              descriptionOpacity={descriptionOpacity}
              titleRightComp={rightComp}
              titleJustifyContent={rightComp ? 'space-between' : undefined}
            >
              {tabs && (
                <Box
                  pos="relative"
                  {...(scrollStatus !== 'start' && {
                    _before: {
                      ...customScrollOverlayStyle,
                      left: 0,
                      top: 0,
                      transform: 'rotate(180deg)',
                    },
                  })}
                  {...(scrollStatus !== 'end' && {
                    _after: customScrollOverlayStyle,
                  })}
                >
                  <TabList
                    onScroll={handleTabListScroll}
                    sx={{
                      scrollbarWidth: 'none',
                      '::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                    color="gray.270"
                  >
                    {tabs.map((tab) => (
                      <Tab key={tab} position="relative">
                        {tab}
                      </Tab>
                    ))}
                  </TabList>
                </Box>
              )}
            </Section>
            {tabs ? (
              <TabPanels>
                {React.Children.map(children, (child) => (
                  <TabPanel p="0">
                    <Stack spacing={tabStackSpacing}>{child}</Stack>
                  </TabPanel>
                ))}
              </TabPanels>
            ) : (
              <Stack spacing={tabStackSpacing}>{children}</Stack>
            )}
          </Stack>
        </Tabs>
      </Box>
    </Grid>
  )
}
