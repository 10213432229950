import React from 'react'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Menu, MenuButton, MenuItem, MenuList, Text, Spinner } from '@chakra-ui/react'
import { getRoute } from 'utils/helpers'
import { Currencies, getPrice } from 'utils/payments'
import { canPublishProduct } from 'components/BackOffice/Shop/helpers'
import { ThreeDotsButton } from 'components/ThreeDotsButton'
import { SingleProductType } from '../../types'
import { QuantityWithTooltip } from './QuantityWithTooltip'
import useTranslation from 'next-translate/useTranslation'

export const ProductRow: React.FC<SingleProductType & { onDelete?: () => void }> = ({
  id,
  type,
  name,
  slug,
  description,
  isEnabled,
  trackInventory,
  productVariantsQuantities,
  Price,
  Pictures,
  onDelete,
}) => {
  const { t } = useTranslation('backOffice')
  const session = useSession()
  const router = useRouter()
  const currency = session?.data?.user?.profile?.currency
  const currencyObj = Currencies[currency as keyof typeof Currencies] || Currencies.eur
  const username = session?.data?.user.profile?.username as string
  const editUrl = getRoute('backofficeProduct', id)
  const publishLoading = false

  const typeTranslated = type ? t(`Product.${type}`, undefined, { fallback: type }) : null

  return (
    <>
      <Text
        // weird SSR fix
        as="div"
        fontWeight="bold"
        fontSize={{ base: 'sm', md: 'inherit' }}
        noOfLines={{ base: 3, lg: undefined }}
      >
        <Text color={isEnabled ? 'primary.500' : 'gray.300'}>
          <Link href={editUrl}>{name || t('Shop.draftProduct')}</Link>
        </Text>
        <Text fontWeight="normal" pt="2" color="teal.200" display={{ base: 'block', xl: 'none' }}>
          {typeTranslated || '-'}
        </Text>
      </Text>
      <Text color="teal.200">{typeTranslated || '-'}</Text>
      <QuantityWithTooltip
        trackInventory={trackInventory}
        productVariantsQuantities={productVariantsQuantities}
      />
      <Text fontWeight="medium" fontSize={{ base: 'sm', md: 'inherit' }}>
        {Price?.amount !== undefined ? getPrice(Price?.amount, currencyObj.name) : '-'}
      </Text>
      <Menu>
        <MenuButton as={ThreeDotsButton} />
        <MenuList>
          {false && !isEnabled &&
            canPublishProduct({
              name,
              description,
              type,
              PicturesKeys: Pictures,
              amount: Price?.amount,
            }) && (
              <MenuItem
                alignItems="center"
                icon={publishLoading ? <Spinner size="sm" thickness="2px" /> : undefined}
                isDisabled={publishLoading}
                onClick={() => console.log('publish')} // refetch after publish
              >
                {t('Shop.publish')}
              </MenuItem>
            )}
          {slug && (
            <MenuItem
              onClick={() =>
                router.push(
                  getRoute('profile', username, { productSlug: slug, isPreview: !isEnabled })
                )
              }
            >
              {t('Shop.preview')}
            </MenuItem>
          )}
          <MenuItem onClick={() => router.push(editUrl)}> {t('Shop.edit')}</MenuItem>
          <MenuItem onClick={onDelete}> {t('Shop.delete')}</MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
