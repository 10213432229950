import React from 'react'
import { useRouter } from 'next/router'
import { Button, Icon } from '@chakra-ui/react'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { RiHeartFill, RiCalendarFill } from 'react-icons/ri'
import { MdLoop } from 'react-icons/md'
import { BsShop } from 'react-icons/bs'
import { DataLimit } from 'src/constants'
import { useBackofficeProductsQuery } from 'src/generated/graphql-frontend'
import { getPageFromOffset } from 'utils/helpers'
import { DataTable, EmptyTableContent, HeaderItem } from 'components/DataTable'
import { Pagination } from 'components/Pagination'
import { NewProductProps, SingleProductType } from '../../types'
import { ProductRow } from './ProductRow'
import { DeleteProductModal } from './DeleteProductModal'
import useTranslation from 'next-translate/useTranslation'

export const ProductsGrid: React.FC<NewProductProps> = ({ onNewProduct }) => {
  const { t } = useTranslation('backOffice')
  const router = useRouter()
  const page = router.query.page as string | undefined
  const [productToDelete, setProductToDelete] = React.useState<SingleProductType | null>(null)
  const { data, loading } = useBackofficeProductsQuery({
    variables: {
      pagination: {
        from: getPageFromOffset({ limitPerPage: DataLimit.backofficeProducts, page }),
        length: DataLimit.backofficeProducts,
      },
    },
    fetchPolicy: 'network-only',
  })

  const headerItems = React.useMemo<HeaderItem[]>(
    () => [
      {
        icon: RiHeartFill,
        label: t('Shop.name'),
      },
      {
        icon: RiCalendarFill,
        label: t('Shop.type'),
      },
      {
        icon: MdLoop,
        label: t('Shop.stock'),
        showFrom: 'xl',
      },
      {
        icon: FaMoneyBillAlt,
        label: t('Shop.price'),
      },
      {
        showFrom: 'xl',
        minW: 16,
        isFiller: true,
      },
    ],
    [t]
  )

  return (
    <>
      <DataTable
        emptyComp={
          <EmptyTableContent
            ctaButton={
              <Button iconSpacing="3" leftIcon={<Icon as={BsShop} />} onClick={onNewProduct}>
                {t('Shop.createYourFirstProduct')}
              </Button>
            }
            imageWidth={{ base: '56px', md: '140px' }}
            imageHeight={{ base: '56px', md: '140px' }}
            imageUrl="/images/creator.svg"
            imageAlt="no products yet"
            title={t('Shop.noProducts')}
            description={t('Shop.noProductsCTA')}
          />
        }
        headerItems={headerItems}
        itemKeyProp="id"
        renderTableRow={(product) => (
          <ProductRow {...product} onDelete={() => setProductToDelete(product)} />
        )}
        templateColumns={{
          base: '3fr repeat(2, 2fr) auto',
          sm: '4fr repeat(2, 2.5fr) auto',
          xl: '2fr repeat(3, 1fr) auto',
        }}
        alignItems="center"
        data={data?.backofficeProducts.products}
        skeletonRowsCount={DataLimit.backofficeProducts}
        footer={
          !!data?.backofficeProducts.products.length && (
            <Pagination
              lastPage={Math.ceil(
                data.backofficeProducts.pagination.total / DataLimit.backofficeProducts
              )}
              currentPage={Number.parseInt(page || '1')}
              onGeneratePageLink={(pageNumber) =>
                `${router.asPath.split('?')[0]}?page=${pageNumber}`
              }
            />
          )
        }
        isLoading={loading}
      />
      <DeleteProductModal product={productToDelete} onClose={() => setProductToDelete(null)} />
    </>
  )
}
