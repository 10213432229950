import React from 'react'
import { Pagination } from 'swiper/modules'
import { Swiper as SwiperClass } from 'swiper/types'
import { SwiperSlide } from 'swiper/react'
import useTranslation from 'next-translate/useTranslation'
import { Box, Flex, Heading, Icon, Skeleton, chakra } from '@chakra-ui/react'
import { BiArrowBack } from 'react-icons/bi'
import { useProductsQuery } from 'src/generated/graphql-frontend'
import { CarouselNavButton } from 'components/CarouselNavButton'
import { StyledSwiper } from 'components/Homepage/Community'
import { renderSlideCard } from 'components/Merchant/CommunityCard'
import { ProductCard } from './ProductCard'

const SwiperNavButton = chakra(CarouselNavButton, {
  baseStyle: {
    bg: 'gray.200',
    color: 'primary.500',
    opacity: 0.65,
  },
})

interface Props {
  username: string
  filterId?: string
}

export const SimilarProducts: React.FC<Props> = ({ username, filterId }) => {
  const { t } = useTranslation('username')
  const [swiper, setSwiper] = React.useState<SwiperClass>()
  const { data, loading } = useProductsQuery({
    variables: {
      username,
      filterId,
      pagination: {
        from: 0,
        length: 9,
      },
    },
  })
  const products = data?.products.products
  if (!loading && !products?.length) {
    return null
  }
  return (
    <>
      <Skeleton alignSelf="flex-start" isLoaded={!loading}>
        <Heading size="lg">{t('Shop.similarProductsPeopleWhoBought')}</Heading>
      </Skeleton>
      <Box position="relative">
        <Box w="full" maxW="full">
          <StyledSwiper
            $bulletsBottom={[-32, -70]}
            modules={[Pagination]}
            spaceBetween={12}
            slidesPerView={1.1}
            threshold={0}
            pagination={{
              type: 'bullets',
              clickable: true,
            }}
            watchSlidesProgress
            breakpoints={{
              480: {
                slidesPerView: 1.33,
                spaceBetween: 12,
                threshold: 4,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 16,
                threshold: 4,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 32,
                threshold: 8,
              },
              950: {
                slidesPerView: 2.33,
                spaceBetween: 32,
                threshold: 8,
              },
              1100: {
                slidesPerView: 2.75,
                spaceBetween: 32,
                threshold: 8,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 32,
                threshold: 8,
              },
            }}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
            {(products || Array.from({ length: 3 })).map((item, index) => (
              <SwiperSlide style={{ height: 'auto' }} key={index}>
                {renderSlideCard(
                  <Flex flex="1" flexDirection="row" alignItems="stretch">
                    <ProductCard key={index} username={username} {...(item || {})} />
                  </Flex>
                )}
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </Box>
        {products && products?.length > 3 && (
          <Box
            position="absolute"
            display={{ base: 'none', md: 'flex' }}
            width="calc(100% + 10rem)"
            justifyContent="space-between"
            left="50%"
            top="50%"
            pointerEvents="none"
            transform="translateX(-50%) translateY(-50%)"
            zIndex={1}
          >
            <SwiperNavButton onClick={() => swiper?.slidePrev()}>
              <Icon as={BiArrowBack} width="6" height="6" />
            </SwiperNavButton>
            <SwiperNavButton onClick={() => swiper?.slideNext()}>
              <Icon as={BiArrowBack} width="6" height="6" transform="scaleX(-1)" />
            </SwiperNavButton>
          </Box>
        )}
      </Box>
    </>
  )
}
