const DB_NAME = 'PushClickAction'
const DB_OBJECT_STORE = 'Urls'
const REDIRECT_EXPIRATION = 10000 // 10 seconds

export function initNotificationsUrlIndexedDb() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1)

    request.onupgradeneeded = () => {
      const db = request.result
      if (!db.objectStoreNames.contains(DB_OBJECT_STORE)) {
        db.createObjectStore(DB_OBJECT_STORE, {
          autoIncrement: true,
        })
      }
    }
    request.onsuccess = () => {
      const db = request.result
      if (!db.objectStoreNames.contains(DB_OBJECT_STORE)) {
        const deleteRequest = window.indexedDB.deleteDatabase(DB_NAME)

        deleteRequest.onerror = () => {
          reject()
        }

        deleteRequest.onsuccess = () => resolve(initNotificationsUrlIndexedDb())
      }

      resolve(true)
    }

    request.onerror = () => {
      reject()
    }
  })
}

export function addUrl(url: string) {
  const request = indexedDB.open(DB_NAME, 1)

  request.onsuccess = () => {
    const db = request.result
    const transaction = db.transaction(DB_OBJECT_STORE, 'readwrite')
    const store = transaction.objectStore(DB_OBJECT_STORE)
    store.put(JSON.stringify({ url, expirationTimestamp: Date.now() + REDIRECT_EXPIRATION }))
    transaction.oncomplete = () => {
      db.close()
    }
  }
}

export function clearDb() {
  const request = indexedDB.open(DB_NAME, 1)

  request.onsuccess = () => {
    const db = request.result
    const transaction = db.transaction(DB_OBJECT_STORE, 'readwrite')
    const objectStore = transaction.objectStore(DB_OBJECT_STORE)
    objectStore.clear()
    transaction.oncomplete = () => {
      db.close()
    }
  }
}

export function getUrl(callback: (url: string) => void) {
  const request = indexedDB.open(DB_NAME, 1)

  request.onsuccess = () => {
    const db = request.result
    const transaction = db.transaction(DB_OBJECT_STORE, 'readonly')
    const objectStore = transaction.objectStore(DB_OBJECT_STORE)

    const objectStoreAll = objectStore.getAll()
    objectStoreAll.onsuccess = () => {
      const resultsJson = objectStoreAll.result as string[]

      const activeNotificationClickRecord = resultsJson
        .map((json) =>
          json
            ? (JSON.parse(json) as {
                url: string
                expirationTimestamp: number
              })
            : null
        )
        .find((item) => item && item.url && Date.now() < item.expirationTimestamp)
      if (activeNotificationClickRecord) {
        callback(activeNotificationClickRecord.url)
      }
    }

    transaction.oncomplete = () => {
      db.close()
      clearDb()
    }
  }
}
