import { Box, Button, Flex, Icon, Input, Text, useDisclosure } from '@chakra-ui/react'
import { Label } from 'components/Label'
import { StyledLinkify } from 'components/StyledLinkify'
import React from 'react'
import { useForm } from 'react-hook-form'
import { RiCheckLine, RiPencilLine } from 'react-icons/ri'
import { useUpdateTimeSlotInfoMutation } from 'src/generated/graphql-frontend'

interface Props {
  id: string
  label: string
  value?: string | null
  placeholder?: string
  fieldName?: string
  canContainUrls?: boolean
  onChange?: (value: string) => void
}

interface Form {
  input: string
}

export const TimeSlotField: React.FC<Props> = ({
  id,
  label,
  value,
  placeholder,
  fieldName,
  canContainUrls,
  onChange,
}) => {
  const { register, handleSubmit, setFocus, watch } = useForm<Form>({
    defaultValues: {
      input: value || undefined,
    },
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [updateField] = useUpdateTimeSlotInfoMutation()

  React.useEffect(() => {
    if (isOpen) {
      setFocus('input')
    }
  }, [isOpen, setFocus])
  const onSubmit = async (data: Form) => {
    onChange?.(data.input)
    onClose()
    if (fieldName) {
      await updateField({
        variables: {
          timeSlotId: id,
          field: fieldName,
          value: data.input,
        },
      })
    }
  }

  const renderValue = () => {
    const value = watch('input') || placeholder
    if (canContainUrls) {
      return (
        <Box __css={{ a: { textDecoration: 'underline' } }}>
          <StyledLinkify>{value}</StyledLinkify>
        </Box>
      )
    }
    return value
  }

  return (
    <div>
      <Label>{label}</Label>
      {/* TODO: Edit can be done by merchant or user */}
      <Flex
        alignItems={isOpen ? 'stretch' : 'center'}
        gap={2}
        as="form"
        onSubmit={isOpen ? handleSubmit(onSubmit) : undefined}
      >
        {isOpen ? (
          <>
            <Input size="sm" {...register('input')} onBlur={onClose} />
            <Button h="auto" colorScheme="green" size="sm" type="submit">
              <Icon as={RiCheckLine} />
            </Button>
          </>
        ) : (
          <>
            <Text as="div">{renderValue()}</Text>
            {(onChange || fieldName) && (
              <Button as="div" variant="ghost" size="sm" onClick={onOpen} type="button">
                <Icon as={RiPencilLine} />
              </Button>
            )}
          </>
        )}
      </Flex>
    </div>
  )
}
