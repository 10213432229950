import { SocialMediaNetworkType } from './types/general'

export const GqlErrorCode = {
  AccountPasswordTooWeak: 'AccountPasswordTooWeak',
  BookingAlreadyInCart: 'BookingAlreadyInCart',
  BookingSettingsNotSetup: 'BookingSettingsNotSetup',
  BookingOverlap: 'BookingOverlap',
  BookingCapacityReached: 'BookingCapacityReached',
  MissingCustomerEmail: 'MissingCustomerEmail',
  ContactInfoNotFound: 'ContactInfoNotFound',
  InvalidField: 'InvalidField',
  TierNotFound: 'TierNotFound',
  LoginToSubscribe: 'LoginToSubscribe',
  InternalSeverError: 'InternalSeverError',
  Unauthorized: 'Unauthorized',
  NotAuthorized: 'NotAuthorized',
  SignUpEmailExists: 'SignUpEmailExists',
  PaymentNotFound: 'PaymentNotFound',
  QuantityTooBig: 'QuantityTooBig',
  ProductAlreadyPublished: 'ProductAlreadyPublished',
  PaymentFailed: 'PaymentFailed',
  PaymentProcessedAlready: 'PaymentProcessedAlready',
  InvalidAction: 'InvalidAction',
  NoUserProfile: 'NoUserProfile',
  UsernameExists: 'UsernameExists',
  WebsiteExists: 'WebsiteExists',
  UpdateEmailTokenNotExist: 'UpdateEmailTokenNotExist',
  NotFound: 'NotFound',
  NoStripeConnectAccount: 'NoStripeConnectAccount',
  ProfileNotFound: 'ProfileNotFound',
  ProfileCurrencyNotFound: 'ProfileCurrencyNotFound',
  NoDeviceId: 'NoDeviceId',
  CustomPaymentLowAmount: 'CustomPaymentLowAmount',
  PaymentLowAmount: 'PaymentLowAmount',
  MissingOrderAddress: 'MissingOrderAddress',
  ProductNotPublishedCart: 'ProductNotPublishedCart',
  OrderNotFree: 'OrderNotFree',
  OrderAlreadyPaid: 'OrderAlreadyPaid',
  SameEmail: 'SameEmail',
  BookingSlotSettingsNotFound: 'BookingSlotSettingsNotFound',
  CommunityPostDoesNotExist: 'CommunityPostDoesNotExist',
  WrongCredentials: 'WrongCredentials',
  PasswordResetLinkExpired: 'PasswordResetLinkExpired',
  InvalidDateRange: 'InvalidDateRange',
  TimeSlotNotSpecified: 'TimeSlotNotSpecified',
  VideoEncodingFailed: 'VideoEncodingFailed',
}

export enum EmailTemplateId {
  AccountInvitation = 'd-****',
  SignIn = 'signin',
  ResetPassword = 'passwordresetrequest',
  EmailUpdate = 'emailupdate',
  OneTimeDonation = 'onetimedonation',
  OneTimeDonationMerchant = 'onetimedonationmerchant',
  RecurringDonation = 'recurringdonation',
  RecurringDonationMerchant = 'recurringdonationmerchant',
  SubscriptionConfirmation = 'subscriptionconfirmation',
  SubscriptionConfirmationMerchant = 'subscriptionconfirmationmerchant',
  SubscriptionCancelled = 'subscriptioncancelled',
  OrderConfirmation = 'orderconfirmation',
  ForgottenPassword = 'forgottenpassword',
}

export enum Headers {
  XRequestedWith = 'X-Requested-With',
  AccessControlAllowOrigin = 'Access-Control-Allow-Origin',
  XHTTPMethodOverride = 'X-HTTP-Method-Override',
  ContentType = 'Content-Type',
  Authorization = 'Authorization',
  Accept = 'Accept',
  XAuthToken = 'x-auth-token',
}

export const RoutesMap = {
  account: 'account',
  backoffice: 'backoffice',
  bookings: 'bookings',
  cart: 'cart',
  contact: 'contact',
  changeEmail: 'change-email',
  login: 'login',
  signup: 'signup',
  forgottenPassword: 'forgotten-password',
  newProfile: 'new-profile',
  orderComplete: 'order-complete',
  orderTracking: 'order-tracking',
  role: 'role',
  termsAndConditions: 'terms-and-conditions',
  passwordReset: 'password-reset',
  pricing: 'pricing',
  privacyPolicy: 'privacy',
  tier: 'tier',
  widget: 'widget',
  community: 'community',
  posts: 'posts',
}

type ProfileOptions = {
  tab?: string // 'about' | 'gallery' | 'shop' | 'memberships'
  productSlug?: string | null
  variantSlug?: string | null
  isPreview?: boolean
  storeLink?: boolean
}

export const RoutesFunc = {
  account: (subPage?: 'profile' | 'bookings') =>
    `${RoutesMap.account}${subPage ? `/${subPage}` : ''}`,
  bookings: () => `${RoutesMap.bookings}`,
  home: (section?: 'creators') => `${section ? `#${section}` : ''}`,
  login: (email?: string) =>
    `${RoutesMap.login}${email ? `/code?email=${encodeURIComponent(email)}` : ''}`,
  signup: (email?: string) =>
    `${RoutesMap.signup}${email ? `?email=${encodeURIComponent(email)}` : ''}`,
  forgottenPassword: (email?: string) =>
    `${RoutesMap.forgottenPassword}${email ? `?email=${encodeURIComponent(email)}` : ''}`,
  profile: (
    username: string,
    { tab, productSlug, variantSlug, isPreview, storeLink }: ProfileOptions = {}
  ) => {
    if (storeLink) {
      return `${username}/store-link`
    }
    const preview = isPreview ? 'preview/' : ''
    if (productSlug && variantSlug) {
      return `${username}/shop/${preview}${productSlug}/${variantSlug}`
    }
    if (productSlug) {
      return `${username}/shop/${preview}${productSlug}`
    }
    return `${username}${tab ? `/${tab}` : ''}`
  },
  cart: (username?: string) => `${username ? `${username}/` : ''}${RoutesMap.cart}`,
  posts: (username: string, id?: string) => `${username}/${RoutesMap.posts}${id ? `/${id}` : ''}`,
  contact: () => RoutesMap.contact,
  changeEmail: (token: string) => `${RoutesMap.changeEmail}/${token}`,
  backoffice: (
    subPage?:
      | 'bookings'
      | 'dashboard'
      | 'supporters'
      | 'memberships'
      | 'widget'
      | 'shop'
      | 'instant-payment'
      | 'settings'
      | 'admin'
      | 'posts'
      | 'store-link',
    id?: string
  ) =>
    `${RoutesMap.backoffice}${subPage ? `/${subPage === 'dashboard' ? '' : subPage}` : ''}${id ? `/${id}` : ''}`,
  backofficeProduct: (id?: string) => `${RoutesMap.backoffice}/shop/product${id ? `/${id}` : ''}`,
  backofficeTier: (id?: string) => `${RoutesMap.backoffice}/shop/tier${id ? `/${id}` : ''}`,
  backofficeOrder: (id: string) => `${RoutesMap.backoffice}/shop/order/${id}`,
  orderComplete: (orderId: string) => `${RoutesMap.orderComplete}/${orderId}`,
  orderTracking: (orderId: string) => `${RoutesMap.orderTracking}/${orderId}`,
  tier: (tierId: string, returnTo?: string) =>
    `${RoutesMap.tier}/${tierId}${returnTo ? `?returnTo=${returnTo}` : ''}`,
  role: () => RoutesMap.role,
  community: (id?: string) => `${RoutesMap.community}${id ? `/${id}` : ''}`,
  termsAndConditions: () => RoutesMap.termsAndConditions,
  passwordReset: (token: string) => `${RoutesMap.passwordReset}/?token=${token}`,
  privacyPolicy: () => RoutesMap.privacyPolicy,
  newProfile: () => `${RoutesMap.backoffice}/${RoutesMap.newProfile}`,
  pay: (id: string) => `pay/${id}`,
  pricing: () => RoutesMap.pricing,
  search: (query?: string) => `search${query ? `?q=${query}` : ''}`,
  instantPayment: (id: string) => `instant-payment/${id}`,
  inbox: () => 'inbox',
  add: () => 'add',
  reels: () => 'reels',
  widget: () => RoutesMap.widget,
}

export type RouteKeyType = keyof typeof RoutesFunc
export const UsernameBlackList = [
  ...Object.values(RoutesMap),
  'profile',
  'about-us',
  'plantico',
  'esoteriq',
  'images',
  'order',
  'contact-us',
  'api',
  'admin',
  'static',
  'community',
]

export const EventType = {
  accountUpdated: 'account.updated',
  invoiceCreated: 'invoice.created',
  invoiceUpdated: 'invoice.updated',
  invoiceDeleted: 'invoice.deleted',
  invoicePaid: 'invoice.paid',
  invoicePaymentSucceeded: 'invoice.payment_succeeded',
  invoicePaymentFailed: 'invoice.payment_failed',
  customerSubscriptionCreated: 'customer.subscription.created',
  customerSubscriptionUpdated: 'customer.subscription.updated',
  customerSubscriptionDeleted: 'customer.subscription.deleted',
} as const

export enum RefetchQueriesEnum {
  ApplicationFees = 'ApplicationFees',
  BackofficeProducts = 'BackofficeProducts',
  BackofficeOrders = 'BackofficeOrders',
  BackofficeProductVariants = 'BackofficeProductVariants',
  MyCarts = 'MyCarts',
  Cart = 'Cart',
  MySubscriptions = 'MySubscriptions',
  Order = 'Order',
  Products = 'Products',
  ProductTypes = 'ProductTypes',
  Profile = 'Profile',
  Profiles = 'Profiles',
  User = 'User',
  Tiers = 'Tiers',
  Supporters = 'Supporters',
  MerchantStats = 'MerchantStats',
  CommunityPost = 'CommunityPost',
  CommunityPosts = 'CommunityPosts',
  CommunityPostComments = 'CommunityPostComments',
  CreatorPost = 'CreatorPost',
  CreatorPosts = 'CreatorPosts',
}

export const DataLimit = {
  backofficeOrders: 10,
  backofficeProducts: 2,
  bookings: 10,
  products: 10,
  supporters: 10,
  subscriptions: 10,
  payments: 10,
  cards: 10,
  websites: 10,
  profiles: 20,
}

export enum URLEnum {
  https = 'https',
  http = 'http',
  www = 'www',
}

export enum TrackNamesEnum {
  SubscribeMemberShip = 'subscribe membership button clicked',
  PageChanged = 'page changed',
  JoinMembership = 'join now membership button clicked',
  PaymentButton = 'payment button clicked',
  PaymentWidgetSwipe = 'payment widget swipe',
  PaymentTypeChanged = 'payment type changed',
  Payment = 'payment',
  PaymentEmail = 'payment email',
  FileUpload = 'file upload',
}

export enum EventTypeEnum {
  drop = 'drop',
  change = 'change',
}

export enum SubscriptionEnum {
  ProductPurchase = 'productPurchase',
  NewOrder = 'newOrder',
}

export enum TimeSlotTypeEnum {
  Booking = 'Booking',
  /**
   * The worker manually marked this time slot as unavailable
   */
  Manual = 'Manual',
}

export const useSecureCookies = process.env.NODE_ENV === 'production'

export const deviceIdCookieName = 'device_id'
export const langCookieName = 'NEXT_LOCALE'

export const minimumAmount = 100

export const localStorageWasHereKey = 'wasHere'

export const hrInSec = 24 * 60 * 60

export const SocialMediaKeyValues: { [key in SocialMediaNetworkType]: string } = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  spotify: 'Spotify',
  tiktok: 'TikTok',
  twitch: 'Twitch',
  twitter: 'X',
  youtube: 'YouTube',
}
