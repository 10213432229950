export const colors = {
  // white: '#90949E',
  gray: {
    200: '#42395e',
    250: 'rgba(130 193 204 / 20%)',
    270: 'rgba(130 193 204 / 20%)',
    290: '#3a9fec',
    300: '#ABB7BF',
    400: '#90949E',
    500: '#313f62',
    600: '#689bff',
    650: '#4C5980',
    700: '#0c141a',
    800: '#0c0820',
  },
  primary: {
    300: '#9946c2',
    500: '#d338cf',
    600: '#661f89',
    700: '#301249',
  },
  peach: {
    100: '#FFF2E8',
    300: '#FFB27D',
    400: '#ffd843',
    500: '#E9845C',
    700: '#B14B23',
  },
  yellow: {
    300: '#FCDC7B',
    500: '#F9C440',
  },
  red: {
    300: '#FFAFAF',
    400: '#FF7878',
    500: '#FF7878',
  },
  purple: {
    400: '#D3CCFF',
    500: '#93BEFE',
  },
  teal: {
    100: '#EEFCFF',
    200: '#BED5DC',
    300: '#A3ECF4',
    400: '#99E0CB',
    600: '#2aebee',
  },
  green: {
    400: '#4ABF9C',
    600: '#5CBB9E',
  },
  skeletor: {
    start: 'var(--chakra-colors-gray-500)',
    end: 'var(--chakra-colors-gray-700)',
  },
}

export default colors
