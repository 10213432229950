import React from 'react'
import { useRouter } from 'next/router'
import { SessionProvider, signIn } from 'next-auth/react'
import { Elements } from '@stripe/react-stripe-js'
import { useToast } from '@chakra-ui/react'
import { ApolloProvider } from 'utils/ApolloProvider'
import { AppPageProps } from 'src/types/general'
import { ShopContextProvider } from 'components/Shop/ShopContext'
import { DeviceInfo } from 'components/DeviceInfo'
import { getStripePromise } from './stripePromise'
import { getRoute } from './helpers'
import {PushNotificationsWrapper} from 'utils/pushNotifications/PushNotificationsWrapper'

const stripePromise = getStripePromise()

export const AppProviders: React.FC<AppPageProps> = ({ session, initialApolloState, children }) => {
  const toast = useToast()
  const router = useRouter()
  const isWidget = router.pathname === getRoute('widget')
  const handleUnauthError = React.useCallback(() => {
    void signIn(undefined, {
      redirect: true,
      callbackUrl: location ? location.pathname : undefined,
    })
  }, [])
  const handleError = React.useCallback(
    (errorMsg: string) => {
      toast({
        position: 'top-right',
        title: errorMsg,
        status: 'error',
      })
    },
    [toast]
  )
  const ShopContextWrapper = isWidget ? React.Fragment : ShopContextProvider
  return (
    <SessionProvider session={session} refetchOnWindowFocus>
      <ApolloProvider
        initialApolloState={initialApolloState}
        onError={handleError}
        onUnauthError={handleUnauthError}
      >
        <PushNotificationsWrapper>
          <Elements stripe={stripePromise}>
            <DeviceInfo />
            <ShopContextWrapper>{children}</ShopContextWrapper>
          </Elements>
        </PushNotificationsWrapper>
      </ApolloProvider>
    </SessionProvider>
  )
}
