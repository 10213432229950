import React, { forwardRef } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

// const variants = {
//   hidden: { opacity: 0, x: -200, y: 0 },
//   enter: { opacity: 1, x: 0, y: 0 },
//   exit: { opacity: 0, x: 0, y: -100 },
// }

export const Container = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  // <motion.main
  //   initial="hidden"
  //   animate="enter"
  //   exit="exit"
  //   variants={variants}
  //   transition={{ type: 'linear' }}
  // >
  <Box
    ref={ref}
    w="full"
    pb="12"
    pt="3"
    maxW={{ base: '2xl', md: '7xl' }}
    mx="auto"
    px={{ base: '5', md: '6' }}
    {...props}
  />
  // </motion.main>
))

export const negativeMx = {
  base: -5,
  md: -6,
}

Container.displayName = 'Container'

export default Container
