import React from 'react'
import { Box, BoxProps, Flex, Stack, Text, Spinner } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { countries } from 'countries-list'
import { Label } from 'components/Label'
import { CopyButton } from 'components/CopyButton'

const InfoItem: React.FC<{ label: string; value: string; isCopiable?: boolean }> = ({
  label,
  value,
  isCopiable,
}) => (
  <div>
    <Label opacity={0.6}>{label}</Label>
    <Flex alignItems="center">
      <Text fontSize="sm">{value}</Text>
      {isCopiable && <CopyButton value={value} />}
    </Flex>
  </div>
)

interface Props extends BoxProps {
  isLoading?: boolean
  iban: string
  swift: string
  bankName: string
  countryCode: string
  variableSymbol: string
}

export const BankTransferPaymentDetails: React.FC<Props> = ({
  isLoading,
  iban,
  swift,
  bankName,
  countryCode,
  variableSymbol,
  ...boxProps
}) => {
  const { t } = useTranslation('common')

  return (
    <Box bg="gray.250" borderRadius="xl" p="6" py="4" {...boxProps}>
      {isLoading ? (
        <Spinner
          display="block"
          my="6"
          mx="auto"
          thickness="6px"
          speed="1.5s"
          emptyColor="teal.200"
          color="primary.500"
          size="xl"
        />
      ) : (
        <Stack>
          <InfoItem label="IBAN" value={iban} isCopiable />
          <InfoItem label="SWIFT" value={swift.toUpperCase()} isCopiable />
          <InfoItem label={t('variableSymbol')} value={variableSymbol} isCopiable />
          <InfoItem
            label={t('bankInfo')}
            value={`${bankName}, ${countries[countryCode as keyof typeof countries]?.name ?? ''}`}
          />
        </Stack>
      )}
    </Box>
  )
}
