import { Box, BoxProps, useColorMode } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'
import { getFadeGradient } from 'utils/theme/helpers'
import { DaysGrid } from './DaysGrid'
import { Navigation } from './Navigation'

const getGradientProps = (bg: string) => ({
  position: 'absolute',
  content: '""',
  display: 'block',
  right: '-1.5',
  top: '0',
  height: '100%',
  width: '3',
  bg,
  zIndex: 'docked',
})

interface Props extends Omit<BoxProps, 'value' | 'onChange'> {
  monthsInAdvance?: number
  dateStartsWShift?: number
  productVariantId: string
  value?: Date
  onChange?(newDate: Date): void
}

export const Calendar: React.FC<Props> = ({
  monthsInAdvance = 3,
  dateStartsWShift,
  value,
  productVariantId,
  onChange,
  ...rest
}) => {
  const mode = useColorMode()
  // TODO: hack, so what?
  const gradient = getFadeGradient(50)({ config: { initialColorMode: mode } })
  const [swiper, setSwiper] = React.useState<SwiperClass>()
  const [offset, setOffset] = React.useState(0)
  const { t } = useTranslation('datetime')

  const gradientProps = getGradientProps(gradient)

  const daysGridProps = {
    productVariantId,
    startDate: new Date(),
    dateStartsWShift,
    t,
  }

  return (
    <Box
      position="relative"
      w="full"
      maxW={{ base: '100%' }}
      _before={{
        ...gradientProps,
        right: 'unset',
        left: '-1.5',
        transform: 'scaleX(-1)',
      }}
      _after={gradientProps}
      {...rest}
    >
      <Swiper
        // modules={[Pagination]}
        style={{ width: '100%' }}
        spaceBetween={24}
        slidesPerView={1}
        threshold={8}
        watchSlidesProgress
        initialSlide={0}
        onSwiper={(swiper) => setSwiper(swiper)}
        onSlideChange={(swiper) => setOffset(swiper.activeIndex)}
      >
        {Array.from({ length: monthsInAdvance }, (_, i) => (
          <SwiperSlide key={i + 1}>
            <DaysGrid {...daysGridProps} monthOffset={i} isActive={offset === i} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Navigation
        position="absolute"
        w="calc(100% + 1px)"
        top="0"
        h="16"
        zIndex="docked"
        onPrev={() => swiper?.slidePrev()}
        onNext={() => swiper?.slideNext()}
      />
    </Box>
  )
}
