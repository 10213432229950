import React from 'react'
import { Flex, Text, FlexProps } from '@chakra-ui/react'

export const GridHeaderText: React.FC<FlexProps> = ({ children, ...props }) => (
  <Flex h="16" alignItems="center" {...props}>
    <Text fontSize={{ base: 'sm', sm: 'md' }} fontWeight="medium">
      {children}
    </Text>
  </Flex>
)
