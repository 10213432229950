import { OrderPaymentIntentError } from 'src/generated/graphql-frontend'

export const extractExtensionErrorMessage = (error: OrderPaymentIntentError) => {
  if (error.unavailableItems) {
    return error.unavailableItems
      .map(
        ({ clientProductName, inventoryQuantity }) =>
          `Quantity for ${clientProductName} is too big. Maximum is ${inventoryQuantity}.`
      )
      .join('\n')
  } else if (error.missingAddress) {
    return 'Address is required to finish this order.'
  } else {
    return 'An error occured trying to process the payment.'
  }
}
