import React from 'react'
import { forwardRef, Icon, IconButton } from '@chakra-ui/react'
import { BsThreeDots } from 'react-icons/bs'

export const ThreeDotsButton: React.FC = forwardRef<{}, 'button'>((props, ref) => (
  <IconButton
    ref={ref}
    variant="ghost"
    aria-label="Report"
    width="6"
    height="6"
    icon={<Icon as={BsThreeDots} />}
    {...props}
  />
))
