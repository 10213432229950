import { Select, useBreakpointValue } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useIsDarkMode } from 'utils/hooks'

const availableLanguages = {
  fr: 'Français',
  en: 'English',
  sk: 'Slovensky',
}

function changeLanguage(lang: string) {
  const currentUrl = new URL(window.location.href)
  currentUrl.searchParams.set('lang', lang)

  window.location.href = currentUrl.toString()
}

export const LanguagePick = () => {
  const { lang } = useTranslation()
  const isDarkMode = useIsDarkMode()
  const isMobileFromHook = useBreakpointValue({ base: true, md: false })
  const isMobile = typeof window === 'undefined' ? true : isMobileFromHook

  return (
    <Select
      placeholder={isMobile ? lang.toUpperCase() : availableLanguages[lang]}
      size="sm"
      width={isMobile ? 14 : 'auto'}
      variant="unstyled"
      fontWeight="medium"
      onChange={(event) => changeLanguage(event.target.value)}
      color={isDarkMode ? 'white' : 'primary.500'}
    >
      {Object.entries(availableLanguages).map(([code, label]) =>
        code === lang ? null : (
          <option key={code} value={code}>
            {label}
          </option>
        )
      )}
    </Select>
  )
}
