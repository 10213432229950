import { createIcon } from '@chakra-ui/react'

export const ShoppingBagIcon = createIcon({
  displayName: 'ShoppingBagIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      d="M9.3 9.3V6.7a4 4 0 0 1 6.7-3 4 4 0 0 1 6.7 3v2.6h2a2 2 0 0 1 2 2v13.4a4.7 4.7 0 0 1-4.7 4.6H10.7A5.3 5.3 0 0 1 5.3 24V11.3a2 2 0 0 1 2-2h2Zm8.9 18c-.6-.7-.9-1.7-.9-2.6V11.3h-10V24a3.3 3.3 0 0 0 3.4 3.3h7.5Zm-2.9-18V6.7a2 2 0 0 0-4 0v2.6h4Zm2 0h3.4V6.7A2 2 0 0 0 17 5.4c.2.4.2.8.2 1.3v2.6Zm2 15.4a2.7 2.7 0 0 0 5.4 0V11.3h-5.4v13.4Z"
      fill="currentColor"
    />
  ),
})

export const HeartIcon = createIcon({
  displayName: 'HeartIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      d="M17.093 7.44 16 8.536l-1.099-1.099A7.167 7.167 0 1 0 4.767 17.573L15.293 28.1a1 1 0 0 0 1.414 0l10.536-10.53a7.168 7.168 0 0 0-5.076-12.234 7.173 7.173 0 0 0-5.074 2.103v.001Zm8.731 8.72L16 25.98l-9.82-9.82a5.167 5.167 0 1 1 7.307-7.307l1.81 1.81a.997.997 0 0 0 1.431-.016l1.779-1.794a5.173 5.173 0 0 1 7.316 7.307h.001Z"
      fill="currentColor"
    />
  ),
})

export const FluentLockClosedIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M10.0002 1.66663C10.8842 1.66663 11.7321 2.01782 12.3572 2.64294C12.9823 3.26806 13.3335 4.1159 13.3335 4.99996V6.66663H14.7918C15.0381 6.66663 15.2819 6.71512 15.5094 6.80935C15.7368 6.90358 15.9435 7.04169 16.1177 7.2158C16.2918 7.38991 16.4299 7.59661 16.5241 7.8241C16.6183 8.05158 16.6668 8.2954 16.6668 8.54163V16.4583C16.6668 16.7045 16.6183 16.9483 16.5241 17.1758C16.4299 17.4033 16.2918 17.61 16.1177 17.7841C15.9435 17.9582 15.7368 18.0963 15.5094 18.1906C15.2819 18.2848 15.0381 18.3333 14.7918 18.3333H5.2085C4.71122 18.3333 4.2343 18.1357 3.88267 17.7841C3.53104 17.4325 3.3335 16.9556 3.3335 16.4583V8.54163C3.3335 8.04435 3.53104 7.56743 3.88267 7.2158C4.2343 6.86417 4.71122 6.66663 5.2085 6.66663H6.66683V4.99996C6.66683 4.1159 7.01802 3.26806 7.64314 2.64294C8.26826 2.01782 9.11611 1.66663 10.0002 1.66663ZM14.7918 7.91663H5.2085C5.04274 7.91663 4.88376 7.98247 4.76655 8.09968C4.64934 8.2169 4.5835 8.37587 4.5835 8.54163V16.4583C4.5835 16.8033 4.8635 17.0833 5.2085 17.0833H14.7918C14.9576 17.0833 15.1166 17.0174 15.2338 16.9002C15.351 16.783 15.4168 16.6241 15.4168 16.4583V8.54163C15.4168 8.37587 15.351 8.2169 15.2338 8.09968C15.1166 7.98247 14.9576 7.91663 14.7918 7.91663ZM10.0002 11.25C10.3317 11.25 10.6496 11.3817 10.884 11.6161C11.1185 11.8505 11.2502 12.1684 11.2502 12.5C11.2502 12.8315 11.1185 13.1494 10.884 13.3838C10.6496 13.6183 10.3317 13.75 10.0002 13.75C9.66864 13.75 9.3507 13.6183 9.11628 13.3838C8.88186 13.1494 8.75016 12.8315 8.75016 12.5C8.75016 12.1684 8.88186 11.8505 9.11628 11.6161C9.3507 11.3817 9.66864 11.25 10.0002 11.25ZM10.0002 2.91663C9.44763 2.91663 8.91773 3.13612 8.52702 3.52682C8.13632 3.91752 7.91683 4.44743 7.91683 4.99996V6.66663H12.0835V4.99996C12.0835 4.44743 11.864 3.91752 11.4733 3.52682C11.0826 3.13612 10.5527 2.91663 10.0002 2.91663Z"
      fill="currentColor"
    />
  ),
})
