import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { UseFormRegister } from 'react-hook-form'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { Section } from 'components/BackOffice/components'
import { InputWrapper } from 'components/InputWrapper'

interface Props {
  register: UseFormRegister<ShopProductInput>
}

export const SKU: React.FC<Props> = ({ register }) => {
  const { t } = useTranslation('backOffice')

  return (
    <Section
      title={t('Product.skuSectionTitleSkuOptional')}
      description={t('Product.skuSectionDescriptionStockKeepingUnit')}
    >
      <InputWrapper {...register('sku')} label={t('Product.skuSectionInputLabel')} variant="outline" w="42" maxW="full" />
    </Section>
  )
}
