import React from 'react'
import Image from 'next/legacy/image'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { VStack, Box, Button, Heading, Icon, SlideFade, ScaleFade } from '@chakra-ui/react'
import { FiArrowLeft } from 'react-icons/fi'
import { getRoute } from 'utils/helpers'
import { Card } from 'components/Card'
import { Description } from 'components/Description'
import { Header } from './Header'

interface Props {
  username?: string
  name?: string
}

export const EmptyCart: React.FC<Props> = ({ name, username }) => {
  const { t } = useTranslation('username')
  return (
    <>
      <Header name={name || username} username={username} />
      <Box w={{ base: 'full', md: 'auto' }} alignSelf="center">
        <ScaleFade in initialScale={0.95}>
          <Card w={{ base: 'full', md: '3xl' }} maxW="full" py="16">
            <VStack spacing="8">
              <SlideFade in delay={0.25} offsetX="-40px" offsetY="0">
                <Box
                  position="relative"
                  width={{ base: '84px', md: '132px' }}
                  height={{ base: '80px', md: '125px' }}
                >
                  <Image
                    src="/images/empty-cart.svg"
                    objectFit="cover"
                    layout="fill"
                    alt="empty pot"
                  />
                </Box>
              </SlideFade>
              <VStack spacing={{ base: 2, md: 4 }} textAlign="center">
                <SlideFade in delay={0.33} offsetY="10px">
                  <Heading size="lg">{t('Cart.yourCartIsEmpty')}</Heading>
                </SlideFade>
                <SlideFade in delay={0.66} offsetY="10px">
                  <Description>
                    {username ? (
                      <>
                        {' '}
                        {t('Cart.returnToShop', { name })}
                        <br />
                        {t('Cart.andCheckOutTheirCoolProducts')}
                      </>
                    ) : (
                      t('Cart.returnHome')
                    )}
                  </Description>
                </SlideFade>
              </VStack>
              <SlideFade in delay={0.75} offsetY="15px">
                <Button
                  as={Link}
                  href={
                    username
                      ? getRoute('profile', username, { tab: 'shop' })
                      : getRoute('home', 'creators')
                  }
                  leftIcon={<Icon as={FiArrowLeft} />}
                >
                  {username ? t('Cart.backToTheShop') : t('Cart.showCreators')}
                </Button>
              </SlideFade>
            </VStack>
          </Card>
        </ScaleFade>
      </Box>
    </>
  )
}
