import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { FormProvider, useForm } from 'react-hook-form'
import { amountPattern } from 'components/AmountInput'
import { Stepper } from './Stepper'
import { Intro } from './Stepper/Intro'
import { Amount } from './Stepper/Amount'
import { EmbedProps, WidgetEmbedForm } from './constants'
import { PaymentMethod } from './Stepper/PaymentMethod'
import { ThankYou } from './Stepper/ThankYou'

export const OneTime: React.FC<Omit<EmbedProps, 'id'>> = ({
  profile,
  defaultDonationAmount = 500,
  onPaid,
}) => {
  const { t } = useTranslation('common')
  const [paymentId, setPaymentId] = React.useState<string>()
  const [step, setStep] = React.useState(0)
  const form = useForm<WidgetEmbedForm>({
    defaultValues: {
      amountCents: defaultDonationAmount,
      isRecurringDonation: false,
    },
  })
  const { getValues } = form
  const canGoNext = React.useCallback(() => {
    if (step === 1) {
      const amount = getValues('amountCents')
      return !!amount && amountPattern.test(amount.toString())
    }
    // TODO: Disable swipe to next slide when on payment page, only button should work
    if (step === 2) {
      return false
    }
    return true
  }, [step, getValues])
  return (
    <FormProvider {...form}>
      <Stepper canGoNext={canGoNext} step={step} setStep={setStep} width="full" pt="6" px="6">
        <Intro pb="9" profile={profile} />
        <Amount title={t('chooseAmount')} pb="9" profile={profile} />
        <PaymentMethod
          title={t('choosePayment')}
          pb="9"
          onPaid={onPaid}
          setPaymentId={setPaymentId}
          profile={profile}
          isEmailRequired
        />
        <ThankYou profile={profile} paymentId={paymentId} isPreviousSwiperble={false} pb="4" />
      </Stepper>
    </FormProvider>
  )
}
