import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Heading, Divider, Stack, MenuItem, Button } from '@chakra-ui/react'
import {
  BackofficeUpdateOrderInput,
  OrderStatusEnum,
  useBackofficeUpdateOrderMutation,
} from 'src/generated/graphql-frontend'
import { Dropdown } from 'components/Dropdown'
import { Controller, useForm } from 'react-hook-form'
import { InputWrapper } from 'components/InputWrapper'

interface Props {
  orderId: string
  status: OrderStatusEnum
  trackingUrl?: string | null
  showShipping?: boolean
}

export const OrderFulfillment: React.FC<Props> = ({
  orderId,
  status,
  showShipping,
  trackingUrl,
}) => {
  const { t } = useTranslation('backOffice')
  const {
    formState: { isSubmitting },
    control,
    register,
    handleSubmit,
  } = useForm<BackofficeUpdateOrderInput>({
    defaultValues: {
      status,
      trackingUrl,
    },
  })
  const [updateOrder] = useBackofficeUpdateOrderMutation()
  const handleFormSubmit = React.useCallback(
    async (data: BackofficeUpdateOrderInput) => {
      await updateOrder({
        variables: {
          id: orderId,
          inputData: {
            ...data,
          },
        },
      })
    },
    [orderId]
  )
  return (
    <Stack spacing="5">
      <Heading size="lg">{t('ShopOrder.headingFulfillment')}</Heading>
      <Divider />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="6">
          <Stack spacing="4">
            <Heading size="md">{t('ShopOrder.headingOrderStatus')}</Heading>
            <Controller
              name="status"
              control={control}
              rules={{ required: t('ShopOrder.selectOrderStatus') }}
              render={({ field, fieldState: { error } }) => (
                <Dropdown
                  {...field}
                  placeholder={t('ShopOrder.placeholderSelectOrderStatus')}
                  errorMessage={error?.message}
                >
                  {(Object.keys(OrderStatusEnum) as Array<keyof typeof OrderStatusEnum>).map(
                    (key) => (
                      <MenuItem key={key} value={key}>
                        {t(`order:${key}`)}
                      </MenuItem>
                    )
                  )}
                </Dropdown>
              )}
            />
          </Stack>
          {showShipping && (
            <div>
              <Heading size="md">{t('ShopOrder.headingShipping')}</Heading>
              <InputWrapper
                {...register('trackingUrl')}
                label="Tracking URL"
                placeholder={t('ShopOrder.placeholderTrackingUrl')}
              />
            </div>
          )}
          <Button type="submit" isLoading={isSubmitting}>
            {t('ShopOrder.buttonUpdateOrder')}
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
