/* eslint-disable react/display-name */
import React from 'react'
import { Box, Grid, GridProps } from '@chakra-ui/react'
import { widgetColors, WidgetColorType } from 'components/Widget'

const borderPosition = { base: '0.5', md: '1.5' }

interface ColorPickGridProps extends Omit<GridProps, 'onChange'> {
  value: WidgetColorType
  hasWhite?: boolean
  onChange(colorScheme: WidgetColorType): void
}

export const ColorPickGrid: React.FC<ColorPickGridProps> = React.forwardRef(
  ({ hasWhite = true, value, onChange, ...props }, ref: React.Ref<HTMLDivElement>) => (
    <Grid ref={ref} gap="4" templateColumns="repeat(auto-fit, minmax(2.5rem, 1fr))" {...props}>
      {Object.entries(widgetColors)
        .slice(hasWhite ? 0 : 1)
        .map(([key]) => (
          <Box
            key={key}
            as="button"
            type="button"
            display="block"
            position="relative"
            bg={key}
            border="2px solid"
            borderColor={key === 'white' ? 'gray.200' : 'transparent'}
            borderRadius={{ base: 'lg', md: 'xl' }}
            onClick={() => onChange(key as WidgetColorType)}
          >
            <Box width="1px" height="0" paddingBottom="100%" />
            <Box
              position="absolute"
              left={borderPosition}
              right={borderPosition}
              top={borderPosition}
              bottom={borderPosition}
              borderRadius={{ base: 'lg', md: 'xl' }}
              border="2px solid"
              borderColor={key === 'white' ? 'primary.500' : 'white'}
              transitionProperty="common"
              transitionDuration="fast"
              opacity={key === value ? 1 : 0}
              transform={`scale(${key === value ? 1 : 0.85})`}
            />
          </Box>
        ))}
    </Grid>
  )
)
