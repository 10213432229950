import * as Sentry from '@sentry/nextjs'

type Config = {
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

export function register(swUrl: string, config?: Config) {
  if ('serviceWorker' in navigator) {
    return registerValidSW(swUrl, config)
  }
  return Promise.resolve()
}

async function registerValidSW(swUrl: string, config?: Config) {
  try {
    const registration = await navigator.serviceWorker.register(swUrl, { scope: '/' })
    registration.onupdatefound = () => {
      const installingWorker = registration.installing
      if (installingWorker == null) {
        return
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          config?.onUpdate?.(registration)
        }
      }
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        void registration.unregister()
      })
      .catch((error) => {
        Sentry.captureException(error)
      })
  }
}
