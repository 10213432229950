import { Button, Heading, Stack, Text, useDisclosure } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { TrackNamesEnum } from 'src/constants'
import { getRoute } from 'utils/helpers'
import { usePlatformConfig } from 'utils/hooks'
import mixpanel from 'utils/mixpanel'
import { Step, StepProps } from '..'
import { EmbedProps } from '../../constants'
import { ShareModal } from './ShareModal'

export const ThankYou: React.FC<
  Pick<EmbedProps, 'profile'> & StepProps & { paymentId?: string }
> = ({ profile, paymentId, goToStep, ...props }) => {
  const { t } = useTranslation('common')
  const config = usePlatformConfig()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { productSettings, name, username } = profile

  return (
    <Step {...props}>
      <config.components.LogoIcon mt="-12" width="90px" height="93px" />
      <Heading fontSize="lg" color={productSettings?.widgetColorScheme || undefined}>
        {t('success')}!
      </Heading>
      <Text px="6" textAlign="center" color={productSettings?.widgetColorScheme || undefined}>
        {t('donationOnWay', { username: name })}
      </Text>
      <Stack flex="1" spacing="2" alignSelf="stretch" justifySelf="stretch">
        <Button
          size="lg"
          onClick={() => {
            onOpen()
            mixpanel.track(TrackNamesEnum.PaymentButton, {
              paymentStep: 4,
              profile,
              buttonType: 'share',
            })
          }}
          colorScheme={productSettings?.widgetColorScheme || undefined}
        >
          {t('shareWithFriends')}
        </Button>
        <ShareModal
          url={`${process.env.NEXT_PUBLIC_APP_URL}${
            paymentId
              ? getRoute('profile', username, { tab: `donation/${paymentId}` })
              : getRoute('profile', username)
          }`}
          isOpen={isOpen}
          onClose={onClose}
        />
        <Button
          variant="ghost"
          size="lg"
          onClick={() => {
            mixpanel.track(TrackNamesEnum.PaymentButton, {
              paymentStep: 4,
              profile,
              buttonType: 'donate again',
            })
            goToStep?.(0)
          }}
          color={productSettings?.widgetColorScheme || undefined}
        >
          {t('donateAgain')}
        </Button>
      </Stack>
    </Step>
  )
}
