import React from 'react'
import { useRouter } from 'next/router'
import NextLink, { LinkProps } from 'next/link'
import { Icon, Button, ButtonProps } from '@chakra-ui/react'
import { IconType } from 'react-icons'

const iconSize = { base: '4', md: '5' }

type ExtendType = Partial<LinkProps> & Omit<ButtonProps, 'leftIcon'>

interface Props extends ExtendType {
  isFirst?: boolean
  leftIcon?: IconType
  href?: string
}

export const CustomMenuButton: React.FC<Props> = ({
  isFirst,
  leftIcon,
  fontSize,
  href,
  onClick,
  ...props
}) => {
  const { route } = useRouter()
  const isActive = href && ((!isFirst && route.startsWith(href)) || route === href)

  return (
    <Button
      as={href ? NextLink : undefined}
      href={href || undefined}
      onClick={onClick}
      variant="link"
      color="primary.500"
      justifyContent="flex-start"
      fontSize={fontSize || { base: '1rem', md: 'lg' }}
      px="0"
      py="0"
      iconSpacing="3"
      opacity={isActive ? 1 : 0.6}
      _hover={{
        textDecoration: 'none',
      }}
      _focus={{
        boxShadow: 'none',
      }}
      leftIcon={leftIcon ? <Icon as={leftIcon} w={iconSize} h={iconSize} /> : undefined}
      {...props}
    />
  )
}
