import React from 'react'
import { Box, BoxProps, AspectRatio } from '@chakra-ui/react'

interface Props extends BoxProps {
  url: string
  aspectRatio: number
}

export const PictureSlide: React.FC<Props> = ({ url, aspectRatio, ...rest }) => (
  <Box
    bgSize="cover"
    bgPos="center"
    bgRepeat="no-repeat"
    bgImage={`url(${url})`}
    position="relative"
    {...rest}
  >
    <AspectRatio ratio={aspectRatio}>
      <div />
    </AspectRatio>
  </Box>
)
