import React from 'react'
import { Button } from '@chakra-ui/react'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import { RefetchQueriesEnum } from 'src/constants'
import { useDeleteProductMutation } from 'src/generated/graphql-frontend'
import { SingleProductType } from '../../types'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  product?: SingleProductType | null
  onClose(): void
}

export const DeleteProductModal: React.FC<Props> = ({ product, onClose }) => {
  const { t } = useTranslation('backOffice')
  const [deleteProduct, { loading: deleteLoading }] = useDeleteProductMutation({
    refetchQueries: [RefetchQueriesEnum.BackofficeProducts],
  })
  return (
    <ModalOrDrawer
      maxW="3xl"
      isOpen={!!product}
      onClose={onClose}
      title={t('Shop.DeleteProductModal.title')}
      description={t('Shop.DeleteProductModal.description', {
        name: product?.name || 'Draft product',
      })}
      footer={
        <>
          <Button variant="outline" mr={2} onClick={onClose}>
            {t('Shop.DeleteProductModal.cancel')}
          </Button>
          <Button
            colorScheme="red"
            isLoading={deleteLoading}
            onClick={async () => {
              if (product) {
                await deleteProduct({ variables: { productId: product.id } })
              }
              onClose()
            }}
          >
            {t('Shop.DeleteProductModal.delete')}
          </Button>
        </>
      }
    >
      {t('Shop.DeleteProductModal.thisActionCannotBeUndone')}
    </ModalOrDrawer>
  )
}
