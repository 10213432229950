import { extendTheme } from '@chakra-ui/react'
import colors from './foundations/colors'
import fontSizes from 'utils/theme/foundations/fontSizes'
import radii from 'utils/theme/foundations/radius'
import fontWeights from 'utils/theme/foundations/fontWeights'

// import '@fontsource/libre-bodoni/400.css'
// import '@fontsource/libre-bodoni/500.css'
// import '@fontsource/libre-bodoni/700.css'
import '@fontsource/rubik/400.css'
import '@fontsource/rubik/500.css'
import '@fontsource/rubik/700.css'
import fonts from 'utils/theme/foundations/fonts'
import components from 'utils/theme/components'
import { headerHeight } from 'components/Header'

export const esoteriqTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  fontSizes,
  fonts,
  radii,
  colors,
  fontWeights,
  components,
  styles: {
    global: {
      body: {
        color: 'white',
        bg: 'gray.800',
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        height: '100vh',
        width: '100vw',
        overflowX: 'hidden',
      },
      '#__next': {
        paddingTop: headerHeight,
      },
    },
  },
})
