import React from 'react'
import {
  Box,
  Button,
  Center,
  chakra,
  Grid,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useClipboard,
} from '@chakra-ui/react'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import { FiLink } from 'react-icons/fi'
import { InputWrapper } from 'components/InputWrapper'
import { Description } from 'components/Description'

const ButtonWrapper = chakra(Box, {
  baseStyle: {
    overflow: 'hidden',
    borderRadius: 'lg',
    display: 'block',
    w: '16',
    h: '16',
    '> *': {
      display: 'block',
    },
  },
})

interface Props {
  url: string
  isOpen: boolean
  onClose(): void
}

export const ShareModal: React.FC<Props> = ({ url, isOpen, onClose }) => {
  const { hasCopied, onCopy } = useClipboard(url)
  return (
    <Modal size="2xl" onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent maxW="min(90vw, 40rem)" mt="24" pb={5}>
        <ModalHeader>Share it now!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid gap="4" templateColumns="repeat(auto-fit, minmax(64px, 1fr))">
            <Center>
              <ButtonWrapper>
                <FacebookShareButton url={url} onClick={() => onClose()}>
                  <FacebookIcon />
                </FacebookShareButton>
              </ButtonWrapper>
            </Center>
            <Center>
              <ButtonWrapper>
                <TwitterShareButton url={url} onClick={() => onClose()}>
                  <TwitterIcon />
                </TwitterShareButton>
              </ButtonWrapper>
            </Center>
            <Center>
              <ButtonWrapper>
                <WhatsappShareButton url={url} onClick={() => onClose()}>
                  <WhatsappIcon />
                </WhatsappShareButton>
              </ButtonWrapper>
            </Center>
            <Center>
              <ButtonWrapper>
                <RedditShareButton url={url} onClick={() => onClose()}>
                  <RedditIcon />
                </RedditShareButton>
              </ButtonWrapper>
            </Center>
            <Center>
              <ButtonWrapper>
                <LinkedinShareButton url={url} onClick={() => onClose()}>
                  <LinkedinIcon />
                </LinkedinShareButton>
              </ButtonWrapper>
            </Center>
          </Grid>
          <Description mt={{ base: '1.5rem', md: '2rem' }} mb={{ base: '0.5rem', md: '1rem' }}>
            Or copy this link
          </Description>
          <Stack direction="row">
            <InputWrapper
              flex="1"
              leftIcon={<FiLink />}
              inputLeftElementColor="primary.500"
              isReadOnly
              defaultValue={url}
              onClick={onCopy}
              _hover={{ cursor: 'pointer' }}
            />
            <Button mr={3} onClick={onCopy}>
              {hasCopied ? 'Copied' : 'Copy'}
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
