import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Stack, Divider, Button, useToast, useDisclosure } from '@chakra-ui/react'
import {
  OrderPaymentIntentError,
  useCartQuery,
  useCompleteFreeOrderMutation,
  useUpsertOrderCustomerDataMutation,
} from 'src/generated/graphql-frontend'
import { RefetchQueriesEnum } from 'src/constants'
import { CurrencyType, getPrice } from 'utils/payments'
import { LoadingGeneral } from 'components/LoadingGeneral'
import {
  PaymentMethodOptions,
  SetSubmitHandlerRef,
  extractExtensionErrorMessage,
} from 'components/PaymentMethodOptions'
import { ErrorMessage } from 'components/ErrorMessage'
import { ProfileType } from 'components/Widget/constants'
import { useShopContext } from '../ShopContext'
import { ICheckoutForm } from './types'
import { AddressForm } from './AddressForm'
import { StepHeader } from './StepHeader'
import { PaymentRequestProvider } from 'utils/hooks'
import { PaymentSubmitResult } from 'components/PaymentMethodOptions/types'
import { useSession } from 'next-auth/react'
import { getIsLoggedIn } from 'utils/helpers'


const isCustomAmountEnabled = false
const platformName = process.env.NEXT_PUBLIC_APP_NAME

interface Props {
  orderId: string
  username: string
  amountCents: number
  requiresAddress?: boolean
  profile: Pick<ProfileType, 'currency' | 'productSettings'>
  minimumAmount: number
  onCustomAmountChange: (amount: number) => void
  onPaymentSuccess(): void
}

export const CheckoutForm: React.FC<Props> = ({
  orderId,
  username,
  profile,
  amountCents,
  requiresAddress,
  minimumAmount,
  onCustomAmountChange,
  onPaymentSuccess,
}) => {
  const [isLoadingShipping, setIsLoadingShipping] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFinished, setIsFinished] = React.useState(false)
  const [freeOrderError, setFreeOrderError] = React.useState<string | null>(null)

  const successfulPaymentIdRef = React.useRef<string | null>(null)
  const submitHandlerRef = React.useRef<SetSubmitHandlerRef>(null)
  const paymentDetailsRef = React.useRef<HTMLDivElement | null>(null)
  const submitOrderHandlerRef = React.useRef<typeof handleSubmitOrder | null>(null)
  const prevMinimumAmount = React.useRef(minimumAmount)
  const prevCountry = React.useRef<string>()

  const { data: session } = useSession()
  const { t } = useTranslation('username')
  const paymentToggle = useDisclosure()
  const toaster = useToast()
  const { refetchMyCarts } = useShopContext()
  const [upsertOrderContactInfo] = useUpsertOrderCustomerDataMutation()
  const [completeFreeOrder] = useCompleteFreeOrderMutation()

  const form = useForm<ICheckoutForm>({
    defaultValues: {
      email: getIsLoggedIn(session) ? session?.user?.email : undefined,
      paymentType: undefined,
      freeOrderCustomAmount: minimumAmount,
    },
  })

  const {
    control,
    watch,
    getValues,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = form

  useCartQuery({
    variables: { username },
    fetchPolicy: 'cache-only',
    onCompleted(data) {
      if (data.order) {
        const address = data.order.Address
        const contactInfo = data.order.ContactInfo
        prevCountry.current = address?.country ?? undefined
        reset({
          ...getValues(),
          ...(contactInfo && {
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName ?? undefined,
            email: contactInfo.email,
            phoneNumber: contactInfo.phoneNumber ?? undefined,
          }),
          ...(data.order.requiresAddress &&
            address && {
              line1: address.line1 ?? undefined,
              // TODO
              // additionalInfo: address.,
              city: address.city ?? undefined,
              zip: address.zip ?? undefined,
              ...(address.country && { country: { value: address.country, label: '' } }),
            }),
        })
      }
    },
  })

  const handleFreeOrder = React.useCallback(async () => {
    const { data } = await completeFreeOrder({
      variables: {
        orderId,
      },
    })

    if (data?.completeFreeOrder?.__typename === 'OrderPaymentIntentError') {
      setFreeOrderError(
        extractExtensionErrorMessage(data.completeFreeOrder as OrderPaymentIntentError)
      )
      return false
    }

    return true
  }, [orderId])

  const handleUpsertContactInfo = React.useCallback(async (data: ICheckoutForm) => {
    const { firstName, lastName, email, phoneNumber, line1, zip, city, country, state } = data
    return upsertOrderContactInfo({
      variables: {
        orderId,
        contactInfoInput: {
          firstName,
          lastName,
          email,
          phoneNumber,
        },
        ...(requiresAddress && {
          addressInput: {
            line1,
            zip,
            city,
            country,
            state,
          },
        }),
      },
    })
  }, [])

  const handleSubmitOrder = React.useCallback(
    async (data: ICheckoutForm | null) => {
      // TODO: Obtain address from Google Apple pay
      setIsLoading(true)

      if (data !== null) {
        const upsertRes = await handleUpsertContactInfo(data)

        if (!upsertRes) {
          toaster({
            position: 'top-right',
            status: 'error',
            title: 'Error',
            description: t('Cart.weArentAbleToSave', { platformName }),
          })
          return
        }
      }

      if (
        (amountCents === 0 && (await handleFreeOrder())) ||
        successfulPaymentIdRef.current ||
        (await submitHandlerRef?.current?.())?.isSuccess
        // TODO: paymentRequest show and listen to paymentMethod event
      ) {
        setIsFinished(true)
        void refetchMyCarts()
        onPaymentSuccess()
      } else {
        setIsLoading(false)
      }
    },
    [
      upsertOrderContactInfo,
      orderId,
      requiresAddress,
      amountCents,
      handleFreeOrder,
      successfulPaymentIdRef,
      toaster,
      t,
      refetchMyCarts,
      onPaymentSuccess,
    ]
  )

  React.useEffect(() => {
    submitOrderHandlerRef.current = handleSubmitOrder
  }, [handleSubmitOrder])

  React.useEffect(() => {
    const prevMin = prevMinimumAmount.current
    if (prevMin !== null && minimumAmount < prevMin) {
      setValue('freeOrderCustomAmount', minimumAmount)
    }
    prevMinimumAmount.current = minimumAmount
  }, [minimumAmount])

  const country = requiresAddress ? watch('country') : undefined

  React.useEffect(() => {
    if (country?.value !== prevCountry.current) {
      prevCountry.current = country?.value
      const changeCountry = async () => {
        setIsLoadingShipping(true)
        await upsertOrderContactInfo({
          variables: {
            orderId,
            addressInput: {
              country,
            },
          },
          refetchQueries: [RefetchQueriesEnum.Cart],
        })
        setIsLoadingShipping(false)
      }
      void changeCountry()
    }
  }, [country, orderId, upsertOrderContactInfo])

  const currency = profile.currency

  const handleContactInfoSubmit = React.useCallback(
    async (data: ICheckoutForm) => {
      await handleUpsertContactInfo(data)

      const { freeOrderCustomAmount } = data
      if (isCustomAmountEnabled && (typeof freeOrderCustomAmount === 'number')) {
        if (freeOrderCustomAmount === 0 || freeOrderCustomAmount >= minimumAmount) {
          onCustomAmountChange(freeOrderCustomAmount)

          if (freeOrderCustomAmount === 0) {
            setTimeout(() => {
              void handleSubmit(submitOrderHandlerRef.current!)()
            }, 100)
            return
          } else {
            paymentDetailsRef.current?.scrollIntoView({ behavior: 'smooth' })
          }
        } else {
          toaster({
            position: 'bottom',
            status: 'error',
            title: 'Error',
            description: t('Cart.donationMinError', { amount: getPrice(minimumAmount, currency) }),
          })
        }
      }

      paymentToggle.onOpen()
    },
    [
      paymentToggle,
      minimumAmount,
      onCustomAmountChange,
      handleUpsertContactInfo,
      handleSubmit,
      toaster,
      t,
      currency,
    ]
  )

  const handleGoNext = React.useCallback(
    (paymentResult: PaymentSubmitResult) => {
      if (paymentResult.isSuccess) {
        successfulPaymentIdRef.current = paymentResult.paymentId ?? null
        return handleSubmitOrder(null)
      }
    },
    [handleSubmitOrder]
  )

  const getEmail = React.useCallback(() => getValues('email'), [])
  const productInput = React.useMemo(() => ({ orderId }), [orderId])

  return (
    <FormProvider {...form}>
      <PaymentRequestProvider amount={amountCents} currency={profile.currency}>
        <Stack spacing="5">
          <StepHeader step={1} title={t('Cart.contactInformation')} isActive />
          <Divider />
          <form onSubmit={handleSubmit(handleContactInfoSubmit)}>
            <AddressForm
              showCustomAmountField={isCustomAmountEnabled}
              minimumAmount={minimumAmount}
              isCountryDisabled={isFinished || isLoading}
              requiresAddress={requiresAddress}
              submitBtnText={t('common:continue')}
              currencyName={currency}
              isSubmitLoading={
                amountCents === 0 ? isLoading : paymentToggle.isOpen ? undefined : isSubmitting
              }
            />
          </form>
          {amountCents > 0 ? (
            <>
              <Divider />
              <div ref={paymentDetailsRef}>
                <StepHeader
                  step={2}
                  title={t('Cart.paymentDetails')}
                  isActive={paymentToggle.isOpen}
                />
              </div>
              {paymentToggle.isOpen && (
                <>
                  <Divider />
                  {isLoadingShipping ? (
                    <LoadingGeneral py="12" loadingText={t('Cart.calculatingShippingPrice')} />
                  ) : (
                    <>
                      <Controller
                        control={control}
                        name="paymentType"
                        rules={{
                          required: t('common:selectPaymentOptionError'),
                        }}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                          <PaymentMethodOptions
                            ref={submitHandlerRef}
                            errorMessage={error?.message}
                            amountCents={amountCents}
                            currency={profile.currency as CurrencyType}
                            productInput={productInput}
                            bankConnection={profile.productSettings.BankConnections?.[0]}
                            size="md"
                            getEmail={getEmail}
                            onGoNext={handleGoNext}
                            {...field}
                          />
                        )}
                      />
                      <Button
                        onClick={handleSubmit(handleSubmitOrder)}
                        w="100%"
                        isDisabled={isFinished}
                        isLoading={isLoading}
                      >
                        {t('Cart.payPrice', { price: getPrice(amountCents, profile.currency) })}
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            freeOrderError && <ErrorMessage>{freeOrderError}</ErrorMessage>
          )}
        </Stack>
      </PaymentRequestProvider>
    </FormProvider>
  )
}
