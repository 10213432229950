import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { useSwapPushNotificationSubscriptionTokensMutation } from 'src/generated/graphql-frontend'
import { VAP_ID_KEY } from './constants'
import { firebaseApp, getFcmToken, setFcmToken } from './tokenUtils'

export function useTokenUpdate() {
  const [swapPushNotificationSubscription] = useSwapPushNotificationSubscriptionTokensMutation()
  async function getTokenWithStorageUpdate() {
    await navigator.serviceWorker.ready
    const registration = await navigator.serviceWorker.getRegistration()
    if ((await isSupported()) && registration) {
      const messaging = getMessaging(firebaseApp)
      const notificationsToken = await getToken(messaging, {
        vapidKey: VAP_ID_KEY,
        serviceWorkerRegistration: registration,
      })
      const localStorageNotificationToken = getFcmToken()
      if (localStorageNotificationToken && localStorageNotificationToken !== notificationsToken) {
        const res = await swapPushNotificationSubscription({
          variables: {
            oldToken: localStorageNotificationToken,
            newToken: notificationsToken,
          },
        })
        const newToken = res.data?.swapPushNotificationSubscriptionTokens?.token
        if (newToken) {
          setFcmToken(newToken)
        }
      }
      return notificationsToken
    }
    return null
  }

  return {
    getTokenWithStorageUpdate,
  }
}
