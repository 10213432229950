import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { Stack, Button, Icon, Tooltip, useToast } from '@chakra-ui/react'
import { MdRefresh } from 'react-icons/md'
import { IoMdAddCircle } from 'react-icons/io'
import { RefetchQueriesEnum } from 'src/constants'
import { PartialProduct, useGenerateProductVariantsMutation } from 'src/generated/graphql-frontend'
import { deepOmit } from 'utils/helpers'
import { OptionsForm } from 'components/Shop'
import { Line } from 'components/BackOffice/Shop/components/Line'
import { BorderWrapper } from '../BorderWrapper'
import { OptionItem } from './OptionItem'

const getDefaultOption = () => ({
  attrId: uuid(),
  name: '',
  values: [],
})

interface Props {
  productId: string
  productOptions?: PartialProduct['AttributeProduct']
  onUpdateProduct(): Promise<void>
}

export const Options: React.FC<Props> = ({ productOptions, productId, onUpdateProduct }) => {
  const { t } = useTranslation('backOffice')
  const toast = useToast()
  const [generateVariants] = useGenerateProductVariantsMutation({
    refetchQueries: [RefetchQueriesEnum.BackofficeProductVariants],
    onCompleted() {
      toast({
        title: t('Product.variantToastVariantsGenerated'),
        description: t('Product.variantToastDescription'),
      })
    },
  })
  const form = useForm<OptionsForm>({
    defaultValues: {
      options: productOptions?.length
        ? productOptions.map((o) => deepOmit(o, ['__typename']))
        : [getDefaultOption()],
    },
    mode: 'onChange',
  })
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid, isSubmitting },
  } = form
  const [openOptionIndex, setOpenOptionIndex] = React.useState<number | null>(
    productOptions?.length ? null : 0
  )
  const handleOptionClose = React.useCallback(() => setOpenOptionIndex(null), [])
  const { append } = useFieldArray({ control, name: 'options' })
  const fields = React.useMemo(() => getValues('options'), [productOptions, openOptionIndex])
  const handleAppendOption = React.useCallback(() => {
    const noNameOptionIndex = fields.findIndex(({ name }) => name?.trim().length === 0)
    if (noNameOptionIndex === -1) {
      append(getDefaultOption())
      setOpenOptionIndex(fields.length)
    } else {
      setOpenOptionIndex(noNameOptionIndex)
    }
  }, [fields])
  const handleGenerateVariants = React.useCallback(
    async ({ options }: OptionsForm) => {
      await onUpdateProduct()
      await generateVariants({
        variables: {
          options,
          productId,
        },
      })
      // TODO: scroll animate to variants
    },
    [onUpdateProduct]
  )
  const isDisabled = !isValid || fields.length === 0
  return (
    <FormProvider {...form}>
      <BorderWrapper padding="2.5">
        {fields.map(({ attrId, name, values }, index) => (
          <React.Fragment key={attrId}>
            <OptionItem
              index={index}
              attrId={attrId}
              name={name}
              values={values}
              isOpen={index === openOptionIndex}
              onOpen={setOpenOptionIndex}
              onClose={handleOptionClose}
            />
            <Line borderColor="inherit" />
          </React.Fragment>
        ))}
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          spacing="4"
          p="4"
        >
          <Button
            variant="ghost"
            leftIcon={<Icon as={IoMdAddCircle} width="5" height="5" />}
            iconSpacing="4"
            onClick={handleAppendOption}
          >
            {t('Product.optionsAddProductOption')}
          </Button>
          <Tooltip
            isDisabled={!isDisabled}
            hasArrow
            label={t('Product.optionsFirstCreateChangeProductOptions')}
          >
            {/* Wrapping in div, or else hover won't trigger Tooltip on disabled button */}
            <div>
              <Button
                colorScheme="green.400"
                leftIcon={<Icon as={MdRefresh} width="5" height="5" />}
                iconSpacing="4"
                onClick={handleSubmit(handleGenerateVariants)}
                isDisabled={isDisabled}
                isLoading={isSubmitting}
              >
                {t('Product.optionsButtonLabelGenerateProductVariants')}
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </BorderWrapper>
    </FormProvider>
  )
}
