import React from 'react'
import { useSession } from 'next-auth/react'
import { Box, Button, Stack, useDisclosure } from '@chakra-ui/react'
import { ThumbnailSizeEnum, useProfileQuery } from 'src/generated/graphql-frontend'
import { ShippingZonesList } from './ShippingZonesList'
import { AddShippingZone } from './AddShippingZone'
import { LoadingGeneral } from 'components/LoadingGeneral'
import { Section } from 'components/BackOffice/components'
import useTranslation from 'next-translate/useTranslation'

export const SettingsTab = () => {
  const { t } = useTranslation('backOffice')
  const session = useSession()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const profileId = session?.data?.user?.profile?.id
  const { data: profile } = useProfileQuery({
    variables: {
      id: profileId,
      size: ThumbnailSizeEnum.Medium,
      coverPictureSize: ThumbnailSizeEnum.CoverPicture,
    },
    skip: !profileId,
    fetchPolicy: 'cache-first',
  })
  if (!profile?.profile) {
    return <LoadingGeneral />
  }
  return (
    <Stack spacing="4">
      <Section title={t('ShippingZones.title')} description={t('ShippingZones.description')}>
        <Button alignSelf="flex-start" onClick={onOpen}>
          {t('ShippingZones.add')}
        </Button>
      </Section>
      <Box h="4" />
      <Section
        title={t('ShippingZones.activeShippingZonesTitle')}
        description={t('ShippingZones.activeShippingZonesDescription')}
      >
        <ShippingZonesList profile={profile.profile} />
      </Section>
      <AddShippingZone isOpen={isOpen} onClose={onClose} onSuccess={onClose} />
    </Stack>
  )
}
