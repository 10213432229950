import React from 'react'
import { Link as ChakraLink, LinkProps as ChakraLinkProps, LinkOverlay } from '@chakra-ui/react'
import NextLink, { LinkProps } from 'next/link'

interface Props extends Omit<ChakraLinkProps, 'href'> {
  href: LinkProps['href']
  children: React.ReactNode
  isLinkOverlay?: boolean
  style?: React.CSSProperties
}

const Link = React.forwardRef<HTMLAnchorElement, Props>(
  ({ href, children, style, isLinkOverlay, ...rest }, ref) => {
    let DynamicLink: typeof ChakraLink | typeof LinkOverlay = ChakraLink
    if (isLinkOverlay) {
      DynamicLink = LinkOverlay
    }
    return (
      <DynamicLink {...rest} as={NextLink} href={href as string /* @todo seems like TS didn't know NextLink is passed in as prop */} style={style} ref={ref}>
        {children}
      </DynamicLink>
    )
  }
)

Link.displayName = 'Link'

export default Link
