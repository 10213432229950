import React from 'react'
import { Icon, Flex, useBreakpointValue } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { TextTag } from 'components/TextTag'
import { HeaderItem } from '../types'

const ResponsiveLabel: React.FC<{
  label?: HeaderItem['label']
}> = ({ label }) => {
  const labelValue = useBreakpointValue(label as Parameters<typeof useBreakpointValue>[0])
  return labelValue as React.ReactElement
}

interface Props {
  icon?: IconType
  label?: HeaderItem['label']
}

export const Header: React.FC<Props> = ({ icon, label }) => (
  <Flex alignItems="center" color="primary.500" opacity={0.6}>
    {icon && <Icon as={icon} flexShrink="0" width="3.5" height="3.5" mr="0.25rem" />}
    <TextTag>{typeof label === 'object' ? <ResponsiveLabel label={label} /> : label}</TextTag>
  </Flex>
)
