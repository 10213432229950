import React from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement, StripeElementChangeEvent } from '@stripe/stripe-js'
import { useToast } from '@chakra-ui/react'
import {
  useCompleteSubscriptionPaymentMutation,
  useCreateSubscriptionMutation,
} from 'src/generated/graphql-frontend'
import { StripeCard } from 'components/StripeCard'
import { SetSubmitHandlerRef } from 'components/PaymentMethodOptions/types'

interface Props {
  merchantId: string
  amount: number
  getEmail(): string
  setSubmitHandlerRef?: SetSubmitHandlerRef
}

export const RecurringStripeCheckout = React.forwardRef<SetSubmitHandlerRef, Props>(
  ({ merchantId, amount, getEmail }, ref) => {
    const toast = useToast()
    const stripe = useStripe()
    const elements = useElements()
    const [cardError, setCardError] = React.useState<string>()
    const displayError = React.useCallback(() => {
      toast({
        title: 'There was an error while using your card to subscribe.',
        position: 'top-right',
        status: 'error',
      })
    }, [])
    const [completeSubscriptionPayment] = useCompleteSubscriptionPaymentMutation({
      onCompleted: () => {
        toast({
          title: 'You were sucessfully subscribed.',
          position: 'top-right',
          status: 'success',
        })
      },
      onError: () => {
        displayError()
      },
    })
    const [createSubscription] = useCreateSubscriptionMutation()
    React.useImperativeHandle(ref, () => async () => {
      if (!stripe || !elements) {
        return {
          isSuccess: false,
        }
      }
      try {
        const card = elements?.getElement(CardElement)
        if (!card) {
          throw new Error('Card not specified')
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: card,
        })
        if (error || !paymentMethod) {
          if (error) {
            setCardError(error.message)
          }
          return { isSuccess: false }
        } else {
          const email = getEmail()
          const createSubscriptionRes = await createSubscription({
            variables: {
              inputData: {
                amount,
                email,
                paymentMethod: paymentMethod.id,
                merchantId,
              },
            },
          })
          if (createSubscriptionRes?.errors) {
            setCardError('An error occurred. Please try again.')
            return { isSuccess: false }
          }
          if (createSubscriptionRes.data) {
            const {
              createSubscription: { clientSecret, subscriptionId },
            } = createSubscriptionRes.data
            const res = await stripe?.confirmCardPayment(clientSecret, {
              payment_method: {
                card: elements?.getElement(CardElement) as StripeCardElement,
                billing_details: { email: getEmail() },
              },
            })
            if (res?.error) {
              setCardError(res.error.message)
              return { isSuccess: false }
            } else {
              void completeSubscriptionPayment({
                variables: { subscriptionId: subscriptionId },
              })
              return { isSuccess: true }
            }
          }
          return { isSuccess: false }
        }
      } catch (error) {
        setCardError((error as StripeElementChangeEvent['error'])?.message)
        return { isSuccess: false }
      }
    })
    return <StripeCard size="sm" cardError={cardError} setCardError={setCardError} />
  }
)

RecurringStripeCheckout.displayName = 'RecurringStripeCheckout'
