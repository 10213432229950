import React from 'react'
import { useRouter } from 'next/router'
import { chakra, Box, Icon, Stack, useToast } from '@chakra-ui/react'
import copy from 'copy-to-clipboard'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'
import { BiLink } from 'react-icons/bi'
import { SocialMediaIcons } from 'components/SocialMedia'
import useTranslation from 'next-translate/useTranslation'

const ShareIconButton = chakra('div', {
  baseStyle: {
    w: 6,
    h: 6,
    p: 0,
    bg: 'transparent',
    display: 'flex',
    alignItems: 'center',
  },
})

export const SharePanel = () => {
  const { t } = useTranslation('username')
  const router = useRouter()
  const url = `${process.env.NEXT_PUBLIC_APP_URL}${router.asPath}`
  const toast = useToast()
  const handleCopyToClipboard = React.useCallback(() => {
    if (copy(url)) {
      toast({
        title: t('Shop.sharePanelProductLinkCopied'),
        description: t('Shop.sharePanelProductLinkSuccessfullyCopied'),
        variant: 'left-accent',
        status: 'success',
      })
    }
  }, [toast, url])
  return (
    <Stack aria-label={t('Shop.sharePanelShareProduct')} direction="row" alignItems="center" py="5" spacing="5">
      <ShareIconButton as="button" aria-label={t('Shop.sharePanelCopyProductLink')} onClick={handleCopyToClipboard}>
        <Icon as={BiLink} display="block" w="6" h="6" />
      </ShareIconButton>
      <FacebookShareButton aria-label={t('Shop.sharePanelShareToFacebook')} url={url}>
        <ShareIconButton>
          <SocialMediaIcons.facebook.colored w="6" h="6" />
        </ShareIconButton>
      </FacebookShareButton>
      <WhatsappShareButton aria-label={t('Shop.sharePanelShareUsingWhatsapp')} url={url}>
        <Box borderRadius="full" overflow="hidden">
          <ShareIconButton>
            <WhatsappIcon />
          </ShareIconButton>
        </Box>
      </WhatsappShareButton>
      <TwitterShareButton aria-label={t('Shop.sharePanelShareOnTwitter')} url={url}>
        <ShareIconButton>
          <SocialMediaIcons.twitter.colored w="6" h="6" />
        </ShareIconButton>
      </TwitterShareButton>
    </Stack>
  )
}
