import React from 'react'
import { Box, Center, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { HiOutlineCheck } from 'react-icons/hi'
import { useOrderQuery } from 'src/generated/graphql-frontend'
import { Card } from 'components/Card'
import { OrderSummary } from './OrderSummary'
import { LoadingGeneral } from 'components/LoadingGeneral'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  id: string
  includeHeader?: boolean
}

export const OrderComplete: React.FC<Props> = ({ id, includeHeader }) => {
  const { t } = useTranslation('orderTracking')
  const { data, loading } = useOrderQuery({
    variables: { id },
  })
  if (loading || !data) {
    return <LoadingGeneral />
  }
  if (!data.order) {
    return <div>{t('orderNotFound')}</div>
  }
  return (
    <Center p="4">
      <Box maxW="600px">
        {includeHeader && (
          <Box p={10} px={{ base: 2, md: 'unset' }}>
            <Flex mx="auto" p="2.5" w="16" h="16" color="white" bg="green.600" borderRadius="full">
              <Icon as={HiOutlineCheck} w="full" h="full" />
            </Flex>
            <Heading textAlign="center" mt="10" size="xl">
              {t('orderConfirmation')}
            </Heading>
            <Text mt="6" px={{ base: 4, md: 10 }} textAlign="center" opacity={0.6}>
              {t('yourOrderHasBeenAccepted')}
            </Text>
          </Box>
        )}
        <Card>
          <OrderSummary {...data.order} />
        </Card>
      </Box>
    </Center>
  )
}
