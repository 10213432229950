import React from 'react'
import { Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { countries } from 'countries-list'
import {
  OrderPaymentIntentError,
  PaymentTypeEnum,
  useCreatePaymentIntentMutation,
  usePaymentIntentManualConfirmMutation,
} from 'src/generated/graphql-frontend'
import { BankTransferPaymentDetails } from 'components/BankTransferPaymentDetails'
import { CheckoutMethodProps, SetSubmitHandlerRef } from '../types'
import { extractExtensionErrorMessage as extractOrderPaymentErrorMessage } from './helpers'

export const BankTransfer = React.forwardRef<SetSubmitHandlerRef, CheckoutMethodProps>(
  (
    {
      amountCents,
      productInput,
      getEmail,
      setPaymentError,
      // colorScheme,
    },
    ref
  ) => {
    const { t } = useTranslation('username')
    const oldAmountRef = React.useRef<number>()
    const [paymentIntentManualConfirm] = usePaymentIntentManualConfirmMutation()
    const [paymentIntent, { data, loading }] = useCreatePaymentIntentMutation({
      onCompleted(data) {
        if (data.createPaymentIntent.bankTransferInfo) {
        }
      },
    })
    React.useEffect(() => {
      if (oldAmountRef.current !== amountCents) {
        void paymentIntent({
          variables: {
            amount: amountCents,
            type: PaymentTypeEnum.BankTransfer,
            productInput,
          },
        })
        oldAmountRef.current = amountCents
      }
    }, [amountCents])
    React.useImperativeHandle(ref, () => async () => {
      const id = data?.createPaymentIntent.bankTransferInfo?.variableSymbol
      if (id) {
        const { data } = await paymentIntentManualConfirm({
          variables: { id, email: getEmail() },
        })
        if (
          data?.paymentIntentManualConfirm?.__typename === 'PaymentResult' &&
          data.paymentIntentManualConfirm.success
        ) {
          return {
            paymentId: id,
            isSuccess: true,
          }
        } else if (data?.paymentIntentManualConfirm?.__typename === 'OrderPaymentIntentError') {
          setPaymentError?.(
            extractOrderPaymentErrorMessage(
              data.paymentIntentManualConfirm as OrderPaymentIntentError
            )
          )
          return {
            isSuccess: false,
          }
        }
      }
      return {
        isSuccess: false,
      }
    })

    const isLoading = loading || !data
    const { iban, swift, variableSymbol, bankName, countryCode } =
      data?.createPaymentIntent.bankTransferInfo || {}

    return (
      <div>
        <BankTransferPaymentDetails
          isLoading={isLoading}
          iban={iban!}
          swift={swift ?? ''}
          variableSymbol={variableSymbol!}
          bankName={bankName!}
          countryCode={countries[countryCode as keyof typeof countries]?.name ?? ''}
        />
        <Text fontSize="sm" textAlign="center">
          {t('Shop.clickOnButtonOnceYouSentTheAmountBankAccount')}
        </Text>
      </div>
    )
  }
)

BankTransfer.displayName = 'BankTransfer'
