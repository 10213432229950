import * as React from 'react'
import * as Sentry from '@sentry/nextjs'
import { isSupported } from 'firebase/messaging'
import { getIsBrave } from '../helpers'
import { getIsSwSupported } from './isSwSupported'
import { getUrl, initNotificationsUrlIndexedDb } from './notificationsUrlIndexedDb'
import { register } from './serviceWorkerRegistration'
import { useTokenUpdate } from './useTokenUpdate'

interface Props {
  children: React.ReactNode
}

let swUpdateInterval: number | null = null
const SW_UPDATE_INTERVAL = 60 * 60 * 1000 // 1 hour in ms
// eslint-disable-next-line @typescript-eslint/naming-convention
const swUrl = '/firebase-messaging-sw.js'

export function PushNotificationsWrapper({ children }: Props) {
  const { getTokenWithStorageUpdate } = useTokenUpdate()

  React.useEffect(() => {
    async function init() {
      if (typeof window === 'undefined' || !(await isSupported())) {
        return
      }

      const { isIphone } = await import('../isMobile')

      if (isIphone()) {
        await initNotificationsUrlIndexedDb()

        getUrl((url) => {
          window.location.href = url
        })
      }

      await register(swUrl)

      await navigator.serviceWorker.ready
      const registration = await navigator.serviceWorker.getRegistration()
      if (registration) {
        if ('Notification' in window && Notification.permission === 'granted') {
          try {
            await getTokenWithStorageUpdate()
          } catch (error) {
            if (!(await getIsBrave())) {
              Sentry.captureException(error)
            }
          }
        }

        navigator.serviceWorker.addEventListener('message', (event) => {
          const { messageType, notification } = event.data as {
            messageType: string
            notification: { click_action: string }
          }
          if (messageType === 'notification-clicked' && notification.click_action) {
            window.location.href = notification.click_action
          }
        })

        if (swUpdateInterval) {
          window.clearInterval(swUpdateInterval)
        }
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        swUpdateInterval = window.setInterval(async () => {
          if (await getIsSwSupported()) {
            const registration = await navigator.serviceWorker.getRegistration()
            if (!registration || registration.installing) {
              return
            }

            try {
              const response = await fetch(swUrl, {
                cache: 'no-store',
                headers: {
                  cache: 'no-store',
                  'cache-control': 'no-cache',
                },
              })
              if (response.status === 200) {
                await registration.update()
              } else {
                Sentry?.captureMessage?.('SW file fetch failed', {
                  extra: {
                    status: response.status,
                  },
                })
              }
            } catch {
              // ignore network error
            }
          }
        }, SW_UPDATE_INTERVAL)
      }
    }

    void init()

    // this should run only on init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}
