import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { Switch } from '@chakra-ui/react'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { Section } from 'components/BackOffice/components'

interface Props {
  control: Control<ShopProductInput>
  setValue: UseFormSetValue<ShopProductInput>
}

export const ShippableProduct: React.FC<Props> = ({ control, setValue }) => {
  const { t } = useTranslation('backOffice')
  React.useEffect(() => setValue('requiresAddress', true), [])
  return (
    <Section
      title={t('Product.inventoryShippableProduct')}
      description={t('Product.inventoryThisProductRequiresAddress')}
      titleRightComp={
        <Controller
          control={control}
          name="requiresAddress"
          render={({ field: { value, ...field } }) => (
            <Switch {...field} isChecked={value || false} />
          )}
        />
      }
    />
  )
}
