import { Box, Stack, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { BookingStatus } from 'src/generated/graphql-frontend'

const getBgColor = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.Cancelled:
      return 'peach.500'
    case BookingStatus.Confirmed:
      return 'green.400'
    default:
      return 'yellow.500'
  }
}

interface Props {
  status: BookingStatus
  type: 'text' | 'line'
}

export const TimeSlotStatusIndicator = ({ status, type }: Props) => {
  const { t } = useTranslation('backOffice')
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      {type === 'text' ? (
        <>
          <Box w={2} h={2} bgColor={getBgColor(status)} borderRadius="50%" />
          <Text>{t(`Bookings.Grid.${status}`)}</Text>
        </>
      ) : (
        <Box
          w={1}
          h="full"
          bgColor={getBgColor(status)}
          borderRadius="sm"
          borderTopRightRadius={0}
          borderBottomRightRadius={0}
        />
      )}
    </Stack>
  )
}
