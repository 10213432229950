const radii = {
  none: '0',
  sm: '0.125rem',
  base: '0.25rem',
  md: '0.5rem',
  lg: '0.625rem',
  xl: '0.875rem',
  '2xl': '1rem',
  '3xl': '1.5rem',
  '4xl': '2rem',
  '5xl': '2.25rem',
  full: '9999px',
}

export default radii
