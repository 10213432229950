import { Button } from '@chakra-ui/react'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { RefetchQueriesEnum } from 'src/constants'
import { useDeleteCreatorPostMutation } from 'src/generated/graphql-frontend'
import { DeletePostType } from './types'

interface Props {
  post?: DeletePostType | null
  onClose(): void
}

export const DeletePostModal: React.FC<Props> = ({ post, onClose }) => {
  const { t } = useTranslation('backOffice')
  const [deletePost, { loading: deleteLoading }] = useDeleteCreatorPostMutation({
    refetchQueries: [RefetchQueriesEnum.CreatorPosts],
  })
  return (
    <ModalOrDrawer
      maxW="3xl"
      isOpen={!!post}
      onClose={onClose}
      title={t('DeletePostModal.title')}
      description={t('DeletePostModal.description', {
        postTitle: post?.title || 'Draft post',
      })}
      footer={
        <>
          <Button variant="outline" mr={2} onClick={onClose}>
            {t('DeletePostModal.cancel')}
          </Button>
          <Button
            colorScheme="red"
            isLoading={deleteLoading}
            onClick={async () => {
              if (post) {
                await deletePost({ variables: { id: post.id } })
              }
              onClose()
            }}
          >
            {t('DeletePostModal.delete')}
          </Button>
        </>
      }
    >
      {t('DeletePostModal.thisActionCannotBeUndone')}
    </ModalOrDrawer>
  )
}
