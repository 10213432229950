import React from 'react'
import { Stack, Heading, Text } from '@chakra-ui/react'
import { ShippingZone as ShippingZoneType } from 'src/generated/graphql-frontend'
import { getPrice } from 'utils/payments'
import useTranslation from 'next-translate/useTranslation'

export const ShippingZone: React.FC<ShippingZoneType & { currency: string }> = ({
  id,
  name,
  amount,
  countryCodes,
  freeAbove,
  isOther,
  currency,
}) => {
  const { t } = useTranslation('backOffice')
  return (
    <Stack
      id={id}
      py="6"
      px="4"
      borderRadius="xl"
      boxShadow="0px 12px 32px rgba(20, 20, 20, 0.089)"
    >
      <Heading size="md">{name}</Heading>
      <Text>{getPrice(amount, currency)}</Text>
      <Text>
        {t('ShippingZones.freeAbove')}:{' '}
        {freeAbove ? getPrice(freeAbove, currency) : t('ShippingZones.notSpecified')}
      </Text>
      <Text>
        {t('ShippingZones.countries')}:{' '}
        {countryCodes ? countryCodes?.join(', ') : t('ShippingZones.noCountriesSpecified')}
      </Text>
      {isOther && <Text>{t('ShippingZones.forOtherCountries')}</Text>}
    </Stack>
  )
}
