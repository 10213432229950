import React from 'react'
import { Box, Stack, Divider, Heading, Text, LinkBox, Center, Icon } from '@chakra-ui/react'
import { HiOutlineChevronRight } from 'react-icons/hi'
import { getRoute } from 'utils/helpers'
import { getPrice } from 'utils/payments'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import Link from 'components/Link'
import { useShopContext } from './ShopContext'

// go to store, go to checkout
interface Props {
  isOpen: boolean
  onClose(): void
}

export const MyCartsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { myCarts } = useShopContext()
  return (
    <ModalOrDrawer
      maxW="3xl"
      title="Your shopping carts"
      description="Here you can see your carts from multiple merchants"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Stack spacing="4">
        {myCarts.map(
          (
            {
              id,
              amountCents,
              itemsCount,
              MerchantProfile: { username, name, currency, profilePicture },
            },
            index,
            { length }
          ) => (
            <React.Fragment key={id}>
              <LinkBox>
                <Stack
                  position="relative"
                  direction="row"
                  p="4"
                  spacing="4"
                  borderRadius="xl"
                  transitionProperty="common"
                  transitionDuration="fast"
                  _hover={{
                    bgColor: 'gray.200',
                  }}
                >
                  <Box
                    w="16"
                    h="16"
                    bgImage={`url(${profilePicture?.thumbnailSignedUrl ?? ''})`}
                    bgSize="cover"
                    bgPos="cover"
                    borderRadius="lg"
                  />
                  <Stack flex="1" spacing="2">
                    <Link isLinkOverlay href={getRoute('cart', username)}>
                      <Heading as="a" size="md">
                        {name}
                      </Heading>
                    </Link>
                    <Stack direction="row" spacing="1" pointerEvents="none" opacity={0.6}>
                      <span>Item count:</span>
                      <Text fontWeight="medium">{itemsCount}</Text>
                    </Stack>
                    <Stack direction="row" spacing="1">
                      <span>Subtotal:</span>
                      <Text fontWeight="medium">{getPrice(amountCents, currency)}</Text>
                    </Stack>
                  </Stack>
                  <Center>
                    <Icon as={HiOutlineChevronRight} w="8" h="8" />
                  </Center>
                </Stack>
              </LinkBox>
              {index < length - 1 && <Divider />}
            </React.Fragment>
          )
        )}
      </Stack>
    </ModalOrDrawer>
  )
}
