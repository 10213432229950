import React from 'react'
import { Button, Icon } from '@chakra-ui/react'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { RiHeartFill, RiBarcodeFill } from 'react-icons/ri'
import { MdLoop } from 'react-icons/md'
import { BiArrowToTop } from 'react-icons/bi'
import { IoMdImage } from 'react-icons/io'
import { useBackofficeProductVariantsQuery } from 'src/generated/graphql-frontend'
import { DataTable, EmptyTableContent, HeaderItem } from 'components/DataTable'
import { UploadFile } from 'components/Upload'
import { VariantRow } from './VariantRow'
import useTranslation from 'next-translate/useTranslation'

const headerItems: HeaderItem[] = [
  {
    icon: IoMdImage,
    showFrom: 'sm',
  },
  {
    icon: RiHeartFill,
    label: {
      base: 'Variant',
      md: 'Variant name',
    },
  },
  {
    icon: FaMoneyBillAlt,
    label: 'Price',
  },
  {
    icon: MdLoop,
    label: 'Quantity',
  },
  {
    icon: RiBarcodeFill,
    label: 'SKU',
    showFrom: 'sm',
  },
  {
    isFiller: true,
  },
]

interface Props {
  productId: string
  mainPictures: UploadFile[]
  currency: string
  trackInventory?: boolean
  onGoToOptions(): void
}

export const VariantsGrid: React.FC<Props> = ({
  productId,
  mainPictures,
  currency,
  trackInventory,
  onGoToOptions,
}) => {
  const { t } = useTranslation('backOffice')
  const { data, loading } = useBackofficeProductVariantsQuery({
    variables: {
      productId,
    },
  })
  return (
    <DataTable
      emptyComp={
        <EmptyTableContent
          ctaButton={
            <Button iconSpacing="3" leftIcon={<Icon as={BiArrowToTop} />} onClick={onGoToOptions}>
              {t('Product.variantsGridGoToProductOptions')}
            </Button>
          }
          imageWidth={{ base: '56px', md: '140px' }}
          imageHeight={{ base: '56px', md: '140px' }}
          imageUrl="/images/creator.svg"
          imageAlt="no variants"
          title={t('Product.variantsGridThisProductDoesntHaveVariantsYet')}
          description={t('Product.variantsGridCreateVariantsUsing')}
        />
      }
      headerItems={headerItems}
      itemKeyProp="id"
      renderTableRow={(item) => (
        <VariantRow
          key={item.id}
          {...item}
          mainPictures={mainPictures}
          currency={currency}
          trackInventory={trackInventory}
        />
      )}
      alignItems="center"
      templateColumns={{
        base: '1fr repeat(2, 2fr) auto',
        sm: 'auto 3fr repeat(3, 1fr) auto',
      }}
      columnGap="3"
      rowGap="5"
      data={data?.backofficeProductVariants}
      skeletonRowsCount={5}
      isLoading={loading}
    />
  )
}
