import React from 'react'
import { Wrap, WrapItem, Center, Icon } from '@chakra-ui/react'
import { BiChevronRight } from 'react-icons/bi'

type ChildType = React.ReactElement<{ color: string }>

interface Props {
  children: ChildType[]
}

export const Breadcrumbs: React.FC<Props> = ({ children }) => {
  const count = React.Children.count(children)
  return (
    <Wrap spacing="2">
      {React.Children.map(children, (child, index) => {
        const isLast = index < count - 1
        return (
          <>
            <WrapItem>
              <Center>
                {React.cloneElement(child , {
                  color: isLast ? 'gray.300' : 'primary.500',
                })}
              </Center>
            </WrapItem>
            {isLast && (
              <WrapItem>
                <Center h="full">
                  <Icon as={BiChevronRight} w="5" h="5" color="gray.300" />
                </Center>
              </WrapItem>
            )}
          </>
        )
      })}
    </Wrap>
  )
}
