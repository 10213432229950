import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Box, Stack, Wrap, Heading, Text, Button } from '@chakra-ui/react'
import { PartialProduct } from 'src/generated/graphql-frontend'
import { GrabButton } from './GrabButton'
import { OptionForm } from './OptionForm'

type AttributeProduct = PartialProduct['AttributeProduct'][number]

interface Props extends AttributeProduct {
  index: number
  isOpen: boolean
  onOpen(index: number): void
  onClose(): void
}

export const OptionItem: React.FC<Props> = ({
  index,
  attrId,
  name,
  values,
  isOpen,
  onOpen,
  onClose,
}) => {
  const { t } = useTranslation('backOffice')
  const handleOpen = React.useCallback(() => onOpen(index), [index, onOpen])
  return (
    <Box p="5">
      {isOpen ? (
        <OptionForm index={index} id={attrId} values={values} onDone={onClose} />
      ) : (
        <Stack direction="row" alignItems="center" spacing="5" userSelect="none">
          <GrabButton />
          <Stack spacing="2" flex="1">
            <Heading size="md">{name}</Heading>
            {values.length ? (
              <Wrap spacing="1">
                {values.map(({ valueId, value }) => (
                  <Box
                    key={valueId}
                    bgColor="gray.250"
                    fontSize="sm"
                    fontWeight="medium"
                    px="4"
                    py="1"
                    borderRadius="full"
                  >
                    {value}
                  </Box>
                ))}
              </Wrap>
            ) : (
              <Text fontSize="sm" opacity={0.65}>
                {t('Product.optionItemNoValuesYet')}
              </Text>
            )}
          </Stack>
          <Button variant="outline" size="sm" onClick={handleOpen}>
            {t('Product.optionItemEdit')}
          </Button>
        </Stack>
      )}
    </Box>
  )
}
