import React from 'react'
import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export const sizes = {
  xs: {
    borderRadius: { base: 'xl', md: '3xl' },
    borderRadiusPicture: { base: 'md', md: 'xl' },
    p: 0,
    dimensions: '40px',
  },
  sm: {
    borderRadius: { base: 'xl', md: '3xl' },
    borderRadiusPicture: { base: 'md', md: 'xl' },
    p: { base: '1.5', md: '2' },
    dimensions: { base: '93px', md: '120px' },
  },
  md: {
    borderRadius: { base: '1.75rem', md: '5xl' },
    borderRadiusPicture: { base: '3xl', md: '1.875rem' },
    p: { base: '2', md: '3' },
    dimensions: { base: '7.5rem', md: '40' },
  },
}

interface ProfilePictureProps extends HTMLChakraProps<'div'> {
  pictureUrl: string
  size?: 'xs' | 'sm' | 'md'
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
  pictureUrl,
  size = 'md',
  children,
  ...props
}) => {
  const componentSize = sizes[size]
  return (
    <chakra.div
      flex="none"
      bg="white"
      borderRadius={componentSize.borderRadius}
      width={componentSize.dimensions}
      height={componentSize.dimensions}
      p={componentSize.p}
      {...props}
    >
      {children}
      <chakra.div
        bg={`url(${pictureUrl}) center`}
        bgSize="cover"
        width="100%"
        height="100%"
        borderRadius={componentSize.borderRadiusPicture}
      />
    </chakra.div>
  )
}
