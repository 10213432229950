import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import { Box, Grid, Flex, Button } from '@chakra-ui/react'
import { getPrice } from 'utils/payments'
import { InputWrapper, emailValidationRegisterOptions } from 'components/InputWrapper'
import { Label } from 'components/Label'
import { CountrySelect } from 'components/CountrySelect'
import { AmountInput } from 'components/AmountInput'
import { ICheckoutForm } from './types'

interface Props {
  requiresAddress?: boolean
  showCustomAmountField?: boolean
  submitBtnText?: string
  isCountryDisabled?: boolean
  currencyName: string
  minimumAmount?: number
  isSubmitLoading?: boolean
}

// TODO: https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
export const AddressForm: React.FC<Props> = ({
  requiresAddress,
  showCustomAmountField,
  submitBtnText,
  isCountryDisabled,
  currencyName,
  minimumAmount = 0,
  isSubmitLoading,
}) => {
  const { t } = useTranslation('username')
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ICheckoutForm>()
  return (
    <Grid
      gap={{ base: '5', md: '10' }}
      templateColumns={{
        base: '1fr',
        md: '1fr 1fr',
      }}
    >
      <InputWrapper
        {...register('firstName', { required: t('Cart.firstNameIsRequired') })}
        variant="outline"
        label={t('Cart.firstName')}
        errorMessage={errors.firstName?.message}
      />
      <InputWrapper
        {...register('lastName', { required: t('Cart.lastNameIsRequired') })}
        variant="outline"
        label={t('Cart.lastName')}
        errorMessage={errors.lastName?.message}
      />
      <InputWrapper
        {...register('email', emailValidationRegisterOptions(t))}
        variant="outline"
        label={t('Cart.emailAddress')}
        errorMessage={errors.email?.message}
      />
      <InputWrapper
        {...register('phoneNumber', {
          required: requiresAddress ? t('Cart.phoneNumberIsRequired') : undefined,
        })}
        variant="outline"
        label={t('Cart.phoneNumber')}
        errorMessage={errors.phoneNumber?.message}
      />
      {requiresAddress && (
        <>
          <Box gridColumn="1/-1">
            <InputWrapper
              {...register('line1', { required: t('Cart.streetHouseNumberIsRequired') })}
              maxW="full"
              variant="outline"
              label={t('Cart.streetNameAndHouseNumber')}
              errorMessage={errors.line1?.message}
            />
          </Box>
          <Box gridColumn="1/-1">
            <InputWrapper
              {...register('additionalInfo')}
              maxW="full"
              variant="outline"
              label={t('Cart.additionalDeliveryInfoOptional')}
            />
          </Box>
          <InputWrapper
            {...register('city', { required: t('Cart.cityIsRequired') })}
            variant="outline"
            label={t('Cart.formLabelCity')}
            errorMessage={errors.city?.message}
          />
          <InputWrapper
            {...register('zip', { required: t('Cart.postalCodeIsRequired') })}
            variant="outline"
            label={t('Cart.postalCode')}
            errorMessage={errors.zip?.message}
          />
          <Box gridColumn="1/-1">
            <Label>{t('Cart.selectShippingCountry')}</Label>
            <CountrySelect
              control={control}
              name="country"
              placeholder={t('Cart.chooseYourShippingCountry')}
              isRequired
              isDisabled={isCountryDisabled}
            />
          </Box>
        </>
      )}
      {showCustomAmountField ? (
        <Controller
          control={control}
          name="freeOrderCustomAmount"
          rules={{
            min: {
              value: minimumAmount,
              message: t('Cart.payAtLeastPrice', { price: getPrice(minimumAmount, currencyName) }),
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <AmountInput
              width="full"
              variant="outline"
              label={t('Cart.payWhatYouWant')}
              currencyName={currencyName}
              errorMessage={error?.message}
              {...field}
            />
          )}
        />
      ) : (
        <Box />
      )}
      <Flex justifyContent="flex-end" alignItems="flex-end">
        <Button type="submit" isLoading={isSubmitLoading}>
          {submitBtnText}
        </Button>
      </Flex>
    </Grid>
  )
}
