import React from 'react'
import { MenuItem } from '@chakra-ui/react'
import { Dropdown, DropdownProps } from 'components/Dropdown'
import { AttributeTypeEnum } from 'src/generated/graphql-frontend'

export const attributeTypes: {
  key: keyof typeof AttributeTypeEnum
  label: string
}[] = [
  {
    key: 'Dropdown',
    label: 'Dropdown',
  },
  {
    key: 'Calendar',
    label: 'Day picker',
  },
  {
    key: 'TimeCalendar',
    label: 'Date & time picker',
  },
]

export const AttributeTypeDropdown: React.FC<Partial<DropdownProps>> = (props) => (
  <Dropdown {...props} placeholder="Attribute value type">
    {attributeTypes.map(({ key, label }) => (
      <MenuItem key={key} value={key}>
        {label}
      </MenuItem>
    ))}
  </Dropdown>
)
