import React from 'react'
import { OrderStatusEnum } from 'src/generated/graphql-frontend'
import { OrdersGrid } from './components'
import { NewProductProps } from './types'

export const CartsTab: React.FC<NewProductProps> = ({ onNewProduct }) => (
  <OrdersGrid
    filterData={{
      status: [OrderStatusEnum.Draft],
    }}
    onNewProduct={onNewProduct}
  />
)
