import React from 'react'
import { CONFIGS } from 'utils/configs'

export const usePlatformConfig = (hostnameParam?: string) =>
  React.useMemo(() => {
    const hostname = hostnameParam ?? (typeof location === 'object' ? location.hostname : 'localhost')
    const domainOnly = hostname.split('.').slice(-2).join('.')
    const config = CONFIGS[domainOnly] ?? CONFIGS.localhost
    if (typeof window !== 'undefined') {
      localStorage.setItem('chakra-ui-color-mode', CONFIGS[domainOnly]?.isDarkMode ? 'dark' : 'light')
    }
    return config
  }, [hostnameParam])
