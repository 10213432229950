import { Decimal } from 'decimal.js'
import { PaymentTypeEnum } from 'src/generated/graphql-backend'

export const GqlErrorCode = {
  // TODO: Include more stripe errors
  StripeGeneralError: 'StripeGeneralError',
  AlreadyRefunded: 'AlreadyRefunded',
}

export const EventType = {
  invoiceCreated: 'invoice.created',
  invoiceUpdated: 'invoice.updated',
  invoiceDeleted: 'invoice.deleted',
  invoicePaid: 'invoice.paid',
  invoicePaymentSucceeded: 'invoice.payment_succeeded',
  invoicePaymentFailed: 'invoice.payment_failed',
  customerSubscriptionCreated: 'customer.subscription.created',
  customerSubscriptionUpdated: 'customer.subscription.updated',
  customerSubscriptionDeleted: 'customer.subscription.deleted',
} as const

export const Currencies = {
  usd: {
    name: 'usd',
    label: 'US Dollar',
    symbol: '$',
  },
  eur: {
    name: 'eur',
    label: 'Euro',
    symbol: '€',
  },
}

export type CurrencyType = keyof typeof Currencies

// TODO: One source of truth see: resolvers/payment/PaymentStatusEnum
export enum PaymentStatus {
  CANCELLED = 'Cancelled',
  FAILED = 'Failed',
  ON_HOLD = 'OnHold',
  PAID = 'Paid',
  PENDING = 'Pending',
  REFUNDED = 'Refunded',
}

export const isPaymentTypeCard = (type: PaymentTypeEnum) => [PaymentTypeEnum.Card, PaymentTypeEnum.ApplePay, PaymentTypeEnum.GooglePay].includes(type) 

export const getPrice = (amount: number, currencySymbol: string, includePriceSymbol = true) => {
  let price = parseFloat((amount / 100).toFixed(2))
  if (Number.isNaN(price)) {
    price = 0
  }
  const withCurrency =
    currencySymbol === 'usd'
      ? [includePriceSymbol && Currencies.usd.symbol, price]
      : [price, includePriceSymbol && Currencies[currencySymbol as CurrencyType].symbol]
  return withCurrency.filter((item) => item !== false).join('')
}

export const amountToCents = (amount: number) => new Decimal(amount).mul(100).toNumber()

export const centsToAmount = (amountInCents: number) =>
  parseFloat((Math.floor(amountInCents) / 100).toFixed(2))
