import React from 'react'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { Control } from 'react-hook-form'
import {chakra, Icon, HStack, Box, Heading, Button, Collapse, Stack, useBreakpointValue} from '@chakra-ui/react'
import { HiArrowLeft } from 'react-icons/hi'
import { FiExternalLink } from 'react-icons/fi'
import { getRoute } from 'utils/helpers'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { TextTag } from 'components/TextTag'
import { useProductCategories } from '../components/Sections'
import useTranslation from 'next-translate/useTranslation'

export interface SaveOptions {
  publish?: boolean
  redirect?: boolean
}

interface Props {
  name: string
  type: string
  isEnabled?: boolean
  slug?: string | null
  control: Control<ShopProductInput>
  goBackHref: string
  isSaving?: boolean
  onSave: (options?: SaveOptions) => Promise<void>
  onUnpublish(): Promise<void>
}

export const FormHeader: React.FC<Props> = ({
  name,
  type,
  isEnabled,
  slug,
  goBackHref,
  isSaving,
  onSave,
  onUnpublish,
}) => {
  const { t } = useTranslation('backOffice')
  const isMobile = useBreakpointValue({ base: true, md: false })
  const productCategories = useProductCategories()
  const session = useSession()
  const username = session.data?.user?.profile?.username
  return (
    <HStack alignItems="center" px="3" spacing={{ base: '3', sm: '6' }}>
      <chakra.a
        as={Link}
        href={goBackHref}
        flex="none"
        width={{ base: '6', sm: '8' }}
        height={{ base: '6', sm: '8' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon as={HiArrowLeft} w={{ base: '4', sm: '6' }} h={{ base: '4', sm: '6' }} />
      </chakra.a>
      <Box overflow="hidden" flex="1">
        <Collapse in={!!name} animateOpacity>
          <Heading
            as={Link}
            href={getRoute('profile', username!, {
              productSlug: slug!,
              isPreview: !isEnabled,
            })}
            target="_blank"
            maxW="full"
            size="sm"
            pb="1.5"
            display="flex"
            alignItems="center"
          >
            <chakra.p isTruncated>{name}</chakra.p>
            <Icon as={FiExternalLink} flex="none" ml="2" width="3.5" height="3.5" />
          </Heading>
        </Collapse>
        <TextTag fontSize={{ base: 'xx-small', sm: 'xs' }} color="primary.500" opacity={0.65} isTruncated>
          {productCategories.find(({ key }) => key === type)?.label}
        </TextTag>
      </Box>
      <Stack direction="row" alignItems="center">
        {isEnabled ? (
          <>
            <Button variant="outline" size={isMobile ? 'sm' : undefined} isDisabled={isSaving} onClick={() => onUnpublish()}>
              {t('Product.headerButtonLabelUnpublish')}
            </Button>
            <Button variant="solid" size={isMobile ? 'sm' : undefined} isDisabled={isSaving} onClick={() => onSave({ publish: true, redirect: true })}>
              {t('Product.headerButtonLabelSaveChanges')}
            </Button>
          </>
        ) : (
          <>
            <Button variant="outline" size={isMobile ? 'sm' : undefined} isDisabled={isSaving} onClick={() => onSave()}>
              {t('Product.headerButtonLabelSaveDraft')}
            </Button>
            <Button variant="solid" size={isMobile ? 'sm' : undefined} isDisabled={isSaving} onClick={() => onSave({ publish: true, redirect: true })}>
              {t(`Product.headerButtonLabelSaveAndPublish${isMobile ? 'Short' : ''}`)}
            </Button>
          </>
        )}
      </Stack>
    </HStack>
  )
}
