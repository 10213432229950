import React, { useEffect } from 'react'
import { useElements, useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import { PaymentRequestPaymentMethodEvent, StripeElementChangeEvent } from '@stripe/stripe-js'
import { CheckoutMethodProps } from '../types'
import {
  PaymentTypeEnum,
  useCompleteStripePaymentMutation,
  useCreatePaymentIntentMutation,
} from 'src/generated/graphql-frontend'
import { extractExtensionErrorMessage } from './helpers'
import { Box } from '@chakra-ui/layout'
import { usePaymentRequest } from 'utils/hooks'

export const PaymentRequestComponent = ({
  amountCents,
  productInput,
  getEmail,
  onGoNext,
  setPaymentError,
  onObtainedPersonalInfo,
}: CheckoutMethodProps) => {
  const { paymentRequest, canMakePaymentResult } = usePaymentRequest()
  const stripe = useStripe()
  const elements = useElements()

  const [createPaymentIntent] = useCreatePaymentIntentMutation()
  const [completeStripePayment] = useCompleteStripePaymentMutation({
    onError(error) {
      console.error(error)
      setPaymentError?.(error.message)
    },
  })

  useEffect(() => {
    const onPaymentMethodObtained = async (event: PaymentRequestPaymentMethodEvent) => {
      console.log('payment method', event)

      const personalInfo = event.paymentMethod.billing_details
      if (personalInfo && onObtainedPersonalInfo) {
        await onObtainedPersonalInfo(personalInfo)
      }

      const result = await (async () => {
        if (!stripe || !elements) {
          return { isSuccess: false }
        }

        const { data } = await createPaymentIntent({
          variables: {
            amount: amountCents,
            type:
              event.walletName === 'applePay'
                ? PaymentTypeEnum.ApplePay
                : event.walletName === 'googlePay'
                  ? PaymentTypeEnum.GooglePay
                  : PaymentTypeEnum.Card,
            productInput,
          },
        })
        const clientSecret = data?.createPaymentIntent.clientSecret
        if (!clientSecret) {
          return { isSuccess: false }
        }
        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id,
          }
        )
        try {
          if (stripeError) {
            event.complete('fail')
            throw new Error(stripeError.message)
          } else {
            event.complete('success')

            if (paymentIntent?.status === 'requires_capture') {
              const { data } = await completeStripePayment({
                variables: { stripePaymentId: paymentIntent.id, email: getEmail() },
              })
              if (
                data?.completeStripePayment?.__typename === 'PaymentResult' &&
                data.completeStripePayment.success
              ) {
                return {
                  paymentId: paymentIntent.id,
                  isSuccess: true,
                }
              } else if (data?.completeStripePayment?.__typename === 'OrderPaymentIntentError') {
                // TODO: Make a generic component and track failed payment ordersz
                const error = extractExtensionErrorMessage(data.completeStripePayment)
                setPaymentError?.(error)
                event.complete('fail')
                return {
                  isSuccess: false,
                }
              }
            }
          }
        } catch (error) {
          event.complete('fail')
          setPaymentError?.((error as StripeElementChangeEvent['error'])?.message)
          console.error(error)
          throw error
        }
        return {
          isSuccess: false,
        }
      })()

      onGoNext?.(result)
    }

    paymentRequest?.on('paymentmethod', onPaymentMethodObtained)

    return () => {
      paymentRequest?.off('paymentmethod', onPaymentMethodObtained)
    }
  }, [
    elements,
    getEmail,
    paymentRequest,
    productInput,
    stripe,
    onObtainedPersonalInfo,
    onGoNext,
    amountCents,
  ])

  if (paymentRequest && (canMakePaymentResult?.applePay || canMakePaymentResult?.googlePay)) {
    return (
      <>
        <Box position="relative">
          <PaymentRequestButtonElement options={{ paymentRequest }} />
          {!paymentRequest && <Box position="absolute" top={0} left={0} right={0} bottom={0} />}
        </Box>
      </>
    )
  }

  return null
}

PaymentRequestComponent.displayName = 'PaymentRequestComponent'
