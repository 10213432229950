import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { addMonths, getDaysInMonth, format, startOfMonth } from 'date-fns'
import { Grid, Box } from '@chakra-ui/react'
import { TextTag } from 'components/TextTag'
import { CalendarButton } from './CalendarButton'
import { GridHeaderText } from './GridHeaderText'
import { useShiftedDaysInWeek } from '../useShiftedDaysInWeek'
import { LoadingGeneral } from 'components/LoadingGeneral'
import { useAvailableTimeSlotsQuery } from 'src/generated/graphql-frontend'
import { useTimePickContext } from '../context'

interface DaysGridProps {
  productVariantId: string
  startDate: Date
  monthOffset: number
  dateStartsWShift?: number
  isActive: boolean
  t: ReturnType<typeof useTranslation>['t']
}

export const DaysGrid: React.FC<DaysGridProps> = ({
  productVariantId,
  startDate,
  monthOffset,
  dateStartsWShift: dateStartsWShiftProp,
  isActive,
  t,
}) => {
  const { chosenDate, setChosenDate } = useTimePickContext()
  const { dateStartsWShift, shiftedDaysInWeek, getDayTranslation } = useShiftedDaysInWeek({
    dateStartsWShift: dateStartsWShiftProp,
  })
  const monthDate = startOfMonth(addMonths(startDate, monthOffset))

  const { data, loading: isLoading } = useAvailableTimeSlotsQuery({
    fetchPolicy: 'network-only',
    variables: {
      month: monthDate.getMonth() + 1,
      year: monthDate.getFullYear(),
      productVariantId,
    },
    skip: !isActive,
  })
  // TODO: When day changes, set chosen date to null to reset TimePick
  // React.useEffect(() => {
  //   if (!isActive) return
  //   setChosenDate()
  // }, [isActive, monthDate, setChosenDate])

  const handleDatePick = React.useCallback(
    (date: Date) => {
      setChosenDate(date)
    },
    [setChosenDate]
  )

  const { availableTimeSlots } = data ?? {}
  const availableDaysInMonth = React.useMemo(
    () =>
      availableTimeSlots?.reduce((acc, timeSlot) => {
        const startDate = new Date(timeSlot.startDate as string)
        if (startDate.getMonth() === monthDate.getMonth()) {
          acc.push(startDate.getDate())
        }
        return acc
      }, [] as number[]),
    [availableTimeSlots, monthDate]
  )
  return (
    <Box w="full" userSelect="none">
      <GridHeaderText textAlign="center" justifyContent="center">
        {t(`month_${monthDate.getMonth() + 1}_long`)} {format(monthDate, 'y')}
      </GridHeaderText>
      <Grid
        w="full"
        justifyItems="center"
        templateColumns={{
          base: 'repeat(7, minmax(1.5rem, 1fr))',
          sm: 'repeat(7, minmax(2rem, 1fr))',
        }}
        templateRows={{ base: 'auto', sm: '2rem' }}
        gap="2"
        px="1.5"
        position="relative"
      >
        {shiftedDaysInWeek.map((dayKey, index) => (
          <TextTag key={dayKey} color="gray.300" textAlign="center" h="8">
            {getDayTranslation(index, true)}
          </TextTag>
        ))}
        {Array.from({ length: monthDate.getDay() - dateStartsWShift }).map((_, index) => (
          <div key={index.toString()} />
        ))}
        {Array.from({ length: getDaysInMonth(monthDate) }).map((_, index) => {
          const date = new Date(monthDate.setDate(index + 1))
          return (
            <Box key={index.toString()} w="full" pt="100%" position="relative">
              <CalendarButton
                position="absolute"
                top="0"
                left="0"
                h="full"
                onClick={() => handleDatePick(date)}
                isAvailable={availableDaysInMonth?.includes(date.getDate())}
                isSelected={
                  date.getDate() === chosenDate?.getDate() &&
                  date.getMonth() === chosenDate?.getMonth()
                }
              >
                {index + 1}
              </CalendarButton>
            </Box>
          )
        })}
        {isLoading && (
          <LoadingGeneral
            position="absolute"
            left={0}
            top={0}
            right={0}
            bottom={0}
            borderRadius="xl"
            bg="whiteAlpha.200"
            backdropFilter="auto"
            backdropBlur="1px"
          />
        )}
      </Grid>
    </Box>
  )
}
