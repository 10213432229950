import React from 'react'
import { useDisclosure, Button, ButtonProps } from '@chakra-ui/react'
// import { Description } from 'components/BackOffice/components/Description'
import { ModalOrDrawer } from 'components/ModalOrDrawer'

type AddPayoutMethodButtonProps = ButtonProps

export const AddPayoutMethodButton: React.FC<AddPayoutMethodButtonProps> = (props) => {
  // const { control } = useForm<Form>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  // const onSubmit = React.useCallback((data: Form) => {
  //   console.log(data)
  //   onClose()
  // }, [])
  return (
    <>
      <Button onClick={onOpen} {...props}>
        Add payout method
      </Button>
      <ModalOrDrawer
        title="Add new payout method"
        description="You can add a new payout method. Can be chosen from credit card, crypto wallet, and custom payout method."
        footer={
          <>
            <Button variant="outline" mr={2} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save changes</Button>
          </>
        }
        maxW="5xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        TODO: Add payout methods
      </ModalOrDrawer>
    </>
  )
}
