export const colors = {
  gray: {
    200: '#F5F5F5',
    250: '#F3F9FA',
    270: '#EFF5F6',
    290: '#D5E1EA',
    300: '#ABB7BF',
    400: '#90949E',
    500: '#66769C',
    600: '#4C5980',
    700: '#35415E',
    800: '#2D3142',
  },
  primary: {
    300: '#012273',
    500: '#001A5A',
    600: '#04194B',
    700: '#031642',
  },
  peach: {
    100: '#FFF2E8',
    300: '#FFB27D',
    400: '#FFB27D',
    500: '#E9845C',
    700: '#B14B23',
  },
  yellow: {
    300: '#FCDC7B',
    500: '#F9C440',
  },
  red: {
    300: '#FFAFAF',
    400: '#FF7878',
    500: '#FF7878',
  },
  purple: {
    400: '#D3CCFF',
    500: '#93BEFE',
  },
  teal: {
    100: '#EEFCFF',
    200: '#BED5DC',
    300: '#A3ECF4',
    400: '#99E0CB',
  },
  green: {
    400: '#4ABF9C',
    600: '#5CBB9E',
  },
  skeletor: {
    start: '#c6d1e7',
    end: '#f0f6ff',
  },
}

export default colors
