import { Flex, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { getPrice } from 'utils/payments'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  currency: string
  subtotal: number
  shippingAmount?: number
}

export const Subtotal: React.FC<Props> = ({ currency, subtotal, shippingAmount }) => {
  const { t } = useTranslation('order')
  const isFreeShipping = shippingAmount === 0
  return (
    <Stack fontWeight="medium" spacing="4">
      <Flex justifyContent="space-between">
        <span>{t('subtotal')}:</span>
        <span>{getPrice(subtotal, currency)}</span>
      </Flex>
      {typeof shippingAmount === 'number' && (
        <Flex justifyContent="space-between">
          <span>{t('shippingFee')}:</span>
          <Text color={isFreeShipping ? 'green.600' : 'inherit'}>
            {isFreeShipping ? t('freeShipping') : getPrice(shippingAmount, currency)}
          </Text>
        </Flex>
      )}
    </Stack>
  )
}
