import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import { Box, Button, Divider, Grid, Heading, Stack, Text } from '@chakra-ui/react'
import { Description } from 'components/Description'
import { useShiftedDaysInWeek } from 'components/TimePick'
import { times, TimeSelect } from '../components/TimeSelect'
import { DayCheckbox } from './DayCheckbox'
import { Form } from './types'
import { SlotSettingsInput } from 'src/generated/graphql-frontend'

const TIME_9AM = 9 * 60
const TIME_5PM = 17 * 60

interface Props {
  onNextStep(slotSettings: SlotSettingsInput[]): void
}

export const General: React.FC<Props> = ({ onNextStep }) => {
  const { t } = useTranslation('backOffice')
  const { getDayTranslation, shiftedDaysInWeek } = useShiftedDaysInWeek()
  const { control, register, getValues } = useForm<Form>({
    defaultValues: {
      startTime: times.find(({ value }) => value === TIME_9AM)?.value,
      endTime: times.find(({ value }) => value === TIME_5PM)?.value,
      days: shiftedDaysInWeek.map((key) => ({ key, value: false })),
    },
  })
  const { fields } = useFieldArray({
    control,
    name: 'days',
  })
  const handleNextStep = () => {
    const dayStart = getValues('startTime')
    const dayEnd = getValues('endTime')
    const slotSettings = getValues('days').map(({ value }, index) => {
      if (!value) return null
      return {
        dayInWeek: index,
        fromTime: dayStart,
        toTime: dayEnd,
        isEnabled: true,
      }
    })

    onNextStep(slotSettings.filter((s) => s !== null) as SlotSettingsInput[])
  }
  return (
    <Stack spacing="6">
      <Box>
        <Description>
          {t('Product.calendarLetsBeginBySelectingAvailable')}
          <br />
          {t('Product.calendarYouCanFineTune')}
        </Description>
        <Divider mt="2.5" />
      </Box>
      <Stack spacing="2.5">
        <Heading size="md">{t('Product.calendarAvailableHours')}</Heading>
        <Stack direction="row" alignItems="center" spacing="4">
          <Box flex="1">
            <TimeSelect control={control} name="startTime" />
          </Box>
          <Text opacity={0.65}>&#8212;</Text>
          <Box flex="1">
            <TimeSelect control={control} name="endTime" />
          </Box>
        </Stack>
      </Stack>
      <Stack spacing="2.5">
        <Heading size="md">{t('Product.calendarAvailableDays')}</Heading>
        <Grid gap={2} templateColumns={{ base: 'repeat(auto-fit, minmax(88px, 1fr))' }}>
          {fields.map((field, index) => (
            <DayCheckbox
              key={field.id}
              index={index}
              label={getDayTranslation(index, true)}
              register={register}
            />
          ))}
        </Grid>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Button onClick={handleNextStep}>{t('Product.calendarButtonLabelContinue')}</Button>
      </Stack>
    </Stack>
  )
}
