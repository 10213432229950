import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { HStack, StackProps, Spinner, Text } from '@chakra-ui/react'

export const LoadingGeneral: React.FC<{ loadingText?: string } & StackProps> = ({
  loadingText,
  ...rest
}) => {
  const { t } = useTranslation('common')
  return (
    <HStack justifyContent="center" {...rest}>
      <Spinner size="md" thickness="3px" />
      <Text pl="2.5" fontSize="md" fontWeight="medium">
        {loadingText || t('loadingLabel')}
      </Text>
    </HStack>
  )
}
