import { chakra, Divider, Grid, Heading, Stack, Text } from '@chakra-ui/react'
import { BankTransferPaymentDetails } from 'components/BankTransferPaymentDetails'
import Link from 'components/Link'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { OrderQuery, OrderStatusEnum, PaymentTypeEnum } from 'src/generated/graphql-frontend'
import { getCountryFromCode, getFullName, notEmptyFilter } from 'utils/helpers'
import { ItemsList } from './ItemsList'
import { OrderTotal } from './OrderTotal'
import { PaymentInfo } from './PaymentInfo'
import { Subtotal } from './Subtotal'

const InfoSection = chakra(Stack, {
  baseStyle: {
    mt: '10',
    p: '6',
    spacing: '6',
    borderColor: 'gray.200',
    borderWidth: '2px',
    borderRadius: '3xl',
  },
})

const getMappedBankConnectionToBankTransferPaymentProps = ({
  iban,
  bankName,
  countryCode,
  swift,
}: {
  iban: string
  swift: string
  bankName: string
  countryCode: string
}) => ({ iban, bankName, countryCode, swift })

export const OrderSummary: React.FC<OrderQuery['order'] & { isMerchant?: boolean }> = ({
  id,
  ProductVariantOrders,
  amount,
  status,
  Address,
  ContactInfo,
  Payment,
  MerchantProfile,
  Shipping,
  isMerchant,
}) => {
  const { t } = useTranslation('order')
  const shippingAmount = Shipping?.amount
  const currency = MerchantProfile.currency
  const isBankTransfer = Payment?.type === PaymentTypeEnum.BankTransfer
  const [bankConnection] = MerchantProfile.productSettings.BankConnections

  const addressAndContactInfo = [
    ...(Address
      ? [
          Address.line1,
          `${Address.city} ${Address.zip}`,
          Address.country && getCountryFromCode(Address.country)?.label,
        ]
      : []),
    ...(ContactInfo
      ? [`${ContactInfo.firstName} ${ContactInfo.lastName}`, ContactInfo.phoneNumber]
      : []),
  ].filter(notEmptyFilter)
  return (
    <>
      <Heading size="lg">{status === OrderStatusEnum.Draft ? 'Cart' : 'Order'} information</Heading>
      <Divider mt="5" mb="6" />
      <Grid rowGap="4" columnGap={{ base: 4, md: 10 }} templateColumns="auto 1fr">
        {[
          `${t('orderId')}:`,
          id,
          `${t('shop')}:`,
          MerchantProfile.name,
          `${t('paymentMethod')}:`,
          Payment ? t(`common:${Payment.type as string}`) : t('notPaid'),
          t('status'),
          t(status),
        ].map((text, index) => (
          <Text key={text} fontWeight={index % 2 === 1 ? 'medium' : 'normal'}>
            {text}
          </Text>
        ))}
      </Grid>

      {Payment && (
        <>
          {isBankTransfer && bankConnection && status === OrderStatusEnum.PendingPayment && (
            <InfoSection>
              <BankTransferPaymentDetails
                p="0"
                py="0"
                border="none"
                background="transparent"
                isLoading={false}
                variableSymbol={Payment.id}
                {...getMappedBankConnectionToBankTransferPaymentProps(bankConnection)}
              />
            </InfoSection>
          )}
          {isMerchant && (
            <InfoSection>
              <PaymentInfo {...Payment} />
            </InfoSection>
          )}
        </>
      )}
      {status === OrderStatusEnum.Draft ? (
        <Divider mt="6" />
      ) : (
        <InfoSection>
          <Stack spacing="2.5">
            {ContactInfo && <Heading size="md">{getFullName(ContactInfo)}</Heading>}
            <Text opacity={0.6}>
              {addressAndContactInfo.map((detail, index) => (
                <>
                  {detail}
                  {index < addressAndContactInfo.length - 1 ? <br /> : null}
                </>
              ))}
            </Text>
          </Stack>
          {Shipping?.trackingUrl && (
            <Stack spacing="2.5">
              <Text fontWeight="medium">${t('followYourOrderDelivery')}:</Text>
              <Link color="peach.500" href={Shipping.trackingUrl}>
                {Shipping.trackingUrl}
              </Link>
            </Stack>
          )}
        </InfoSection>
      )}
      <Stack mt="10" spacing="6">
        <ItemsList
          currency={currency}
          username={MerchantProfile.username}
          items={ProductVariantOrders}
          isMerchant={isMerchant}
        />
        <Divider />
        <Subtotal currency={currency} subtotal={amount} shippingAmount={shippingAmount} />
        <Divider />
        <OrderTotal amountCents={amount + (shippingAmount ?? 0)} currency={currency} />
      </Stack>
    </>
  )
}
