import React from 'react'
import { useFieldArray, UseFormReturn, Controller } from 'react-hook-form'
import { Stack, Box } from '@chakra-ui/react'
import { ProductTypeAttributeInput, ProductTypeInput } from 'src/generated/graphql-frontend'
import { InputWrapper } from 'components/InputWrapper'
import { AttributeTypeDropdown } from './AttributeTypeDropdown'
import { NewAttributeInput } from './NewAttributeInput'
import { Row } from '../../ProductForm/components/Options/OptionForm'

interface Props {
  register: UseFormReturn<ProductTypeInput>['register']
  control: UseFormReturn<ProductTypeInput>['control']
}

export const Attributes: React.FC<Props> = ({ register, control }) => {
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'Attributes',
  })
  const handleNewAttribute = React.useCallback(
    (newOptionValue: ProductTypeAttributeInput) => {
      append(newOptionValue)
    },
    [update, append]
  )
  return (
    <Stack spacing="2">
      {fields.map(({ id }, index) => (
        <Row key={id} onRemove={() => remove(index)}>
          <Stack direction="row" spacing="2">
            <InputWrapper
              {...register(`Attributes.${index}.name`)}
              flex="1"
              placeholder="Attribute name"
              variant="outline"
              maxW="100%"
              autoComplete="off"
            />
            <Box flex="1">
              <Controller
                name={`Attributes.${index}.type`}
                control={control}
                rules={{ required: 'Select product type' }}
                render={({ field }) => <AttributeTypeDropdown {...field} />}
              />
            </Box>
          </Stack>
        </Row>
      ))}
      <Row isNewOption>
        <NewAttributeInput onNewAttribute={handleNewAttribute} />
      </Row>
    </Stack>
  )
}
