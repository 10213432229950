/* eslint-disable react/display-name */
import React from 'react'
import {
  DrawerProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Icon,
  IconButton,
  IconButtonProps,
} from '@chakra-ui/react'
import { FiMenu } from 'react-icons/fi'
import { MerchantMenu } from './Merchant'

export function MobileNavContent(props: Omit<DrawerProps, 'children'>) {
  return (
    <Drawer size="xs" placement="right" {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton color="primary.500" size="lg" />
        <DrawerBody>
          <MerchantMenu py="12" minW="52" isDrawer onClose={props.onClose} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const MobileNavButton = React.forwardRef(
  (props: IconButtonProps, ref: React.Ref<HTMLButtonElement>) => (
    <IconButton
      ref={ref}
      variant="unstyled"
      color="primary.500"
      borderRadius="md"
      display={{ base: 'flex', md: 'none' }}
      icon={<Icon as={FiMenu} width="8" height="8" />}
      {...props}
    />
  )
)
