import { ReactSelect, ReactSelectProps } from 'components/ReactSelect'
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form'

export interface ReactSelectControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'name' | 'defaultValue' | 'render'>,
    Omit<ReactSelectProps, 'onChange'> {
  name: TName
}

/**
 * Use value of generic type, not object like { label: string; value: TFieldValues[TName] }
 * @param props 
 * @returns 
 */
export const ReactSelectController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: ReactSelectControllerProps<TFieldValues, TName>
) => {
  const { control, name, ...rest } = props
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...field } }) => (
        <ReactSelect
          {...rest}
          {...field}
          // @ts-expect-error onChange from ReactSelectProps is not compatible with onChange from ControllerProps
          onChange={(value: { label: string; value: TFieldValues[TName] }) => {
            onChange(value.value)
          }}
        />
      )}
    />
  )
}
