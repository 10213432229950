import { chakra, Text } from '@chakra-ui/react'

export const TextTag = chakra(Text, {
  baseStyle: {
    fontSize: 'xs',
    textTransform: 'uppercase',
    fontWeight: 'medium',
    letterSpacing: '0.125em',
  },
})
