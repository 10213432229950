import React from 'react'
import { Icon, IconButton, IconButtonProps, useClipboard } from '@chakra-ui/react'
import { MdCheck, MdOutlineContentCopy } from 'react-icons/md'

export const CopyButton: React.FC<{ value: string, hasCopied?: boolean } & Omit<IconButtonProps, 'aria-label'>> = ({
  value,
  hasCopied: hasCopiedProp,
  ...rest
}) => {
  const { hasCopied, onCopy } = useClipboard(value)
  return (
    <IconButton
      display="flex"
      alignItems="center"
      justifyContent="center"
      ml="2"
      size="xs"
      variant="unstyled"
      aria-label="Copy"
      color={hasCopied || hasCopiedProp ? 'green.600' : 'primary.500'}
      transition="none"
      boxShadow="none"
      icon={
        <Icon
          as={hasCopied || hasCopiedProp ? MdCheck : MdOutlineContentCopy}
          display="block"
          height={5}
          width={5}
        />
      }
      {...rest}
      onClick={onCopy}
    />
  )
}
