import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import type { PartsStyleObject, SystemStyleObject } from '@chakra-ui/theme-tools'

const baseStyleHeader: SystemStyleObject = {
  px: 6,
  py: 4,
  fontSize: 'xl',
  fontWeight: 'medium',
}

const baseStyleCloseButton: SystemStyleObject = {
  position: 'absolute',
  top: { base: 2, md: 10 },
  insetEnd: { base: 2, md: 10 },
}

const baseStyle: PartsStyleObject<typeof parts> = {
  dialog: {
    p: { base: 2, md: 10 },
    borderRadius: { base: '2xl', md: '5xl' },
  },
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
}

export default {
  parts: parts.keys,
  baseStyle,
  defaultProps: {
    size: '3xl',
  },
}
