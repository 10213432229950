import React from 'react'
import { useSession } from 'next-auth/react'
import { Controller, useForm } from 'react-hook-form'
import { Stack, Button, Checkbox, Text } from '@chakra-ui/react'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import { ShippingZoneInput, useCreateShippingZoneMutation } from 'src/generated/graphql-frontend'
import { InputWrapper } from 'components/InputWrapper'
import { fieldHasValueValidation } from 'utils/helpers'
import { RefetchQueriesEnum } from 'src/constants'
import { Label } from 'components/Label'
import { AmountInput } from 'components/AmountInput'
import { CountrySelect } from 'components/CountrySelect'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  isOpen: boolean
  onSuccess(): void
  onClose(): void
}

export const AddShippingZone: React.FC<Props> = ({ isOpen, onSuccess, onClose }) => {
  const { t } = useTranslation('backOffice')
  const session = useSession()
  const currency = session?.data?.user?.profile?.currency || 'eur'
  const {
    control,
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<ShippingZoneInput>()
  const [createShippingZone, { loading }] = useCreateShippingZoneMutation({
    refetchQueries: [RefetchQueriesEnum.Profile],
  })
  const handleFormSubmit = React.useCallback(
    async (inputData: ShippingZoneInput) => {
      if (loading) {
        return
      }
      const result = await createShippingZone({
        variables: {
          inputData,
        },
      })
      if (!!result?.data) {
        onSuccess()
      }
    },
    [onSuccess, loading]
  )
  React.useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen])
  return (
    <ModalOrDrawer
      title={t('ShippingZones.createShippingZone')}
      description={t('ShippingZones.createShippingZoneDescription')}
      maxW="container.md"
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <>
          <Button variant="outline" mr={2} onClick={onClose}>
            {t('ShippingZones.cancel')}
          </Button>
          <Button onClick={handleSubmit(handleFormSubmit)} isLoading={isSubmitting}>
            {t('ShippingZones.createShippingZone')}
          </Button>
        </>
      }
    >
      <Stack as="form" onSubmit={handleSubmit(handleFormSubmit)} spacing="4">
        <InputWrapper
          {...register('name', {
            validate: fieldHasValueValidation(t('ShippingZones.nameIsRequired')),
          })}
          label={t('ShippingZones.name')}
          hint={t('ShippingZones.nameHint')}
          errorMessage={errors.name?.message}
        />
        <Controller
          control={control}
          rules={{ required: t('ShippingZones.amountRequired') }}
          name="amount"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <AmountInput
              variant="outline"
              label={t('ShippingZones.shippingPrice')}
              hint={t('ShippingZones.shippingPriceHint')}
              currencyName={currency}
              errorMessage={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="freeAbove"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <AmountInput
              variant="outline"
              label={t('ShippingZones.freeShippingAbove')}
              hint={t('ShippingZones.freeShippingAboveHint')}
              currencyName={currency}
              errorMessage={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          rules={{
            validate: (value) => {
              if (!value && !getValues('countryCodes')?.length) {
                return t('ShippingZones.countriesRequired')
              }
              return true
            },
          }}
          name="isOther"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <Checkbox isChecked={Boolean(value)} onChange={onChange}>
                {t('ShippingZones.useForOtherCountries')}
              </Checkbox>
              {error && (
                <Text pt="1" fontSize="sm" color="red.400">
                  {error.message}
                </Text>
              )}
            </div>
          )}
        />
        <div>
          <Label>{t('ShippingZones.countries')}</Label>
          <CountrySelect
            control={control}
            name="countryCodes"
            placeholder={t('ShippingZones.selectCountriesPlaceholder')}
            isMulti
          />
        </div>
      </Stack>
    </ModalOrDrawer>
  )
}
