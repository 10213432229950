import React from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Button, Stack, Icon } from '@chakra-ui/react'
import { IoMdAddCircle } from 'react-icons/io'
import { InputWrapper } from 'components/InputWrapper'
import { OptionsForm } from 'components/Shop'
import useTranslation from 'next-translate/useTranslation'

interface OptionInputForm {
  optionValue: string
}

interface Props {
  index: number
  onNewOption(newOption: string): void
  getValues: UseFormReturn<OptionsForm>['getValues']
}

export const NewOptionInput: React.FC<Props> = ({ index, onNewOption, getValues }) => {
  const { t } = useTranslation('backOffice')
  const {
    formState: { errors },
    register,
    reset,
    handleSubmit,
  } = useForm<OptionInputForm>()
  const handleFormSubmit = React.useCallback(
    (data: OptionInputForm) => {
      {
        onNewOption(data.optionValue)
        reset()
      }
    },
    [onNewOption]
  )
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack direction="row" spacing="2">
        <InputWrapper
          {...register('optionValue', {
            required: true,
            validate: (newValue) => {
              const newValueTrimmed = newValue.trim()
              return (
                !getValues('options')[index].values.some(
                  ({ value }) => value === newValueTrimmed
                ) || t('Product.newOptionValueMustBeUnique')
              )
            },
          })}
          errorMessage={errors.optionValue?.message}
          placeholder={t('Product.newOptionInputPlaceholder')}
          variant="outline"
          flex="1"
          maxW="100%"
          autoComplete="off"
        />
        <Button
          aria-label={t('Product.newOptionInputAriaLabel')}
          type="submit"
          variant="ghost"
          px="2.5"
          rightIcon={<Icon as={IoMdAddCircle} width="5" height="5" />}
        >
          {t('Product.newOptionButtonLabel')}
        </Button>
      </Stack>
    </form>
  )
}
