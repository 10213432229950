import { useStripe } from '@stripe/react-stripe-js'
import {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  PaymentRequestWallet,
} from '@stripe/stripe-js'
import useTranslation from 'next-translate/useTranslation'
import { createContext, useContext, useEffect, useState } from 'react'
import { ProfileQuery, ThumbnailSizeEnum, useProfileQuery } from 'src/generated/graphql-frontend'

type CamMakePaymentResultType = Record<PaymentRequestWallet, boolean>

interface PaymentRequestContextValue {
  isLoading: boolean
  canMakePaymentResult: CamMakePaymentResultType | null
  /**
   * This value indicates that Google / Apple Pay is available. If not, the value is null.
   */
  paymentRequest: PaymentRequest | null
  isRecurringDonation: boolean | null
  /**
   * Amount in cents.
   */
  amount: number | null
  setAmount: (amount: number | null) => void
  currency: string | null
  profile: ProfileQuery['profile'] | null
}

interface Props {
  profileId?: string
  currency?: string | null
  /**
   * Controlled amount in cents.
   */
  amount?: number
  /**
   * Initial amount in cents.
   */
  initialAmount?: number
  children: React.ReactNode
}

export const PaymentRequestContext = createContext<PaymentRequestContextValue>({
  isLoading: true,
  canMakePaymentResult: null,
  paymentRequest: null,
  isRecurringDonation: null,
  amount: null,
  setAmount: () => {},
  currency: null,
  profile: null,
})

export const PaymentRequestProvider = ({
  profileId,
  amount,
  currency: currencyProp = null,
  initialAmount = 500,
  children,
}: Props) => {
  const stripe = useStripe()
  const { t } = useTranslation()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null)
  const [canMakePaymentResult, setCanMakePaymentResult] = useState<CamMakePaymentResultType | null>(
    null
  )
  const [isRecurringDonation, setIsRecurringDonation] = useState(false)
  const [amountState, setAmountState] = useState<number | null>(amount || initialAmount)

  const { data: profile } = useProfileQuery({
    variables: {
      id: profileId,
      coverPictureSize: ThumbnailSizeEnum.Small,
      size: ThumbnailSizeEnum.Small,
    },
    skip: !profileId,
  })

  const { currency } = profile?.profile || { currency: currencyProp }

  const finalAmount = amount || amountState

  useEffect(() => {
    if (stripe && currency) {
      console.error('currency', currency)
      console.error('finalAmount', finalAmount)
      if (finalAmount) {
        console.error('finalAmount', finalAmount)
        const newPaymentRequest = stripe?.paymentRequest({
          // TODO: Obtain the country from the merchant's profile.
          country: 'US',
          currency,
          total: {
            label: t('payOrder'),
            amount: finalAmount,
          },
          requestPayerName: true,
          requestPayerEmail: true,
          disableWallets: ['link'],
          // requestPayerEmail: getValues('email').length < 3,
        })

        console.error('newPaymentRequest', newPaymentRequest)

        void newPaymentRequest
          ?.canMakePayment()
          .then((result) => {
            console.error('result', result)
            setCanMakePaymentResult(result as CamMakePaymentResultType)
            if (result) {
              setPaymentRequest(newPaymentRequest)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, [stripe, currency, finalAmount])

  useEffect(() => {
    if (paymentRequest) {
      const callback = (event: PaymentRequestPaymentMethodEvent) => {
        // console.error('paymentmethod', event)
        debugger

        const { paymentMethod } = event
        if (paymentMethod) {
          // console.error('paymentMethod', paymentMethod)
          debugger

          const { id } = paymentMethod
          if (id) {
            // console.error('id', id)
            debugger
          }
        }
      }
      paymentRequest.on('paymentmethod', callback)
      return () => {
        paymentRequest.off('paymentmethod', callback)
      }
    }
  }, [paymentRequest])

  return (
    <PaymentRequestContext.Provider
      value={{
        isLoading: !stripe,
        canMakePaymentResult,
        paymentRequest,
        isRecurringDonation,
        amount: finalAmount,
        setAmount: setAmountState,
        currency,
        profile: profile?.profile || null,
      }}
    >
      {children}
    </PaymentRequestContext.Provider>
  )
}

export const usePaymentRequest = () => useContext(PaymentRequestContext)
