import {
  AspectRatio,
  Box,
  ButtonProps,
  Center,
  Link as ChakraLink,
  HStack,
  Heading,
  Icon,
  ScaleFade,
  Spinner,
  Stack,
  chakra,
  useColorModeValue
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { BiTrash } from 'react-icons/bi'
import { BsCheck } from 'react-icons/bs'
import { IoMdClose } from 'react-icons/io'
import { downloadFileFromUrl } from 'utils/helpers'
import { FilePreviewProps } from './types'

const ActionButton: React.FC<ButtonProps> = ({ children, opacity, onClick }) => (
  <chakra.button
    type="button"
    bg="white"
    color="red.500"
    borderRadius="md"
    width="8"
    height="8"
    display="flex"
    alignItems="center"
    justifyContent="center"
    opacity={opacity}
    transitionProperty="common"
    transitionDuration="fast"
    _hover={{
      opacity: 1,
    }}
    onClick={onClick}
  >
    <chakra.div width="6" height="6" overflow="hidden">
      {children}
    </chakra.div>
  </chakra.button>
)

export const FilePreview: React.VFC<FilePreviewProps> = ({
  previewMediaURL,
  percentageUploaded,
  fileName,
  downloadUrl,
  onItemRemove,
}) => {
  const bg = useColorModeValue('white', 'gray.700')
  const { t } = useTranslation('common')
  const isComplete = percentageUploaded == 100
  return (
    <ScaleFade in initialScale={0.85}>
      <Box
        position="relative"
        w="full"
        bg={bg}
        overflow="hidden"
        borderRadius="2xl"
        boxShadow="lg"
        borderColor="gray.250"
        borderWidth="2px"
        userSelect="none"
      >
        <Stack direction="row" p="2" spacing="4">
          {previewMediaURL && (
            <Center flex="none">
              <AspectRatio ratio={1} w="16">
                <Box
                  position="absolute"
                  left="0"
                  top="0"
                  w="full"
                  h="full"
                  bgSize="cover"
                  bgPos="center"
                  bgRepeat="no-repeat"
                  bgImage={`url(${previewMediaURL})`}
                  borderRadius="md"
                  mr="6"
                />
              </AspectRatio>
            </Center>
          )}
          <Stack flex="1" p="2" spacing="1">
            <Heading size="md">{fileName}</Heading>
            {downloadUrl ? (
              <ChakraLink
                target="_blank"
                href={downloadUrl}
                download={fileName}
                textDecoration="underline"
                onClick={(event) => {
                  event.preventDefault()
                  downloadFileFromUrl({ url: downloadUrl, filename: fileName })
                }}
              >
                {t('Upload.buttonLabelDownload')}
              </ChakraLink>
            ) : (
              <HStack>
                {isComplete ? (
                  <>
                    <Icon as={BsCheck} width={6} height={6} color="green.500" />
                    <span>{t('Upload.buttonLabelUploaded')}</span>
                  </>
                ) : (
                  <>
                    <Spinner thickness="3px" />
                    <span>{`${Math.round(percentageUploaded)}%`}</span>
                  </>
                )}
              </HStack>
            )}
          </Stack>
          {onItemRemove && (
            <Center>
              <ActionButton onClick={onItemRemove}>
                <chakra.div
                  transform={`translateY(${percentageUploaded === 100 ? '-50%' : 0})`}
                  transitionProperty="common"
                  transitionDuration="fast"
                >
                  <Icon as={IoMdClose} width="6" height="6" />
                  <Icon as={BiTrash} width="6" height="6" />
                </chakra.div>
              </ActionButton>
            </Center>
          )}
        </Stack>
        {!isComplete && (
          <Box
            position="absolute"
            bg="primary.500"
            width={`${percentageUploaded}%`}
            height="1"
            opacity={0.65}
            transitionProperty="common"
            transitionDuration="fast"
            transform="translateY(-100%)"
          />
        )}
      </Box>
    </ScaleFade>
  )
}
