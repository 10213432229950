import React from 'react'
import { Flex, Stack, Heading, FlexProps } from '@chakra-ui/react'
import { Description } from 'components/Description'

interface SectionProps {
  title?: React.ReactNode
  titleRightComp?: React.ReactNode
  description?: React.ReactNode
  descriptionOpacity?: number
  titleJustifyContent?: FlexProps['justifyContent']
  innerRef?: React.RefObject<HTMLDivElement>
  isDisabled?: boolean
  children?: React.ReactNode | React.ReactElement | React.ReactElement[]
}

export const Section: React.FC<SectionProps> = ({
  title,
  titleRightComp,
  description,
  descriptionOpacity,
  titleJustifyContent = { base: 'space-between', md: 'flex-start' },
  innerRef,
  isDisabled,
  children,
}) => (
  <Stack
    ref={innerRef}
    spacing={{ base: '4', md: '6' }}
    opacity={isDisabled ? 0.5 : 1}
    tabIndex={isDisabled ? -1 : undefined}
    pointerEvents={isDisabled ? 'none' : undefined}
    transitionProperty="opacity"
    transitionDuration="fast"
  >
    <Stack spacing="4">
      {title && (
        <Flex justifyContent={titleJustifyContent} alignItems="center">
          {typeof title === 'string' ? (
            <Heading size="md" pr="2">
              {title}
            </Heading>
          ) : (
            title
          )}
          {titleRightComp}
        </Flex>
      )}
      {description && (
        <Description as="div" opacity={descriptionOpacity}>
          {description}
        </Description>
      )}
    </Stack>
    {children}
  </Stack>
)
