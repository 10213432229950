import React from 'react'
import { Flex, Stack, Heading, Divider } from '@chakra-ui/react'
import { pulse } from 'utils/theme/helpers'
import { ItemsList } from '../ItemsList'
import { OrderTotal } from '../OrderTotal'
import { ProductVariantOrdersType } from './types'
import { Subtotal } from '../Subtotal'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  currency: string
  username: string
  items: ProductVariantOrdersType
  subtotal: number
  shippingAmount?: number
  isUpdating?: boolean
}

export const CartItems: React.FC<Props> = ({
  currency,
  username,
  items,
  subtotal,
  shippingAmount,
  isUpdating,
}) => {
  const { t } = useTranslation('username')
  return (
    <Stack spacing="5" animation={isUpdating ? `${pulse} 1s infinite` : undefined}>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Heading size="lg">{t('Cart.cartItemsItems')}</Heading>
      </Flex>
      <Divider />
      <Stack spacing="6">
        <ItemsList
          currency={currency}
          username={username}
          items={items}
          canEdit
          hasDividerAfter
        />
      </Stack>
      <Divider />
      <Subtotal currency={currency} subtotal={subtotal} shippingAmount={shippingAmount} />
      <Divider />
      <OrderTotal amountCents={subtotal + (shippingAmount ?? 0)} currency={currency} />
    </Stack>
  )
}
