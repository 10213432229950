import { Button, ButtonProps, keyframes } from '@chakra-ui/react'
import React from 'react'
import { useIsDarkMode } from 'utils/hooks'

const bounceAnim = keyframes`
  0% {
    transform: scale(0.94);
    filter: brightness(0.5);
  }
  60% {
    transform: scale(1.06) rotateX(10deg);
    filter: brightness(1.33) contrast(0.67);
  }
  100% {
    transform: scale(1);
    filter: brightness(1);
  }
`

export interface CalendarButtonProps extends ButtonProps {
  isAvailable?: boolean
  isSelected?: boolean
}

export const CalendarButton: React.FC<CalendarButtonProps> = ({
  isAvailable,
  isSelected,
  ...props
}) => {
  const isDarkMode = useIsDarkMode()

  return (
    <Button
      minW="0"
      w="100%"
      h="100%"
      py="0"
      px="0"
      fontSize={{ base: 'sm', sm: 'md', md: 'sm' }}
      borderRadius={{ base: 'md', sm: 'xl', md: 'md' }}
      border="none"
      {...((isSelected && {
        colorScheme: 'primary',
        animation: `${bounceAnim} 500ms forwards`,
        ...(isDarkMode && {
          color: 'primary',
          borderStyle: 'solid',
          borderColor: 'primary.300',
          borderWidth: '1px',
        }),
      }) ||
        (isAvailable && {
          colorScheme: isDarkMode ? 'purple.200' : 'teal.100',
          backgroundColor: isDarkMode ? 'purple.800' : 'gray.200',
          color: isDarkMode ? 'teal.100' : 'primary.500',
          borderStyle: 'solid',
          borderColor: isDarkMode ? 'whiteAlpha.200' : 'teal.200',
          borderWidth: '1px',
        }) || { isDisabled: true })}
      _disabled={{
        bg: 'whiteAlpha.200',
        // TODO: Not color accessible
        color: 'teal.200',
        cursor: 'not-allowed',
      }}
      _active={{
        ...(isDarkMode ? { bg: 'purple.700' } : { bg: 'teal.100' }),
        transform: 'scale(0.93)',
      }}
      transitionProperty="transform"
      transitionDuration="fast"
      _hover={{
        _disabled: {
          bg: 'whiteAlpha.200',
          color: 'teal.200',
        },
      }}
      {...props}
    />
  )
}
