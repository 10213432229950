export enum MessagingErrorCode {
  PermissionBlocked = 'messaging/permission-blocked',
}

export enum NotificationsPermissionState {
  Unsupported,
  Granted,
  Denied,
  Default,
  BraveBlocked,
  GrantedAndEnabled,
  GrantedAndDisabled,
}

export const firebaseConfig = {
  apiKey: 'AIzaSyBAfWptoFWi8HMICf31NvzmALIUbFocibY',
  authDomain: 'esoteriq-9f6dd.firebaseapp.com',
  projectId: 'esoteriq-9f6dd',
  storageBucket: 'esoteriq-9f6dd.appspot.com',
  messagingSenderId: '842136997982',
  appId: '1:842136997982:web:48a5d3defef6f024d0a4cf',
  measurementId: 'G-DMKSRX1QNR'
}

export const VAP_ID_KEY =
  'BG3yJmJy2eqQ-KT8rWK7sGYKZTgMv0Rsfoxizmd_0kAnOS6KBrmHoIYVQkHlswpHpURGfud2y6FY88LIuNuadgo'
