import React from 'react'
import { useRouter } from 'next/router'
import { useForm, Controller } from 'react-hook-form'
import { Stack, Button } from '@chakra-ui/react'
import * as yup from 'yup'
import { ShopProductInput, useCreateProductMutation } from 'src/generated/graphql-frontend'
import { getRoute } from 'utils/helpers'
import { useYupValidationResolver } from 'hooks/useYupValidationResolver'
import { InputWrapper } from 'components/InputWrapper'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import { ErrorMessage } from 'components/ErrorMessage'
import { nameValidator } from '../ProductForm'
import { ProductTypePick } from '../ProductForm/components/Sections'
import { Label } from 'components/Label'
import { Description } from 'components/Description'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  isOpen: boolean
  onClose(): void
}

const validationSchema = yup.object({
  name: nameValidator,
  type: yup.string().required('errors.productForm.type'),
})

export const AddNewProductModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('backOffice')
  const router = useRouter()
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    register,
  } = useForm<ShopProductInput>({
    resolver: useYupValidationResolver(validationSchema),
  })
  const [createProduct] = useCreateProductMutation()
  const onSubmit = React.useCallback(async (inputData: ShopProductInput) => {
    const { data } = await createProduct({
      variables: {
        inputData,
      },
    })
    if (data && data.createProduct.id) {
      await router.replace({
        pathname: getRoute('backofficeProduct', data.createProduct.id),
      })
    } else {
      console.error('Product could not be created')
    }
  }, [])
  return (
    <ModalOrDrawer
      maxW="3xl"
      title={t('AddNewProductModal.title')}
      footer={
        <>
          <Button variant="outline" mr={2} onClick={onClose}>
            {t('AddNewProductModal.cancel')}
          </Button>
          <Button onClick={handleSubmit(onSubmit)} isLoading={isSubmitting}>
            {t('AddNewProductModal.continue')}
          </Button>
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Stack as="form" spacing="4" alignItems="stretch" onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          {...register('name')}
          label={t('AddNewProductModal.productName')}
          placeholder={t('AddNewProductModal.productNamePlaceholder')}
          variant="outline"
          maxW="auto"
          errorMessage={errors.name?.message}
        />
        <Stack spacing="2">
          <Label>{t('AddNewProductModal.productType')}</Label>
          <Controller
            control={control}
            name="type"
            render={({ field }) => <ProductTypePick {...field} />}
          />
          <div>
            <Description>{t('AddNewProductModal.chooseProductCategory')}</Description>
            {errors.type?.message && <ErrorMessage>{errors.type.message}</ErrorMessage>}
          </div>
        </Stack>
      </Stack>
    </ModalOrDrawer>
  )
}
