// Next 2 functions are copied from https://github.com/firebase/firebase-js-sdk/blob/master/packages/util/src/environment.ts

function isIndexedDBAvailable(): boolean {
  try {
    return typeof indexedDB === 'object'
  } catch {
    return false
  }
}

function validateIndexedDBOpenable(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      let preExist: boolean = true
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const DB_CHECK_NAME = 'validate-browser-context-for-indexeddb-analytics-module'
      // eslint-disable-next-line no-restricted-globals
      const request = self.indexedDB.open(DB_CHECK_NAME)
      request.onsuccess = () => {
        request.result.close()
        // delete database only when it doesn't pre-exist
        if (!preExist) {
          // eslint-disable-next-line no-restricted-globals
          self.indexedDB.deleteDatabase(DB_CHECK_NAME)
        }
        resolve(true)
      }
      request.onupgradeneeded = () => {
        preExist = false
      }

      request.onerror = () => {
        reject(request.error?.message || '')
      }
    } catch (error) {
      reject(error)
    }
  })
}

// End copied functions

export const getIsSwSupported = async () => {
  try {
    // This throws if open() is unsupported, so adding it to the conditional
    // statement below can cause an uncaught error.
    await validateIndexedDBOpenable()
  } catch {
    return false
  }

  return (
    typeof navigator !== 'undefined' &&
    isIndexedDBAvailable() &&
    navigator.cookieEnabled &&
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window &&
    'fetch' in window &&
    // eslint-disable-next-line no-prototype-builtins
    ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
    // eslint-disable-next-line no-prototype-builtins
    PushSubscription.prototype.hasOwnProperty('getKey')
  )
}
