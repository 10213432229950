import React from 'react'
import NextLink from 'next/link'
import { Heading, Link, Text } from '@chakra-ui/react'
import { getRoute } from 'utils/helpers'
import { Breadcrumbs } from 'components/Breadcrumbs'
import Skeleton from 'components/Skeleton'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  name?: string
  username?: string
  isLoading?: boolean
}

export const Header: React.FC<Props> = ({ name, username, isLoading }) => {
  const { t } = useTranslation('username')
  return (
    <>
      <Breadcrumbs>
        {isLoading ? (
          <Skeleton.Text width="32" />
        ) : (
          <Link
            as={NextLink}
            href={
              username
                ? getRoute('profile', username, { tab: 'shop' })
                : getRoute('home', 'creators')
            }
          >
            {name || t('Cart.home')}
          </Link>
        )}
        {isLoading ? <Skeleton.Text width="24" /> : <Text>{t('Cart.Checkout')}</Text>}
      </Breadcrumbs>
      <Heading size="xl">{isLoading ? <Skeleton.Text width="80" /> : t('Cart.Checkout')}</Heading>
    </>
  )
}
