import { PaymentTypeEnum, ProfileQuery, S3File } from 'src/generated/graphql-frontend'

export const widgetColors: { [key in WidgetColorType]: string } = {
  'yellow.300': 'primary.500',
  'peach.400': 'primary.500',
  'red.300': 'primary.500',
  'red.400': 'white',
  'teal.400': 'primary.500',
  'teal.300': 'primary.500',
  'purple.500': 'white',
  'purple.400': 'primary.500',
  'primary.500': 'white',
}

export type WidgetColorType =
  | 'yellow.300'
  | 'peach.400'
  | 'red.300'
  | 'red.400'
  | 'teal.400'
  | 'teal.300'
  | 'purple.500'
  | 'purple.400'
  | 'primary.500'

export interface WidgetEmbedForm {
  amountCents?: number
  isRecurringDonation: boolean
  email?: string
  paymentType?: PaymentTypeEnum
}

export type ProfileType = NonNullable<ProfileQuery['profile']>

export interface EmbedProps {
  id: string
  defaultDonationAmount?: number
  onPaid?(amount: number, priceWithCurrency: string, paymentId: string | undefined): void
  profile: Pick<
    ProfileType,
    'id' | 'currency' | 'productSettings' | 'name' | 'username' | 'stripeChargesEnabled'
  > & {
    profilePicture?: Pick<S3File, 'thumbnailSignedUrl'> | null
    coverPicture?: Pick<S3File, 'thumbnailSignedUrl'> | null
    Tiers?: ProfileType['Tiers']
  }
}
