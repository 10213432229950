import { Box, chakra } from '@chakra-ui/react'
import { getColorModeValue } from 'utils/theme/helpers'

export const ProfilePicture = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flex: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    w: '10',
    h: '10',
    borderRadius: 'lg',
    bgSize: 'cover',
    bgPos: 'center',
    borderWidth: '2px',
    borderColor: getColorModeValue('white', 'gray.800'),
  },
})
