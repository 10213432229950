// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (process.env.NODE_ENV === 'production') {
    window.gtag('config', process.env.GA_TRACKING_ID, {
      page_path: url,
    })
  } else {
    console.log('GA pageview', url)
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events

interface EventParams {
  action: string
  category: string
  label: string
  value: string
}

export const event = ({ action, category, label, value }: EventParams) => {
  if (process.env.NODE_ENV === 'production') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    })
  } else {
    console.log('GA event', { action, category, label, value })
  }
}
