import React from 'react'
import {
  chakra,
  forwardRef,
  Stack,
  ButtonProps,
  Button,
  Text,
  Menu,
  MenuList,
  MenuButton,
} from '@chakra-ui/react'
import { IoIosArrowDown } from 'react-icons/io'
import { size } from 'theme/components/Input'
import { Label } from 'components/Label'

export const DropdownButton = chakra(Button, {
  baseStyle: {
    h: size.md.h,
    color: 'gray.700',
    bg: 'gray.250',
    textAlign: 'left',
    border: '1px solid transparent',
    _hover: {
      color: 'gray.700',
      bg: 'gray.250',
    },
    _active: {
      color: 'gray.700',
      bg: 'gray.250',
    },
  },
})

export interface DropdownProps {
  placeholder: React.ReactNode
  isInvalid?: boolean
  errorMessage?: string
  autoSelect?: boolean
  label?: string
  value?: string | null
  onChange?(newValue: string): void
  children: React.ReactElement<ButtonProps> | Array<React.ReactElement<ButtonProps>>
}

export const Dropdown = forwardRef<DropdownProps, 'div'>(
  (
    {
      placeholder,
      value,
      errorMessage,
      label,
      autoSelect = false,
      onChange,
      isInvalid,
      children,
      ...props
    }: DropdownProps,
    ref
  ) => {
    const caption =
      React.useMemo(
        () =>
          value
            ? (React.Children.toArray(children) as Array<React.ReactElement<ButtonProps>>).find(
                ({ props: { value: itemValue } }) => itemValue === value
              )?.props.children
            : undefined,
        [value, children]
      ) ?? placeholder
    const handleClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
      (event) => onChange?.(event.currentTarget.value),
      [onChange]
    )
    return (
      <div>
        {label && <Label>{label}</Label>}
        <Menu matchWidth autoSelect={autoSelect}>
          {({ isOpen }) => (
            <Stack {...props}>
              <MenuButton
                as={DropdownButton}
                ref={ref}
                color={value ? 'gray.700' : 'gray.300'}
                borderColor={isInvalid ? 'red.400' : undefined}
                rightIcon={
                  <chakra.div
                    transition="transform"
                    transitionDuration="fast"
                    transform={`rotateX(${isOpen ? 180 : 0}deg)`}
                  >
                    <IoIosArrowDown />
                  </chakra.div>
                }
              >
                {caption}
              </MenuButton>
              <MenuList maxW="100vw">
                {React.Children.map(children, (child) =>
                  React.cloneElement(child, {
                    fontWeight: child.props.value === value ? 'bold' : 'normal',
                    onClick: handleClick,
                  })
                )}
              </MenuList>
              {errorMessage && (
                <Text pt="1" fontSize="sm" color="red.400">
                  {errorMessage}
                </Text>
              )}
            </Stack>
          )}
        </Menu>
      </div>
    )
  }
)
