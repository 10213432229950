import React from 'react'
import { v4 as uuid } from 'uuid'
import { Box, BoxProps, Button, useToast, UseToastOptions } from '@chakra-ui/react'
import { Accept, ErrorCode, FileRejection } from 'react-dropzone'
import { ItemUploader } from 'components/ItemUploader'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import { Dropzone } from './Dropzone'
import { FilePreview } from './FilePreview'
import { ItemsWrapper } from './ItemsWrapper'
import { ImageGridFilePreview } from './ImageGridFilePreview'
import useTranslation from 'next-translate/useTranslation'
import { UploadType } from './constants'

export const oneMegaByte = 1048576

export const IMAGE_MIME_TYPES: Accept = {
  // images
  'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.tiff', '.webp'],
}

export const VIDEO_MIME_TYPES: Accept = {
  'video/*': ['.mp4', '.mov'],
  // video
  // 'video/mp4',
  // 'video/webm',
}

export const UPLOAD_MIME_TYPES = {
  ...IMAGE_MIME_TYPES,
  // documents
  'application/*': ['.doc', '.docx', '.pdf'],
  'text/*': ['.txt', '.pdf'],
  // TODO: Add more
}

const deleteWaitDefaultData: {
  resolve(value: DeleteDataInfo | PromiseLike<DeleteDataInfo>): void
  reject(reason?: any): void
} = {
  resolve: () => {},
  reject: () => {},
}

interface DeleteDataInfo {
  id: string
  key?: string
  previewMediaUrl?: string | null
}

export interface UploadFile {
  key?: string
  id: string
  file?: File
  name: string
  signedUrl?: string
  isCompleted?: boolean
  hasError?: boolean
}

interface UploadProps extends BoxProps {
  multiple?: boolean
  isPublic?: boolean
  hasCopyToClipboard?: boolean
  initialFiles?: UploadFile[]
  acceptedMimeTypes?: Accept
  maxFiles?: number
  maxSize?: number
  isCompact?: boolean
  /**
   * Grid will be used
   * "Upload images" copy will be used, instead of "Upload files"
   */
  uploadType?: UploadType
  onUploadStart?(file: UploadFile): void
  onUploadComplete(file: UploadFile): void
  onUploadError?(file: UploadFile): void
  onItemRemove(id: string, key: string): void
}

const Upload: React.FC<UploadProps> = ({
  multiple = true,
  initialFiles = [],
  acceptedMimeTypes = IMAGE_MIME_TYPES,
  maxFiles,
  maxSize,
  isPublic,
  isCompact,
  uploadType = UploadType.File,
  onUploadComplete,
  onItemRemove,
  onUploadStart,
  onUploadError,
  ...props
}) => {
  const { t } = useTranslation('common')
  const errorMessages = React.useMemo(
    () => ({
      [ErrorCode.FileInvalidType]: t('errors.productForm.fileInvalidType'),
      [ErrorCode.FileTooLarge]: t('errors.productForm.fileTooLarge'),
      [ErrorCode.TooManyFiles]: t('errors.productForm.tooManyFiles'),
    }),
    []
  )
  const toastOptions: UseToastOptions = {
    position: 'top-right',
    isClosable: true,
    status: 'error',
  }
  const toast = useToast()
  const deleteWaitPromiseRef = React.useRef(deleteWaitDefaultData)
  const [toBeDeletedItem, setToBeDeletedItem] = React.useState<DeleteDataInfo>()
  const rejectDelete = React.useCallback(() => {
    deleteWaitPromiseRef.current.reject()
    deleteWaitPromiseRef.current = deleteWaitDefaultData
    setToBeDeletedItem(undefined)
  }, [])
  const [items, setItems] = React.useState<UploadFile[]>(initialFiles)
  const handleItemComplete = React.useCallback(
    (id: string, key: string) => {
      setItems((prevFiles) => {
        const prevFileIndex = prevFiles.findIndex((item) => item.id === id)
        const newFile = { ...prevFiles[prevFileIndex], key, isCompleted: true }
        return [
          ...prevFiles.slice(0, prevFileIndex),
          newFile,
          ...prevFiles.slice(prevFileIndex + 1),
        ]
      })
      const prevFileIndex = items.findIndex((item) => item.id === id)
      const newFile = { ...items[prevFileIndex], key, isCompleted: true }
      onUploadComplete(newFile)
    },
    [items]
  )
  const handleItemError = React.useCallback(
    (id: string) => {
      setItems((prevFiles) =>
        prevFiles.map((item) => (item.id === id ? { ...item, hasError: true } : item))
      )
      const foundItem = items.find((item) => item.id === id)
      if (!foundItem) {
        return
      }

      onUploadError?.(foundItem)
      toast({
        ...toastOptions,
        title: t('Upload.unSuccessful', { name: foundItem?.name }),
      })
    },
    [items, onUploadError]
  )
  const openDeleteModal = async ({ id, key, previewMediaUrl }: DeleteDataInfo) => {
    setToBeDeletedItem({ id, key, previewMediaUrl })
    await new Promise<DeleteDataInfo>((resolve, reject) => {
      deleteWaitPromiseRef.current = { resolve, reject }
    })
  }
  const handleItemRemove = React.useCallback(
    async ({ id, key }: { id: string; key?: string }, previewMediaUrl?: string | null) => {
      try {
        await openDeleteModal({ id, key, previewMediaUrl })
        setToBeDeletedItem(undefined)
        setItems((prevFiles) => prevFiles.filter((item) => item.id !== id))
        if (key) {
          onItemRemove(id, key)
        }
      } catch {
        // do nothing
      }
    },
    [onItemRemove]
  )
  const handleFilesPicked = React.useCallback(
    (accepted: File[], rejected: FileRejection[]) => {
      if (accepted.length) {
        const newFiles: UploadFile[] = accepted
          .slice(0, maxFiles ? maxFiles - items.length : undefined)
          .map((file) => ({
            id: uuid(),
            file,
            name: file.name,
            isCompleted: false,
            hasError: false,
          }))
        setItems((prevFiles) => [...prevFiles, ...newFiles])
      }
      if (rejected.length) {
        rejected.forEach(({ file, errors }) => {
          toast({
            ...toastOptions,
            title: t('errors.productForm.cantBeUploaded', {
              name: file.name,
            }),
            description: errors.length
              ? `${t('errors.productForm.reason')}: ${errors
                  .map(({ code }) => errorMessages[code] as string)
                  .join('. ')}`
              : undefined,
          })
        })
      }
    },
    [items, maxFiles]
  )
  const toBeDeletedItemFile = React.useMemo(
    () => (toBeDeletedItem ? items.find((item) => toBeDeletedItem?.id === item.id) : undefined),
    [toBeDeletedItem]
  )
  const FilePreviewComp = uploadType === UploadType.Image ? ImageGridFilePreview : FilePreview
  const uploadSpecificContent = React.useMemo(() => {
    if (uploadType === UploadType.Image) {
      return {
        uploadTypeLabel: t('Upload.images'),
        deleteTitle: t('Upload.deletePicture'),
        deleteDescription: t('Upload.areYouSureYouWantToDeleteThisPicture'),
      }
    }

    if (uploadType === UploadType.Video) {
      return {
        uploadTypeLabel: t('Upload.videos'),
        deleteTitle: t('Upload.deleteVideo'),
        deleteDescription: t('Upload.areYouSureYouWantToDeleteThisVideo'),
      }
    }

    return {
      uploadTypeLabel: t('Upload.files'),
      deleteTitle: t('Upload.deleteItem'),
      deleteDescription: t('Upload.areYouSureYouWantToDeleteThisItem'),
    }
  }, [t, uploadType])

  return (
    <Box {...props}>
      {(multiple || items.length === 0) && (
        <Dropzone
          uploadType={uploadType}
          instructions={t('Upload.instructions', {
            uploadType: uploadSpecificContent.uploadTypeLabel,
          })}
          releaseToUpload={t('Upload.releaseToUpload')}
          title={`${t('Upload.upload')} ${uploadSpecificContent.uploadTypeLabel}`}
          accept={acceptedMimeTypes}
          multiple={multiple}
          isCompact={isCompact}
          maxSize={maxSize}
          maxFiles={maxFiles}
          onDrop={handleFilesPicked}
        />
      )}
      <ItemsWrapper isGrid={uploadType === UploadType.Image} isCompact={isCompact}>
        {items.map((item) =>
          item.file ? (
            <ItemUploader
              key={item.id}
              file={item.file}
              isPublic={isPublic!}
              onError={() => handleItemError(item.id)}
              onUploadStart={() => onUploadStart?.(item)}
              onUploadFinished={(key: string) => handleItemComplete(item.id, key)}
            >
              {({ progress, previewMediaURL, name, extension }) => {
                const percentageUploaded = item.isCompleted ? 100 : Math.min(progress * 100, 99)
                const fileName = `${name}${extension}`
                return (
                  <FilePreviewComp
                    previewMediaURL={previewMediaURL}
                    fileName={fileName}
                    percentageUploaded={percentageUploaded}
                    onItemRemove={() =>
                      handleItemRemove({ id: item.id, key: item.key }, previewMediaURL)
                    }
                  />
                )
              }}
            </ItemUploader>
          ) : (
            <FilePreviewComp
              key={item.id}
              previewMediaURL={uploadType === UploadType.Image ? item.signedUrl || '' : ''}
              fileName={item.name || item.key || ''}
              percentageUploaded={100}
              onItemRemove={() =>
                item.key && handleItemRemove({ id: item.id, key: item.key }, item.signedUrl)
              }
            />
          )
        )}
      </ItemsWrapper>
      {toBeDeletedItem && (
        <ModalOrDrawer
          maxW="3xl"
          title={uploadSpecificContent.deleteTitle}
          description={uploadSpecificContent.deleteDescription}
          footer={
            <>
              <Button variant="outline" mr={2} onClick={rejectDelete}>
                {t('Upload.buttonLabelCancel')}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => deleteWaitPromiseRef.current.resolve(toBeDeletedItem)}
              >
                {t('Upload.buttonLabelDelete')}
              </Button>
            </>
          }
          isOpen
          onClose={rejectDelete}
        >
          {toBeDeletedItemFile ? (
            <FilePreview
              previewMediaURL={toBeDeletedItem.previewMediaUrl || ''}
              fileName={toBeDeletedItemFile.name || toBeDeletedItemFile.key || ''}
              percentageUploaded={100}
            />
          ) : (
            <div />
          )}
        </ModalOrDrawer>
      )}
    </Box>
  )
}

export { Upload }
