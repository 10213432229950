import React from 'react'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { ShopProductOption } from 'src/generated/graphql-frontend'
import { useVariantsDropdown } from './useVariantsDropdown'
import { RequiredAddProductInput } from './types'

interface Props {
  productId?: string
  options: ShopProductOption[] | undefined | null
  isBuyNow?: boolean
  onSubmit(data: RequiredAddProductInput): void
  onClose(): void
}

export const VariantDrawer: React.FC<Props> = ({
  productId,
  options,
  isBuyNow,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation('username')
  const [variantId, setVariantId] = React.useState<string>()
  const { dropdown, data, loading } = useVariantsDropdown({
    options: options,
    productId: productId,
    onSelect: (productVariant) => {
      setVariantId(productVariant?.id)
    },
  })
  const handleSubmit = React.useCallback(() => {
    if (variantId) {
      onSubmit({ variantId, quantity: 1 })
    }
  }, [data, variantId])
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={!!productId}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton color="primary.500" size="lg" />
        <DrawerHeader borderBottomWidth="1px">{t('Shop.pickVariant')}</DrawerHeader>
        <DrawerBody>{dropdown}</DrawerBody>
        <DrawerFooter>
          <Button w="100%" isDisabled={!variantId} isLoading={loading} onClick={handleSubmit}>
            {isBuyNow ? t('Shop.actionButtonsBuyNow') : t('Shop.actionButtonsAddToCart')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
