import { HStack, Heading, Icon, IconButton, Stack } from '@chakra-ui/react'
import { InputWrapper } from 'components/InputWrapper'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { BiTrash } from 'react-icons/bi'
import { ProfileInput } from 'src/generated/graphql-frontend'
import { SocialMediaNetworkType } from 'src/types/general'
import { SocialMediaIcons } from '.'
import { socialMediaOnlyUsername } from './SocialMedia'

interface NetworkFormProps {
  network: SocialMediaNetworkType
  onRemove?(): void
  register: UseFormRegister<ProfileInput>
  index: number
}

export const NetworkForm: React.FC<NetworkFormProps> = ({ network, onRemove, register, index }) => {
  const { t } = useTranslation('profile')
  const NetworkIcon = SocialMediaIcons[network].colored
  const isUsernameOnly = socialMediaOnlyUsername.includes(network)
  return (
    <Stack spacing="4">
      <HStack spacing="2">
        <NetworkIcon width="5" height="5" />
        <Heading size="sm" textTransform="capitalize">
          {network}
        </Heading>
      </HStack>
      <HStack>
        <InputWrapper
          variant="outline"
          flex="1"
          maxW="full"
          placeholder={
            isUsernameOnly
              ? t('SocialNetworkControls.networkUsername', { network })
              : `https://${network}.com/username`
          }
          {...register(`socialMediaAccounts.${index}.url`)}
        />
        {onRemove && (
          <IconButton
            aria-label="Remove"
            variant="unstyled"
            borderRadius="md"
            px="2.5"
            icon={<Icon as={BiTrash} width="6" height="6" />}
            onClick={onRemove}
          />
        )}
      </HStack>
    </Stack>
  )
}
