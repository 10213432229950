import React from 'react'
import { Flex, VStack, FlexProps } from '@chakra-ui/react'

interface Props extends FlexProps {
  hasBorder?: boolean
  emptyComp?: React.ReactNode
  isEmpty?: boolean
}

export const BorderWrapper: React.FC<Props> = ({
  hasBorder = true,
  emptyComp,
  isEmpty,
  children,
  ...props
}) => (
  <Flex
    flexDir="column"
    padding={{ base: 0, lg: hasBorder ? 10 : undefined }}
    {...(hasBorder && {
      borderColor: 'gray.200',
      borderRadius: '2xl',
      borderWidth: { base: children ? '0' : '2px', lg: '2px' },
    })}
    {...props}
  >
    {isEmpty ? (
      <VStack spacing={{ base: 6, md: 10 }} justifyContent="center" py="10" px="6">
        {emptyComp}
      </VStack>
    ) : (
      children
    )}
  </Flex>
)
