/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { SystemStyleFunction, lighten, darken } from '@chakra-ui/theme-tools'

const outlineVariant: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props
  const color = `${c}.500`
  const hoverColor = lighten(`${c}.500`, 8)
  const activeColor = darken(`${c}.500`, 8)
  return {
    bgColor: 'transparent',
    borderColor: color,
    color,
    _hover: {
      bgColor: 'transparent',
      color: hoverColor,
      borderColor: hoverColor,
    },
    _active: {
      bgColor: 'transparent',
      color: activeColor,
      borderColor: activeColor,
    },
  }
}

const solidVariant: SystemStyleFunction = (props) => {
  const { colorScheme: cs } = props
  const cSplit = cs.split('.')
  const c = cSplit[0]
  const shade = cSplit.slice(1).join('.') || props.colorMode === 'dark' ? '600' : '500'
  const color = props.colorMode === 'dark' ? 'white' : 'white'
  return {
    bgColor: `${c}.${shade}`,
    border: '1px solid',
    borderColor: props.colorMode === 'dark' ? `${c}.500` : `${c}.${shade}`,
    color,
    _hover: {
      bgColor: darken(`${c}.${shade}`, 5),
      _disabled: {
        bgColor: `${c}.300`,
      },
    },
    _active: {
      bgColor: darken(`${c}.${shade}`, 12),
    },
  }
}

const Button = {
  baseStyle: {
    color: 'white',
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  sizes: {
    md: {
      h: 'auto',
      minW: undefined,
      // TODO: Fix responsivity, check accross multiple screens
      px: { base: 5, md: 7 },
      py: { base: 3, md: '1.125rem' },
      borderRadius: { base: 'xl', md: 'xl' },
      fontSize: {
        base: '14px',
        md: 'md',
      },
    },
  },
  variants: {
    solid: solidVariant,
    outline: outlineVariant,
  },
  defaultProps: {
    colorScheme: 'primary',
  },
}

export default Button
