import React, { ReactElement } from 'react'
import { getFileExtensionFromName, stripFileExtensionFromName } from '../../utils/helpers'
import { useUploadFile } from '../../utils/useUploadFile'

interface ItemUploaderProps {
  file: File
  isPublic: boolean
  children(value: {
    progress: number
    previewMediaURL: string | null
    name: string
    awsKey?: string
    extension: string
  }): ReactElement
  onError(error: Error): void
  onUploadStart?: () => void
  onUploadFinished(key: string): void
}

const ItemUploader: React.FC<ItemUploaderProps> = ({
  children,
  file,
  isPublic,
  onError,
  onUploadStart,
  onUploadFinished,
}) => {
  const isInitRef = React.useRef(false)
  const [progress, setProgress] = React.useState(0)
  const [awsKey, setAwsKey] = React.useState<string>()
  const [previewMediaURL, setPreviewMediaURL] = React.useState<string | null>(null)
  const uploadFile = useUploadFile()

  const handleUploadFinish = React.useCallback(
    (key: string) => {
      onUploadFinished?.(key)
      setAwsKey(key)
    },
    [onUploadFinished]
  )

  React.useEffect(() => {
    if (isInitRef.current) {
      return
    }
    isInitRef.current = true
    void uploadFile({
      file,
      isPublic,
      onError,
      onUploadFinished: handleUploadFinish,
      onUploadStart,
      setPreviewMediaURL,
      setProgress,
    })
  }, [file])

  return children({
    awsKey,
    progress,
    previewMediaURL,
    name: stripFileExtensionFromName(file.name),
    extension: getFileExtensionFromName(file.name),
  })
}

export { ItemUploader }
