import React from 'react'
import { v4 as uuid } from 'uuid'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, BoxProps, Stack, Button, IconButton, ButtonProps, Icon } from '@chakra-ui/react'
import { BiTrash } from 'react-icons/bi'
import { ShopProductOptionValue } from 'src/generated/graphql-frontend'
import { InputWrapper } from 'components/InputWrapper'
import { TextTag } from 'components/TextTag'
import { OptionsForm } from 'components/Shop'
import { GrabButton } from './GrabButton'
import { NewOptionInput } from './NewOptionInput'
import useTranslation from 'next-translate/useTranslation'

export const TrashButton: React.FC<ButtonProps> = (props) => (
  <IconButton
    variant="ghost"
    aria-label="Share"
    px="0"
    icon={<Icon as={BiTrash} w="6" h="6" />}
    {...props}
  />
)

interface RowProps extends BoxProps {
  label?: React.ReactNode
  isNewOption?: boolean
  onRemove?(): void
}

export const Row: React.FC<RowProps> = ({ label, isNewOption, children, onRemove, ...props }) => (
  <Box {...props}>
    {label && (
      <TextTag pl="12" pb="2">
        {label}
      </TextTag>
    )}
    <Stack direction="row" alignItems="center" spacing="4" userSelect="none">
      <Box w="6" h="6">
        <GrabButton {...(isNewOption && { opacity: 0.65, cursor: 'not-allowed' })} />
      </Box>
      <Box flex="1">{children}</Box>
      {!isNewOption && (
        <Box>
          <TrashButton onClick={onRemove} />
        </Box>
      )}
    </Stack>
  </Box>
)

interface Props {
  index: number
  id: string
  values: ShopProductOptionValue[]
  onDone(): void
}

export const OptionForm: React.FC<Props> = ({ index, onDone }) => {
  const { t } = useTranslation('backOffice')
  const {
    control,
    register,
    getValues,
    trigger,
    formState: { isValid },
  } = useFormContext<OptionsForm>()
  const { fields, update, remove } = useFieldArray({ name: 'options', control })
  const handleNewOptionValue = React.useCallback(
    (newOptionValue: string) => {
      {
        const field = getValues('options')[index]
        update(index, {
          ...field,
          values: [
            ...field.values,
            {
              valueId: uuid(),
              value: newOptionValue,
            },
          ],
        })
      }
    },
    [update, index]
  )
  const removeValue = React.useCallback(
    (removeValueId: string) => {
      {
        const field = getValues('options')[index]
        update(index, {
          ...field,
          values: [...field.values.filter(({ valueId }) => valueId !== removeValueId)],
        })
      }
    },
    [update, index]
  )
  return (
    <Stack spacing="4">
      <Row
        label={t('Product.optionFormRowOptionName')}
        onRemove={() => {
          remove(index)
          void trigger('options')
          onDone()
        }}
      >
        <InputWrapper
          {...register(`options.${index}.name`, { required: true })}
          variant="outline"
          maxW="100%"
          autoComplete="off"
        />
      </Row>
      <Stack spacing="2" pl="5">
        {fields[index].values.map(({ valueId }, itemIdx) => (
          <Row
            key={valueId}
            label={itemIdx === 0 ? t('Product.optionFormRowOptionValues') : undefined}
            onRemove={valueId ? () => removeValue(valueId) : undefined}
          >
            <InputWrapper
              {...register(`options.${index}.values.${itemIdx}.value`, { required: true })}
              variant="outline"
              maxW="100%"
              autoComplete="off"
            />
          </Row>
        ))}
        <Row isNewOption>
          <NewOptionInput index={index} onNewOption={handleNewOptionValue} getValues={getValues} />
        </Row>
        <Box pl="10">
          <Button isDisabled={!isValid} alignSelf="flex-start" onClick={onDone}>
            {t('Product.optionFormButtonLabelDone')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}
