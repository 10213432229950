import Mixpanel, { Callback } from 'mixpanel-browser'
import { getIsSsr } from './helpers'

let distinctId: string | undefined

const { NEXT_PUBLIC_MIXPANEL_TOKEN } = process.env
Mixpanel.init(NEXT_PUBLIC_MIXPANEL_TOKEN, {
  debug: process.env.NODE_ENV !== 'production',
  loaded: function (mixpanel) {
    distinctId = mixpanel.get_distinct_id()
  },
})

const isMixPanelActive = NEXT_PUBLIC_MIXPANEL_TOKEN && !getIsSsr() // process.env.NODE_ENV === 'production' && !getIsSsr()

type People = { people: { set(prop: string, to: any, callback?: Callback): void } }

type Actions = Pick<
  typeof Mixpanel,
  'identify' | 'alias' | 'track' | 'get_distinct_id' | 'reset' | 'register'
> &
  People & { get_user_id: () => string | undefined }

const mixpanel: Actions = {
  identify: (id) => {
    if (isMixPanelActive) Mixpanel.identify(id)
  },
  alias: (id) => {
    if (isMixPanelActive) Mixpanel.alias(id)
  },
  track: (name, props) => {
    if (isMixPanelActive) Mixpanel.track(name, props)
  },
  people: {
    set: (...props) => {
      if (isMixPanelActive) Mixpanel.people.set(props)
    },
  },
  reset: () => {
    if (isMixPanelActive) Mixpanel.reset()
  },
  register: (...props) => {
    if (isMixPanelActive) Mixpanel.register(...props)
  },
  get_distinct_id: () => distinctId,
  get_user_id: () => {
    if (isMixPanelActive) {
      const cookie = document.cookie.split(' ').find((name) => name.startsWith('mp'))
      if (cookie) {
        const cookieValue: Record<string, string> = JSON.parse(
          decodeURIComponent(cookie.split('=')[1])
        )
        return cookieValue.$user_id
      }
    }
  },
}

export default mixpanel
