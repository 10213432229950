import { chakra } from '@chakra-ui/react'

export const CarouselNavButton = chakra('button', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    w: '48px',
    h: '48px',
    borderRadius: 'xl',
    bg: '#FFF4EC',
    color: '#643C1F',
    transition: 'background 0.25s ease-out',
    pointerEvents: 'all',
    _disabled: {
      bg: 'gray.200',
      color: '#C6C6C6',
    },
  },
})
