import React from 'react'
import { Stack, Heading, Text } from '@chakra-ui/react'
import { ProductTypesQuery } from 'src/generated/graphql-frontend'

export const ProductType: React.FC<ProductTypesQuery['productTypes'][number]> = ({
  name,
  slug,
  isDigital,
  isShippable,
}) => (
  <Stack py="6" px="4" borderRadius="xl" boxShadow="0px 12px 32px rgba(20, 20, 20, 0.089)">
    <Heading size="md">{name}</Heading>
    <Text>{slug}</Text>
    <Text>
      This product is: {isShippable && 'Shippable'}
      {isDigital && 'Digital'}
    </Text>
  </Stack>
)
