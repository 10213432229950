import { chakra, Text } from '@chakra-ui/react'

export const Description = chakra(Text, {
  baseStyle: {
    color: 'gray.600',
    fontSize: { base: 'sm', md: 'md' },
    lineHeight: 'tall',
    opacity: 0.6,
  },
})
