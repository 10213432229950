import dynamic from 'next/dynamic'
import { SocialMediaNetworkType } from 'src/types/general'
import { defaultTheme, esoteriqTheme } from './theme'

const esoteriqCardRatio = 0.6412556054
type DimensionStringType = `${number}px` | `${number}%`

interface ImageConfig {
  url: string
  marginLeft?: number
  ratio?: number
}

export interface DecorationImageConfig {
  url: string
  /**
   * base, md
   */
  width: (number | DimensionStringType)[]
  /**
   * base, md
   */
  height: (number | DimensionStringType)[]
}

interface Config {
  theme: any
  isDarkMode?: boolean
  components: {
    Logo: any
    LogoIcon: any
  }
  images: {
    homePageSection2: ImageConfig
    cards: Required<Omit<ImageConfig, 'marginLeft'>>[]
    decorations: DecorationImageConfig[]
    faq: ImageConfig
    footer: ImageConfig
    // TODO: empty-cart.svg, empty-pot.svg
  }
  socials: {
    [key in SocialMediaNetworkType]?: string
  }
}

const planticoConfig: Config = {
  theme: defaultTheme,
  components: {
    Logo: dynamic(() => import('src/components/Logos/Plantico/Logo')),
    LogoIcon: dynamic(() => import('src/components/Logos/Plantico/LogoIcon')),
  },
  images: {
    cards: [
      {
        url: '/images/homepage/choose-amount-card.svg',
        ratio: 0.77,
      },
      {
        url: '/images/homepage/starter-card.svg',
        ratio: 0.87,
      },
      {
        url: '/images/homepage/support-chantelle-card.svg',
        ratio: 0.87,
      },
    ],
    decorations: [
      {
        height: [42, 42],
        width: [41, 41],
        url: '/images/decorations/coin.svg',
      },
      {
        width: ['14px', '40px'],
        height: ['14px', '40px'],
        url: '/images/decorations/coin.svg',
      },
      {
        width: ['28px', '45px'],
        height: ['26px', '43px'],
        url: '/images/decorations/heart.svg',
      },
      {
        width: ['20px', '20px'],
        height: ['20px', '20px'],
        url: '/images/decorations/coin.svg',
      },
    ],
    homePageSection2: { url: '/images/homepage/illustration-section-2.svg' },
    faq: { url: '/images/homepage/illustration-hand-with-a-heart-sm.svg' },
    footer: { url: '/images/homepage/plant-illustration.svg' },
  },
  socials: {
    facebook: 'https://facebook.com/plantico.io',
    instagram: 'https://instagram.com/plantico.io',
  },
}

const esoteriqConfig: Config = {
  theme: esoteriqTheme,
  isDarkMode: true,
  components: {
    Logo: dynamic(() => import('src/components/Logos/Esoteriq/Logo')),
    LogoIcon: dynamic(() => import('src/components/Logos/Esoteriq/LogoIcon')),
  },
  images: {
    cards: [
      {
        url: '/images/esoteriq/3.svg',
        ratio: esoteriqCardRatio,
      },
      {
        url: '/images/esoteriq/4.svg',
        ratio: esoteriqCardRatio,
      },
      {
        url: '/images/esoteriq/2.svg',
        ratio: esoteriqCardRatio,
      },
    ],
    decorations: [
      {
        height: [88, 88],
        width: [88, 88],
        url: '/images/esoteriq/10.svg',
      },
      {
        width: ['44px', '84px'],
        height: ['44px', '84px'],
        url: '/images/esoteriq/sun.svg',
      },
      {
        width: ['60px', '180px'],
        height: ['60px', '180px'],
        url: '/images/esoteriq/star.svg',
      },
    ],
    homePageSection2: { url: '/images/esoteriq/eye.svg' },
    faq: { url: '/images/esoteriq/7.svg' },
    footer: { url: '/images/esoteriq/6.svg', marginLeft: -12 },
  },
  socials: {
    facebook: 'https://facebook.com/esoteriq.fr',
    instagram: 'https://instagram.com/esoteriq.fr',
  },
}

export const CONFIGS: Record<string, Config> = {
  localhost: planticoConfig,
  'plantico.io': planticoConfig,
  'esoteriq.fr': esoteriqConfig,
}
