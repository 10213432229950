import React from 'react'
import { format } from 'date-fns/format'
import { Heading, Text, VStack, HStack, Button, useBreakpointValue } from '@chakra-ui/react'
import {
  OrderQuery,
  PaymentStatusEnum,
  PaymentTypeEnum,
  usePaymentIntentManualApproveMutation,
  usePaymentIntentManualCancelMutation,
} from 'src/generated/graphql-frontend'
import { getPrice } from 'utils/payments'
import useTranslation from 'next-translate/useTranslation'
import { RefetchQueriesEnum } from 'src/constants'

export const PaymentInfo: React.FC<NonNullable<NonNullable<OrderQuery['order']>['Payment']>> = ({
  id,
  amount,
  status,
  paidAt,
  type,
  currency,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { t } = useTranslation('common')
  const [approvePayment, approvePaymentData] = usePaymentIntentManualApproveMutation({
    refetchQueries: [RefetchQueriesEnum.Order],
    awaitRefetchQueries: true,
  })
  const [cancelPayment, cancelPaymentData] = usePaymentIntentManualCancelMutation({
    refetchQueries: [RefetchQueriesEnum.Order],
    awaitRefetchQueries: true,
  })
  const ButtonsWrapper = isMobile ? VStack : HStack
  return (
    <>
      <Heading size="md">{t('paymentInfo')}</Heading>
      <Text>{t('amount')}: {getPrice(amount, currency)}</Text>
      {type === PaymentTypeEnum.BankTransfer && (
        <Text>
          {t('variableSymbol')}: {id}
        </Text>
      )}
      {paidAt && <Text>{t('paidAt')}: {format(new Date(paidAt as string), 'dd/MM/yyyy')}</Text>}
      {type === PaymentTypeEnum.BankTransfer && status === PaymentStatusEnum.Pending && (
        <ButtonsWrapper alignItems="stretch" justifyContent="stretch">
          <Button
            w="100%"
            size="sm"
            colorScheme="green"
            isLoading={approvePaymentData.loading}
            onClick={() => {
              if (window.confirm(t('areYouSureYouWantToApprovePayment'))) {
                void approvePayment({ variables: { id } })
              }
            }}
          >
            {t('approvePayment')}
          </Button>
          <Button
            w="100%"
            size="sm"
            colorScheme="red"
            isLoading={cancelPaymentData.loading}
            onClick={() => {
              if (window.confirm(t('areYouSureYouWantToCancelPayment'))) {
                void cancelPayment({ variables: { id } })
              }
            }}
          >
            {t('cancelPaymentAndOrder')}
          </Button>
        </ButtonsWrapper>
      )}
    </>
  )
}
