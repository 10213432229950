import { Checkbox, Text, VStack } from '@chakra-ui/react'
import { useShiftedDaysInWeek } from 'components/TimePick'
import React from 'react'

interface Props {
  dayInWeek: number
  value: boolean
  onChange: (value: boolean) => void
}

export const DayCheckbox: React.FC<Props> = ({ dayInWeek, value, onChange }) => {
  const { getDayTranslation } = useShiftedDaysInWeek()
  const id = dayInWeek.toString()
  return (
    <VStack
      as="label"
      htmlFor={id}
      flex="1"
      borderRadius="lg"
      // bg={value ? 'teal.200' : 'white'}
      borderColor="teal.200"
      borderWidth="1px"
      spacing="1"
      py="2.5"
      cursor="pointer"
    >
      <Checkbox id={id} size="sm" isChecked={value} onChange={(e) => onChange(e.target.checked)} />
      <Text>{getDayTranslation(dayInWeek, true)}</Text>
    </VStack>
  )
}
