import React from 'react'
import { Flex } from '@chakra-ui/react'
import Skeleton from 'components/Skeleton'
import { DataTableProps } from '../types'
import { Line } from './Line'

export const DataTableSkeleton = React.memo<
  Pick<DataTableProps<{}>, 'headerItems' | 'skeletonRowsCount' | 'skeletonColumnsCount'>
>(({ headerItems, skeletonColumnsCount = 3, skeletonRowsCount = 10 }) => {
  const renderRow = React.useCallback(
    (height?: number) => {
      const source = headerItems ?? Array.from({ length: skeletonColumnsCount })
      return source.map(({ showFrom, minW } = {}, index = 0) => (
        <Flex
          key={index}
          alignItems="center"
          w="full"
          height={height}
          display={showFrom ? { base: 'none', [showFrom]: 'flex' } : undefined}
        >
          <Skeleton.Text flexBasis={Skeleton.randWidth(0.4)} minW={minW} />
        </Flex>
      ))
    },
    [headerItems, skeletonColumnsCount]
  )
  return (
    <>
      {Array.from({ length: skeletonRowsCount + 1 }).map((_, index) => (
        <React.Fragment key={index}>
          {renderRow(index === 0 ? 5 : 8)}
          <Line borderBottomWidth={index === 0 ? '2px' : undefined} />
        </React.Fragment>
      ))}
    </>
  )
})

DataTableSkeleton.displayName = 'DataTableSkeleton'
