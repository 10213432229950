import { extendTheme } from '@chakra-ui/react'
import { headerHeight } from 'src/components/Header'
import foundations from './foundations'
import components from './components'

export const defaultTheme = extendTheme({
  ...foundations,
  components,
  styles: {
    global: {
      body: {
        // color: 'white',
        // bg: 'gray.800',
        color: 'gray.700',
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        height: '100vh',
        width: '100vw',
        overflowX: 'hidden',
      },
      '#__next': {
        paddingTop: headerHeight,
      },
    },
  },
})
