import { mode, cssVar, SystemStyleFunction } from '@chakra-ui/theme-tools'

const $bg = cssVar('tooltip-bg')
const $arrowBg = cssVar('popper-arrow-bg')

const baseStyle: SystemStyleFunction = (props) => {
  const bg: string = mode('gray.700', 'gray.300')(props)
  return {
    [$bg.variable]: `colors.${bg}`,
    px: '4',
    py: '2',
    bg: [$bg.reference],
    [$arrowBg.variable]: [$bg.reference],
    color: mode('whiteAlpha.900', 'gray.900')(props),
    borderRadius: 'lg',
    fontWeight: 'medium',
    fontSize: 'sm',
    textAlign: 'center',
    boxShadow: 'md',
    maxW: '320px',
    zIndex: 'tooltip',
  }
}

export default {
  baseStyle,
}
