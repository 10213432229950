import React from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import {
  Box,
  Stack,
  Text,
  Divider,
  Icon,
  useDisclosure,
  Collapse,
  BoxProps,
  Button,
  useColorModeValue,
} from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { FaChevronDown } from 'react-icons/fa'
import { IoIosPerson, IoIosRepeat } from 'react-icons/io'
import { HiPlus } from 'react-icons/hi'
import { BiDollar } from 'react-icons/bi'
import { getRoute } from 'utils/helpers'
import { getColorModeValue, pulse } from 'utils/theme/helpers'
import { useMyProfilesQuery, useSwitchProfileMutation } from 'src/generated/graphql-frontend'
import { TextTag } from 'components/TextTag'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import { ProfilePicture } from 'components/ProfilePicture'
import { CustomMenuButton } from './CustomMenuButton'
import useTranslation from 'next-translate/useTranslation'

import blank from 'public/images/blank.png'

interface ProfileBtnProps extends BoxProps {
  pictureUrl?: string | null
  leftIcon?: IconType
  rightIcon?: React.ReactNode
  name: string
  label?: string
  labelColorScheme?: string
  onClick(): void
}

const ProfileBtn: React.FC<ProfileBtnProps> = ({
  label,
  name,
  onClick,
  labelColorScheme,
  leftIcon,
  pictureUrl,
  rightIcon,
  ...props
}) => (
  <Stack
    as="button"
    direction="row"
    alignItems="center"
    textAlign="left"
    borderRadius="md"
    pl="2"
    pr="3.5"
    py="1.5"
    minW="full"
    spacing="2.5"
    onClick={onClick}
    transitionProperty="common"
    transitionDuration="fast"
    _hover={{
      bgColor: getColorModeValue('gray.200', 'gray.700'),
    }}
    {...props}
  >
    <ProfilePicture bgImage={pictureUrl ? `url(${pictureUrl})` : undefined}>
      {leftIcon && <Icon as={leftIcon} w="4" h="4" />}
    </ProfilePicture>
    <Box flex="1">
      <Text fontWeight="medium" noOfLines={1}>
        {name}
      </Text>
      {label && (
        <TextTag display="flex" alignItems="center" color={labelColorScheme}>
          {label}
        </TextTag>
      )}
    </Box>
    {rightIcon}
  </Stack>
)

interface Props {
  callbackUrl?: string
  defaultIsOpen?: boolean
  onClose?(): void
  onSwitched?(): void
}

export const MyProfiles: React.FC<Props> = ({
  callbackUrl,
  defaultIsOpen,
  onClose: onCloseProp,
  onSwitched,
}) => {
  const { t } = useTranslation('merchant')
  const colorSchemeBecomeACreator = useColorModeValue('primary', 'yellow')
  const { isOpen, onToggle } = useDisclosure()
  const modalToggle = useDisclosure({
    defaultIsOpen,
  })
  const router = useRouter()
  const session = useSession()
  const profile = session.data?.user.profile
  const { data } = useMyProfilesQuery()
  const [switchProfile, { loading }] = useSwitchProfileMutation()
  const handleSwitchProfile = React.useCallback(
    async (id: string) => {
      modalToggle.onClose()
      await switchProfile({
        variables: {
          switchProfileId: id,
        },
      })
      onSwitched?.()
      if (callbackUrl) {
        location.replace(`${process.env.NEXT_PUBLIC_APP_URL}/${callbackUrl}`)
      } else {
        location.reload()
      }
    },
    [callbackUrl, onSwitched]
  )
  if (!profile) {
    return (
      <Button as={Link} href={getRoute('newProfile')} leftIcon={<Icon as={BiDollar} />} colorScheme={colorSchemeBecomeACreator}>
        {t('MyProfiles.becomeACreator')}
      </Button>
    )
  }
  const { name, username, label, labelColorScheme, profilePicture } = profile
  return (
    <>
      <Stack spacing="4" animation={loading ? `${pulse} infinite 1s linear` : undefined}>
        <ProfileBtn
          pictureUrl={profilePicture?.thumbnailSignedUrl ?? blank.src}
          name={name}
          label={label}
          labelColorScheme={labelColorScheme}
          rightIcon={
            <Icon
              as={FaChevronDown}
              width={{ base: '4', md: '3.5' }}
              height={{ base: '4', md: '3.5' }}
              transition="transform 0.125s ease-out"
              transform={`rotateX(${isOpen ? 180 : 0}deg)`}
            />
          }
          ml="-2"
          mr="-2.5"
          onClick={onToggle}
        />
        <Collapse in={isOpen} animateOpacity>
          <Stack spacing="6">
            <CustomMenuButton href={getRoute('profile', username)} leftIcon={IoIosPerson}>
              {t('MyProfiles.showProfile')}
            </CustomMenuButton>
            <CustomMenuButton onClick={modalToggle.onOpen} leftIcon={IoIosRepeat}>
              {t('MyProfiles.switchProfile')}
            </CustomMenuButton>
            <Divider borderBottomWidth="2px" />
          </Stack>
        </Collapse>
      </Stack>
      <ModalOrDrawer
        maxW="xl"
        title={t('MyProfiles.switchProfile')}
        description={t('MyProfiles.switchBetweenProfile')}
        isOpen={modalToggle.isOpen}
        onClose={() => {
          modalToggle.onClose()
          onCloseProp?.()
        }}
      >
        <Stack spacing="4">
          {data?.myProfiles &&
            data?.myProfiles
              .filter(({ id }) => id !== profile.id)
              .map(({ id, label, labelColorScheme, name, profilePicture }) => (
                <React.Fragment key={id}>
                  <ProfileBtn
                    pictureUrl={profilePicture?.thumbnailSignedUrl ?? ''}
                    name={name}
                    label={label}
                    labelColorScheme={labelColorScheme}
                    onClick={() => handleSwitchProfile(id)}
                  />
                  <Divider />
                </React.Fragment>
              ))}
          <ProfileBtn
            leftIcon={HiPlus}
            name={t('MyProfiles.addNewProfile')}
            onClick={() => {
              modalToggle.onClose()
              void router.push(getRoute('newProfile'))
            }}
          />
        </Stack>
      </ModalOrDrawer>
    </>
  )
}
