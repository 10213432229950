import React from 'react'

export const useIsReducedMotion = () => {
  const [isReducedMotion, setIsReducedMotion] = React.useState(false)
  // const [isLowBattery, setIsLowBattery] = React.useState(false) - TODO: Implement this feature

  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    
    const mediaQuery = window.matchMedia('(prefers-reduced-motion)')
    setIsReducedMotion(mediaQuery.matches)
    const listener = () => setIsReducedMotion(mediaQuery.matches)
    mediaQuery.addEventListener('change', listener)
    return () => mediaQuery.removeEventListener('change', listener)
  }, [])

  return isReducedMotion
}
