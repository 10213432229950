import { Heading, Icon, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { DropzoneProps, useDropzone } from 'react-dropzone'
import { BsFillFileEarmarkBinaryFill, BsFillImageFill } from 'react-icons/bs'
import { IoIosVideocam } from 'react-icons/io'
import { getColorModeValue } from 'utils/theme/helpers'
import { UploadType } from './constants'

interface Props extends DropzoneProps {
  title: string
  releaseToUpload: string
  instructions: string
  isCompact?: boolean
  uploadType?: UploadType
}

const Dropzone: React.FC<Props> = ({
  instructions,
  releaseToUpload,
  title,
  isCompact,
  uploadType = UploadType.File,
  ...props
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone(props)
  const color = useColorModeValue('gray.300', 'whiteAlpha.600')
  const activeColor = useColorModeValue('primary.600', 'whiteAlpha.900')

  const IconComponent = React.useMemo(() => {
    if (uploadType === UploadType.Image) {
      return BsFillImageFill
    }

    if (uploadType === UploadType.Video) {
      return IoIosVideocam
    }

    return BsFillFileEarmarkBinaryFill
  }, [uploadType])

  // omit isActive to suppress react warning
  const { isActive, ...dropZoneProps } = getRootProps({ isActive: isDragActive })

  return (
    <Stack
      px={isCompact ? '4' : '8'}
      minH={{ sm: isCompact ? '20' : '48', md: isCompact ? '20' : '32' }}
      justifyContent="center"
      // textAlign="center"
      borderWidth="2px"
      borderStyle="dashed"
      borderColor="gray.300"
      borderRadius="3xl"
      bgColor="gray.250"
      color={isDragActive ? activeColor : color}
      cursor="pointer"
      transitionProperty="common"
      transitionDuration="fast"
      _hover={{
        color: activeColor,
      }}
      {...dropZoneProps}
    >
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getInputProps()}
      />
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '4', md: isCompact ? '4' : '8' }}
        alignItems="center"
      >
        <Icon
          as={IconComponent}
          w={{ base: '6', sm: '10', md: isCompact ? '10' : '14' }}
          h={{ base: '6', sm: '10', md: isCompact ? '10' : '14' }}
        />
        <Stack
          alignItems={{ base: 'center', md: 'flex-start' }}
          textAlign={{ base: 'center', md: 'left' }}
          spacing={isCompact ? '1' : '2'}
        >
          <Heading
            fontSize="md"
            __css={{ color: getColorModeValue('primary.600', 'whiteAlpha.800') }}
          >
            {title}
          </Heading>
          <Text fontSize={{ base: 'sm', md: isCompact ? 'md' : 'lg' }}>
            {isDragActive ? releaseToUpload : instructions}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}

export { Dropzone }
