import React from 'react'
import { MotionProps } from 'framer-motion'
import { FlexProps, VStack } from '@chakra-ui/react'
import { MotionBox } from 'components/Motion'

const offset = 32
const delay = 0.075

const variants = {
  enter: (direction: number) => ({
    x: offset * direction,
    opacity: 0,
    transition: {
      x: {
        type: 'spring',
        mass: 0.75,
        velocity: 1.5,
        damping: 20,
        stiffness: 150,
        restSpeed: 0.45,
      },
    },
  }),
  animate: {
    y: 0,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    x: offset * direction * -1,
    opacity: 0.75,
    transition: {
      x: {
        type: 'spring',
        mass: 0.45,
        velocity: 2,
        stiffness: 120,
        restSpeed: 0.45,
      },
    },
  }),
}

export interface StepProps extends MotionProps, Pick<FlexProps, 'as'> {
  title?: string
  step?: number
  width?: number | string
  py?: FlexProps['py']
  pb?: FlexProps['pb']
  alignSelf?: FlexProps['alignSelf']
  alignItems?: FlexProps['alignItems']
  goToStep?(step: number): void
  nextStep?(force?: boolean): void
  prevStep?(): void
  onSubmit?: React.FormEventHandler<HTMLDivElement> & React.FormEventHandler<HTMLFormElement>
  isPreviousSwiperble?: boolean
}

export const Step: React.FC<StepProps> = ({ as, onSubmit, custom, alignSelf, children }) => (
  <VStack as={as} w="full" onSubmit={onSubmit} spacing="4">
    {children &&
      React.Children.map(children as React.ReactElement, (child: React.ReactElement, index) =>
        child && child.props ? (
          <MotionBox
            key={index}
            display="flex"
            custom={custom}
            variants={variants}
            initial="enter"
            animate="animate"
            exit="exit"
            // transition={{
            //   x: {
            //     delay: index * delay,
            //   },
            //   opacity: { duration: 0.25, delay: index * delay },
            // }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            alignSelf={alignSelf || child.props.alignSelf}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            alignItems={child.props.alignItems}
          >
            {child}
          </MotionBox>
        ) : null
      )}
  </VStack>
)
