import React from 'react'
import { useMotionValue, useTransform } from 'framer-motion'
import { chakra, Box, AspectRatio, Icon, Text, ScaleFade } from '@chakra-ui/react'
import { MotionBox } from 'components/Motion'
import { IoMdClose } from 'react-icons/io'
import { BiTrash } from 'react-icons/bi'
import { BsStar, BsStarFill } from 'react-icons/bs'
import { FilePreviewProps } from './types'
import { ActionButton } from './ActionButton'

function template({ x, y }: { x: string | number; y?: string | number }) {
  return `translateX(${parseFloat(x.toString())}%) ${
    y ? `translateY(${parseFloat(y.toString())}%)` : ''
  }`
}

export const ImageGridFilePreview: React.VFC<FilePreviewProps> = ({
  previewMediaURL,
  percentageUploaded,
  isPicked,
  onPick,
  onItemRemove,
}) => {
  const progress = useMotionValue(percentageUploaded)
  React.useEffect(() => {
    progress.set(percentageUploaded)
  }, [percentageUploaded])
  const textX = useTransform(progress, [0, 100], [0, -100])
  const isComplete = percentageUploaded === 100
  return (
    <ScaleFade in initialScale={0.85}>
      <Box
        position="relative"
        w="full"
        bg="white"
        overflow="hidden"
        borderRadius="2xl"
        boxShadow="lg"
        userSelect="none"
      >
        <AspectRatio ratio={1}>
          <div />
        </AspectRatio>
        {previewMediaURL && (
          <Box
            position="absolute"
            left="0"
            top="0"
            w="full"
            h="full"
            bgSize="cover"
            bgPos="center"
            bgRepeat="no-repeat"
            borderRadius="md"
            mr="6"
            style={{ backgroundImage: `url(${previewMediaURL})` }}
          />
        )}
        <MotionBox
          position="absolute"
          top="0"
          left="0"
          bg="primary.500"
          w="full"
          height="full"
          opacity={0.65}
          transitionProperty="common"
          transitionDuration="fast"
          transformTemplate={template}
          style={{ x: progress }}
        />
        <MotionBox
          position="absolute"
          left="0"
          right="0"
          top="50%"
          transformTemplate={template}
          style={{
            x: progress,
            y: -50,
          }}
          transitionProperty="common"
          transitionDuration="fast"
          opacity={isComplete ? 0 : 1}
        >
          <MotionBox
            transformTemplate={template}
            display="inline-block"
            p="4"
            transitionProperty="common"
            transitionDuration="fast"
            style={{ x: textX }}
          >
            <Text
              color="white"
              fontSize="lg"
              fontWeight="medium"
              textShadow="0 1px 5px rgba(0, 0, 0, 0.35)"
            >
              {Math.round(percentageUploaded)}%
            </Text>
          </MotionBox>
        </MotionBox>
        <Box position="absolute" left="50%" bottom="3" transform="translateX(-50%)">
          {onPick && (
            <ActionButton opacity={0.65} onClick={onPick}>
              {isPicked ? (
                <Icon as={BsStarFill} width="6" height="6" color="yellow.300" />
              ) : (
                <Icon as={BsStar} width="6" height="6" color="primary.500" />
              )}
            </ActionButton>
          )}
          {onItemRemove && (
            <ActionButton opacity={0.65} onClick={onItemRemove}>
              <chakra.div
                transform={`translateY(${isComplete ? '-50%' : 0})`}
                transitionProperty="common"
                transitionDuration="fast"
              >
                <Icon as={IoMdClose} width="6" height="6" />
                <Icon as={BiTrash} width="6" height="6" />
              </chakra.div>
            </ActionButton>
          )}
        </Box>
      </Box>
    </ScaleFade>
  )
}
