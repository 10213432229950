import * as React from 'react'
import dynamic from 'next/dynamic'
import { useToast } from '@chakra-ui/react'
import { getApiS3FileByTokenUrl } from 'src/utils/helpers'
import { useUploadFile } from '../../utils/useUploadFile'
import { ThumbnailSizeEnum } from 'src/generated/graphql-frontend'
import {PartialBlock} from '@blocknote/core/src/blocks/defaultBlocks'

const CustomTextEditorComponent = dynamic(
  () => import('../TextEditor').then((module) => module.TextEditor),
  {
    ssr: false,
  }
)

export interface Props {
  initialContent?: PartialBlock[]
  placeholder?: string
  onChangeContent: (contentJson: string) => void
  isSimple?: boolean
}

export function CustomTextEditor({ initialContent, placeholder, isSimple, onChangeContent }: Props) {
  const uploadFile = useUploadFile()
  const toast = useToast()

  return (
    <CustomTextEditorComponent
      initialContent={initialContent}
      placeholder={placeholder}
      onChangeContent={onChangeContent}
      isSimple={isSimple}
      onImageUpload={async (file) => {
        const fileKey = await uploadFile({
          file,
          isPublic: true,
          onUploadFinished(key) {},
          setProgress: (progress) => {},
          onError(error) {
            toast({
              position: 'top-right',
              title: error.message,
              status: 'error',
            })
          },
          onUploadStart() {},
        })

        return getApiS3FileByTokenUrl(fileKey ?? '', ThumbnailSizeEnum.CoverPicture)
      }}
    />
  )
}
