import { Box } from '@chakra-ui/react'
import Linkify from 'linkify-react'

export const StyledLinkify = (props: React.ComponentProps<typeof Linkify>) => (
  <Box
    __css={{
      a: {
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    }}
  >
    <Linkify {...props} options={{ target: '_blank', ...props.options }} />
  </Box>
)
