import { chakra, keyframes, Box } from '@chakra-ui/react'

const bg = ['var(--chakra-colors-skeletor-start)', 'var(--chakra-colors-skeletor-end)']

export const pulse = keyframes`
  0% {background-color: ${bg[0]}}
  50% {background-color: ${bg[1]}}
  100% {background-color: ${bg[0]}}
`

const pulseStyle = {
  animation: `${pulse} 1.5s infinite ease-out`,
}

const borderRadius = 'lg'

const NBSP = '\\00a0'
const Text = chakra(Box, {
  baseStyle: {
    ...pulseStyle,
    textIndent: -9999,
    borderRadius,
    transform: 'scaleY(0.8)',
    _before: {
      content: `"${NBSP}"`,
      lineHeight: 1,
    },
  },
})

const Rect = chakra(Box, {
  baseStyle: {
    ...pulseStyle,
    borderRadius,
    height: '2rem',
  },
})

const Circle = chakra(Box, {
  baseStyle: {
    ...pulseStyle,
    borderRadius: 'circle',
    height: '4rem',
  },
})

const randWidth = (factor: number, maxWidth = 1) =>
  `${Math.round((Math.random() * factor + (Math.max(maxWidth, factor) - factor)) * 100)}%`

export default {
  Text,
  Rect,
  Circle,
  randWidth,
}
