const logMessage = (
  method: 'log' | 'info' | 'warn' | 'error',
  message: any,
  ...args: unknown[]
) => {
  (console[method])(message, ...args)
}

export default {
  /* eslint-disable */
  debug: (message: string, ...args: any) => logMessage("log", message, ...args), // 'log' is the default method
  info: (message: string, ...args: any) => logMessage("log", message, ...args),
  warn: (message: string, ...args: any) => logMessage("warn", message, ...args),
  error: (message: string, ...args: any) => logMessage("error", message, ...args),
  /* eslint-enable */
}
