import React from 'react'
import { useRouter } from 'next/router'
import { Controller, useForm } from 'react-hook-form'
import { Box, Heading, MenuItem, Grid } from '@chakra-ui/react'
import { getRoute } from 'utils/helpers'
import {
  ProductVariantFromCombinationsMutationResult,
  ShopProductOption,
  useProductVariantFromCombinationsMutation,
} from 'src/generated/graphql-frontend'
import { Dropdown } from 'components/Dropdown'
import useTranslation from 'next-translate/useTranslation'

interface Form {
  combinations: string[]
}

interface Props {
  options?: ShopProductOption[] | undefined | null
  productId?: string
  value?: ShopProductOption[]
  onSelect?(
    productVariant: NonNullable<
      ProductVariantFromCombinationsMutationResult['data']
    >['productVariantFromCombinations']
  ): void
}

export const useVariantsDropdown = ({ options, productId, value, onSelect }: Props) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { control, watch } = useForm<Form>({
    defaultValues: {
      combinations: value?.map(({ values: [{ valueId }] }) => valueId),
    },
  })
  const [productVariantFromCombinations, { data, loading }] =
    useProductVariantFromCombinationsMutation()
  React.useEffect(() => {
    const subscription = watch(({ combinations }) => {
      if (productId && combinations && combinations.length > 0) {
        // & is different from the current value
        const loadNewVariant = async () => {
          const { data } = await productVariantFromCombinations({
            variables: {
              productId,
              combinations: combinations.filter(Boolean) as string[],
            },
          })
          const newPv = data?.productVariantFromCombinations
          if (newPv) {
            if (onSelect) {
              onSelect(newPv)
            } else {
              void router.replace(
                getRoute('profile', newPv.Profile.username, {
                  productSlug: newPv.Product.slug,
                  variantSlug: newPv.slug,
                  isPreview: !newPv.Product.isEnabled,
                })
              )
            }
          }
        }
        void loadNewVariant()
      }
    })
    return subscription.unsubscribe
  }, [productId, onSelect])
  const dropdown = React.useMemo(
    () =>
      Array.isArray(options) && options.length > 0 ? (
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(auto-fill, minmax(10rem, 1fr))' }}
          gap={{ base: '4', md: '12' }}
        >
          {options.map(({ attrId, values, name }, index) => (
            <Box key={attrId}>
              <Heading size="md" pb="4">
                {name}
              </Heading>
              <Controller
                name={`combinations.${index}`}
                control={control}
                render={({ field }) => (
                  <Dropdown {...field} placeholder={t('pickValue')}>
                    {values.map(({ valueId, value }) => (
                      <MenuItem key={valueId} value={valueId}>
                        {value}
                      </MenuItem>
                    ))}
                  </Dropdown>
                )}
              />
            </Box>
          ))}
        </Grid>
      ) : null,
    [options]
  )
  return { data, loading, dropdown }
}
