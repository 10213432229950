import React from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { FullStory } from '@fullstory/browser'
import mixpanel from 'utils/mixpanel'
import { TrackNamesEnum } from 'src/constants'

const { NEXT_PUBLIC_FULLSTORY_ORG_ID } = process.env

interface Props {
  children: React.ReactElement
}

export const Mixpanel: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const session = useSession()
  const handleRouteChange = React.useCallback(
    (url: string) => {
      const { user } = session.data || {}
      mixpanel.track(TrackNamesEnum.PageChanged, { toPage: url })
      const userId = mixpanel.get_user_id()
      if (user?.id) {
        if (NEXT_PUBLIC_FULLSTORY_ORG_ID) {
          FullStory('setIdentity', {
            uid: user.id,
            properties: { email: user.email, displayName: user.name },
          })
        }
        if (user.id !== userId) {
          mixpanel.identify(user.id)
          mixpanel.alias(user.id)
          mixpanel.people.set(user.id, {
            $email: user.email,
            $name: user.name,
            $created: user.createdAt,
            ...user,
          })
        }
      }
    },
    [session.data]
  )
  React.useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, session, mixpanel])
  React.useEffect(() => {
    handleRouteChange(window.location.href)
  }, [])
  return children
}
