import React from 'react'
import { v4 as uuid } from 'uuid'
import {
  Box,
  BoxProps,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  InputProps,
  Text,
  forwardRef,
  ColorProps,
  InputElementProps,
} from '@chakra-ui/react'
import { Translate } from 'next-translate'
import { Label } from './Label'

export const emailValidationRegisterOptions = (t: Translate) => ({
  required: t('auth:emailIsRequired'),
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: t('auth:pleaseEnterValidEmail'),
  },
})

export interface InputWrapperProps extends InputProps {
  flex?: BoxProps['flex']
  errorMessage?: string
  isError?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  inputLeftElementColor?: ColorProps['color']
  label?: string
  hint?: React.ReactNode
  rightIconPrtProps?: InputElementProps
  isReadOnly?: boolean
}

export const InputWrapper = forwardRef<InputWrapperProps, 'div'>(
  (
    {
      errorMessage,
      isError,
      leftIcon,
      rightIcon,
      inputLeftElementColor,
      label,
      hint,
      flex,
      maxW = 'xl',
      rightIconPrtProps,
      isReadOnly,
      ...props
    },
    ref
  ) => {
    const [tempId] = React.useState(uuid())
    return (
      <Box flex={typeof flex === undefined ? '1' : flex} maxW={maxW} position="relative">
        {label && <Label htmlFor={tempId}>{label}</Label>}
        <InputGroup size={props.size} alignItems="center">
          {leftIcon && (
            <InputLeftElement color={inputLeftElementColor || 'gray.300'} pointerEvents="none" top="50%" transform="translateY(-50%)">
              {leftIcon}
            </InputLeftElement>
          )}
          <Input
            ref={ref}
            id={tempId}
            errorBorderColor="red.400"
            isInvalid={isError ?? !!errorMessage}
            isReadOnly={isReadOnly}
            {...props}
          />
          {rightIcon && (
            <InputRightElement pointerEvents="none" top="50%" transform="translateY(-50%)" {...rightIconPrtProps}>
              {rightIcon}
            </InputRightElement>
          )}
        </InputGroup>
        {hint && (
          <Text pt="1" fontSize="sm" color="gray.500">
            {hint}
          </Text>
        )}
        {errorMessage && (
          <Text pt="1" fontSize="sm" color="red.400">
            {errorMessage}
          </Text>
        )}
      </Box>
    )
  }
)

// width="100%"
// flex="1"
// display="flex"
// flexDir="column"
// alignItems="stretch"
