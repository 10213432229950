import { IconButton } from '@chakra-ui/react'
import { LuGift } from 'react-icons/lu'

import type { IconButtonProps } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

export const SupportButton = (props: Omit<IconButtonProps, 'aria-label'>) => {
  const { t } = useTranslation('common')
  return (
    <IconButton
      aria-label={t('Support')}
      position="fixed"
      bottom={3}
      right={3}
      zIndex="sticky"
      borderRadius="50%"
      w={14}
      h={14}
      border="none"
      // bg="primary.500"
      colorScheme="primary"
      size="lg"
      icon={<LuGift />}
      boxShadow="lg"
      transitionProperty="opacity, transform"
      transitionDuration="normal"
      transitionTimingFunction="ease-in-out"
      {...props}
    />
  )
}
