import React from 'react'
import { useSession } from 'next-auth/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'
import { chakra, Box, Grid, Heading, Flex, Stack, Icon } from '@chakra-ui/react'
import { BiArrowBack } from 'react-icons/bi'
import { ProductTypeEnum, ProductVariantQuery, TimeSlotInput } from 'src/generated/graphql-frontend'
import { getPrice } from 'utils/payments'
import { TextTag } from 'components/TextTag'
import { CarouselNavButton } from 'components/CarouselNavButton'
import { MerchantBadge } from './MerchantBadge'
import { ProductTabs } from './ProductTabs'
import { PictureSlide } from './PictureSlide'
import { useVariantsDropdown } from './useVariantsDropdown'
import { ActionButtons } from './ActionButtons'
import { SharePanel } from './SharePanel'
import { SimilarProducts } from './SimilarProducts'
import { ManageProduct } from './ManageProduct'
import useTranslation from 'next-translate/useTranslation'
import { TimePick } from 'components/TimePick'
import { BookNowButton } from './BookNowButton'

// https://www.sneakersnstuff.com/en/product/52167/adidas-forum-low-x-sns

const SwiperNavButton = chakra(CarouselNavButton, {
  baseStyle: {
    bg: 'gray.200',
    color: 'primary.500',
    opacity: 0.65,
  },
})

export const ProductDetail: React.FC<NonNullable<ProductVariantQuery['productVariant']>> = ({
  id,
  name,
  description,
  isDefault,
  Pictures,
  Price,
  AttributeProduct,
  AttributeVariant,
  Profile,
  Product,
  BookingSettings,
}) => {
  const { t } = useTranslation('username')
  const session = useSession()
  const [swiper, setSwiper] = React.useState<SwiperClass>()
  const [swiperPreview, setSwiperPreview] = React.useState<SwiperClass>()
  const [chosenTimeSlot, setChosenTimeSlot] = React.useState<TimeSlotInput | null>(null)
  const variantsDropdown = useVariantsDropdown({
    productId: Product.id,
    options: AttributeProduct,
    value: AttributeVariant,
  })
  const handlePictureSlideChange = React.useCallback(
    (swiper: SwiperClass) => {
      const currIdx = swiper.activeIndex - 1
      swiperPreview?.slideTo(Math.floor((currIdx % Pictures.length) / 4) * 4)
    },
    [swiperPreview, Pictures]
  )

  const isBooking = Product.type === ProductTypeEnum.Booking
  return (
    <Box pb="20">
      {Profile.username === session.data?.user.profile?.username && (
        <ManageProduct id={Product.id} isEnabled={Product.isEnabled} />
      )}
      <Grid
        mt={{ base: 4, sm: 8 }}
        gap={{ base: 8, md: 12, xl: 24 }}
        templateColumns={{ base: 'auto', md: 'minmax(200px, auto) minmax(300px, 600px)' }}
      >
        <Box alignSelf="start" overflow="hidden">
          <Box position="relative" overflow="hidden" borderRadius="4xl">
            <Swiper
              loop={Pictures.length > 1}
              spaceBetween={32}
              slidesPerView={1}
              threshold={0}
              onSwiper={(swiper) => setSwiper(swiper)}
              onSlideChangeTransitionEnd={handlePictureSlideChange}
            >
              {Pictures.map(({ signedUrl }) => (
                <SwiperSlide key={signedUrl}>
                  <PictureSlide url={signedUrl} aspectRatio={0.95} borderRadius="4xl" />
                </SwiperSlide>
              ))}
            </Swiper>
            {Pictures.length > 1 && (
              <Box
                position="absolute"
                display={['none', 'flex']}
                width="100%"
                justifyContent="space-between"
                left="0"
                top="50%"
                px="4"
                pointerEvents="none"
                transform="translateY(-50%)"
                zIndex={1}
              >
                <SwiperNavButton onClick={() => swiper?.slidePrev()}>
                  <Icon as={BiArrowBack} width="6" height="6" />
                </SwiperNavButton>
                <SwiperNavButton onClick={() => swiper?.slideNext()}>
                  <Icon as={BiArrowBack} width="6" height="6" transform="scaleX(-1)" />
                </SwiperNavButton>
              </Box>
            )}
          </Box>
          <Box h="6" />
          <Box overflow="hidden" borderRadius="2xl">
            <Swiper
              loop={false}
              spaceBetween={16}
              slidesPerView={4}
              threshold={8}
              onSwiper={(swiper) => setSwiperPreview(swiper)}
            >
              {Pictures.map(({ thumbnailSignedUrl }, index) => (
                <SwiperSlide key={thumbnailSignedUrl}>
                  <PictureSlide
                    url={thumbnailSignedUrl ?? ''}
                    aspectRatio={1}
                    borderRadius="2xl"
                    cursor="pointer"
                    onClick={() => swiper?.slideTo(index + 1)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
        <Box>
          <Stack spacing={{ base: 4, md: 12 }}>
            <Heading size="xl">{isDefault ? name : Product.name}</Heading>
            <MerchantBadge
              name={Profile.name}
              username={Profile.username}
              label={Profile.label}
              labelColor={Profile.labelColorScheme}
              pictureUrl={Profile.profilePicture?.thumbnailSignedUrl ?? ''}
              target="_blank"
            />
            {variantsDropdown.dropdown}
            <Grid
              templateColumns={{ base: '1fr', md: '1fr' /*, lg: 'auto 1fr'*/ }}
              gap={{ base: 3, md: 6 }}
              alignItems="center"
              pb={{ base: 4, md: 0 }}
            >
              <Stack spacing={2} w="full" overflow="hidden">
                {isBooking && BookingSettings?.isEnabled && (
                  <Flex w="full" maxW={{
                    base: '100%',
                    md: '300px',
                  }}>
                    <TimePick
                      value={undefined}
                      productVariantId={id}
                      daysInAdvance={BookingSettings?.daysInAdvance}
                      onChange={setChosenTimeSlot}
                    />
                  </Flex>
                )}
                <Flex alignItems="flex-end">
                  <Heading size="xl">{getPrice(Price.amount, Price.currency)}</Heading>
                  {Price.requiresAddress && (
                    <TextTag flex="none" ml="1em" pb="0.5em" color="primary.500">
                      {t('Shop.plusShipping')}
                    </TextTag>
                  )}
                </Flex>
              </Stack>
              {isBooking ? (
                <BookNowButton
                  endDate={chosenTimeSlot?.endDate}
                  startDate={chosenTimeSlot?.startDate}
                  username={Profile.username}
                  productId={Product.id}
                  variantId={isDefault ? undefined : id}
                />
              ) : (
                <ActionButtons
                  productId={Product.id}
                  variantId={isDefault ? undefined : id}
                  hasOptions={AttributeProduct.length > 0}
                  username={Profile.username}
                  hasBuyNow
                />
              )}
            </Grid>
            <ProductTabs
              description={description}
              details={AttributeVariant}
              delivery={Price.ShippingZones}
              currency={Price.currency}
            />
            <Box pt={{ base: 8, md: 0 }}>
              <Heading size="md">{t('Shop.shareThis')}</Heading>
              <SharePanel />
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Box pt={{ base: 16, sm: 24, md: 48 }}>
        <Stack spacing={{ base: 6, md: 12 }}>
          <SimilarProducts username={Profile.username} filterId={Product.id} />
        </Stack>
      </Box>
    </Box>
  )
}
