import React from 'react'
import { HTMLChakraProps } from '@chakra-ui/react'
import { TextTag } from 'components/TextTag'

interface Props extends Omit<HTMLChakraProps<'label'>, 'as'> {
  children: React.ReactNode
}

export const Label: React.FC<Props> = ({ children, ...rest }) => (
  <TextTag
    as="label"
    display="block"
    pt={{ base: '2', md: '3' }}
    pb={{ base: '1', md: '2' }}
    {...rest}
  >
    {children}
  </TextTag>
)
