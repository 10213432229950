import React from 'react'
import { Stack } from '@chakra-ui/react'
import { Calendar } from './Calendar'
import { Times } from './Times'
import { TimePickProvider } from './context'
import { TimeSlotInput } from 'src/generated/graphql-frontend'
import { addDays, differenceInMonths, endOfMonth } from 'date-fns'

// when moving calendar - left, right, we fetch all available dates and hours in those days
// automatically show the closest available month and day in chosen month
// slide month animation + drag, time slots pop out and pop in

// right side show
interface Props {
  /**
   * Number of days in advance to show available time slots
   * @default 90
   */
  daysInAdvance?: number
  dateStartsWShift?: number
  productVariantId: string
  value?: Date
  onChange?(timeSlot: TimeSlotInput): void
}

export const TimePick: React.FC<Props> = ({
  daysInAdvance = 90,
  dateStartsWShift,
  productVariantId,
  value,
  onChange,
}) => {
  const [chosenDate, setChosenDate] = React.useState(value)
  const [chosenTime, setChosenTime] = React.useState(value)
  const handleTimePick = React.useCallback((startDate: Date, endDate: Date) => {
    setChosenTime(startDate)
    onChange?.({ startDate, endDate })
  }, [onChange])

  const monthsInAdvance = differenceInMonths(endOfMonth(addDays(new Date(), daysInAdvance)), new Date()) + 1
  return (
    <TimePickProvider
      value={{
        chosenDate,
        chosenTime,
        setChosenDate,
        setChosenTime,
      }}
    >
      <Stack
        // direction={{ base: 'column', md: 'row' }}
        direction="column"
        justifyContent="space-between"
        spacing="4"
        w="full"
        flex="1"
        maxW={{ base: 'calc(100vw - 2rem)', md: 'auto' }}
      >
        <Calendar
          value={value}
          monthsInAdvance={monthsInAdvance}
          productVariantId={productVariantId}
          dateStartsWShift={dateStartsWShift}
        />
        {chosenDate && <Times
          date={chosenDate}
          productVariantId={productVariantId}
          dateStartsWShift={dateStartsWShift}
          maxW={{ base: '100%', md: '350px' }}
          flex="1"
          onPick={handleTimePick}
        />}
      </Stack>
    </TimePickProvider>
  )
}
