import { Flex, HTMLChakraProps, Icon, Tag, chakra } from '@chakra-ui/react'
import { CarouselNavButton } from 'components/CarouselNavButton'
import Container from 'components/Container'
import { ProfileInfoCover } from 'components/Merchant'
import { CommunityCard, renderSlideCard } from 'components/Merchant/CommunityCard'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import { ProfilePage } from 'components/ProfilePage'
import { useRouter } from 'next/router'
import React from 'react'
import { BiArrowBack } from 'react-icons/bi'
import {
  ProfilesQueryResult,
  ThumbnailSizeEnum,
  useProfileQuery,
} from 'src/generated/graphql-frontend'
import styled from 'styled-components'
import { Mousewheel, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'

const CATEGORY = [
  'All',
  'Artists',
  'Bloggers',
  'Musicians',
  'Writers',
  'Podcasters',
  'Artisans',
  'Small businesses',
  'Communities',
  'Photographers',
  'Other',
]

export const StyledSwiper = styled(Swiper)<{
  $bulletActiveColor?: string
  $bulletInactiveColor?: string
  $bulletsBottom: number[]
}>`
  overflow: visible;
  --swiper-pagination-bullet-inactive-color: ${({ $bulletInactiveColor }) =>
    $bulletInactiveColor || 'var(--chakra-colors-teal-200)'};
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: ${({ $bulletActiveColor }) =>
    $bulletActiveColor || 'var(--chakra-colors-primary-500)'};
  .swiper-pagination {
    bottom: ${({ $bulletsBottom }) => $bulletsBottom[0]}px;
    --swiper-pagination-bullet-size: 6px;
    @media (min-width: 768px) {
      bottom: ${({ $bulletsBottom }) => $bulletsBottom[1]}px;
      --swiper-pagination-bullet-size: 8px;
    }
  }
  .swiper-pagination-bullet {
    transition: background 0.25s ease-out;
  }
  .swiper-slide {
    perspective: 500px;
  }
`

interface Props extends HTMLChakraProps<'div'> {
  profiles?: NonNullable<NonNullable<ProfilesQueryResult['data']>['profiles']>['profiles']
}

export function Community({ profiles, ...props }: Props) {
  const router = useRouter()
  const { modalId } = router.query
  const [swiper, setSwiper] = React.useState<SwiperClass>()
  const username = (modalId as string)?.split('-').slice(1).join('-')
  const { data: profile } = useProfileQuery({
    variables: {
      username: username,
      size: ThumbnailSizeEnum.Medium,
      coverPictureSize: ThumbnailSizeEnum.CoverPicture,
    },
    skip: !username,
  })
  return (
    <>
      {false && (
        <chakra.div
          mx={{ base: '-4', md: '0' }}
          px={{ base: '4', md: '0' }}
          pb={{ base: '3', md: '0' }}
          mb={{ base: '-3', md: '0' }}
          overflowX={{ base: 'auto', md: 'unset' }}
          maxW="100vw"
        >
          <Flex
            flexWrap={{ base: 'nowrap', md: 'wrap' }}
            justifyContent={{ base: 'flex-start', md: 'center' }}
            {...props}
          >
            {CATEGORY.map((label, index) => (
              <Tag
                key={index.toString()}
                flex="none"
                mt={{ base: '0', md: '4' }}
                mr={{ base: '2.5', md: '4' }}
                size={{ base: 'sm', md: 'md' }}
                {...(index === 0
                  ? {
                      variant: 'solid',
                      bgColor: 'teal.100',
                      fontWeight: 'medium',
                      color: 'primary.500',
                    }
                  : { variant: 'outline' })}
              >
                {label}
              </Tag>
            ))}
          </Flex>
        </chakra.div>
      )}
      {!!profiles?.length && (
        <chakra.div
          my={{ base: '4', md: '14' }}
          mx="8"
          width={['full', null, 'calc(100% + 180px)']}
          px={['0', null, '120px']}
          position="relative"
        >
          <StyledSwiper
            $bulletsBottom={[-32, -70]}
            modules={[Pagination, Mousewheel]}
            spaceBetween={12}
            slidesPerView={1.1}
            threshold={0}
            mousewheel={{ forceToAxis: true }}
            pagination={{
              type: 'bullets',
              clickable: true,
            }}
            watchSlidesProgress
            breakpoints={{
              480: {
                slidesPerView: 1.33,
                spaceBetween: 12,
                threshold: 4,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 16,
                threshold: 4,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 32,
                threshold: 8,
              },
              950: {
                slidesPerView: 2.33,
                spaceBetween: 32,
                threshold: 8,
              },
              1100: {
                slidesPerView: 2.75,
                spaceBetween: 32,
                threshold: 8,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 32,
                threshold: 8,
              },
            }}
            onSwiper={(swiper) => setSwiper(swiper)}
            // onSlideChange={() => console.log('slide change')}
          >
            {profiles.map(
              ({
                id,
                name,
                username,
                description,
                label,
                labelColorScheme,
                profilePicture,
                coverPicture,
                supportersCount,
              }) => (
                <SwiperSlide key={username}>
                  {renderSlideCard(
                    <CommunityCard
                      id={id}
                      name={name}
                      username={username}
                      description={description || ''}
                      supportersCount={supportersCount}
                      label={label || ''}
                      labelColorScheme={labelColorScheme || ''}
                      pictureUrl={
                        profilePicture?.thumbnailSignedUrl ||
                        `https://picsum.photos/240/240?${username}`
                      }
                      coverPictureUrl={
                        coverPicture?.thumbnailSignedUrl ||
                        `https://picsum.photos/360/150?${username}`
                      }
                    />
                  )}
                </SwiperSlide>
              )
            )}
          </StyledSwiper>
          <chakra.div
            position="absolute"
            display={['none', null, 'flex']}
            width="100%"
            justifyContent="space-between"
            left="0"
            top="50%"
            pointerEvents="none"
            transform="translateY(-50%)"
            zIndex={1}
          >
            <CarouselNavButton onClick={() => swiper?.slidePrev()}>
              <Icon as={BiArrowBack} width="6" height="6" />
            </CarouselNavButton>
            <CarouselNavButton onClick={() => swiper?.slideNext()}>
              <Icon as={BiArrowBack} width="6" height="6" transform="scaleX(-1)" />
            </CarouselNavButton>
          </chakra.div>
        </chakra.div>
      )}
      <ModalOrDrawer
        hasPx={false}
        isOpen={!!modalId}
        onClose={() => router.push('/', undefined, { scroll: false })}
      >
        {profile?.profile && (
          <>
            <ProfileInfoCover profile={profile.profile} isInModal />
            <Container>
              <ProfilePage profile={profile.profile} />
            </Container>
          </>
        )}
      </ModalOrDrawer>
    </>
  )
}
