import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import slugify from 'slugify'
import { Box, Heading, Stack, Button, Checkbox, Text, MenuItem } from '@chakra-ui/react'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import { ProductTypeInput } from 'src/generated/graphql-frontend'
import { InputWrapper } from 'components/InputWrapper'
import { fieldHasValueValidation } from 'utils/helpers'
import { Label } from 'components/Label'
import { Dropdown } from 'components/Dropdown'
import { Attributes } from './Attributes'
import { Line } from '../../components/Line'
import { useProductCategories } from '../../ProductForm/components/Sections'

interface Props {
  isOpen: boolean
  onSuccess(data: ProductTypeInput): void
  onClose(): void
}

export const AddProductTypeModal: React.FC<Props> = ({ isOpen, onSuccess, onClose }) => {
  const {
    control,
    setValue,
    getValues,
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ProductTypeInput>({
    defaultValues: {
      isShippable: false,
      isDigital: false,
    },
  })
  const productCategories = useProductCategories()
  const handleFormSubmit = React.useCallback(() => {}, [])
  React.useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen])
  const registerName = register('name', {
    validate: fieldHasValueValidation('Product type name is required'),
  })
  return (
    <ModalOrDrawer
      title="Create product type"
      description="Create a product type like a shoe, or a drink..."
      maxW="container.md"
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <>
          <Button variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(handleFormSubmit)} isLoading={isSubmitting}>
            Create product type
          </Button>
        </>
      }
    >
      <Stack as="form" onSubmit={handleSubmit(handleFormSubmit)} spacing="4">
        <Controller
          name="type"
          control={control}
          rules={{ required: 'Select product type' }}
          render={({ field }) => (
            <Box>
              <Label>Product type</Label>
              <Dropdown
                {...field}
                placeholder="Select product type"
                errorMessage={errors.type?.message}
              >
                {productCategories.map(({ key, label }) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Dropdown>
            </Box>
          )}
        />
        <InputWrapper
          {...{
            ...registerName,
            onBlur: (event) => {
              const {
                target: { value },
              } = event
              setValue('slug', slugify(value.trim().toLowerCase()))
              void registerName.onBlur(event)
            },
          }}
          label="Product type name"
          errorMessage={errors.name?.message}
        />
        <InputWrapper
          {...register('slug', { validate: fieldHasValueValidation('URL Slug is required') })}
          label="Slug"
          hint="This is used mainly for SEO purposes in URL"
        />
        <Controller
          control={control}
          name="isShippable"
          render={({ field: { value, onChange } }) => (
            <Checkbox isChecked={value} onChange={onChange}>
              This product type is shippable
            </Checkbox>
          )}
        />
        <div>
          <Controller
            control={control}
            rules={{
              validate: (isChecked) =>
                !isChecked ||
                !getValues('isShippable') ||
                'You can only choose one of the checkboxes.',
            }}
            name="isDigital"
            render={({ field: { value, onChange } }) => (
              <Checkbox isChecked={value} onChange={onChange}>
                This is a digital product type
              </Checkbox>
            )}
          />
          {errors.isDigital && (
            <Text pt="1" fontSize="sm" color="red.400">
              {errors.isDigital.message}
            </Text>
          )}
        </div>
        <Line borderColor="teal.200" />
        <Heading size="sm" pt="2.5">
          Attributes
        </Heading>
        <Attributes register={register} control={control} />
      </Stack>
    </ModalOrDrawer>
  )
}
