import { chakra, Flex } from '@chakra-ui/react'

export const NotificationDot = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    bgColor: 'primary.500',
    minW: '7',
    px: '1',
    h: '7',
    color: 'white',
  },
})

