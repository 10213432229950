import React from 'react'
import { ButtonProps, chakra } from '@chakra-ui/react'

export const ActionButton: React.FC<ButtonProps> = ({ children, onClick }) => (
  <chakra.button
    type="button"
    bg="white"
    color="red.500"
    borderRadius="md"
    width="8"
    height="8"
    display="flex"
    alignItems="center"
    justifyContent="center"
    opacity={0.65}
    transitionProperty="common"
    transitionDuration="fast"
    _hover={{
      opacity: 1,
    }}
    onClick={onClick}
  >
    <chakra.div width="6" height="6" overflow="hidden">
      {children}
    </chakra.div>
  </chakra.button>
)
