import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { UseFormRegister } from 'react-hook-form'
import { Textarea } from '@chakra-ui/react'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { Section } from 'components/BackOffice/components'
import { InputWrapper } from 'components/InputWrapper'

interface Props {
  register: UseFormRegister<ShopProductInput>
}

export const ThankYouMessage: React.FC<Props> = ({ register }) => {
  const { t } = useTranslation('backOffice')
  return (
    <Section
      title={t('Product.thankYouMessageAddMessageOptional')}
      description={t('Product.thankYouMessageAddMessageSentAfterBuy')}
    >
      <InputWrapper
        as={Textarea}
        {...register('thankYouMessage')}
        variant="outline"
        maxW="full"
        minH="32"
      />
    </Section>
  )
}
