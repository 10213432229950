import React from 'react'
import { Text, Box, Flex, Icon, Stack, Heading } from '@chakra-ui/react'
import type { IconType } from 'react-icons/lib'
import { HiArrowUp, HiArrowDown } from 'react-icons/hi'
import { TextTag } from './TextTag'
import { getPrice } from 'utils/payments'
import Skeleton from './Skeleton'

interface CustomStatProps {
  labelIcon: IconType
  label: React.ReactNode
  number?: React.ReactNode | null
  difference?: number | null
  differenceExtra?: React.ReactNode
  currencySymbol?: string
  isCurrency?: boolean
  isDisabled?: boolean
  isLoading?: boolean
}

// TODO: Clicking a stat will show a modal with graph

export const CustomStat: React.FC<CustomStatProps> = ({
  labelIcon: LabelIcon,
  label,
  number = '0',
  difference,
  differenceExtra,
  currencySymbol,
  isCurrency,
  isDisabled,
  isLoading,
}) => (
  <Box px={{ base: '4', md: '6' }} py={{ base: '4', md: '6' }} bg="gray.200" borderRadius="xl">
    <Stack spacing="2" opacity={isDisabled ? 0.6 : 1}>
      <Flex align="flex-start">
        <Icon as={LabelIcon} width="2.5" height="2.5" mr="2" transform="translateY(4px)" />
        <TextTag fontSize={{ base: '10px', md: 'xs' }}>{label}</TextTag>
      </Flex>
      <Heading size="lg">
        {isLoading ? (
          <Skeleton.Text maxW={Skeleton.randWidth(0.15, 0.3)} />
        ) : isCurrency && currencySymbol && typeof number === 'number' ? (
          getPrice(number, currencySymbol)
        ) : (
          number
        )}
      </Heading>
      {isLoading && <Skeleton.Text fontSize="xs" maxW="80%" />}
      {!isLoading && !!difference && (
        <Flex color={difference === 0 ? 'gray.300' : difference > 0 ? 'green.600' : 'peach.500'}>
          {Math.abs(difference) > 0 && (
            <Icon as={difference > 0 ? HiArrowUp : HiArrowDown} width="3.5" height="3.5" />
          )}
          <Text fontSize="xs">
            <Text as="span" fontWeight="medium">
              {/* {parseFloat((Math.round(Math.abs(difference) * 10000) / 100).toFixed(2))}% */}
              {difference}%
            </Text>
            <Text pl="2" as="span" color="gray.300">
              {differenceExtra}
            </Text>
          </Text>
        </Flex>
      )}
    </Stack>
  </Box>
)
