import React from 'react'
import { Box, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { BackofficeProductVariantsQuery } from 'src/generated/graphql-frontend'
import { Currencies, CurrencyType, getPrice } from 'utils/payments'
import { ThreeDotsButton } from 'components/ThreeDotsButton'
import { UploadFile } from 'components/Upload'
import blank from 'public/images/blank.png'
import { EditForm } from './EditForm'

export const VariantRow: React.FC<
  BackofficeProductVariantsQuery['backofficeProductVariants'][number] & {
    mainPictures: UploadFile[]
    currency: string
    trackInventory?: boolean
  }
> = ({ mainPictures, currency, trackInventory, ...product }) => {
  const { t } = useTranslation('backOffice')
  const { id, sku, name, inventoryQuantity, Price, Pictures } = product
  const { isOpen, onToggle, onClose } = useDisclosure()
  const currencyObj = Currencies[currency as CurrencyType]
  const imageUrl = !!Pictures && Pictures.length ? Pictures[0]?.thumbnailSignedUrl : blank.src
  return (
    <React.Fragment key={id}>
      <Box
        w="12"
        h="12"
        cursor="pointer"
        borderRadius="lg"
        backgroundImage={`url(${imageUrl!})`}
        backgroundSize="cover"
        backgroundPosition="center"
        display={{ base: 'none', sm: 'block' }}
      />
      <Text
        // weird SSR fix
        as="div"
        fontWeight="bold"
        fontSize={{ base: 'sm', md: 'inherit' }}
        noOfLines={{ base: 3, lg: undefined }}
        cursor="pointer"
      >
        <a onClick={onToggle}>{name}</a>
      </Text>
      <Text fontSize="sm">{Price ? getPrice(Price?.amount, currencyObj.name) : ''}</Text>
      <Text fontSize="sm">{inventoryQuantity}</Text>
      <Text fontSize="sm" display={{ base: 'none', sm: 'block' }}>
        {sku}
      </Text>
      <Menu>
        <MenuButton as={ThreeDotsButton} />
        <MenuList>
          <MenuItem onClick={onToggle}>{t('Product.variantRowButtonLabelEdit')}</MenuItem>
          <MenuItem onClick={() => console.log('delete')}>{t('Product.variantRowButtonLabelDelete')}</MenuItem>
        </MenuList>
      </Menu>
      {isOpen && (
        <Box gridColumn="1/-1" mb="4" pt="2" pb="4" px="4" bg="gray.250" borderRadius="lg">
          <EditForm
            currency={currencyObj.name}
            {...product}
            mainPictures={mainPictures}
            trackInventory={trackInventory}
            onClose={onClose}
          />
        </Box>
      )}
    </React.Fragment>
  )
}
