import { Grid } from '@chakra-ui/react'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import React from 'react'
import { DayCheckbox } from './DayCheckbox'


const DAY_START = 9 * 60
const DAY_END = 17 * 60

interface Props {
  setValue: UseFormSetValue<ShopProductInput>
  watch: UseFormWatch<ShopProductInput>
}

export const DaysCheckboxes: React.FC<Props> = ({ setValue, watch }) => {
  const daysSlotSettings = watch('BookingTemplateSettings.SlotSettings')
  const days = React.useMemo(
    () =>
      Array.from({ length: 7 }).map((_, dayInWeek) =>
        daysSlotSettings.some(({ dayInWeek: day }) => day === dayInWeek)
      ),
    [daysSlotSettings]
  )

  const handleToggleDay = (dayInWeek: number, value: boolean) => {
    if (value) {
      setValue('BookingTemplateSettings.SlotSettings', [
        ...daysSlotSettings,
        { dayInWeek, fromTime: DAY_START, toTime: DAY_END, isEnabled: true },
      ])
    } else {
      setValue(
        'BookingTemplateSettings.SlotSettings',
        daysSlotSettings.filter(({ dayInWeek: day }) => day !== dayInWeek)
      )
    }
  }

  return (
    <Grid gap={2} templateColumns={{ base: 'repeat(auto-fit, minmax(88px, 1fr))' }}>
      {days.map((value, index) => (
        <DayCheckbox
          key={index}
          dayInWeek={index}
          value={value}
          onChange={(newValue) => handleToggleDay(index, newValue)}
        />
      ))}
    </Grid>
  )
}
