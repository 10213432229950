import React from 'react'
import { Button, Spinner, useDisclosure } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { localStorageWasHereKey } from 'src/constants'
import { getIsLoggedIn, getRoute } from 'utils/helpers'
import blank from 'public/images/blank.png'
import { MobileNavContent } from '../MobileNav'
import { ProfilePicture } from '../ProfilePicture'

// TODO: Maybe instead of a spinner we save data in localStorage and use it temporarily while loading actual session
export const User = () => {
  const { t } = useTranslation('auth')
  const router = useRouter()
  const { data, status } = useSession()
  const isLoggedIn = getIsLoggedIn(data)
  const mobileNavToggle = useDisclosure()

  React.useEffect(() => {
    router.events.on('beforeHistoryChange', mobileNavToggle.onClose)
    return () => router.events.off('beforeHistoryChange', mobileNavToggle.onClose)
  }, [])

  if (status === 'loading') {
    return <Spinner size="md" />
  }

  const wasLoggedInBefore = typeof window !== 'undefined' && Boolean(localStorage.getItem(localStorageWasHereKey))
  return (
    <>
      {isLoggedIn ? (
        <ProfilePicture
          bgImage={`url(${
            (data?.user.profile || data?.user.personalProfile)?.profilePicture
              ?.thumbnailSignedUrl || blank.src
          })`}
          cursor="pointer"
          onClick={mobileNavToggle.onOpen}
        />
      ) : (
        <>
          <NextLink href={wasLoggedInBefore ? getRoute('login') : getRoute('signup')}>
            <Button as="a" variant="solid">
              {t(wasLoggedInBefore ? 'signIn' : 'signUp')}
            </Button>
          </NextLink>
        </>
      )}
      <MobileNavContent isOpen={mobileNavToggle.isOpen} onClose={mobileNavToggle.onClose} />
    </>
  )
}
