import React from 'react'
import { Divider, Grid } from '@chakra-ui/react'
import { ProductVariantOrdersType } from '../Cart/types'
import { ListItem } from './ListItem'
import { TimeSlotItem } from 'components/Bookings/TimeSlotItem'

interface Props {
  items: ProductVariantOrdersType
  currency: string
  username: string
  hasDividerAfter?: boolean
  canEdit?: boolean
  /**
   * Merchant will see booking items as {@link TimeSlotItem}
   */
  isMerchant?: boolean
}

export const ItemsList: React.FC<Props> = ({
  currency,
  username,
  items,
  isMerchant,
  hasDividerAfter,
  canEdit,
}) => (
  <>
    {items.map((item, index, { length }) => {
      const timeSlot = item.TimeSlot
      return (
        <React.Fragment key={item.id}>
          {timeSlot && isMerchant ? (
            <Grid
              templateColumns={{
                base: 'auto 1fr',
                sm: '1fr 3fr auto',
              }}
              gap={2}
            >
              <TimeSlotItem
                end={timeSlot.end}
                id={timeSlot.id}
                isPersonal={false}
                sessionEnd={timeSlot.sessionEnd}
                start={timeSlot.start}
                status={timeSlot.status}
                meetingDetails={timeSlot.meetingDetails}
                ContactInfo={timeSlot.ContactInfo}
                MerchantProfile={timeSlot.MerchantProfile}
                ProductVariantOrder={item}
                isPaidFor={timeSlot.isPaidFor}
                showDate
              />
            </Grid>
          ) : (
            <ListItem {...item} currency={currency} username={username} canEdit={canEdit} />
          )}
          {hasDividerAfter && index < length - 1 && <Divider />}
        </React.Fragment>
      )
    })}
  </>
)
