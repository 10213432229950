import React from 'react'
import { Icon, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import type { IconType } from 'react-icons/lib'

interface Props {
  label: string
  icon?: IconType
  groupHasValue?: boolean
  isChecked?: boolean
  onClick?(): void
}

export const CardWithIconButton: React.FC<Props> = ({
  label,
  icon,
  groupHasValue,
  isChecked,
  onClick,
}) => {
  const activeBg = useColorModeValue('peach.400', 'primary.600')
  return (
  <Stack
    cursor="pointer"
    alignItems="center"
    w="full"
    py={{ base: 4, sm: 6 }}
    px="3.5"
    spacing={{ base: 2, sm: 4 }}
    {...(groupHasValue
      ? {
        bg: isChecked ? activeBg : 'gray.250',
        color: isChecked ? 'white' : 'gray.300',
        transform: 'scale(0.97)',
      }
      : {
        bg: isChecked ? activeBg : 'whiteAlpha.100',
        color: isChecked ? 'white' : 'primary.500',
      })}
    borderRadius="lg"
    _focus={{
      boxShadow: 'outline',
      outline: 0,
    }}
    _hover={{
      bg: isChecked ? activeBg : 'white',
      transform: 'scale(1.05)',
      boxShadow: '0px 12px 32px rgba(20, 20, 20, 0.089)',
    }}
    transitionProperty="common"
    transitionDuration="normal"
    tabIndex={0}
    onKeyDown={
      onClick
        ? (event) => {
          if (['Space', 'Enter'].includes(event.code)) {
            onClick()
            event.preventDefault()
          }
        }
        : undefined
    }
    onClick={onClick}
  >
    {icon && <Icon as={icon} w="6" h="6" />}
    <Text fontWeight="medium" fontSize={{ base: 'sm', sm: 'md' }} textAlign="center">
      {label}
    </Text>
  </Stack>
)
  }
