import { chakra, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { useIsDarkMode } from 'utils/hooks'
import { headerHeight } from './useHeaderHeight'

const EASING = '0.25s ease-in-out'

const HeaderStrip: React.FC<{ children: React.ReactNode; isScroll?: boolean }> = ({
  isScroll,
  children,
}) => {
  const bg = useColorModeValue('rgba(255, 255, 255, 0.65)', 'rgb(23 14 27 / 64%)')
  const isDarkMode = useIsDarkMode()
  const shadowValue = isDarkMode
    ? '0px 8px 32px rgb(190 102 53 / 10%)'
    : '0px 14px 40px rgba(132, 132, 132, 0.14)'

  return (
    <chakra.header
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      pos="fixed"
      top="0"
      left="0"
      right="0"
      width="full"
      height={headerHeight}
      transitionProperty="all"
      transitionDuration="slow"
      transitionTimingFunction="ease-in-out"
      bg={isScroll ? bg : 'transparent'}
      shadow={isScroll ? shadowValue : undefined}
      zIndex="sticky"
      {...(isScroll && {
        backdropFilter: 'auto',
        backdropBlur: '4px',
        backdropBrightness: isDarkMode ? '120%' : '105%',
        backdropContrast: '105%',
        backdropHueRotate: isDarkMode ? '19deg' : '5deg',
      })}
    >
      <chakra.div width="100%" mx="auto" maxW="7xl">
        <chakra.div mx="auto" maxW="7xl">
          {children}
        </chakra.div>
      </chakra.div>
    </chakra.header>
  )
}

export { HeaderStrip }
