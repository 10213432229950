const Heading = {
  baseStyle: {
    fontWeight: 'medium',
    color: 'primary.500',
  },
  sizes: {
    '4xl': {
      fontSize: ['6xl', null, '7xl'],
      lineHeight: 1,
    },
    '3xl': {
      fontSize: ['5xl', null, '6xl'],
      lineHeight: 1,
    },
    '2xl': {
      fontSize: ['4xl', null, '5xl'],
      lineHeight: [1.2, null, 1],
      fontWeight: 'bold',
    },
    xl: {
      fontSize: ['2xl', null, '4xl'],
      lineHeight: 1.25,
    },
    lg: {
      fontSize: ['lg', null, '2xl'],
      lineHeight: [1.33, null, 1.2],
    },
    md: { fontSize: ['1rem', null, 'lg'], lineHeight: 1.2 },
    sm: { fontSize: { base: 'sm', md: 'lg' }, lineHeight: 1.2 },
    xs: { fontSize: 'sm', lineHeight: 1.2 },
  },
  defaultProps: {
    size: 'xl',
  },
}

export default Heading
