import React from 'react'
import { useRouter } from 'next/router'
import { useScroll } from 'framer-motion'
import { getRoute } from 'utils/helpers'
import { HeaderContent } from './HeaderContent'
import { HeaderStrip } from './HeaderStrip'
import { RoutesMap } from 'src/constants'

// https://dribbble.com/shots/5567430-Rainbow-Messenger-Animation

const disableHeaderPathnames = [getRoute('backofficeProduct'), getRoute('widget')]

function Header() {
  const router = useRouter()
  const [y, setY] = React.useState(0)
  const { scrollY } = useScroll()
  React.useEffect(() => scrollY.onChange(() => setY(scrollY.get())), [scrollY])
  const { pathname } = router
  const isStickyByDefault = ![
    '/',
    `/${RoutesMap.login}`,
    `/${RoutesMap.signup}`,
    `/${RoutesMap.role}`,
    `/${RoutesMap.forgottenPassword}`,
    `/${RoutesMap.passwordReset}`,
  ].includes(pathname)
  const isScroll = isStickyByDefault || y > 64
  const isDisabled = React.useMemo(
    () => disableHeaderPathnames.find((value) => pathname.startsWith(value)),
    [pathname]
  )
  if (isDisabled) return null
  return <HeaderStrip isScroll={isScroll}>{<HeaderContent hasRightContent />}</HeaderStrip>
}

export { Header }
