import React from 'react'
import { useForm } from 'react-hook-form'
import { chakra, Stack, Box, Button } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { InputWrapper } from 'components/InputWrapper'
import { getIsLoggedIn, getRoute } from 'utils/helpers'
import { ErrorMessage } from 'components/ErrorMessage'
import { Glow } from 'components/Glow'

interface Form {
  email: string
}

export const GetStarted = () => {
  const { t } = useTranslation('homePage')
  const { data } = useSession()
  const router = useRouter()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>()

  const handleFormSubmit = React.useCallback(async ({ email }: Form) => {
    await router.push(getRoute('signup', email))
  }, [])

  return getIsLoggedIn(data) ? null : (
    <chakra.form position="relative" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack direction="row" position="relative" zIndex="banner">
        <Box flexBasis="350px" minW={{ base: 'unset', md: '350px' }}>
          <InputWrapper
            {...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('gettingStarted.invalidEmail'),
              },
            })}
            variant="outline"
            type="email"
            placeholder={t('gettingStarted.enterEmail')}
            isInvalid={!!errors.email}
            w="full"
          />
        </Box>
        <Button
          type="submit"
          variant="solid"
          fontWeight="medium"
          textTransform="uppercase"
          letterSpacing="wide"
          px={{ base: 10, md: 8 }}
          isLoading={isSubmitting}
        >
          {t('gettingStarted.getStarted')}
          <Glow />
        </Button>
      </Stack>
      {errors.email && <ErrorMessage position="absolute">{errors.email.message}</ErrorMessage>}
    </chakra.form>
  )
}
