import React from 'react'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import { Box, BoxProps, Button, Icon, Stack, Heading, Text } from '@chakra-ui/react'
import { HiArrowLeft } from 'react-icons/hi'
import { MotionBox } from 'components/Motion'
import { MerchantBadge } from './MerchantBadge'

const fullScreenProps: BoxProps = {
  position: 'absolute',
  left: '0',
  top: '0',
  right: '0',
  bottom: '0',
}

export const Featured: React.FC = () => {
  const videoContainerRef = React.useRef<HTMLDivElement>(null)
  const x = useMotionValue(0)
  // const infoTransform = useTransform(x, [0, -450], [0, 120])
  const infoOpacity = useTransform(x, [0, -450], [1, 0])
  const handleVideoTouch = React.useCallback<
    React.TouchEventHandler<HTMLDivElement | HTMLVideoElement>
  >((event) => {
    const videoEl = videoContainerRef?.current?.querySelector('video') as HTMLVideoElement
    if (event.type === 'touchstart') {
      videoEl.pause()
    } else if (event.type === 'touchend') {
      try {
        void videoEl.play()
      } catch {
        // do nothing
      }
    }
  }, [])
  return (
    <MotionBox userSelect="none" position="absolute" left="0" top="0" width="100vw" height="100vh">
      <MotionBox position="fixed">
        <div ref={videoContainerRef}>
          <Box
            as="video"
            left="0"
            top="0"
            width="100vw"
            height="100vh"
            objectFit="cover"
            src="/barbervideo.mp4"
            autoPlay
            muted
            loop
            preload="preload"
          />
        </div>
        <Box {...fullScreenProps} bg="rgba(0, 0, 0, 0.25)">
          <Box
            {...fullScreenProps}
            position="fixed"
            onTouchStart={handleVideoTouch}
            onTouchEnd={handleVideoTouch}
          />
          <Box
            {...fullScreenProps}
            position="fixed"
            top="66%"
            bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 15.14%);"
            opacity={0.65}
            pointerEvents="none"
          />
          <Box
            display="flex"
            flexDir="column"
            justifyContent="space-between"
            position="fixed"
            left="6"
            top="12"
            right="6"
            bottom="12"
            flex="1"
            pointerEvents="none"
          >
            <MotionBox
              as="button"
              pointerEvents="all"
              alignSelf="flex-start"
              whileTap={{ scale: 0.95 }}
            >
              <Button
                leftIcon={<Icon as={HiArrowLeft} w="4" h="4" />}
                iconSpacing="4"
                variant="ghost"
                color="white"
                px="1"
                py="2.5"
                flex="none"
                _focus={{
                  outline: 0,
                }}
              >
                back to shop
              </Button>
            </MotionBox>
            <Box>
              <Stack spacing="6">
                <MerchantBadge
                  name="Chantelle Sulivan"
                  username="chantelle"
                  label="Barber"
                  labelColor="red.500"
                  pictureUrl="https://picsum.photos/240/240"
                  rating={4.2}
                />
                <Heading size="xl" color="white">
                  Mens&apos; haircut
                </Heading>
                <Text color="white" opacity={0.65} fontSize="sm" lineHeight="tall">
                  Enjoy your time when getting a haircut and have a nice experience with one of our
                  barbers.
                  <br />
                  <br />
                  Choose if you want our barbers to trim your beard too
                </Text>
              </Stack>
              <Box mt="12" pointerEvents="all">
                <motion.button
                  style={{ width: '100%', opacity: infoOpacity }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Button
                    w="full"
                    size="lg"
                    h="16"
                    iconSpacing="4"
                    colorScheme="yellow.300"
                    color="black"
                    alignSelf="flex-start"
                  >
                    Book for $12.99
                  </Button>
                </motion.button>
              </Box>
            </Box>
          </Box>
        </Box>
      </MotionBox>
    </MotionBox>
  )
}
