import React from 'react'
import { Flex, VStack } from '@chakra-ui/react'

export const BorderWrapper: React.FC<{ children: React.ReactNode; emptyComp: React.ReactNode }> = ({
  emptyComp,
  children,
  ...props
}) => (
  <Flex
    flexDir="column"
    padding={{ base: 0, lg: 10 }}
    borderWidth={{ base: children ? '0' : '2px', lg: '2px' }}
    borderColor="gray.200"
    borderRadius="2xl"
    {...props}
  >
    {children || (
      <VStack spacing={{ base: 6, md: 10 }} justifyContent="center" py="10" px="6">
        {emptyComp}
      </VStack>
    )}
  </Flex>
)
