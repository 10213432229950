import React from 'react'
import { Center, Spinner, Portal } from '@chakra-ui/react'
import { useHeaderHeight } from 'components/Header'

export const PageSpinner = () => {
  const height = useHeaderHeight()
  return (
    <Portal>
      <Center
        position="fixed"
        bg="rgba(255, 255, 255, 0.3)"
        left="0"
        top={`${height}px`}
        width="100vw"
        height={`calc(100vh - ${height}px)`}
      >
        <Spinner size="xl" thickness="4px" />
      </Center>
    </Portal>
  )
}
