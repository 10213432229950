import React from 'react'
import Image from 'next/legacy/image'
import useTranslation from 'next-translate/useTranslation'
import { Box, Stack, Flex, Text, Button, Heading, useColorModeValue } from '@chakra-ui/react'
import { OneTime } from './OneTime'
import { Membership } from './Membership'
import { EmbedProps } from './constants'
import { PaymentRequestProvider } from 'utils/hooks'

// TODO: When switching to one-time back from membership, remember last step in one-time
// or don't unmount it, so that when user decides to go for one-time instead of membership
// they can finish the payment

export const Embed: React.FC<Omit<EmbedProps, 'id'>> = ({
  profile,
  defaultDonationAmount,
  onPaid,
}) => {
  const { t } = useTranslation('common')
  const bg = useColorModeValue('white', 'gray.800')
  const { id: profileId, username, name } = profile || {}
  const [isOneTime, setIsOneTime] = React.useState(true)
  const hasTiers = !!profile.Tiers?.length
  if (!profile.stripeChargesEnabled) {
    return (
      <Box>
        <Stack alignItems="center" textAlign="center" pt="24" spacing={{ base: 4, md: 8 }}>
          <Box
            position="relative"
            width={{ base: '35px', md: '58px' }}
            height={{ base: '30px', md: '50px' }}
          >
            <Image src="/images/empty-pot.svg" objectFit="cover" layout="fill" alt="Sad plantico" />
          </Box>
          <Heading size="md">
            You can&apos;t support
            <br />
            {name} right now.
          </Heading>
          <Stack spacing="1" opacity={0.6}>
            <Text>They have to finish setting up their account.</Text>
            <Text>Come back later.</Text>
          </Stack>
        </Stack>
      </Box>
    )
  }
  // TODO: Use pill shape for one time / membership switch
  return (
    <PaymentRequestProvider profileId={profileId} initialAmount={defaultDonationAmount}>
      <Box borderRadius="5xl" bg={bg} pos="relative">
        {name && (
          <Heading pt={{ base: 4, md: 8 }} px={{ base: 4, md: 8 }} fontSize="lg">
            {t('supportTitle', { name })}
          </Heading>
        )}
        {hasTiers && (
          <Flex w="full" pt="5" pb="1" px={{ base: 4, md: 8 }} justifyContent="space-between">
            <Button
              flex="1"
              maxW="64"
              variant={isOneTime ? 'solid' : 'outline'}
              onClick={() => setIsOneTime(true)}
            >
              {t('oneTime')}
            </Button>
            <Button
              flex="1"
              maxW="64"
              ml="2.5"
              variant={isOneTime ? 'outline' : 'solid'}
              onClick={() => setIsOneTime(false)}
            >
              {t('membership')}
            </Button>
          </Flex>
        )}
        {profile &&
          username &&
          (isOneTime ? (
            <OneTime
              profile={profile}
              defaultDonationAmount={defaultDonationAmount}
              onPaid={onPaid}
            />
          ) : (
            <Membership profileId={profileId} />
          ))}
      </Box>
    </PaymentRequestProvider>
  )
}
