import React from 'react'
import { HStack, Center, Heading } from '@chakra-ui/react'

interface Props {
  step: number
  title: string
  isActive?: boolean
}

export const StepHeader: React.FC<Props> = ({ step, title, isActive }) => (
  <HStack spacing="4">
    <Center
      w="9"
      h="9"
      bgColor={isActive ? 'peach.500' : 'gray.290'}
      borderRadius="full"
      transitionDuration="normal"
      transitionProperty="common"
    >
      <Heading size="md" color="white">
        {step}
      </Heading>
    </Center>
    <Heading
      color={isActive ? 'primary.500' : 'gray.290'}
      size="lg"
      transitionDuration="normal"
      transitionProperty="common"
    >
      {title}
    </Heading>
  </HStack>
)
