import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import {
  getColor,
  lighten,
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'
import { darken, shade, rgba } from 'polished'
import colors from '../tenants/esoteriq/foundations/colors'

const baseStyle: PartsStyleObject<typeof parts> = {
  field: {
    color: 'gray.700',
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    transitionTimingFunction: 'ease-out',
    _placeholder: {
      color: 'gray.300',
    },
  },
  addon: {
    bg: 'transparent',
  },
}

export const size: { [key: string]: SystemStyleObject } = {
  lg: {
    fontSize: 'lg',
    px: 4,
    h: 12,
    borderRadius: 'md',
  },

  md: {
    fontSize: { base: '16px', md: 'md' },
    px: '3',
    h: { base: '2.75rem', md: '3.625rem' },
    minH: { base: '2.75rem', md: '3.625rem' },
    borderRadius: 'lg',
  },

  thin: {
    fontSize: { base: '16px', md: 'md' },
    px: '5',
    h: '2.75rem',
    minH: '2.75rem',
    borderRadius: 'lg',
  },

  sm: {
    fontSize: { base: '16px', md: 'sm' },
    px: '3',
    h: '2.75rem',
    minH: '2.75rem',
    borderRadius: 'lg',
  },

  xs: {
    fontSize: 'xs',
    px: 2,
    h: 6,
    borderRadius: 'sm',
  },
}

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
}

const variantOutline: PartsStyleFunction<typeof parts> = (props) => {
  const { theme, isInvalid } = props
  const borderColor = isInvalid ? 'red.400' : 'primary.300'
  return {
    field: {
      border: '1px solid',
      borderColor: 'teal.200',
      bg: props.colorMode === 'dark' ? 'gray.700' : 'white',
      color: props.colorMode === 'dark' ? 'white' : undefined,
      _hover: {
        borderColor: lighten('teal.200', 5),
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: 'red.400',
      },
      _focus: {
        zIndex: 1,
        borderColor,
        boxShadow: `0 0 0 1px ${getColor(theme, borderColor) as string}`,
      },
    },
    addon: {
      bg: 'transparent',
    },
  }
}

const variantFilled: PartsStyleFunction<typeof parts> = (props) => {
  const { isInvalid, colorMode } = props
  const normalBorderColor = colorMode === 'light' ? 'primary.300' : 'primary.600'
  const borderColor = isInvalid ? 'red.400' : normalBorderColor
  const color = colorMode === 'light' ? 'gray.800' : 'white'
  return {
    group: {
      color,
    },
    field: {
      bg: colorMode === 'dark' ? shade(0.45, colors.primary[700]) : 'gray.100',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.200',
      // bg: colorMode === 'dark' ? 'gray.650' : 'gray.800',
      boxShadow: '0 0 0 0 transparent',
      color,
      _hover: {
        borderColor,
        bg: props.colorMode === 'dark' ? darken(0.1, colors.primary[700]) : 'gray.250',
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: 'red.400',
      },
      _focus: {
        bg: props.colorMode === 'dark' ? 'gray.800' : 'gray.250',
        borderColor: rgba(colors.primary[500], 0.4),
        borderWidth: '1px',
        boxShadow: `0 0 28px 2px ${rgba(colors.primary[500], 0.06)}`,
      },
    },
    element: {
      color
    },
    addon: {
      bg: 'transparent',
      color,
    },
  }
}

const variants = {
  filled: variantFilled,
  outline: variantOutline,
}

const Input = {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    variant: 'filled',
  },
}

export default Input
