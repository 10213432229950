import colors from './colors'
import fonts from './fonts'
import fontSizes from './fontSizes'
import fontWeights from './fontWeights'
import radii from './radius'

const foundations = {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  radii,
}

export default foundations
