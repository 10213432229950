import axios, { AxiosRequestConfig } from 'axios'
import { FileTypeEnum, useFileUploadS3PresignMutation } from 'src/generated/graphql-frontend'
import { fixRotationOfFile } from './helpers'

export const useUploadFile = () => {
  const [fileUploadS3Presign] = useFileUploadS3PresignMutation()
  return async ({
    file,
    setProgress,
    setPreviewMediaURL,
    isPublic = false,
    onUploadStart,
    onUploadFinished,
    onError,
  }: {
    file: File
    setProgress?: (progress: number) => void
    onError?: (error: Error) => void
    onUploadStart?: () => void
    onUploadFinished?: (key: string) => void
    setPreviewMediaURL?: (url: string) => void
    isPublic?: boolean
  }) => {
    const getFileType = (): FileTypeEnum => {
      if (file.type.startsWith('image')) {
        return FileTypeEnum.Image
      }
      return file.type.startsWith('video') ? FileTypeEnum.Video : FileTypeEnum.Document
    }
    if (getFileType() === FileTypeEnum.Image) {
      const blobWithFixedOrientation = await fixRotationOfFile(file)
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e.target?.result) {
          setPreviewMediaURL?.(e.target.result as string)
        }
      }
      reader.readAsDataURL(blobWithFixedOrientation)
    }
    onUploadStart?.()
    const res = await fileUploadS3Presign({
      variables: {
        fileName: file.name,
        fileType: getFileType(),
        contentType: file.type,
        fileSize: file.size,
        isPublic,
      },
    })
    const { signedRequest, key } = res.data?.fileUploadS3Presign || {}
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: ({ loaded, total }) => setProgress?.(loaded / (total ?? 1)),
    }
    try {
      if (signedRequest && key) {
        await axios.put(signedRequest, file, options)
        onUploadFinished?.(key)
        return key
      }
      return ''
    } catch (error) {
      onError?.(error as Error)
      return null
    }
  }
}
