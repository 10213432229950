import { createContext, useContext } from 'react'

interface TimePickContextType {
  chosenDate?: Date
  chosenTime?: Date
  setChosenDate: (date: Date) => void
  setChosenTime: (date: Date) => void
}

export const TimePickContext = createContext<TimePickContextType>({
  chosenDate: undefined,
  chosenTime: undefined,
  setChosenDate: () => {},
  setChosenTime: () => {}
})

export const TimePickProvider = TimePickContext.Provider

export const useTimePickContext = () => useContext(TimePickContext)
