import { Box, Button, chakra, useBreakpointValue } from '@chakra-ui/react'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import React from 'react'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi'
import { useIsDarkMode } from 'utils/hooks'
import { getColorModeValue } from 'utils/theme/helpers'
import Link from '../Link'

const CustomButton = chakra(Button, {
  baseStyle: {
    py: '0',
    px: '0',
    fontSize: { base: 'sm', md: 'md' },
    fontWeight: 'medium',
    borderRadius: 'lg',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: { base: 10, md: 12 },
    height: { base: 10, md: 12 },
    bg: getColorModeValue('#ebf1ff', 'primary.700'),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'whiteAlpha.300',
    _disabled: {
      bg: getColorModeValue('gray.200', 'gray.800'),
      borderColor: 'transparent',
      color: 'gray.500',
      cursor: 'not-allowed',
    },
  },
})

const THREE_DOTS_LEFT = -1
const THREE_DOTS_RIGHT = -2

interface PaginationButtonProps {
  maxNumOfButtons: number
  firstPage: number
  lastPage: number
  currentPage: number
}

interface PaginationProps {
  firstPage?: number
  lastPage: number
  currentPage: number
  onPageChange?: (nextPage: number) => void
  onGeneratePageLink?: (nextPage: number) => string
}

type ButtonProps =
  | {
      as: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>>
      href: string
      style: { textDecoration: string }
    }
  | { onClick: () => void }

const getPaginationButtons = ({
  maxNumOfButtons,
  firstPage,
  lastPage,
  currentPage,
}: PaginationButtonProps) => {
  if (maxNumOfButtons % 2 === 0) {
    throw Error('maxNumOfButtons must be an odd number')
  }
  if (maxNumOfButtons <= 3) {
    throw Error('maxNumOfButtons must be more than 3')
  }
  const numOfPages = lastPage - firstPage + 1
  const pages = Array.from({ length: numOfPages }).map((_, index) => firstPage + index)
  if (numOfPages <= maxNumOfButtons) {
    return pages
  }
  const numOfButtons = Math.min(lastPage - firstPage + 1, maxNumOfButtons)
  return Array.from({ length: numOfButtons - 1 })
    .reduce(
      (acc: number[], _, index) => {
        const getPage = (koef: number) => koef + acc[koef === 1 ? acc.length - 1 : 0]
        let koef = index % 2 !== 0 ? 1 : -1
        if (getPage(koef) > lastPage || getPage(koef) < firstPage) {
          koef *= -1
        }
        const page = getPage(koef)
        return koef === 1 ? [...acc, page] : [page, ...acc]
      },
      [currentPage]
    )
    .slice(1, numOfButtons - 1)
    .reduce((acc: Array<number>, page, index, arr) => {
      if (index === 0) {
        return [firstPage, firstPage + 1 === page ? page : THREE_DOTS_LEFT]
      }
      if (index === arr.length - 1) {
        return [...acc, lastPage - 1 === page ? page : THREE_DOTS_RIGHT, lastPage]
      }
      return [...acc, page]
    }, [])
}

export const Pagination = ({
  firstPage = 1,
  lastPage,
  currentPage,
  onPageChange,
  onGeneratePageLink,
}: PaginationProps) => {
  const [ref] = useAutoAnimate()
  const isDarkMode = useIsDarkMode()
  const previousPage = currentPage - 1
  const nextPage = currentPage + 1
  const handlePrevClick = React.useCallback(() => {
    onPageChange?.(previousPage)
  }, [onPageChange, previousPage])
  const handleNextClick = React.useCallback(() => {
    onPageChange?.(nextPage)
  }, [onPageChange, nextPage])
  const handlePageChange = React.useCallback(
    (page: number) => {
      onPageChange?.(page)
    },
    [onPageChange]
  )

  const MAX_NUMBER_OF_BUTTONS = useBreakpointValue({ base: 5, md: 7 }) || 7

  const isPrevButtonDisabled = currentPage <= firstPage
  const isNextButtonDisabled = currentPage >= lastPage

  const prevButtonProps = React.useMemo(
    () =>
      onGeneratePageLink
        ? {
            as: isPrevButtonDisabled ? 'button' : Link,
            href: onGeneratePageLink(previousPage),
            style: { textDecoration: 'none' },
          }
        : { onClick: handlePrevClick },
    [handlePrevClick, onGeneratePageLink, previousPage, isPrevButtonDisabled]
  ) as ButtonProps

  const nextButtonProps = React.useMemo(
    () =>
      onGeneratePageLink
        ? {
            as: isNextButtonDisabled ? 'button' : Link,
            href: onGeneratePageLink(nextPage),
            style: { textDecoration: 'none' },
          }
        : { onClick: handleNextClick },
    [handleNextClick, onGeneratePageLink, nextPage, isNextButtonDisabled]
  ) as ButtonProps

  return (
    <Box
      ref={ref}
      display="grid"
      gridAutoFlow="column"
      justifyContent="center"
      alignItems="center"
      gridGap="2"
    >
      <CustomButton {...prevButtonProps} disabled={isPrevButtonDisabled} aria-label="prev">
        <HiOutlineChevronLeft />
      </CustomButton>
      {getPaginationButtons({
        maxNumOfButtons: MAX_NUMBER_OF_BUTTONS,
        firstPage,
        lastPage,
        currentPage,
      }).map((pageNumber) => {
        if ([THREE_DOTS_LEFT, THREE_DOTS_RIGHT].includes(pageNumber)) {
          return (
            <Box padding="0.5" fontSize="smaller" key={pageNumber}>
              ...
            </Box>
          )
        }
        const buttonProps = (
          onGeneratePageLink
            ? { as: Link, href: onGeneratePageLink(pageNumber), style: { textDecoration: 'none' } }
            : { onClick: () => handlePageChange(pageNumber) }
        ) as ButtonProps

        return (
          <CustomButton
            {...buttonProps}
            key={pageNumber}
            {...(isDarkMode
              ? {
                  bgColor: pageNumber === currentPage ? 'primary.500' : 'primary.700',
                  color: pageNumber === currentPage ? 'whiteAlpha.800' : 'whiteAlpha.650',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: pageNumber === currentPage ? 'transparent' : 'whiteAlpha.300',
                }
              : {
                  bgColor: pageNumber === currentPage ? 'primary.500' : undefined,
                  color: pageNumber === currentPage ? 'gray.200' : 'primary.500',
                })}
          >
            <span>{pageNumber}</span>
          </CustomButton>
        )
      })}
      <CustomButton {...nextButtonProps} disabled={isNextButtonDisabled} aria-label="next">
        <HiOutlineChevronRight />
      </CustomButton>
    </Box>
  )
}
