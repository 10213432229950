import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

export const AdditionalRowContent: React.FC<
  { children: React.ReactNode; hasTriangle?: boolean } & BoxProps
> = ({ hasTriangle, children, ...rest }) => (
  <Box
    gridColumn="1/-1"
    mt="3"
    py="3"
    px="4"
    bg="gray.250"
    borderRadius="lg"
    fontSize={{ base: 'sm', xl: '1rem' }}
    position="relative"
    _after={
      hasTriangle
        ? {
            position: 'absolute',
            content: '""',
            display: 'block',
            width: 4,
            height: 4,
            left: 4,
            top: -2,
            transform: 'rotateZ(45deg)',
            bg: 'inherit',
          }
        : undefined
    }
    {...rest}
  >
    {children}
  </Box>
)
