import * as yup from 'yup'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import {ObjectSchema} from 'yup'

export const useYupValidationResolver = <T extends object | null | undefined = object | undefined, C = object>(validationSchema: ObjectSchema<T | undefined, C>) => {
  const { t } = useTranslation('common')
  return React.useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        }) as T

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        if (errors instanceof yup.ValidationError) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path!]: {
                  type: currentError.type ?? 'validation',
                  message: t(currentError.message),
                },
              }),
              {}
            ),
          } as {
            values: object
            errors: {
              [key: string]: {
                message: string
                key: string
              }
            }
          }
        }
        return {
          values: {},
          errors: {},
        }
      }
    },
    [validationSchema]
  )
}
