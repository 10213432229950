import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Controller, Control } from 'react-hook-form'
import { Text } from '@chakra-ui/react'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { Section } from 'components/BackOffice/components'
import { AmountInput } from 'components/AmountInput'


const shopTransactionFee = process.env.NEXT_PUBLIC_SHOP_TRANSACTION_FEE

interface Props {
  currency: string
  innerRef?: React.RefObject<HTMLDivElement>
  hasOptions?: boolean
  control: Control<ShopProductInput>
}

export const Price: React.FC<Props> = ({ currency, hasOptions, control }) => {
  const { t } = useTranslation('backOffice')

  return (
    <Section
      title={t('Product.priceSectionTitle')}
      // TODO: Set fee percentage from profile config
      description={t('Product.priceSectionDescription', { shopTransactionFee })}
    >
      {hasOptions ? (
        <Text>
          {t('Product.priceSetThePriceForEachOption')}
        </Text>
      ) : (
        <Controller
          control={control}
          name="amountCents"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <AmountInput
              width="full"
              alignSelf="stretch"
              textAlign="center"
              fontSize="xl"
              fontWeight="medium"
              borderRadius="2xl"
              variant="outline"
              maxW="20rem"
              currencyName={currency}
              errorMessage={error?.message}
              {...field}
            />
          )}
        />
      )}
    </Section>
  )
}
