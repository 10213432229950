import { ReactSelectProps } from 'components/ReactSelect'
import { padStart } from 'lodash'
import { Control, FieldPath, FieldValues } from 'react-hook-form'
import { ReactSelectController, ReactSelectControllerProps } from '../../SlotsSettings/ReactSelectController'

export const times = Array.from({ length: 24 * 4 }, (_, i) => {
  const value = i * 15
  const label = `${padStart(Math.floor(value / 60).toString(), 2, '0')}:${padStart(
    (value % 60).toString(),
    2,
    '0'
  )}`
  return { value: value, label }
})

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends ReactSelectProps, ReactSelectControllerProps<TFieldValues, TName> {
  name: TName
  control: Control<TFieldValues>
  requiredMessage?: string
}

export const TimeSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  requiredMessage,
  isRequired,
  ...rest
}: Props<TFieldValues, TName>) => (
  <ReactSelectController
    name={name}
    control={control}
    options={times}
    // rules={isRequired ? { required: requiredMessage } : undefined}
    {...rest}
  />
)
