import UAParser from 'ua-parser-js'

export const getBrowserInfo = () => {
  if (!process.browser) {
    return null
  }

  const parser = new UAParser()
  const userOs = parser.getOS()
  const userDevice = parser.getDevice()
  const userBrowser = parser.getBrowser()
  const browserWindowResolution: number[] = []
  const screenResolution: number[] = []
  let pixelRatio: number | undefined = undefined

  if (window) {
    if (window.screen) {
      screenResolution[0] = window.screen.width
      screenResolution[1] = window.screen.height
    }
    browserWindowResolution[0] = window.innerWidth
    browserWindowResolution[1] = window.innerHeight

    pixelRatio = window.devicePixelRatio
  }

  return {
    browserName: userBrowser?.name,
    browserVersion: userBrowser?.version,
    osName: userOs?.name,
    osVersion: userOs?.version,
    deviceModel: userDevice?.model,
    deviceType: userDevice?.type,
    deviceVendor: userDevice?.vendor,
    browserWindowResolution,
    screenResolution,
    pixelRatio,
    language: navigator.language,
    languages: navigator.languages as string[],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
}
