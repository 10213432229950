import React from 'react'
import {
  Box,
  HStack,
  IconProps,
  Text,
  useColorModeValue,
  useRadio,
  useRadioGroup,
  useTheme,
} from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { getColorModeValue } from 'utils/theme/helpers'

const commonTransition = {
  transitionDuration: 'fast',
  transitionProperty: 'opacity, border-color, box-shadow',
}

const shadow = {
  base: '0px 0px 30px rgba(132, 132, 132, 0.14)',
  checked: '0px 0px 34px rgba(132, 132, 132, 0.22)',
}

interface Props extends ReturnType<ReturnType<typeof useRadioGroup>['getRadioProps']> {
  icon: React.ReactElement<IconProps>
  groupHasValue?: boolean
  colorScheme?: string | null
  isEmbed?: boolean
  isBreakpoint?: boolean
}

export const RadioOption: React.FC<Props> = ({
  icon,
  groupHasValue,
  isEmbed,
  isBreakpoint,
  children,
  colorScheme,
  ...props
}) => {
  const theme = useTheme()
  const {
    getInputProps,
    getCheckboxProps,
    state: { isChecked },
  } = useRadio(props)

  const bg = useColorModeValue('white', 'gray.500')
  const input = getInputProps()
  const checkbox = getCheckboxProps()
  const getResponsiveValue = React.useCallback(
    (value: { base: any; md: any }) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      isEmbed || isBreakpoint ? value.base : value.md,
    [isEmbed, isBreakpoint]
  )
  return (
    <Box as="label" flex="auto">
      <input {...input} />
      <Box
        {...checkbox}
        display="flex"
        flexDir={getResponsiveValue({ base: 'row-reverse', md: 'row' })}
        alignItems="center"
        justifyContent={getResponsiveValue({
          base: 'space-between',
          md: 'flex-start',
        })}
        pl="4"
        pr={getResponsiveValue({ base: '6', md: '4' })}
        py={getResponsiveValue({ base: '3', md: '5' })}
        fontSize={getResponsiveValue({ base: 'sm', md: 'md' })}
        bg={getResponsiveValue({ base: getColorModeValue('gray.200', 'gray.100'), md: bg })}
        color={getResponsiveValue({
          base: colorScheme || 'primary.600',
          md: colorScheme || 'primary.500',
        })}
        borderWidth="1px"
        borderRadius="xl"
        borderColor="transparent"
        cursor="pointer"
        userSelect="none"
        boxShadow={getResponsiveValue({
          base: 'none',
          md: `0 0 0 1px transparent, ${isChecked ? shadow.checked : shadow.base}`,
        })}
        {...commonTransition}
        {...(isChecked
          ? {
              bg: bg,
              color: colorScheme || 'primary.500',
              borderColor: 'currentColor',
              boxShadow: getResponsiveValue({
                base: 'none',
                md: `0 0 0 1px transparent, ${shadow.checked}`,
              }),
            }
          : groupHasValue && {
              bg: getResponsiveValue({ base: 'gray.250', md: bg }),
              color: 'gray.300',
              borderColor: getResponsiveValue({
                base: 'currentColor',
                md: 'transparent',
              }),
            })}
        _focus={{
          boxShadow: getResponsiveValue({
            base: 'none',
            md: `0 0 0 1px ${getColor(theme, 'primary.500') as string}, ${
              isChecked ? shadow.checked : shadow.base
            }`,
          }),
        }}
        __css={{
          WebkitTapHighlightColor: 'rgba(19, 52, 131, 0.25)',
        }}
      >
        {React.cloneElement(icon, { width: '5', height: '5', mr: '2.5' })}
        <HStack alignItems="center" spacing="0">
          <Box
            display={getResponsiveValue({ base: 'block', md: 'none' })}
            position="relative"
            flex="none"
            width="4"
            height="4"
            mr="3"
            borderRadius="full"
            borderWidth="1px"
            borderColor="currentColor"
            _after={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate3d(-50%, -50%, 0)',
              content: '""',
              display: 'block',
              bg: colorScheme || 'primary.500',
              width: '2',
              height: '2',
              borderRadius: 'full',
              opacity: isChecked ? 1 : 0,
              ...commonTransition,
            }}
          />
          <Box position="relative">
            <Box
              position="absolute"
              left="0"
              top="0"
              opacity={isChecked ? 0 : 1}
              {...commonTransition}
            >
              {children}
            </Box>
            <Text fontWeight="medium" opacity={isChecked ? 1 : 0} {...commonTransition}>
              {children}
            </Text>
          </Box>
        </HStack>
      </Box>
    </Box>
  )
}
