import React from 'react'
import { Button, Flex, Icon, Stack, Text } from '@chakra-ui/react'
import { FaEye } from 'react-icons/fa'
import { AiOutlineEdit } from 'react-icons/ai'
import { TextTag } from 'components/TextTag'
import Link from 'next/link'
import { getRoute } from 'utils/helpers'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  id: string
  isEnabled?: boolean
}

export const ManageProduct: React.FC<Props> = ({ id, isEnabled }) => {
  const { t } = useTranslation('username')
  return (
    <Flex
      p="4"
      my="4"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="lg"
      borderColor="gray.300"
      borderWidth="2px"
    >
      <Stack spacing="2">
        <Stack alignItems="center" direction="row">
          <Icon as={FaEye} w="4" h="4" />
          <Text fontWeight="medium">{isEnabled ? t('Shop.manageProductLive') : t('Shop.manageProductOnlyVisibleToYou')}</Text>
        </Stack>
        <TextTag>{isEnabled ? t('Shop.manageProductPublished') : t('Shop.manageProductNotPublished')}</TextTag>
      </Stack>
      <Stack direction="row" spacing="2">
        <Button as={Link} href={getRoute('backofficeProduct', id)} leftIcon={<Icon as={AiOutlineEdit} />} variant="outline">
          {t('Shop.manageProductEdit')}
        </Button>
      </Stack>
    </Flex>
  )
}
