import React from 'react'
import { useRouter } from 'next/router'
import { Button, Icon, Stack } from '@chakra-ui/react'
import { getRoute } from 'utils/helpers'
import { ShoppingBagIcon } from 'components/Icons'
import { useShopContext } from './ShopContext'
import useTranslation from 'next-translate/useTranslation'

const buttonProps = {
  h: { base: 16, sm: 'auto' },
  w: { base: 'full', md: 'unset' },
  fontSize: { base: 'md', md: 'unset' },
}

enum AddTypeEnum {
  Cart,
  BuyNow,
}

interface Props {
  productId?: string
  variantId?: string
  hasOptions?: boolean
  username: string
  hasBuyNow?: boolean
}

export const ActionButtons: React.FC<Props> = ({
  productId,
  variantId,
  hasOptions,
  username,
  hasBuyNow,
}) => {
  const { t } = useTranslation('username')
  const [isAddingToCart, setIsAddingToCart] = React.useState<AddTypeEnum | undefined>(undefined)
  const router = useRouter()
  const { addToCart } = useShopContext()
  return (
    <Stack direction={{ base: 'column', sm: 'row' }} gap={{ base: 2, md: 4 }}>
      <Button
        variant={hasBuyNow ? 'outline' : 'solid'}
        {...buttonProps}
        flex={{ base: 'none', sm: '1' }}
        fontSize={{ base: 'unset', sm: 'md' }}
        leftIcon={<Icon as={ShoppingBagIcon} width="8" height="8" color="primary.500" />}
        fontWeight="medium"
        isLoading={isAddingToCart === AddTypeEnum.Cart}
        isDisabled={isAddingToCart === AddTypeEnum.BuyNow}
        onClick={async (event) => {
          event.preventDefault()
          setIsAddingToCart(AddTypeEnum.Cart)
          try {
            await addToCart({ productId, variantId, hasOptions })
          } catch {}
          setIsAddingToCart(undefined)
        }}
      >
        {t('Shop.actionButtonsAddToCart')}
      </Button>
      {hasBuyNow && (
        <Button
          {...buttonProps}
          fontWeight="medium"
          fontSize={{ base: 'none', sm: 'md' }}
          flex={{ base: 'unset', sm: '1' }}
          isLoading={isAddingToCart === AddTypeEnum.BuyNow}
          isDisabled={isAddingToCart === AddTypeEnum.Cart}
          onClick={async (event) => {
            event.preventDefault()
            setIsAddingToCart(AddTypeEnum.BuyNow)
            try {
              const res = await addToCart({ productId, variantId, hasOptions, isBuyNow: true })
              setIsAddingToCart(undefined)
              if (res) {
                void router.push(getRoute('cart', username))
              }
            } catch {
              setIsAddingToCart(undefined)
            }
          }}
        >
          {t('Shop.actionButtonsBuyNow')}
        </Button>
      )}
    </Stack>
  )
}
