import React from 'react'
import { Flex, Stack, Text, Heading } from '@chakra-ui/react'
import { getPrice } from 'utils/payments'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  amountCents: number
  currency: string
}

export const OrderTotal: React.FC<Props> = ({ amountCents, currency }) => {
  const { t } = useTranslation('order')

  return (
    <Flex justifyContent="space-between">
      <Stack spacing="2.5">
        <Text fontWeight="medium">{t('totalIncludingTax')}</Text>
        <Text fontSize="sm" opacity={0.6}>
          {t('includingAllDutiesAndTaxes')}
        </Text>
      </Stack>
      <Heading size="lg">{getPrice(amountCents, currency)}</Heading>
    </Flex>
  )
}
