import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Section } from 'components/BackOffice/components'
import { BorderWrapper } from '../../BorderWrapper'
import { General } from './General'
import { DayAvailability } from './DayAvailability'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { ShopProductInput, SlotSettingsInput } from 'src/generated/graphql-frontend'
import { Flex } from '@chakra-ui/react'
import { DaysCheckboxes } from './components'

interface Props {
  control: Control<ShopProductInput>
  watch: UseFormWatch<ShopProductInput>
  setValue: UseFormSetValue<ShopProductInput>
}

export const CalendarAvailability: React.FC<Props> = ({ control, watch, setValue }) => {
  const { t } = useTranslation('backOffice')
  const daysSlotSettings = watch('BookingTemplateSettings.SlotSettings')
  const [stepIndex, setStepIndex] = React.useState(daysSlotSettings?.length ? 1 : 0)
  
  const days = React.useMemo(
    () =>
      daysSlotSettings
        ?.map(({ dayInWeek }) => dayInWeek)
        .filter((day, index, self) => self.indexOf(day) === index)
        .sort((a, b) => a - b),
    [daysSlotSettings]
  )

  // React.useEffect(() => {
  //   for(let i = 0; i < daysSlotSettings.length; i++) {
  //     const nextSlotSetting = daysSlotSettings[i + 1]
  //     if (!nextSlotSetting) {
  //       break
  //     }
  //     if (daysSlotSettings[i].toTime > nextSlotSetting.fromTime) {
  //       setValue(`BookingTemplateSettings.SlotSettings.${i}.toTime`, nextSlotSetting.fromTime)
  //     }
  //   }
  // }, [daysSlotSettings])

  const handleGeneralAvailabilitySet = (slotSettings: SlotSettingsInput[]) => {
    setValue('BookingTemplateSettings.SlotSettings', slotSettings)
    setStepIndex(1)
  }

  return (
    <Section
      title={t('Product.calendarSectionTitle')}
      description={t('Product.calendarSectionDescription')}
    >
      {stepIndex === 0 ? (
        <BorderWrapper padding="6">
          {stepIndex === 0 && <General onNextStep={handleGeneralAvailabilitySet} />}
        </BorderWrapper>
      ) : (
        <Flex flexDir="column" gap={4}>
          <DaysCheckboxes setValue={setValue} watch={watch} />
          {days?.map((dayInWeek) => (
            <DayAvailability
              key={dayInWeek}
              dayInWeek={dayInWeek}
              control={control}
              watch={watch}
              setValue={setValue}
            />
          ))}
        </Flex>
      )}
    </Section>
  )
}
