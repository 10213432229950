import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'
import { CheckboxProps } from '@chakra-ui/react'
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'

const baseStyleControl: SystemStyleFunction = (props) => {
  const { colorScheme: cs } = props
  const cSplit = cs.split('.')
  const c = cSplit[0]
  const shade = cSplit.slice(1).join('.') || '500'
  return {
    w: '100%',
    transitionProperty: 'box-shadow',
    transitionDuration: 'normal',
    border: '1px solid',
    borderRadius: 'sm',
    borderColor: 'gray.300',
    bg: 'gray.250',

    _checked: {
      bg: `${c}.${shade}`,
      borderColor: `${c}.500`,
      color: 'white',
    },

    _hover: {
      borderColor: `${c}.400`,
      borderWidth: '2px',

      _checked: {
        borderColor: `${c}.500`,
      },
    },

    _active: {
      borderColor: `${c}.500`,
    },
  }
}

const baseStyleLabel: SystemStyleObject = {
  userSelect: 'none',
  _disabled: { opacity: 0.4 },
}

const baseStyleIcon: SystemStyleObject = {
  transitionProperty: 'transform',
  transitionDuration: 'normal',
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  icon: baseStyleIcon,
  control: baseStyleControl(props),
  label: baseStyleLabel,
})

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    control: { w: 4, h: 4, borderRadius: 'base' },
    label: { fontSize: 'sm' },
    icon: { fontSize: '0.65rem', transform: 'scaleX(1)' },
  },
  md: {
    control: { w: 7, h: 7, borderRadius: 'md' },
    label: { fontSize: { base: 'sm', md: 'md' } },
    icon: { fontSize: '0.88rem', transform: 'scaleX(1.15)' },
  },
}

const defaultProps: CheckboxProps = {
  size: 'md',
  colorScheme: 'peach',
}

const Checkbox = {
  parts: parts.keys,
  baseStyle,
  sizes,
  defaultProps,
}

export default Checkbox
