import React from 'react'
import { Button, useDisclosure } from '@chakra-ui/react'
import { AddProductTypeModal } from './AddProductTypeModal'
import { ProductTypesList } from './ProductTypesList'

export const TypesTab = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen}>Add new product type</Button>
      <ProductTypesList />
      <AddProductTypeModal isOpen={isOpen} onClose={onClose} onSuccess={onClose} />
    </>
  )
}
