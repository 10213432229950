import { Box, Button, Grid, Icon, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { AdditionalRowContent } from 'components/DataTable'
import { Label } from 'components/Label'
import { ProfilePicture } from 'components/Merchant'
import { differenceInMinutes, format, isBefore } from 'date-fns'
import useTranslation from 'next-translate/useTranslation'
import React, { useMemo } from 'react'
import { RiPlayFill } from 'react-icons/ri'
import {
  BookingStatus,
  BookingsQuery,
  useApproveRejectTimeSlotMutation,
  useFindMultipleTimeSlotsWithSameOrderMutation,
} from 'src/generated/graphql-frontend'
import { RejectTimeSlotModal } from './RejectTimeSlotModal'
import { TimeSlotField } from './TimeSlotField'
import { TimeSlotStatusIndicator } from './TimeSlotStatusIndicator'
import { MultiPromise } from './types'
import { CurrencyType } from 'utils/payments'
import { MdVideocam } from 'react-icons/md'
import { findLinkInString, getFullName } from 'utils/helpers'

const MINIMUM_MINUTES_TO_JOIN = 30
const MINIMUM_MINUTES_TO_COUNTDOWN = 24 * 60

export type TimeSlotItemType = BookingsQuery['bookings']['bookings'][number]

export const TimeSlotItem: React.FC<
  TimeSlotItemType & { isPersonal: boolean; showDate?: boolean }
> = ({
  id,
  start,
  sessionEnd,
  MerchantProfile,
  ProductVariantOrder,
  // amount,
  ContactInfo,
  meetingDetails,
  status,
  isPersonal,
  isPaidFor,
  showDate,
  // type,
}) => {
  const { t } = useTranslation('backOffice')
  const { isOpen, onToggle } = useDisclosure()
  const [multiPromise, setMultiPromise] = React.useState<MultiPromise>()
  const [now, setNow] = React.useState(new Date())

  const [findMatchingOrders, { data, loading: l1 }] =
    useFindMultipleTimeSlotsWithSameOrderMutation()
  const [approveReject, { loading: l2 }] = useApproveRejectTimeSlotMutation()

  // used for showing loading spinner on the correct action
  const [action, setAction] = React.useState<MultiPromise['action']>()
  const isLoading = l1 || l2

  const handleCancelClose = () => {
    multiPromise?.reject()
    setMultiPromise(undefined)
  }

  const handleApprove = async () => {
    setAction('approve')
    const result = await findMatchingOrders({
      variables: {
        timeSlotId: id,
      },
    })

    if ((result.data?.findMultipleTimeSlotsWithSameOrder.length ?? 0) > 1) {
      try {
        await new Promise<string | undefined>((resolve, reject) => {
          setMultiPromise({ action: 'approve', resolve, reject })
        })
      } catch {
        return
      } finally {
        handleCancelClose()
      }
    }

    await approveReject({
      variables: {
        timeSlotId: id,
        action: 'approve',
      },
    })
  }

  const handleReject = async () => {
    setAction('reject')
    await findMatchingOrders({
      variables: {
        timeSlotId: id,
      },
    })

    try {
      const message = await new Promise<string | undefined>((resolve, reject) => {
        setMultiPromise({ action: 'reject', resolve, reject })
      })

      await approveReject({
        variables: {
          timeSlotId: id,
          action: 'reject',
          message,
        },
      })
    } catch {
      return
    } finally {
      handleCancelClose()
    }
  }

  const meetingLink = React.useMemo(
    () => (meetingDetails ? findLinkInString(meetingDetails) : null),
    [meetingDetails]
  )

  React.useEffect(() => {
    if (meetingLink && differenceInMinutes(start, new Date()) < MINIMUM_MINUTES_TO_COUNTDOWN) {
      const interval = window.setInterval(() => setNow(new Date()), 60_000)
      return () => window.clearInterval(interval)
    }
  }, [start, meetingLink])

  const canJoin = useMemo(
    () =>
      differenceInMinutes(start, now) < MINIMUM_MINUTES_TO_JOIN &&
      isBefore(now, new Date(sessionEnd as string)),
    [sessionEnd, start, now]
  )

  const merchantPicture = MerchantProfile?.profilePicture?.thumbnailSignedUrl
  const startDateTime = new Date(start as string)
  return (
    <React.Fragment key={id}>
      <Stack
        // weird SSR fix
        as="div"
        direction="row"
      >
        <TimeSlotStatusIndicator status={status} type="line" />
        <div>
          {showDate && <Text fontWeight="medium">{format(startDateTime, 'dd. MM.')}</Text>}
          <Text fontWeight="medium">{format(startDateTime, 'HH:mm')}</Text>
          <Text fontSize="sm" color="teal.200">
            {format(new Date(sessionEnd as string), 'HH:mm')}
          </Text>
        </div>
      </Stack>
      <div>
        {ContactInfo && (
          <Text fontWeight="medium">
            {getFullName(ContactInfo)}
          </Text>
        )}
        {ProductVariantOrder && (
          <Text color="teal.200" fontSize="sm">
            {ProductVariantOrder.ProductVariant.name}
          </Text>
        )}
      </div>
      <Stack>
        {canJoin && meetingLink && (
          <Button
            as="a"
            target="_blank"
            href={meetingLink}
            size="sm"
            colorScheme="green"
            leftIcon={<Icon as={MdVideocam} />}
          >
            {t('Bookings.Grid.joinCall')}
          </Button>
        )}
        <Button
          variant="ghost"
          size="sm"
          pr={0}
          onClick={onToggle}
          rightIcon={<Icon as={RiPlayFill} transform={isOpen ? 'rotate(90deg)' : ''} />}
        >
          {t('Bookings.Grid.details')}
        </Button>
      </Stack>
      {isOpen && (
        <AdditionalRowContent
          as={Grid}
          gridColumnGap={{ base: 4, md: 6 }}
          gridRowGap={2}
          gridTemplateColumns={{
            base: '1fr',
            md: '130px 1fr',
          }}
          bgColor="transparent"
          borderColor="teal.200"
          borderRadius="md"
          borderWidth={1}
        >
          <Stack spacing={2}>
            <TimeSlotStatusIndicator status={status} type="text" />
            {!isPersonal && status === BookingStatus.Pending && (
              <Button
                size="sm"
                onClick={handleApprove}
                isLoading={action === 'approve' && isLoading}
              >
                {t('Bookings.Grid.approve')}
              </Button>
            )}
            {!isPersonal && startDateTime > new Date() && (
              <Button
                colorScheme="red"
                size="sm"
                onClick={handleReject}
                isLoading={action === 'reject' && isLoading}
              >
                {t('Bookings.Grid.cancel')}
              </Button>
            )}
          </Stack>
          <Stack spacing={2}>
            <TimeSlotField
              id={id}
              label={t('Bookings.Grid.email')}
              fieldName="email"
              value={ContactInfo?.email}
              placeholder={t('Bookings.Grid.notSet')}
            />
            <TimeSlotField
              id={id}
              label={t('Bookings.Grid.phone')}
              fieldName="phoneNumber"
              value={ContactInfo?.phoneNumber}
              placeholder={t('Bookings.Grid.notSet')}
            />
            <TimeSlotField
              canContainUrls
              id={id}
              label={t('Bookings.Grid.meetingDetails')}
              fieldName={isPersonal ? undefined : 'meetingDetails'}
              value={meetingDetails}
              placeholder={t('Bookings.Grid.notSet')}
            />
            <div>
              <Label>{t('Bookings.Grid.meetingHost')}</Label>
              {merchantPicture ? (
                <Tooltip label={MerchantProfile?.name} placement="top" hasArrow>
                  <Box width="fit-content">
                    <ProfilePicture pictureUrl={merchantPicture} size="xs" />
                  </Box>
                </Tooltip>
              ) : (
                MerchantProfile?.name
              )}
            </div>
          </Stack>
          <RejectTimeSlotModal
            affectedTimeSlots={data?.findMultipleTimeSlotsWithSameOrder}
            multiPromise={multiPromise}
            currency={MerchantProfile?.currency as CurrencyType}
            isOrderAlreadyPaid={!!isPaidFor}
            end={sessionEnd}
            start={start}
            isLoading={isLoading}
          />
        </AdditionalRowContent>
      )}
    </React.Fragment>
  )
}
