import { Button, Icon } from '@chakra-ui/react'
import { ShoppingBagIcon } from 'components/Icons'
import { useShopContext } from './ShopContext'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { getRoute } from 'utils/helpers'

interface Props {
  productId: string
  variantId?: string
  username: string
  startDate?: Date
  endDate?: Date
}

export const BookNowButton: React.FC<Props> = ({ productId, variantId, username, startDate, endDate }) => {
  const { t } = useTranslation('username')
  const router = useRouter()
  const { addToCart } = useShopContext()
  const [isAddingToCart, setIsAddingToCart] = React.useState(false)
  return (
    <Button
      variant="solid"
      colorScheme="primary"
      leftIcon={<Icon as={ShoppingBagIcon} width="8" height="8" color="white" />}
      fontWeight="medium"
      isLoading={isAddingToCart}
      isDisabled={!startDate || !endDate}
      onClick={async (event) => {
        event.preventDefault()
        setIsAddingToCart(true)
        try {
          await addToCart({
            productId,
            variantId,
            timeSlot: {
              startDate,
              endDate,
            },
          })
          void router.push(getRoute('cart', username))
        } catch {}
        setIsAddingToCart(false)
      }}
    >
      {t('Shop.bookNow')}
    </Button>
  )
}
