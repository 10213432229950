import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { oneMegaByte, Upload, UploadFile, UploadType } from 'components/Upload'
import { Section } from 'components/BackOffice/components'
import useTranslation from 'next-translate/useTranslation'

const mbLimit = 10
const pictureLimit = 12

interface Props {
  initialFiles: UploadFile[]
  setValue: UseFormReturn<ShopProductInput>['setValue']
  getValues: UseFormReturn<ShopProductInput>['getValues']
}

export const PresentationMedia: React.FC<Props> = ({ initialFiles, setValue, getValues }) => {
  const { t } = useTranslation('backOffice')
  const handlePictureUploadComplete = React.useCallback(
    (newFile: UploadFile) =>
      newFile.key && setValue('PicturesKeys', [...(getValues('PicturesKeys') || []), newFile.key]),
    []
  )
  const handlePictureRemove = React.useCallback(
    (_, key: string) =>
      setValue('PicturesKeys', getValues('PicturesKeys')?.filter((item) => item !== key) || []),
    []
  )
  return (
    <Section
      title={t('Product.presentationMediaPicturesOfProduct')}
      description={
        <>
          <div>{t('Product.presentationMediaAddPhotosOfTheProduct', { mbLimit })}</div>
          <div>{t('Product.presentationMediaYouCanUploadMaxPictures', { pictureLimit })}</div>
        </>
      }
    >
      <Upload
        multiple
        maxFiles={pictureLimit}
        maxSize={mbLimit * oneMegaByte}
        isPublic
        uploadType={UploadType.Image}
        initialFiles={initialFiles}
        onUploadComplete={handlePictureUploadComplete}
        onItemRemove={handlePictureRemove}
      />
    </Section>
  )
}
