import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { Box, Stack, Icon, IconButton } from '@chakra-ui/react'
import { ProductTypeAttributeInput } from 'src/generated/graphql-frontend'
import { InputWrapper } from 'components/InputWrapper'
import { AttributeTypeDropdown } from './AttributeTypeDropdown'
import { IoMdAddCircle } from 'react-icons/io'

interface Props {
  onNewAttribute(newAttribute: ProductTypeAttributeInput): void
}

export const NewAttributeInput: React.FC<Props> = ({ onNewAttribute }) => {
  const { register, control, reset, handleSubmit } = useForm<ProductTypeAttributeInput>({
    defaultValues: {
      attrId: uuid(),
    },
  })
  const handleFormSubmit = React.useCallback(
    (data: ProductTypeAttributeInput) => {
      onNewAttribute(data)
      reset({
        attrId: uuid(),
        name: '',
        type: undefined,
      })
    },
    [onNewAttribute, reset]
  )
  return (
    <Stack direction="row" spacing="2">
      <InputWrapper
        {...register('name', {
          required: true,
        })}
        flex="1"
        placeholder="Attribute name"
        variant="outline"
        maxW="100%"
        autoComplete="off"
      />
      <Box flex="1">
        <Controller
          name="type"
          control={control}
          rules={{ required: 'Select product type' }}
          render={({ field }) => <AttributeTypeDropdown {...field} />}
        />
      </Box>
      <IconButton
        aria-label="Add attribute"
        variant="ghost"
        px="0"
        icon={<Icon as={IoMdAddCircle} width="6" height="6" />}
        onClick={handleSubmit(handleFormSubmit)}
      />
    </Stack>
  )
}
