import { Box, ColorProps, FlexProps } from '@chakra-ui/react'
import { MotionBox } from 'components/Motion'
import { AnimationDefinition, MotionValue, useSpring, useTransform } from 'framer-motion'
import React from 'react'

interface NavigationDotsProps extends Omit<FlexProps, 'transition'> {
  length: number
  activeIndex: number
  x: MotionValue<number>
  activeDotColor?: ColorProps['color']
  transition?: AnimationDefinition
}

export const NavigationDots: React.FC<NavigationDotsProps> = ({
  length,
  activeIndex,
  x,
  activeDotColor = 'primary.500',
  ...props
}) => {
  const pos = activeIndex * 12
  const xPos = useTransform(x, [-300, 0, 300], [pos + 12, pos, pos - 12])
  return (
    <MotionBox
      // {...props}
      pos="relative"
      display="grid"
      width="fit-content"
      bottom={-8}
      mb={4}
      mx="auto"
      gap={2}
      justifyContent="center"
      gridAutoFlow="column"
      gridGap="0.375rem"
      style={{ opacity: activeIndex === length - 1 ? 0 : 1 }}
    >
      {[...Array(length)].map((_, index) => (
        <Box
          key={index}
          width="6px"
          height="6px"
          borderRadius="50%"
          bg="#DAEBE8"
          transitionProperty="common"
          transitionDuration="normal"
        />
      ))}
      <MotionBox
        position="absolute"
        left="0"
        top="0"
        width="6px"
        height="6px"
        borderRadius="50%"
        bg={activeDotColor}
        style={{
          x: useSpring(xPos, {
            mass: 0.5,
            damping: 30,
            stiffness: 210,
            restSpeed: 0.6,
          }),
        }}
      />
    </MotionBox>
  )
}
