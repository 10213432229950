import React from 'react'
import { Grid } from '@chakra-ui/react'
import { ProfileQueryResult } from 'src/generated/graphql-frontend'
import { ShippingZone } from './ShippingZone'

interface Props {
  profile: NonNullable<NonNullable<ProfileQueryResult['data']>['profile']>
}

export const ShippingZonesList: React.FC<Props> = ({ profile }) => (
  <Grid gap="4" templateColumns={{ base: '1fr', md: 'repeat(auto-fit, minmax(24rem, 1fr))' }}>
    {profile.ShopSettings.ShippingZones.map(({ id, ...shippingZone }) => (
      <ShippingZone key={id} id={id} currency={profile.currency} {...shippingZone} />
    ))}
  </Grid>
)
