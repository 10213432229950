import React from 'react'
import { useRouter } from 'next/router'
import {
  Grid,
  Box,
  Stack,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToast,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { getRoute } from 'utils/helpers'
import { ProfileQueryResult } from 'src/generated/graphql-frontend'
import { MotionBox } from 'components/Motion'
import { Section } from 'components/BackOffice/components'
import { Embed } from 'components/Widget'
import { SupportButton } from 'components/SupportButton'

export const tabStackSpacing = { base: '10', md: '12' }

export interface HashTab {
  key: string
  label: string
  isFullScreen?: boolean
  isHidden?: boolean
  hasTabsWrapper?: boolean
}

export type HashTabs = HashTab[]

const getTabIndex = (defaultTabKey?: string, hashTabs?: HashTabs) => {
  if (!defaultTabKey || !hashTabs) {
    return 0
  }
  return Math.max(
    hashTabs.findIndex(({ key }) => key === defaultTabKey),
    0
  )
}

interface LayoutProps {
  title?: string
  description?: React.ReactNode
  descriptionOpacity?: number
  hashTabs?: HashTabs
  defaultTabKey?: string
  aboveContentComp?: React.ReactNode
  children?: React.ReactNode
  defaultDonationAmount?: number
  profile?: NonNullable<ProfileQueryResult['data']>['profile']
}

export const Layout: React.FC<LayoutProps> = ({
  title,
  description,
  descriptionOpacity = 1,
  hashTabs,
  defaultTabKey,
  profile,
  aboveContentComp,
  defaultDonationAmount,
  children,
}) => {
  const router = useRouter()
  const toast = useToast()
  const isDonationButtonVisible = useBreakpointValue({ base: true, md: false })
  const bg = useColorModeValue('white', isDonationButtonVisible ? 'transparent' : 'gray.900')
  const widgetRef = React.useRef<HTMLDivElement>(null)

  const handleDonationButtonClick = React.useCallback(() => {
    if (widgetRef.current) {
      widgetRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const tabIndex = React.useMemo(() => getTabIndex(defaultTabKey, hashTabs), [defaultTabKey])
  const tabsComp = hashTabs ? (
    <TabPanels>
      {React.Children.map(children, (child) => (
        <TabPanel p="0">
          <Stack alignItems="flex-start" spacing={tabStackSpacing}>
            {aboveContentComp}
            {child}
          </Stack>
        </TabPanel>
      ))}
    </TabPanels>
  ) : (
    <Stack spacing={tabStackSpacing}>{children}</Stack>
  )
  const isFullScreen = Boolean(hashTabs?.[tabIndex]?.isFullScreen)
  const hasTabsWrapper = Boolean(hashTabs?.[tabIndex]?.hasTabsWrapper)

  return (
    <Tabs colorScheme="primary" defaultIndex={tabIndex} isManual isLazy index={tabIndex}>
      <Stack spacing={{ base: '6', md: '12' }}>
        <Section
          title={<Heading size="lg">{title}</Heading>}
          description={description}
          descriptionOpacity={descriptionOpacity}
        >
          {hashTabs && (
            <TabList color="gray.270">
              {hashTabs
                .filter(({ isHidden }) => !isHidden)
                .map(({ key, label }) => {
                  const href = getRoute('profile', profile?.username || '', { tab: key })
                  return (
                    <Tab
                      key={key}
                      as="a"
                      href={href}
                      onClick={(event) => {
                        event.preventDefault()
                        void router.push(href)
                      }}
                    >
                      {label}
                    </Tab>
                  )
                })}
            </TabList>
          )}
        </Section>
      </Stack>

      <Grid
        mt={{ base: '2', md: '8' }}
        gap={{ base: '0', md: '8' }}
        templateColumns={isFullScreen ? 'auto' : { base: '1fr', md: '1fr minmax(auto, 24rem)' }}
        alignItems="start"
      >
        {isFullScreen ? (
          tabsComp
        ) : (
          <>
            {hasTabsWrapper ? (
              <Box
                px={{ base: '0', md: '10' }}
                py="10"
                bg={bg}
                borderRadius="5xl"
                boxShadow={{
                  base: 'none',
                  md: '0px 0px 40px rgba(132, 132, 132, 0.14)',
                }}
              >
                {tabsComp}
              </Box>
              ) : (
              <Box>{tabsComp}</Box>
            )}
            <Box position="relative">
              <MotionBox
                ref={widgetRef}
                flex="none"
                bg={bg}
                w="full"
                minH="md"
                borderRadius="5xl"
                boxShadow="0px 0px 40px rgba(132, 132, 132, 0.14)"
                overflow="hidden"
                // transition={{
                //   type: 'spring',
                //   damping: 80,
                //   stiffness: 180,
                // }}
                initial={{
                  y: 0,
                }}
                // animate={{
                //   y: offset,
                // }}
              >
                {profile && (
                  <Embed
                    profile={profile}
                    defaultDonationAmount={defaultDonationAmount}
                    onPaid={(_, priceWithCurrency) =>
                      toast({
                        status: 'success',
                        title: 'Success',
                        description: `You successfully paid ${priceWithCurrency} to ${profile.name}`,
                        position: 'top-right',
                      })
                    }
                  />
                )}
              </MotionBox>
            </Box>
          </>
        )}
      </Grid>
      <SupportButton
        onClick={handleDonationButtonClick}
        {...(!isDonationButtonVisible && {
          opacity: 0,
          pointerEvents: 'none',
          transform: 'scale(0.5)',
        })}
      />
    </Tabs>
  )
}
