import React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'

export const AttachmentUnavailable: React.FC = () => (
  <Box borderRadius="2xl" p="4" boxShadow="md">
    <Heading size="sm">Attachment unavailable</Heading>
    <Text opacity={0.65} fontSize="sm" pt="4">
      This attachment will become available once your bank transfer payment is approved by the
      merchant.
      <br />
      You will be notified by e-mail once they do.
    </Text>
  </Box>
)
