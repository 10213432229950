import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import {
  DeleteObjectsCommand,
  DeleteObjectsCommandInput,
  GetObjectCommand,
  GetObjectCommandInput,
  ListObjectsV2Command,
  ListObjectsV2CommandInput,
  PutObjectCommand,
  PutObjectCommandInput,
  CopyObjectCommand,
  CopyObjectCommandInput,
  S3Client,
} from '@aws-sdk/client-s3'
import logger from './logger'

const S3ClientInstance = new S3Client({
  apiVersion: 'v4',
  credentials: {
    accessKeyId: process.env.STORAGE_ACCESS_KEY_ID,
    secretAccessKey: process.env.STORAGE_SECRET_ACCESS_KEY,
  },
  region: 'auto',
  forcePathStyle: true,
  endpoint: `https://${process.env.CLOUDFLARE_ACCOUNT_ID}.r2.cloudflarestorage.com`,
})

interface SignObjectParams {
  Bucket: string
  Key: string
  Expires?: number
}

const HOUR_IN_SECONDS = 60 * 60
const DEFAULT_UPLOAD_TIME = 60 * 2 // 2 min

export const signObject = async ({ Bucket, Key, Expires = HOUR_IN_SECONDS }: SignObjectParams) => {
  try {
    const command = new GetObjectCommand({
      Bucket,
      Key,
    })

    return await getSignedUrl(S3ClientInstance, command, {
      expiresIn: Expires,
    })
  } catch {
    return null
  }
}

interface PreSignObjectParams {
  Bucket: string
  Key: string
  Expires?: number
  ContentType: string
}

export const presignObject = async ({
  Bucket,
  Key,
  ContentType,
  Expires = DEFAULT_UPLOAD_TIME,
}: PreSignObjectParams) => {
  try {
    const command = new PutObjectCommand({
      Bucket,
      Key,
      ContentType,
    })
    const uploadUrl = await getSignedUrl(S3ClientInstance, command, {
      expiresIn: Expires,
    })
    return uploadUrl
  } catch (error) {
    console.log(error)
    return null
  }
}

export const storeObject = async ({ Bucket, Key, Body, ...rest }: PutObjectCommandInput) => {
  try {
    const uploadInstance = new PutObjectCommand({
      Bucket,
      Key,
      Body,
      ...rest,
    })

    const result = await S3ClientInstance.send(uploadInstance)
    return result
  } catch (error) {
    logger.error('s3:storeObject', error)
    throw error
  }
}

export const listObjects = async ({ Bucket, ...rest }: ListObjectsV2CommandInput) => {
  try {
    const command = new ListObjectsV2Command({
      Bucket,
      ...rest,
    })

    const result = await S3ClientInstance.send(command)

    return result.Contents
  } catch (error) {
    logger.error('s3:storeObject', error)
    throw error
  }
}

export const getObject = async ({ Bucket, Key, ...rest }: GetObjectCommandInput) => {
  try {
    const command = new GetObjectCommand({
      Bucket,
      Key,
      ...rest,
    })

    const result = await S3ClientInstance.send(command)

    return result.Body
  } catch (error) {
    logger.error('s3:getObject', error)
    throw error
  }
}

interface RemoveObjectsParams extends Omit<DeleteObjectsCommandInput, 'Delete'> {
  DeleteKeys: string[]
}

export const removeObjects = async ({ Bucket, DeleteKeys, ...rest }: RemoveObjectsParams) => {
  try {
    const command = new DeleteObjectsCommand({
      Bucket,
      Delete: {
        Objects: DeleteKeys.map((Key) => ({ Key })),
      },
      ...rest,
    })

    return await S3ClientInstance.send(command)
  } catch (error) {
    logger.error('s3:removeObject', error)
    throw error
  }
}

export const copyObject = async ({ Bucket, CopySource, Key, ...rest }: CopyObjectCommandInput) => {
  try {
    const command = new CopyObjectCommand({
      Bucket,
      Key,
      CopySource,
      ...rest,
    })

    return await S3ClientInstance.send(command)
  } catch (error) {
    logger.error('s3:copyObject', error)
    throw error
  }
}
