import React from 'react'
import { chakra, useDisclosure, Box, Button, Flex, Stack, useToast } from '@chakra-ui/react'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
import copy from 'copy-to-clipboard'
import { FiLink } from 'react-icons/fi'
import { Description } from 'components/Description'
import { InputWrapper } from 'components/InputWrapper'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import useTranslation from 'next-translate/useTranslation'

const ButtonWrapper = chakra(Box, {
  baseStyle: {
    overflow: 'hidden',
    borderRadius: 'lg',
    '> *': {
      display: 'block',
    },
  },
})

interface Props {
  children: React.ReactElement<React.HTMLProps<HTMLButtonElement>>
  url: string
}

const ShareProfileModal: React.FC<Props> = ({ children, url }) => {
  const { t } = useTranslation('merchant')
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleCopyToClipboard = React.useCallback(() => {
    copy(url)
    toast({
      title: t('ShareProfileModal.profileLinkCopied'),
      description: t('ShareProfileModal.profileLinkCopiedToClipboard'),
      variant: 'left-accent',
      isClosable: true,
      position: 'top-right',
      status: 'success',
    })
    onClose()
  }, [toast, onClose, url])

  return (
    <>
      {React.cloneElement<React.HTMLProps<HTMLButtonElement>>(children, { onClick: onOpen })}
      <ModalOrDrawer
        title={t('ShareProfileModal.shareYourProfileLinkVia')}
        isOpen={isOpen}
        onClose={onClose}
        maxW="2xl"
      >
        <Flex alignItems="center" justifyContent="space-between">
          <ButtonWrapper>
            <FacebookShareButton url={url} onClick={() => onClose()}>
              <FacebookIcon />
            </FacebookShareButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <TwitterShareButton url={url} onClick={() => onClose()}>
              <TwitterIcon />
            </TwitterShareButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <WhatsappShareButton url={url} onClick={() => onClose()}>
              <WhatsappIcon />
            </WhatsappShareButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <RedditShareButton url={url} onClick={() => onClose()}>
              <RedditIcon />
            </RedditShareButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <LinkedinShareButton url={url} onClick={() => onClose()}>
              <LinkedinIcon />
            </LinkedinShareButton>
          </ButtonWrapper>
        </Flex>
        <Description mt={{ base: '1.5rem', md: '2rem' }} mb={{ base: '0.5rem', md: '1rem' }}>
          {t('common:OrCopyThisLink')}
        </Description>
        <Stack direction="row">
          <InputWrapper
            flex="1"
            leftIcon={<FiLink />}
            inputLeftElementColor="primary.500"
            isReadOnly
            defaultValue={url}
            onClick={handleCopyToClipboard}
            _hover={{ cursor: 'pointer' }}
          />
          <Button mr={3} onClick={handleCopyToClipboard}>
            {t('ShareProfileModal.copy')}
          </Button>
        </Stack>
      </ModalOrDrawer>
    </>
  )
}

export default ShareProfileModal
