import React from 'react'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  count: number
}

export const SupportersText: React.FC<Props> = ({ count }) => {
  const { t } = useTranslation('merchant')
  let translationKeySuffix = 'other'
  if (count === 0) {
    translationKeySuffix = 'zero'
  } else if (count === 1) {
    translationKeySuffix = 'one'
  } else if (count >= 2 && count <= 4) {
    translationKeySuffix = 'two_four'
  }

  return <>{t(`CommunityCard.supporter_${translationKeySuffix}`, { count })}</>
}
