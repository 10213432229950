import React from 'react'
import { Grid } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useOrderQuery } from 'src/generated/graphql-frontend'
import { Card } from 'components/Card'
import { OrderSummary } from 'components/Shop'
import { LoadingGeneral } from 'components/LoadingGeneral'
import { OrderFulfillment } from './components'

interface Props {
  id: string
}

export const CustomerOrder: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation('backOffice')
  const { data, loading } = useOrderQuery({
    variables: { id },
  })
  if (loading || !data) {
    return <LoadingGeneral loadingText={t('ShopOrder.loadingOrder')} />
  }
  if (!data.order) {
    return <div>{t('ShopOrder.orderNotFound')}</div>
  }
  const { order } = data
  return (
    <Grid
      templateColumns={{ base: '1fr', md: 'auto minmax(auto, 32rem)' }}
      gap="6"
      alignItems="start"
    >
      <Card minW={0}>
        <OrderSummary isMerchant {...order} />
      </Card>
      <Card minW={0}>
        <OrderFulfillment
          key={data.order.Payment?.status}
          orderId={order.id}
          status={order.status}
          trackingUrl={order.Shipping?.trackingUrl}
          showShipping={order.requiresAddress}
        />
      </Card>
    </Grid>
  )
}
