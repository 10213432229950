import { chakra, Box, keyframes } from '@chakra-ui/react'

const pulseGrow = keyframes`
  0% {
    transform: scaleY(0.8) scaleX(0.95);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1.17) scaleX(1.05);
    opacity: 0;
  }
`

export const Glow = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    borderWidth: { base: '3px', md: '5px' },
    borderColor: 'purple.500',
    borderRadius: 'inherit',
    pointerEvents: 'none',
    zIndex: 0,
    animation: `${pulseGrow} 2s infinite`,
  },
})
