import React from 'react'
import { Box, Flex, Grid } from '@chakra-ui/react'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { BorderWrapper } from 'components/BorderWrapper'
import { DataTableProps } from './types'
import { DataTableSkeleton, Header, Line } from './components'

// TODO: Infinite scrolling, sticky header with sorting, filter by name, recurring type, row mounting animation
export const DataTable = <T extends Record<string, any>>({
  alignItems = 'end',
  columnGap = '3',
  data,
  emptyComp,
  footer,
  hasBorder = true,
  headerItems,
  isLoading,
  itemKeyProp,
  renderBeforeItem,
  renderTableRow,
  rowGap = '3',
  shouldRenderLine = true,
  skeletonColumnsCount,
  skeletonRowsCount,
  templateColumns,
}: DataTableProps<T>) => {
  const [ref] = useAutoAnimate<HTMLDivElement>()

  const hasData = !!data && data.length > 0
  return (
    <BorderWrapper maxW="full" hasBorder={hasBorder} emptyComp={emptyComp} isEmpty={!isLoading && !hasData}>
      <Grid
        ref={ref}
        templateColumns={templateColumns}
        gridColumnGap={isLoading ? '8' : columnGap}
        gridRowGap={rowGap}
        alignItems={alignItems}
        fontSize={{ base: 'xs', md: 'md' }}
        maxW="full"
      >
        {hasData ? (
          <>
            {headerItems?.map(({ icon, label, showFrom, isFiller }, index) => (
              <Box
                key={index}
                display={showFrom ? { base: 'none', [showFrom]: 'block' } : undefined}
              >
                {isFiller ? <div /> : <Header icon={icon} label={label} />}
              </Box>
            ))}
            {headerItems && <Line mt="0" borderBottomWidth="2px" />}
            {data.map((item, index) => (
              <React.Fragment key={item[itemKeyProp]}>
                {renderBeforeItem?.(item, data[index - 1])}
                {renderTableRow(item, index)}
                {shouldRenderLine && <Line />}
              </React.Fragment>
            ))}
          </>
        ) : (
          <DataTableSkeleton
            headerItems={headerItems}
            skeletonColumnsCount={skeletonColumnsCount}
            skeletonRowsCount={skeletonRowsCount}
          />
        )}
      </Grid>
      {footer && (
        <Flex justifyContent="center" w="full">
          {footer}
        </Flex>
      )}
    </BorderWrapper>
  )
}
