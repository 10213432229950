import React from 'react'
import { Button, useDisclosure } from '@chakra-ui/react'
import { Layout } from '../components'
import { ProductsTab, OrdersTab, CartsTab, SettingsTab } from './tabs'
import { AddNewProductModal } from './components'
import useTranslation from 'next-translate/useTranslation'

export const Shop = () => {
  const { t } = useTranslation('backOffice')
  const { onClose, onOpen, isOpen } = useDisclosure()
  return (
    <>
      <Layout
        title={t('Product.shop')}
        description={t('Product.sectionDescriptionHereYouCanManageYourProduct')}
        descriptionOpacity={0.6}
        rightComp={<Button onClick={onOpen}>{t('Product.btnAddTitle')}</Button>}
        tabs={[
          t('Product.tabCurrentProducts'),
          t('Product.tabOrders'),
          t('Product.tabCarts'),
          t('Product.tabShippingZones'),
        ].filter(Boolean)}
      >
        <ProductsTab onNewProduct={onOpen} />
        <OrdersTab onNewProduct={onOpen} />
        <CartsTab onNewProduct={onOpen} />
        <SettingsTab />
      </Layout>
      <AddNewProductModal onClose={onClose} isOpen={isOpen} />
    </>
  )
}
