import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import {Button, chakra, Checkbox, Flex, Grid, Stack, Text, Textarea} from '@chakra-ui/react'
import {RefetchQueriesEnum} from 'src/constants'
import {BackofficeProductVariantsQuery, useUpdateProductVariantMutation,} from 'src/generated/graphql-frontend'
import {AmountInput} from 'components/AmountInput'
import {InputWrapper} from 'components/InputWrapper'
import {TextTag} from 'components/TextTag'
import {ImageGridFilePreview, Upload, UploadFile, UploadType} from 'components/Upload'

const RowWrapper = chakra(Grid, {
  baseStyle: {
    gridTemplateColumns: { base: 'auto', md: '1fr 1fr' },
    gap: '4',
  },
})

interface Form {
  name: string
  sku: string
  amountCents: number
  inventoryQuantity?: string | null
  description: string
  PicturesKeys: string[]
}

type BackofficeProductVariant = BackofficeProductVariantsQuery['backofficeProductVariants'][number]

interface Props extends BackofficeProductVariant {
  currency: string
  mainPictures: UploadFile[]
  trackInventory?: boolean
  onClose(): void
}

export const EditForm: React.FC<Props> = ({
  id,
  name,
  sku,
  Price,
  inventoryQuantity,
  description,
  Pictures,
  mainPictures,
  currency,
  trackInventory,
  onClose,
}) => {
  const { t } = useTranslation('backOffice')
  const {
    formState: { errors, isSubmitting },
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      name: name!,
      amountCents: Price?.amount,
      inventoryQuantity: inventoryQuantity?.toString(),
      sku: sku!,
      description: description!,
      PicturesKeys: Pictures?.map(({ key }) => key) ?? [],
    },
  })
  const [hasCustomDescription, setHasCustomDescription] = React.useState(
    Boolean(description?.length)
  )
  const [updateProduct] = useUpdateProductVariantMutation({
    refetchQueries: [RefetchQueriesEnum.BackofficeProductVariants],
  })
  const handleFormSubmit = React.useCallback(
    async (data: Form) => {
      await updateProduct({
        variables: {
          variantId: id,
          inputData: {
            ...data,
            inventoryQuantity: parseInt(data.inventoryQuantity || '0'),
            description: hasCustomDescription ? data.description : null,
          },
        },
      })
      onClose()
    },
    [id, hasCustomDescription, onClose]
  )
  const handlePictureUploadComplete = React.useCallback(
    (newFile: { key?: string }) =>
      newFile.key && setValue('PicturesKeys', [...(getValues('PicturesKeys') || []), newFile.key]),
    []
  )
  const handlePictureRemove = React.useCallback(
    (_, key: string) =>
      setValue('PicturesKeys', getValues('PicturesKeys')?.filter((item) => item !== key) || []),
    []
  )
  const filteredPictures = React.useMemo(
    () => Pictures?.filter(({ key }) => !mainPictures.some(({ key: mainKey }) => mainKey === key)),
    [mainPictures, Pictures]
  )
  const PictureKeys = watch('PicturesKeys')
  return (
    <Stack w="full" spacing="4" as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <RowWrapper>
        <InputWrapper
          {...register('name', { required: t('Product.variantEditFormNameIsRequired') })}
          variant="outline"
          label="Name"
          errorMessage={errors.name?.message}
        />
        <InputWrapper {...register('sku')} variant="outline" label={t('Product.variantEditFormInputLabelSKUOptional')} />
      </RowWrapper>
      <RowWrapper>
        <Controller
          control={control}
          name="amountCents"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <AmountInput
              variant="outline"
              label={t('Product.variantEditFormInputLabelPrice')}
              currencyName={currency}
              errorMessage={error?.message}
              {...field}
            />
          )}
        />
        <InputWrapper
          {...register('inventoryQuantity')}
          variant="outline"
          label={t('Product.variantEditFormInputLabelQuantity')}
          isDisabled={!trackInventory}
        />
      </RowWrapper>
      <div>
        <TextTag pb="2">{t('Product.variantEditFormDescription')}</TextTag>
        <Checkbox
          isChecked={!hasCustomDescription}
          onChange={(event) => setHasCustomDescription(!event.currentTarget.checked)}
        >
          {t('Product.variantEditFormUseMainProductDescription')}
        </Checkbox>
        {hasCustomDescription && (
          <InputWrapper
            {...register('description')}
            as={Textarea}
            variant="outline"
            mt="2"
            maxW="full"
            minH="32"
          />
        )}
      </div>
      <div>
        <TextTag pb="2">{t('Product.variantEditFormProductVariantPictures')}</TextTag>
        <Upload
          multiple
          isPublic
          isCompact
          uploadType={UploadType.Image}
          initialFiles={filteredPictures?.map(({ id, key, name, thumbnailSignedUrl }) => ({
            id,
            key,
            name,
            signedUrl: thumbnailSignedUrl || '',
          }))}
          onUploadComplete={handlePictureUploadComplete}
          onItemRemove={handlePictureRemove}
        />
      </div>
      {mainPictures.length > 0 && (
        <div>
          <TextTag pt="4" pb="2">
            {t('Product.variantEditFormAllProductPictures')}
          </TextTag>
          <Text fontSize="sm" opacity={0.6}>
            {t('Product.variantEditFormYouCanChoosePicture')}
          </Text>
          <Grid gap="3" templateColumns="repeat(auto-fill, minmax(7.5rem, 1fr))">
            {mainPictures.map(({ id, key, name, signedUrl }) => {
              const isPicked = PictureKeys?.includes(key!)
              return (
                <ImageGridFilePreview
                  key={id}
                  previewMediaURL={signedUrl}
                  fileName={name}
                  percentageUploaded={100}
                  isPicked={isPicked}
                  onPick={() =>
                    isPicked
                      ? handlePictureRemove(null, key!)
                      : handlePictureUploadComplete({ key })
                  }
                />
              )
            })}
          </Grid>
        </div>
      )}
      <Flex justifyContent="flex-end">
        <Button variant="outline" mr="2" onClick={() => onClose()}>
          {t('Product.variantEditFormButtonLabelCancel')}
        </Button>
        <Button type="submit" isLoading={isSubmitting}>
          {t('Product.variantEditFormButtonLabelSaveChanges')}
        </Button>
      </Flex>
    </Stack>
  )
}
