import React from 'react'
import omit from 'lodash/omit'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useForm } from 'react-hook-form'
import { useSpring } from 'react-spring'
import * as yup from 'yup'
import { Stack, Heading, Switch, Box, useToast } from '@chakra-ui/react'
import { getRoute } from 'utils/helpers'
import {
  ShopProductInput,
  ShopProductOption,
  useUpdateProductMutation,
  BackofficeProductQuery,
  ProductTypeEnum,
  useUnpublishProductMutation,
} from 'src/generated/graphql-frontend'
import { useYupValidationResolver } from 'hooks/useYupValidationResolver'
import { HeaderStrip, useHeaderHeight } from 'components/Header'
import { Section, tabStackSpacing } from 'components/BackOffice/components'
import {
  Attachments,
  CalendarAvailability,
  Inventory,
  NameDescription,
  PresentationMedia,
  Price,
  ShippableProduct,
  SKU,
  SlotsSettings,
  ThankYouMessage,
  VariantsGrid,
} from './components/Sections'
import { FormHeader, Options, PageSpinner, SaveOptions } from './components'
import useTranslation from 'next-translate/useTranslation'

export const nameValidator = yup.string().nullable().required('errors.productForm.name')

const validationSchema = yup.object({
  name: nameValidator,
  inventoryQuantity: yup
    .number()
    .transform((value: string | number) => (Number.isNaN(value) ? null : value))
    .nullable()
    .when('trackInventory', {
      is: true,
      then: (schema) =>
        schema.when('hasOptions', {
          is: false,
          then: (schema) => schema.required('errors.productForm.quantity'),
        }),
    }),
})

const validationSchemaPublish = yup.object({
  name: nameValidator,
  description: yup.string().nullable().required('errors.productForm.description'),
  inventoryQuantity: yup
    .number()
    .transform((value: string | number) => (Number.isNaN(value) ? null : value))
    .nullable()
    .when('trackInventory', {
      is: true,
      then: (schema) =>
        schema.when('hasOptions', {
          is: false,
          then: (schema) => schema.required('errors.productForm.quantity'),
        }),
    }),
  PicturesKeys: yup.array().min(1, 'errors.productForm.picture'),
  AttachmentsKeys: yup
    .array()
    .of(yup.string())
    .when('type', {
      is: ProductTypeEnum.Download,
      then: (schema) => schema.required().min(1, 'errors.productForm.attachment'),
    }),
})

export const ProductForm: React.FC<{
  backofficeProduct: NonNullable<BackofficeProductQuery['backofficeProduct']>
}> = ({ backofficeProduct }) => {
  const { t } = useTranslation('backOffice')
  const { id } = backofficeProduct
  const toast = useToast()
  const session = useSession()
  const router = useRouter()
  const currency = session.data!.user.profile!.currency
  const shopProductForm = useForm<ShopProductInput>({
    defaultValues: {
      name: backofficeProduct.name ?? undefined,
      description: backofficeProduct.description,
      inventoryQuantity: backofficeProduct.inventoryQuantity,
      sku: backofficeProduct.sku,
      hasOptions: backofficeProduct.hasOptions,
      trackInventory: backofficeProduct.trackInventory,
      requiresAddress: backofficeProduct.requiresAddress,
      PicturesKeys: backofficeProduct.Pictures?.map(({ key }) => key),
      AttachmentsKeys: backofficeProduct.Attachments?.map(({ key }) => key),
      thankYouMessage: backofficeProduct.thankYouMessage,
      amountCents: backofficeProduct.Price?.amount,
      BookingTemplateSettings: backofficeProduct.BookingSettings
        ? {
            ...omit(backofficeProduct.BookingSettings, '__typename'),
            SlotSettings:
              backofficeProduct.BookingSettings.SlotSettings?.map((slot) =>
                omit(slot, '__typename')
              ) ?? [],
          }
        : undefined,
    },
  })
  const { register, watch, setValue, getValues, control } = shopProductForm

  const validator = useYupValidationResolver(validationSchema)
  const validatorPublish = useYupValidationResolver(validationSchemaPublish)
  const currentHeaderHeight = useHeaderHeight()
  const [updateProductMutation, { loading: saving }] = useUpdateProductMutation()
  const [unpublishProduct] = useUnpublishProductMutation({
    onCompleted() {
      void router.push(getRoute('backoffice', 'shop'))
    },
  })

  const handleSave = React.useCallback(async ({ publish, redirect }: SaveOptions = {}) => {
    const { values, errors } = publish
      ? await validatorPublish(getValues())
      : await validator(getValues())
    const errorKeys = Object.keys(errors)

    const errorMessage =
      publish && !session.data?.user.profile?.stripeChargesEnabled
        ? t('Product.errorPublishStripeNotConnected')
        : errorKeys.length
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            errors[errorKeys[0]].message
          : null

    // TODO: If profile doesn't have stripe yet, allow to save draft when pressing Save & Publish, but show error and don't redirect
    if (errorMessage) {
      toast({
        title: errorMessage,
        position: 'top',
        status: 'error',
      })
      return
    }

    const { data } = await updateProductMutation({
      variables: {
        id: backofficeProduct.id,
        inputData: { ...(values as ShopProductInput), active: publish },
      },
    })

    if (data && redirect) {
      await router.push(
        getRoute('profile', session.data!.user.profile!.username, {
          productSlug: data.updateProduct.slug,
          isPreview: !data.updateProduct.isEnabled,
        })
      )
    }
  }, [])

  const productOptionsRef = React.createRef<HTMLDivElement>()
  const [, setY] = useSpring(() => ({ y: 0 }))
  const animateScrollTo = React.useCallback(
    async (y: number, focusNode?: HTMLElement | null) => {
      await Promise.all(
        setY({
          reset: true,
          from: { y: window.scrollY },
          to: {
            y,
          },
          onChange: (props) => window.scrollTo(0, props.value.y - currentHeaderHeight),
          ...(focusNode && { onRest: () => focusNode.focus() }),
        })
      )
    },
    [currentHeaderHeight]
  )
  const productAttributes = backofficeProduct?.AttributeProduct
  const [productOptions, setProductOptions] = React.useState<ShopProductOption[]>([])
  React.useEffect(() => {
    if (!!productAttributes?.length) {
      setProductOptions(productAttributes)
      return
    }
  }, [])

  const optionsKeyRerender = React.useMemo(() => JSON.stringify(productOptions), [productOptions])
  const Pictures = backofficeProduct.Pictures
  const picturesUploadFiles = React.useMemo(
    () =>
      Pictures?.map(({ id, key, name, thumbnailSignedUrl }) => ({
        id,
        key,
        name,
        signedUrl: thumbnailSignedUrl || '',
      })) || [],
    [Pictures]
  )

  const attachments = backofficeProduct.Attachments
  const attachmentsUploadFiles = React.useMemo(
    () =>
      attachments?.map(({ id, key, name, thumbnailSignedUrl }) => ({
        id,
        key,
        name,
        signedUrl: thumbnailSignedUrl || '',
      })) || [],
    [attachments]
  )
  const productType = backofficeProduct.type
  const trackInventory = !!watch('trackInventory')
  const hasOptions = !!watch('hasOptions')

  const updateProductBeforeGeneratingVariants = React.useCallback(async () => {
    await updateProductMutation({
      variables: {
        id: backofficeProduct.id,
        inputData: {
          ...getValues(),
        },
      },
    })
  }, [])

  return (
    <>
      <HeaderStrip isScroll>
        <FormHeader
          name={backofficeProduct.name!}
          type={productType!}
          isEnabled={backofficeProduct.isEnabled}
          slug={backofficeProduct.slug}
          isSaving={saving}
          control={control}
          goBackHref={getRoute('backoffice', 'shop')}
          onSave={handleSave}
          onUnpublish={async () => {
            await unpublishProduct({ variables: { productId: id } })
          }}
        />
      </HeaderStrip>
      {saving && <PageSpinner />}
      <Box filter={saving ? 'blur(2px)' : undefined}>
        <Section
          title={<Heading size="lg">{t('Product.headingEditProduct')}</Heading>}
          descriptionOpacity={0.6}
          description={t('Product.sectionDescriptionHereYouCanManageYourProduct')}
        >
          <Stack spacing={tabStackSpacing}>
            <NameDescription shopProductForm={shopProductForm} />
            <Price currency={currency} hasOptions={hasOptions} control={control} />
            <PresentationMedia
              getValues={getValues}
              initialFiles={picturesUploadFiles}
              setValue={setValue}
            />
            {ProductTypeEnum.Physical === productType && (
              <>
                <SKU register={register} />
                <Inventory register={register} watch={watch} />
                <ShippableProduct control={control} setValue={setValue} />
              </>
            )}
            {ProductTypeEnum.Download === productType && (
              <Attachments
                getValues={getValues}
                initialFiles={attachmentsUploadFiles}
                setValue={setValue}
              />
            )}
            {[ProductTypeEnum.Videocall, ProductTypeEnum.Booking].includes(
              productType as ProductTypeEnum
            ) && (
              <>
                <SlotsSettings control={control} register={register} />
                <CalendarAvailability control={control} watch={watch} setValue={setValue} />
              </>
            )}
            {ProductTypeEnum.Physical === productType && (
              <Section
                innerRef={productOptionsRef}
                title={t('Product.sectionTitleProductOptions')}
                description={t('Product.sectionDescriptionSetUpProductVariants')}
                titleRightComp={<Switch {...register('hasOptions')} />}
              >
                {hasOptions && (
                  <Options
                    key={optionsKeyRerender}
                    productId={id}
                    productOptions={productOptions}
                    onUpdateProduct={updateProductBeforeGeneratingVariants}
                  />
                )}
              </Section>
            )}
            {hasOptions && (
              <Section
                title={t('Product.sectionTitleProductVariants')}
                description={t('Product.sectionDescriptionEditProductVariants')}
              >
                <VariantsGrid
                  productId={id}
                  mainPictures={picturesUploadFiles || []}
                  currency={currency}
                  trackInventory={trackInventory}
                  onGoToOptions={async () => {
                    const ref = productOptionsRef.current
                    await animateScrollTo(
                      ref ? ref.getBoundingClientRect().top + window.scrollY - 32 : window.scrollY,
                      ref?.querySelector('input')
                    )
                  }}
                />
              </Section>
            )}
            <ThankYouMessage register={register} />
          </Stack>
        </Section>
      </Box>
    </>
  )
}
