import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { Section } from 'components/BackOffice/components'
import { Upload, UploadFile, UPLOAD_MIME_TYPES } from 'components/Upload'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  initialFiles: UploadFile[]
  setValue: UseFormReturn<ShopProductInput>['setValue']
  getValues: UseFormReturn<ShopProductInput>['getValues']
}

export const Attachments: React.FC<Props> = ({ initialFiles, setValue, getValues }) => {
  const { t } = useTranslation('backOffice')
  const handleAttachmentUploadComplete = React.useCallback(
    (newFile: UploadFile) =>
      newFile.key &&
      setValue('AttachmentsKeys', [...(getValues('AttachmentsKeys') || []), newFile.key]),
    []
  )
  const handleAttachmentRemove = React.useCallback(
    (_, key: string) =>
      setValue(
        'AttachmentsKeys',
        getValues('AttachmentsKeys')?.filter((item) => item !== key) || []
      ),
    []
  )
  return (
    <Section title={t('Product.inventorySectionTitleAttachments')} description={t('Product.inventorySectionDescriptionAttachments')}>
      <Upload
        multiple
        acceptedMimeTypes={UPLOAD_MIME_TYPES}
        initialFiles={initialFiles}
        onUploadComplete={handleAttachmentUploadComplete}
        onItemRemove={handleAttachmentRemove}
      />
    </Section>
  )
}
