import Alert from './Alert'
import Button from './Button'
import Divider from './Divider'
import Heading from './Heading'
import Tag from './Tag'
import Input from './Input'
import Menu from './Menu'
import Select from './Select'
import Tabs from './Tabs'
import Modal from './Modal'
import Checkbox from './Checkbox'
import Tooltip from './Tooltip'

const components = {
  Alert,
  Button,
  Divider,
  Heading,
  Tag,
  Input,
  Menu,
  Select,
  Tabs,
  Modal,
  Checkbox,
  Tooltip,
}

export default components
