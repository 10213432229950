import React from 'react'
import { UseFormRegister, UseFormWatch } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import { Switch } from '@chakra-ui/react'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { Section } from 'components/BackOffice/components'
import { InputWrapper } from 'components/InputWrapper'

interface Props {
  register: UseFormRegister<ShopProductInput>
  watch: UseFormWatch<ShopProductInput>
}

export const Inventory: React.FC<Props> = ({ watch, register }) => {
  const { t } = useTranslation('backOffice')
  return (
    <Section
      title={t('Product.inventorySectionTitleInventory')}
      description={t('Product.inventorySectionDescription')}
      titleRightComp={<Switch {...register('trackInventory')} />}
    >
      {watch('trackInventory') && !watch('hasOptions') && (
        <InputWrapper
          {...register('inventoryQuantity')}
          inputMode="numeric"
          label={t('Product.inventoryQuantity')}
          variant="outline"
          hint={t('Product.inventoryAmountYouCanSellToCustomers')}
          w="42"
          maxW="full"
        />
      )}
    </Section>
  )
}
