import { VStack, Heading, Text, Center } from '@chakra-ui/react'
import { useHeaderHeight } from 'components/Header'
import { GetStarted } from 'components/Homepage'
import useTranslation from 'next-translate/useTranslation'


const platformName = process.env.NEXT_PUBLIC_APP_NAME

interface Props {
  username: string
}

export const NotFound = ({ username }: Props) => {
  const { t } = useTranslation('username')
  const height = useHeaderHeight()
  return (
    <Center
      position="absolute"
      bg="rgba(255, 255, 255, 0.3)"
      left="0"
      top={`${height}px`}
      width="100vw"
      height={`calc(100vh - ${height}px)`}
      textAlign="center"
      px={4}
    >
      <VStack spacing={6}>
        <VStack spacing={3}>
          <Heading>{t('thisProfileDoesNotExist')}</Heading>
          <Text fontSize={{ base: 'md', md: 'lg' }}>{t('claimAsYoursOnPlantico', { username, platformName })}</Text>
        </VStack>
        <GetStarted />
      </VStack>
    </Center>
  )
}
