import React from 'react'
import NextLink from 'next/link'
import { chakra, Box, Heading, Text, HTMLChakraProps, useColorModeValue } from '@chakra-ui/react'
import { getRoute } from 'utils/helpers'
import { SupportersText } from 'components/SupportersText'
import { TextTag } from '../TextTag'
import { ProfilePicture } from './ProfilePicture'
import { CoverPicture } from '.'
import CardButton from 'components/CardButton'

interface CommunityCardProps extends HTMLChakraProps<'div'> {
  username: string
  name?: string | null
  description: string
  pictureUrl: string
  coverPictureUrl: string
  label: string
  labelColorScheme: string
  supportersCount: number
}

// TODO: Fix applying perspective to the card, so the 3d rotation is actually visible
export function renderSlideCard(children: React.ReactNode) {
  return function renderFunc({ isVisible }: { isVisible: boolean }) {
    return (
      <chakra.div
        display="flex"
        flexDir="column"
        transition="opacity 0.25s ease-out, transform 0.25s ease-out"
        opacity={[1, isVisible ? 1 : 0]}
        pointerEvents={isVisible ? 'initial' : 'none'}
        transform={[null, isVisible ? null : 'rotateX(13deg) scale(0.96)']}
        css="perspective: 400px;"
        minH="full"
      >
        {children}
      </chakra.div>
    )
  }
}

export function CommunityCard({
  id,
  name,
  username,
  description,
  supportersCount,
  label,
  labelColorScheme,
  pictureUrl,
  coverPictureUrl,
  ...props
}: CommunityCardProps) {
  const color = useColorModeValue('primary.700', 'white')
  return (
    <NextLink href={getRoute('profile', username)}>
      {/* <NextLink href={`?modalId=modal-${username}`} as={`/profile/${username}`} scroll={false}> */}
      <CardButton {...props}>
        <CoverPicture
          id={`cover-${username}`}
          height={{ base: '120px', md: '150px' }}
          pictureUrl={coverPictureUrl}
          borderRadius="inherit"
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
        />
        <chakra.div p="6">
          <chakra.div
            mt={{ base: '-4.25rem', md: '-20' }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ProfilePicture id={id} pictureUrl={pictureUrl} size="sm" />
            <chakra.div pt="12">
              <TextTag color={labelColorScheme}>{label}</TextTag>
            </chakra.div>
          </chakra.div>
          <chakra.div display="flex" flexDirection="column" alignItems="flex-start">
            <Heading fontSize="lg" mt={4} color={color}>
              {name}
            </Heading>
            <Box minHeight="3.6rem">
              <Text mt="3" fontSize="sm" lineHeight="tall" noOfLines={2}>
                {description}
              </Text>
            </Box>
            <chakra.div bg="gray.200" borderRadius="md" py="2" px="5" mt="6">
              <Text fontSize="sm" color="gray.400">
                <SupportersText count={supportersCount} />
              </Text>
            </chakra.div>
          </chakra.div>
        </chakra.div>
      </CardButton>
    </NextLink>
  )
}
